import {
  ArrowRightIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { FC } from "react";
import { Image } from "@amenda-components/App";
import { RoundedVariants } from "@amenda-types";

interface Props {
  cardRemove?: boolean;
  disabled?: boolean;
  image: string;
  projectName: string;
  projectNumber: string;
  projectDescription: string;
  enableHighlighting?: boolean;
  onClick?: () => void;
}

export const MiniCard: FC<Props> = ({
  cardRemove,
  disabled,
  image,
  projectName,
  projectNumber,
  projectDescription,
  enableHighlighting = false,
  onClick,
}) => {
  const Icon = cardRemove ? XMarkIcon : ArrowRightIcon;
  return (
    <div className="flex border border-gray-300 hover:bg-gray-50">
      <div className="w-24">
        <Image
          url={image}
          size="pb-5/6"
          magicSize="default"
          rounded={RoundedVariants.none}
          enableHighlighting={enableHighlighting}
        />
      </div>
      <div className="flex flex-1 items-center justify-between overflow-hidden">
        <div className="min-w-0 flex-1 px-4 py-2">
          <p className="overflow-hidden truncate whitespace-nowrap text-left text-base text-gray-900 duration-300 ease-in-out">
            {projectName}
          </p>
          <p className="xxl:text-md truncate text-left text-sm text-gray-500 duration-300 ease-in-out lg:text-sm">
            {projectNumber}
          </p>
          <p className="text-gray-500">{projectDescription}</p>
        </div>
        {onClick && (
          <div className="shrink-0 pr-2">
            {!disabled ? (
              <button
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 ring-2 ring-indigo-500 ring-offset-2 hover:text-gray-500 focus:outline-none"
                disabled={disabled}
                onClick={onClick}
              >
                <span className="sr-only">
                  {cardRemove ? "Remove from list" : "Add to list"}
                </span>
                <Icon className="h-5 w-5" aria-hidden="true" />
              </button>
            ) : (
              <CheckIcon className="h-5 w-5 text-indigo-500" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
