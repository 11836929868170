import {
  CheckIcon,
  CloudArrowUpIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { IconButton, Spinner } from "@amenda-components/App";

import { AggregateOption } from "./common";
import { AggregationPicker } from "./AggregationPicker";
import { FC } from "react";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useDashboardStore } from "@amenda-domains/mutations";

export const AggregationLoader: FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div className="flex w-full justify-center">
      <Spinner spinnerSize="md" />
    </div>
  );
};

const displayIcon = (isLoading: boolean, isEditing: boolean) => {
  if (!isEditing && !isLoading) {
    return PencilIcon;
  }
  return isLoading ? CloudArrowUpIcon : CheckIcon;
};

interface Props {
  isLoading: boolean;
  isEditing: boolean;
  aggregateOptions: AggregateOption[];
  handleSubmit: () => void;
}

export const AggregationPickerWrapper: FC<Props> = ({
  isLoading,
  isEditing,
  aggregateOptions,
  handleSubmit,
}) => {
  const addWidget = useDashboardStore((state) => state.addWidget);

  const handleChange = (option: AggregateOption) => {
    addWidget(option);
  };

  return (
    <div
      className={clsx(
        "absolute right-0 z-50 transition-all duration-500 ease-in-out group-hover:block",
        {
          hidden: !isLoading && !isEditing,
        },
      )}
    >
      <div className="flex flex-row">
        <div id="component-picker" className="m-1 mt-[7px]">
          <Transition
            as="div"
            show={isEditing}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-150"
            leaveTo="opacity-0"
          >
            <AggregationPicker
              options={aggregateOptions}
              onChange={handleChange}
            />
          </Transition>
        </div>
        <IconButton
          Icon={displayIcon(isLoading, isEditing)}
          iconSize={1}
          label="Edit"
          variant="clean"
          className={clsx("m-1", {
            "animate-pulse": isLoading,
            "bg-green-100 hover:bg-green-300": isEditing || isLoading,
          })}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
