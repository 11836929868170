import { FC, ReactNode } from "react";

import { EmptyIndicator } from "@amenda-components/Shared";
import { Option } from "@amenda-types";
import { Select } from "@amenda-components/FormComponents";
import { Tooltip } from "./Tooltip";
import { coloredSelectTheme } from "@amenda-styles/theme";
import { useTranslation } from "react-i18next";

enum ColoredSelectEnum {
  GoodForUse = "good_for_use",
  GoodReference = "good_reference",
  NotRecommended = "not_recommended",
}

export const defaultColoredOptions = [
  {
    value: ColoredSelectEnum.GoodForUse,
    label: "Good for use",
  },
  {
    value: ColoredSelectEnum.GoodReference,
    label: "Good reference",
  },
  {
    value: ColoredSelectEnum.NotRecommended,
    label: "Not recommended",
  },
];

interface Props {
  id?: string;
  value?: ColoredSelectEnum;
  size?: "sm" | "md" | "lg";
}

export const ColoredBadge: FC<Props> = ({ id, value, size = "md" }) => {
  const { badgeCss } = coloredSelectTheme();
  if (!value) return null;

  const option = defaultColoredOptions.find((option) => option.value === value);

  return (
    <Tooltip id={id} message={option?.label}>
      <div
        className={badgeCss({
          value,
          size,
        })}
      />
    </Tooltip>
  );
};

interface ReadOnlyColoredSelectProps {
  value?: ColoredSelectEnum;
  options: Option[];
}

export const ReadOnlyColoredSelect: FC<ReadOnlyColoredSelectProps> = ({
  value,
  options: skipOptions,
}) => {
  const { t } = useTranslation();
  if (!value) {
    return <EmptyIndicator />;
  }

  const option = defaultColoredOptions.find((option) => option.value === value);
  return (
    <div className="flex w-full items-center space-x-1">
      <ColoredBadge value={value} />
      <span>{t(option?.label ?? "")}</span>
    </div>
  );
};

interface ColoredSelectProps {
  error?: string;
  id?: string;
  label?: string;
  options: Option[];
  optional?: ReactNode;
  value?: string;
  disabled?: boolean;
  hideDefault?: boolean;
  className?: string;
  hasMenuOverflow?: boolean;
  showErrorMessage?: boolean;
  optionChildren?: (option: Option, isSelected: boolean) => ReactNode;
  onChange: (option: string) => void;
  onBlur?: () => void;
}

export const ColoredSelect: FC<ColoredSelectProps> = ({
  value,
  options: skipOptions,
  ...rest
}) => {
  const { t } = useTranslation();
  const translatedOptions = defaultColoredOptions.map((o) => ({
    ...o,
    label: t(o.label),
  }));

  return (
    <Select
      selectedOption={value}
      options={translatedOptions}
      optionChildren={(option, selected) => {
        return (
          <div className="flex w-full items-center space-x-1">
            <ColoredBadge size="sm" value={option.value as ColoredSelectEnum} />
            <span>{t(option.label)}</span>
          </div>
        );
      }}
      {...rest}
    />
  );
};
