import { FC } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
}

export const PulseLoader: FC<Props> = ({ className = "" }) => {
  return (
    <div className="amenda-pulse-loader absolute ml-auto pt-3 text-center text-xs leading-4 text-gray-700 opacity-0 group-hover:opacity-100">
      <div className={clsx(className, "border-indigo-500")}></div>
      <div className={clsx(className, "border-indigo-500")}></div>
    </div>
  );
};
