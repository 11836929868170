import { FC, ReactNode } from "react";

import { Transition } from "@headlessui/react";
import { useAppStore } from "@amenda-domains/mutations";

interface Props {
  children: ReactNode;
}

export const LargerScreensSidebar: FC<Props> = ({ children }) => {
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);

  if (!sidebarOpen) return null;
  return (
    <Transition
      as="div"
      appear={true}
      show={sidebarOpen}
      enter="transition-opacity duration-400"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-400"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="right-0 z-50 hidden bg-white lg:flex lg:shrink-0">
        <div className="flex h-screen w-80 flex-col border-l border-gray-200 pb-10 pt-4">
          <div className="h-full w-full overflow-y-auto pb-4">{children}</div>
        </div>
      </div>
    </Transition>
  );
};

export const SmallerScreensSidebar: FC<Props> = ({ children }) => {
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);

  if (!sidebarOpen) return null;
  return (
    <Transition
      appear={true}
      show={sidebarOpen}
      enter="transition-opacity duration-400"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-400"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="z-50 h-screen w-full border-l bg-white pt-20 lg:hidden">
        <div className="h-full overflow-y-auto overscroll-y-contain pb-2">
          {children}
        </div>
      </div>
    </Transition>
  );
};
