import { ActionBar, IconButtonBase, Tooltip } from "@amenda-components/App";
import {
  AllowedAttachmentType,
  AppRoutes,
  GeneralPermissionTypes,
} from "@amenda-types";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import { Share2Icon, XIcon } from "lucide-react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  useAttachmentStore,
  useGenerateAttachmentUrl,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { DeleteModal } from "@amenda-components/Shared";
import { DownloadIcon } from "lucide-react";
import { GeneralAccessTypes } from "@amenda-constants";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ShareModal } from "@amenda-components/Shared/ShareModal";
import { downloadFromUrl } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  isEditing: boolean;
  handleClose: () => void;
}

export const ConstructionDetailActionBar: FC<Props> = ({
  isEditing,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openShareModal, setOpenShareModal] = useState(false);
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { updateAttachment, loading } = useUpdateAttachment();
  const openModal = useAttachmentStore((state) => state.modals.openDeleteModal);
  const setOpenModal = useAttachmentStore((state) => state.setOpenDeleteModal);
  const { generateAttachmentUrl } = useGenerateAttachmentUrl();

  const constructionDetailsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.ConstructionDetails,
  );

  const handleToggleEditing = () => {
    const path = generatePath(
      isEditing
        ? AppRoutes.ConstructionDetailsView
        : AppRoutes.ConstructionDetailsEdit,
      {
        constructionDetailId: selectedAttachment?.id,
      },
    );

    navigate(path);
  };

  const handleDownload = async () => {
    const data = await generateAttachmentUrl({
      notificationMessage: "Downloading pdf...",
      input: {
        bucketname: selectedAttachment?.bucketname,
        filename: selectedAttachment?.filename,
        uploadLocation: "S3",
        type: AllowedAttachmentType.pdf,
      },
    });

    if (data?.url) {
      const filename =
        selectedAttachment?.originalFilename ?? selectedAttachment?.filename;
      downloadFromUrl(data.url, filename);
    }
  };

  const deleteAttachment = async () => {
    await updateAttachment({
      input: {
        _id: selectedAttachment.id,
        isDeleted: true,
      },
    });
    setOpenModal(false);
    handleClose();
  };

  const handleCloseShare = () => setOpenShareModal(false);

  const handleShare = async (type: GeneralAccessTypes, users: any[]) => {
    const share = {
      type,
      users: users.map((u) => ({
        id: u._id,
        role: u[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };

    await updateAttachment({
      input: {
        share,
        _id: selectedAttachment.id,
      },
    });
    setOpenShareModal(false);
  };

  return (
    <>
      <DeleteModal
        title="Delete Construction Detail"
        description="Are you sure you want to delete this construction detail?"
        loading={loading}
        openModal={openModal}
        handleDelete={deleteAttachment}
        handleCloseModal={() => setOpenModal(false)}
      />
      <ShareModal
        title="Share construction detail with"
        loading={loading}
        openModal={openShareModal}
        ownerId={selectedAttachment?.ownerId}
        shareType={selectedAttachment?.share?.type}
        shareWith={selectedAttachment?.share?.users || []}
        onSubmit={handleShare}
        handleClose={handleCloseShare}
      />
      <ActionBar className="sticky top-0 z-20 flex items-center justify-end border-b border-gray-200 bg-white">
        {() => (
          <>
            {!isEditing && (
              <>
                {constructionDetailsGeneralPermissions[
                  GeneralPermissionTypes.Share
                ] && (
                  <Tooltip id="share" message="Share">
                    <IconButtonBase
                      label="Share"
                      onClick={() => setOpenShareModal(true)}
                    >
                      <Share2Icon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
                {constructionDetailsGeneralPermissions[
                  GeneralPermissionTypes.Download
                ] && (
                  <Tooltip id="downloadImage" message="Download Pdf">
                    <IconButtonBase
                      label="Download Pdf"
                      onClick={handleDownload}
                    >
                      <DownloadIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
              </>
            )}
            {constructionDetailsGeneralPermissions[
              GeneralPermissionTypes.Edit
            ] && (
              <Tooltip
                id="toggleEdit"
                className="flex items-center space-x-1"
                message={isEditing ? "Cancel" : "Edit"}
              >
                <IconButtonBase
                  className="flex space-x-1 md:w-auto"
                  variant="primaryAlt"
                  label={isEditing ? "Cancel" : "Edit"}
                  onClick={handleToggleEditing}
                >
                  {isEditing ? (
                    <XIcon className="h-5 w-5" />
                  ) : (
                    <PencilIcon className="h-5 w-5" />
                  )}
                  <span className="hidden leading-none md:block">
                    {t(isEditing ? "Cancel" : "Edit")}
                  </span>
                </IconButtonBase>
              </Tooltip>
            )}
            <IconButtonBase
              className="bg-gray-800 p-1 text-white"
              label="Close Modal"
              onClick={handleClose}
            >
              <XIcon className="h-8 w-8" />
            </IconButtonBase>
          </>
        )}
      </ActionBar>
    </>
  );
};
