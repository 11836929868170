import { FC, ReactNode } from "react";
import { HelperMessage, LoaderWrapper } from "@amenda-components/App";

import { Badges } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { searchBadgeOptions } from "@amenda-constants";
import { useAppStore } from "@amenda-domains/mutations";
import { useNavigate } from "react-router-dom";

interface Props {
  hideNavigationBadges?: boolean;
  startSearch: boolean;
  loading: boolean;
  results: any;
  children?: (props: { projects: any[]; users: any[] }) => ReactNode;
}

export const SearchResults: FC<Props> = ({
  startSearch,
  loading,
  results,
  hideNavigationBadges = true,
  children,
}) => {
  const navigate = useNavigate();
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const handleBadgeClick = (option: any) => {
    toggleSearchModal(false);
    navigate(`/${option[0]}`);
  };

  const users = results?.users || [];
  const projects = results?.projects || [];

  return (
    <div
      className={clsx("flex-1 overflow-y-auto bg-white", {
        "min-h-0 pt-2": startSearch,
      })}
    >
      <div
        className={clsx({
          hidden: !startSearch,
        })}
      >
        {loading ? (
          <LoaderWrapper className="h-28" spinnerSize="sm" />
        ) : (
          <>
            {isEmpty(projects) && isEmpty(users) ? (
              <div className="mt-2">
                <HelperMessage
                  helpText="There are no results for this term"
                  message="No results found"
                  className="h-28"
                />
              </div>
            ) : (
              children?.({
                users,
                projects,
              })
            )}
          </>
        )}
      </div>
      {!hideNavigationBadges && (
        <>
          <div className="mt-2 border-t border-gray-300" />
          <div className="ml-2">
            <Badges
              label=""
              options={searchBadgeOptions}
              onChange={handleBadgeClick}
              selectedOptions={[]}
            />
          </div>
        </>
      )}
    </div>
  );
};
