import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
} from "lucide-react";

import DayJs from "dayjs";
import { FC } from "react";
import { datePickerTheme } from "@amenda-styles/theme";
import { useTranslation } from "react-i18next";

export interface DatePickerTimeComparisonProps {
  minDate?: Date;
  maxDate?: Date;
  startDate?: string;
  endDate?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
}

interface DatePickerYearPickerProps extends DatePickerTimeComparisonProps {
  value?: string;
  year: number;
}

export const getDisabledDates = ({
  date,
  maxDate,
  selectsEnd,
  endDate,
  minDate,
  selectsStart,
  startDate,
}: Omit<DatePickerYearPickerProps, "year"> & { date: Date }) => {
  let isDisabled = false;
  if (minDate) {
    isDisabled = isDisabled || DayJs(date).isBefore(minDate, "day");
  }
  if (maxDate) {
    isDisabled = isDisabled || DayJs(date).isAfter(maxDate, "day");
  }
  if (selectsEnd && startDate) {
    isDisabled = isDisabled || DayJs(date).isBefore(startDate, "day");
  }
  if (selectsStart && endDate) {
    isDisabled = isDisabled || DayJs(date).isAfter(endDate, "day");
  }

  return isDisabled;
};

const getEarlyAndLatestDates = ({
  maxDate,
  selectsEnd,
  endDate,
  minDate,
  selectsStart,
  startDate,
}: Omit<DatePickerYearPickerProps, "year">) => {
  let earliestDate;
  let latestDate;
  if (maxDate) {
    latestDate = DayJs(maxDate);
  } else if (selectsEnd && endDate) {
    latestDate = DayJs(endDate);
  }

  if (minDate) {
    earliestDate = DayJs(minDate);
  } else if (selectsStart && startDate) {
    earliestDate = DayJs(startDate);
  }

  return {
    earliestDate,
    latestDate,
  };
};

export const DatePickerYearPicker: FC<DatePickerYearPickerProps> = ({
  value,
  year,
  ...rest
}) => {
  const { earliestDate, latestDate } = getEarlyAndLatestDates(rest);
  const { yearBtnCss } = datePickerTheme({
    disabled:
      (latestDate && latestDate.year() < year) ||
      (earliestDate && earliestDate.year() > year),
  });

  return (
    <div
      className={yearBtnCss({
        isToday: year === DayJs().year(),
        isSelected: Boolean(value) && year === DayJs(value).year(),
      })}
    >
      {year}
    </div>
  );
};

interface DatePickerMonthPickerProps extends DatePickerTimeComparisonProps {
  value?: string;
  month: number;
  shortMonth: string;
  longMonth: string;
  day: any;
}

export const DatePickerMonthPicker: FC<DatePickerMonthPickerProps> = ({
  value,
  month,
  shortMonth,
  day,
  ...rest
}) => {
  const { yearBtnCss } = datePickerTheme();
  const { earliestDate, latestDate } = getEarlyAndLatestDates(rest);

  return (
    <div
      className={yearBtnCss({
        isToday: DayJs(day).month(month).isSame(DayJs(), "M"),
        isSelected:
          Boolean(value) && DayJs(day).month(month).isSame(DayJs(value), "M"),
        disabled:
          (latestDate && DayJs(day).month(month).isAfter(latestDate, "M")) ||
          (earliestDate && DayJs(day).month(month).isBefore(earliestDate, "M")),
      })}
    >
      {shortMonth}
    </div>
  );
};

interface DatePickerQuarterPickerProps extends DatePickerTimeComparisonProps {
  value?: string;
  quarter: number;
  shortQuarter: string;
}

export const DatePickerQuarterPicker: FC<DatePickerQuarterPickerProps> = ({
  shortQuarter,
}) => {
  return <div>{shortQuarter}</div>;
};

interface Props {
  month?: string;
  year: string;
  showYearPicker: boolean;
  showMonthPicker?: boolean;
  showQuarterPicker?: boolean;
  increaseMonth?: () => void;
  decreaseMonth?: () => void;
  increaseYear: () => void;
  decreaseYear: () => void;
  onYearClick: () => void;
  onMonthClick?: () => void;
}

export const DatePickerHeader: FC<Props> = ({
  month,
  year,
  showYearPicker = false,
  showMonthPicker = false,
  showQuarterPicker = false,
  increaseMonth,
  decreaseMonth,
  increaseYear,
  decreaseYear,
  onYearClick,
  onMonthClick,
}) => {
  const { t } = useTranslation();

  const { headerBtnCss, headerBtnIconCss, headerMonthYearBtnCss } =
    datePickerTheme();

  return (
    <div className="mb-1 flex items-center justify-between rounded-sm border border-gray-300 p-1">
      {showQuarterPicker ? (
        <button type="button" className={headerBtnCss()} onClick={decreaseYear}>
          <span className="sr-only">{t("Previous Month")}</span>
          <ChevronLeftIcon className={headerBtnIconCss()} aria-hidden="true" />
        </button>
      ) : showYearPicker ? (
        <button
          type="button"
          className={headerBtnCss({
            disabled: showMonthPicker,
          })}
          onClick={decreaseYear}
        >
          <span className="sr-only">{t("Previous Year")}</span>
          <ChevronsLeftIcon className={headerBtnIconCss()} aria-hidden="true" />
        </button>
      ) : (
        <button
          type="button"
          className={headerBtnCss({
            disabled: showMonthPicker,
          })}
          onClick={decreaseMonth}
        >
          <span className="sr-only">{t("Previous Month")}</span>
          <ChevronLeftIcon className={headerBtnIconCss()} aria-hidden="true" />
        </button>
      )}
      <div className="flex grow items-center justify-center space-x-2">
        {month && (
          <button className={headerMonthYearBtnCss()} onClick={onMonthClick}>
            {month}
          </button>
        )}
        <button className={headerMonthYearBtnCss()} onClick={onYearClick}>
          {year}
        </button>
      </div>
      {showQuarterPicker ? (
        <button type="button" className={headerBtnCss()} onClick={increaseYear}>
          <span className="sr-only">{t("Next Year")}</span>
          <ChevronRightIcon className={headerBtnIconCss()} aria-hidden="true" />
        </button>
      ) : showYearPicker ? (
        <button
          type="button"
          className={headerBtnCss({
            disabled: showMonthPicker,
          })}
          onClick={increaseYear}
        >
          <span className="sr-only">{t("Next Year")}</span>
          <ChevronsRightIcon
            className={headerBtnIconCss()}
            aria-hidden="true"
          />
        </button>
      ) : (
        <button
          type="button"
          className={headerBtnCss({
            disabled: showMonthPicker,
          })}
          onClick={increaseMonth}
        >
          <span className="sr-only">{t("Next Month")}</span>
          <ChevronRightIcon className={headerBtnIconCss()} aria-hidden="true" />
        </button>
      )}
    </div>
  );
};
