import {
  ChangeEvent,
  FC,
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useRef,
} from "react";

import clsx from "clsx";
import { getIndicatorColor } from "./common";

interface Props {
  range: number;
  label?: string;
  min?: number;
  mini?: boolean;
  className?: string;
  children?: (props: { range: number }) => ReactNode;
  onChange: (value: number) => void;
}

type SliderProps = Pick<Props, "range" | "children" | "className"> & {
  ref: RefObject<HTMLInputElement>;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const DefaultSlider = forwardRef<any, SliderProps>(
  ({ range, children, className, handleChange }, ref) => {
    const formattedRange = Number(range).toFixed();

    return (
      <div className={clsx("flex flex-col items-center", className)}>
        <div className="flex w-full items-center space-x-1">
          <span className="font-apercu text-base">0</span>
          <input
            ref={ref}
            type="range"
            value={range}
            className={clsx(
              "range-lg amenda-range-slider h-2 w-full appearance-none rounded-lg bg-gray-300",
              getIndicatorColor(range),
            )}
            onChange={handleChange}
          />
          <span className="font-apercu text-base">100</span>
        </div>
        {children && children({ range: Number(formattedRange) })}
      </div>
    );
  },
);

const MiniSlider = forwardRef<any, SliderProps>(
  ({ range, children, className, handleChange }, ref) => {
    const formattedRange = Number(range).toFixed();

    return (
      <div className={clsx("flex flex-col items-center", className)}>
        <div className="flex w-full items-center space-x-1">
          <span className="font-apercu text-xs">0</span>
          <input
            ref={ref}
            type="range"
            value={range}
            className={clsx(
              "range-lg amenda-range-slider mini h-2 w-full appearance-none rounded-lg bg-gray-300",
              getIndicatorColor(range),
            )}
            onChange={handleChange}
          />
          <span className="font-apercu text-xs">{formattedRange}%</span>
        </div>
        {children && children({ range: Number(formattedRange) })}
      </div>
    );
  },
);

export const SimilarityRangeSlider: FC<Props> = ({
  range,
  children,
  className,
  min = 0,
  mini = false,
  onChange,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value >= min) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (ref.current) {
      const w = (ref.current.offsetWidth * range) / 100;
      ref.current.style.boxShadow = `inset ${w}px 0 currentColor`;
    }
  }, [range]);

  if (mini) {
    return (
      <MiniSlider
        ref={ref}
        range={range}
        className={className}
        handleChange={handleChange}
      >
        {children}
      </MiniSlider>
    );
  }
  return (
    <DefaultSlider
      ref={ref}
      range={range}
      className={className}
      handleChange={handleChange}
    >
      {children}
    </DefaultSlider>
  );
};
