import {
  ActionBar,
  FullScreenModal,
  ModalBaseTitle,
} from "@amenda-components/App";
import {
  ArrowDownTrayIcon,
  FolderIcon,
  LinkIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { FC, ReactNode } from "react";

import { AvailableImageVariants } from "@amenda-types";
import { BCFImage } from "./BCFImage";
import { Topic } from "@parametricos/bcf-js";
import { useTranslation } from "react-i18next";

interface Props {
  openModal: boolean;
  selectedBcfTopic?: Topic;
  setSelectedBcfTopic: (topic?: Topic) => void;
  setOpenModal: (openModal: boolean) => void;
}

interface TitleAndSubtitleProps {
  title: string;
  children: ReactNode;
}

const TitleAndSubtitle: FC<TitleAndSubtitleProps> = ({ title, children }) => {
  const { t } = useTranslation();
  return (
    <div className="mr-10 text-left text-sm">
      <dt className="text-gray-500">{t(title)}</dt>
      <dd className="mt-0.5 text-gray-800">{children}</dd>
    </div>
  );
};

export const ViewSelectedBCF: FC<Props> = ({
  openModal,
  selectedBcfTopic,
  setOpenModal,
  setSelectedBcfTopic,
}) => {
  const handleClose = () => {
    setSelectedBcfTopic(undefined);
    setOpenModal(false);
  };

  const actionBarProperties = [
    {
      icon: LinkIcon,
      label: "Copy Link",
    },
    {
      icon: ArrowDownTrayIcon,
      label: "Download Image",
      variant: "base",
    },
    {
      icon: FolderIcon,
      label: "Move to Folder",
      disabled: true,
    },
    {
      icon: TrashIcon,
      onClick: () => setOpenModal(true),
      label: "Delete Image",
    },
    {
      icon: XMarkIcon,
      label: "Close Modal",
    },
  ];
  return (
    <FullScreenModal isOpen={openModal} onClose={handleClose}>
      <div className="flex min-w-0 flex-1 flex-col justify-between overflow-auto px-2 pt-4 lg:overflow-hidden lg:bg-gray-100 lg:px-5 lg:py-4">
        <div>
          <div className="pb-2 lg:hidden">
            <ActionBar>
              {({ IconButton }) => {
                return actionBarProperties.map((property) => {
                  return (
                    <div key={property.label}>
                      <IconButton
                        Icon={property.icon}
                        label={property.label}
                        disabled={property?.disabled}
                        variant={property?.variant as any}
                        onClick={property?.onClick}
                      />
                    </div>
                  );
                });
              }}
            </ActionBar>
          </div>
          <span className="text-sm text-gray-400">
            {selectedBcfTopic?.markup?.topic.title || ""}
          </span>
          <ModalBaseTitle
            className="mb-2 pt-1 text-xl"
            title={selectedBcfTopic?.markup?.topic.description || ""}
          />
        </div>
        <div className="absolute w-full px-16 py-16 pb-10">
          {selectedBcfTopic && (
            <BCFImage
              bcfTopic={selectedBcfTopic}
              size="pb-6/12"
              magicSize="3xl"
              variant={AvailableImageVariants.scaleDown}
            />
          )}
        </div>
        <div className="z-50 mt-4 flex justify-center py-2">
          <TitleAndSubtitle title="Author">
            {selectedBcfTopic?.markup?.topic.creation_author || ""}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="BCF Index">
            {selectedBcfTopic?.markup?.topic.index}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="Erstellt am">
            {selectedBcfTopic?.markup?.topic?.creation_date?.toLocaleString()}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="Zugewiesen an">
            {selectedBcfTopic?.markup?.topic.assigned_to || "nicht zugewiesen"}
          </TitleAndSubtitle>
        </div>
        <div className="lg:hidden"></div>
      </div>
    </FullScreenModal>
  );
};
