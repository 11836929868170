import { getDefaultURL, isAttachmentPdfOfTypeTemplate } from "@amenda-utils";
import { gql, useMutation, useSubscription } from "urql";
import {
  useAddAttachmentToResource,
  useAuthStore,
  useCreateAttachment,
  useGenerateAttachmentUrl,
  useProjectStore,
  useTemplatesStore,
  useUpsertTemplateData,
} from ".";

import { AvailableNotificationTypes } from "@amenda-types";
import { UID_SIZES } from "@amenda-constants";
import { uid } from "uid";
import { useAppStore } from "@amenda-domains/mutations/app";
import { useEffect } from "react";

const GENERATE_PDF_FROM_URL = gql`
  mutation ($input: UrlToPDFInput!) {
    isGeneratingPdf: generatePDFFromUrl(input: $input)
  }
`;

const GENERATE_PDF_SUBSCRIPTION = gql`
  subscription {
    pdfGenerated {
      mimetype
      filename
      createdAt
      encoding
      tenantId
      url
      bucketname
      resourceId
      previewFilename
      ownerId
      templateDataId
    }
  }
`;

const GENERATE_TEMPLATE_PREVIEW_SUBSCRIPTION = gql`
  subscription {
    templatePreviewGenerated {
      mimetype
      filename
      createdAt
      encoding
      tenantId
      url
      bucketname
      resourceId
      ownerId
    }
  }
`;

export const useGeneratePdf = () => {
  const showNotification = useAppStore((state) => state.showNotification);

  const [result, callGeneratePdf] = useMutation(GENERATE_PDF_FROM_URL);

  const url = getDefaultURL();
  const handleGeneratePdf = ({
    resourceId,
    category,
    filename,
    format,
    options = {},
    path,
  }: {
    resourceId: string;
    category: string;
    filename: string;
    format: string;
    path: string;
    options?: any;
  }) =>
    callGeneratePdf({
      input: {
        url: `${url}/${path}/generate-pdf`,
        options: {
          resourceId,
          category,
          filename,
          format,
          scale: 1,
          ...options,
        },
      },
    })
      .then(({ data }) => {
        if (data.isGeneratingPdf) {
          showNotification(
            AvailableNotificationTypes.Success,
            "PDF wird erzeugt und nach Fertigstellung an das Projekt angehängt...",
          );
        } else {
          throw Error("Failed to generate pdf");
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });

  return {
    loading: result.fetching,
    handleGeneratePdf,
  };
};

// FIXME: Refactor this code when pdf subs will be needed again
// causing errors around updating on unmounted component
// disconnected from subscription wrapper for now
export const useGeneratePdfSubscription = () => {
  const [response] = useSubscription({ query: GENERATE_PDF_SUBSCRIPTION });
  const user = useAuthStore((state) => state.loggedInUser);
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const selectedTemplateData = useTemplatesStore(
    (state) => state.selectedTemplateData,
  );
  const setUserTemplates = useTemplatesStore((state) => state.setUserTemplates);
  const data = response.data;
  const { upsertTemplateData } = useUpsertTemplateData(setUserTemplates);
  const { addAttachmentToResource } = useAddAttachmentToResource();
  const setHasNewNotification = useAppStore(
    (state) => state.setHasNewNotification,
  );
  const addNotificationMessage = useAppStore(
    (state) => state.addNotificationMessage,
  );
  const { createAttachment } = useCreateAttachment();
  const { generateAttachmentUrl } = useGenerateAttachmentUrl();

  useEffect(() => {
    if (data?.pdfGenerated?.tenantId === user.tenantId && data?.pdfGenerated) {
      const { filename, bucketname, previewFilename } = data?.pdfGenerated;
      const handleTemplateData = async (attachment: any) => {
        if (isAttachmentPdfOfTypeTemplate(attachment)) {
          await upsertTemplateData({
            input: {
              _id: selectedTemplateData?.id,
              attachmentId: attachment?.id,
            },
          });
        }
      };

      const handleAttachmentAsync = async () => {
        const attachment = await createAttachment({
          input: {
            type: "pdf",
            bucketname,
            filename,
            previewFilename,
          },
        });
        addAttachmentToResource({
          input: {
            resourceId: selectedProject.id,
            attachmentIds: [attachment.id],
          },
        });
        handleTemplateData(attachment);
        generateAttachmentUrl({
          input: {
            bucketname,
            filename,
            type: "pdf",
          },
          callback: (pdfUrl) => {
            const { ownerId } = data.pdfGenerated;
            if (ownerId === user.uid) {
              const id = uid(UID_SIZES.sm);

              setHasNewNotification(true);
              addNotificationMessage({
                id,
                path: pdfUrl,
                message: "PDF erzeugt - hier klicken um zu öffnen",
              });
            }
          },
        });
      };

      handleAttachmentAsync();
    }
  }, [
    user.uid,
    user.tenantId,
    data?.pdfGenerated,
    selectedProject.id,
    selectedTemplateData?.id,
    addAttachmentToResource,
    createAttachment,
    generateAttachmentUrl,
    upsertTemplateData,
    setHasNewNotification,
    addNotificationMessage,
  ]);
};

export const useGenerateTemplateSubscription = (
  addThumbnailToResource: (resourceId: string, thumbnailUrl: string) => any,
) => {
  const user = useAuthStore((state) => state.loggedInUser);
  const [response] = useSubscription({
    query: GENERATE_TEMPLATE_PREVIEW_SUBSCRIPTION,
  });

  const data = response.data;
  const { generateAttachmentUrl } = useGenerateAttachmentUrl();

  useEffect(() => {
    if (
      data?.templatePreviewGenerated?.tenantId &&
      data.templatePreviewGenerated.tenantId === user.tenantId
    ) {
      generateAttachmentUrl({
        input: {
          type: "image",
          filename: data?.templatePreviewGenerated?.filename,
          bucketname: data?.templatePreviewGenerated?.bucketname,
        },
        callback: (thumbnailUrl) =>
          addThumbnailToResource(
            data?.templatePreviewGenerated?.resourceId,
            thumbnailUrl,
          ),
      });
    }
  }, [
    user.tenantId,
    data?.templatePreviewGenerated?.tenantId,
    data?.templatePreviewGenerated?.bucketname,
    data?.templatePreviewGenerated?.filename,
    data?.templatePreviewGenerated?.resourceId,
    generateAttachmentUrl,
    addThumbnailToResource,
  ]);
};
