import { ChangeEvent, FC, useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface SearchFieldProps {
  placeHolder?: string;
  borderBottom?: boolean;
  autoFocus?: boolean;
  variant?: "default" | "slim";
  isClearable?: boolean;
  defaultValue?: string;
  onSearch: (value: string) => Promise<void>;
}

export const SearchField: FC<SearchFieldProps> = ({
  placeHolder = "",
  isClearable = false,
  borderBottom = false,
  defaultValue = "",
  variant = "default",
  onSearch,

  ...rest
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  const handleSearch = async (value: string) => {
    setInputValue(value);
    await onSearch(value);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleSearch(value);
  };

  const handleClearSearch = async () => {
    handleSearch("");
  };

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={clsx("relative flex flex-grow items-stretch border-gray-200", {
        border: !borderBottom,
        "border-b": borderBottom,
        "h-9 lg:h-10": variant === "slim",
      })}
    >
      <input
        className={clsx(
          "block h-full w-full border-none py-2 pr-2 text-sm focus:ring-0 sm:leading-6 lg:py-4",
          {
            "pl-8 lg:pr-6": isClearable,
            "pl-4 lg:pr-12": !isClearable,
          },
        )}
        type="text"
        value={inputValue}
        autoComplete="off"
        tabIndex={0}
        placeholder={t(placeHolder)}
        onChange={handleChange}
        {...rest}
      />
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 flex items-center",
          {
            "left-0 pl-1": isClearable,
            "right-0 pr-3": !isClearable,
          },
        )}
      >
        <MagnifyingGlassIcon
          className="h-5 w-5 text-gray-400 lg:h-6 lg:w-6"
          aria-hidden="true"
        />
      </div>
      {isClearable && inputValue && (
        <button
          onClick={handleClearSearch}
          className="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <XIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};
