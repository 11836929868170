import { Button, IconButton, Tooltip } from "@amenda-components/App";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";

import { CostGroupModal } from "./CostGroupModal";
import { CostGroupPicker } from "./CostGroupPicker";
import { FC } from "react";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  readOnly: boolean;
}

export const CostGroupHeader: FC<Props> = ({ readOnly }) => {
  const { t } = useTranslation();
  const selectedCostGroupId = useProjectStore(
    (state) => state.selectedCostGroupId,
  );
  const clearSelectedProjectCostGroup = useProjectStore(
    (state) => state.clearSelectedProjectCostGroup,
  );
  const setOpenProjectCostGroupModal = useProjectStore(
    (state) => state.setOpenProjectCostGroupModal,
  );

  const onClickNew = () => {
    clearSelectedProjectCostGroup();
    setOpenProjectCostGroupModal(true);
  };

  const onClickEdit = () => {
    setOpenProjectCostGroupModal(true);
  };

  return (
    <>
      <CostGroupModal />
      <div className="flex flex-row justify-between">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <CostGroupPicker />
            {selectedCostGroupId && !readOnly && (
              <Tooltip id="editCurrentCostGroup" message="Edit cost entries">
                <IconButton
                  label="Edit"
                  Icon={PencilIcon}
                  iconSize={1}
                  className="text-gray-900 hover:text-gray-900 focus:outline-none focus:ring-0"
                  onClick={onClickEdit}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          {!readOnly && (
            <Button
              className="font-apercu ml-2 flex items-center space-x-1 focus:ring-0"
              size="xs"
              onClick={onClickNew}
            >
              <PlusIcon className="h-5 w-5" />
              <span>{t("New Costs")}</span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
