import { FC } from "react";
import clsx from "clsx";

interface Props {
  padding: number;
}

export const PaddingRow: FC<Props> = ({ padding }) => {
  if (padding <= 0) {
    return null;
  }
  return (
    <div style={{ height: `${padding}px` }} className={clsx("table-row")} />
  );
};
