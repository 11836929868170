import { FC, useState } from "react";
import { Link, Modal } from "@amenda-components/App";

import { ReadOnlyLabelledInputCard } from "./LabelledInput";
import { isEmpty } from "lodash";

interface Props {
  type?: string;
  values: {
    label: string;
    value: string;
  }[];
}

export const LabelledInputTableCell: FC<Props> = ({ type, values }) => {
  const [openModal, setOpenModal] = useState(false);

  if (isEmpty(values)) {
    return null;
  }

  const selectedValues = values.slice(0, 2);
  selectedValues.push({ value: "...", label: "More" });
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div className="flex w-full flex-wrap items-center space-x-3">
      <Link className="w-full text-left" variant="primary" onClick={handleOpen}>
        <span>{selectedValues.map(({ value }) => value).join(", ")}</span>
      </Link>
      <Modal
        isOpen={openModal}
        isElevated={true}
        withCancel={false}
        closeModalFromTitle={true}
        size="md"
        className="w-11/12 md:w-1/2 lg:w-4/12"
        onClose={handleClose}
        title="View Details"
      >
        <div className="flex flex-wrap">
          {values.map(({ label, value }) => (
            <div key={value} className="w-full px-1 pb-1">
              <ReadOnlyLabelledInputCard
                label={label}
                value={value}
                type={(type as any) || "email"}
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
