import { Avatar, IconButton, Tooltip } from "@amenda-components/App";
import {
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PhoneIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import {
  flattenUserDetails,
  getUserName,
} from "@amenda-components/Contacts/common";
import { useAppStore, useUsersStore } from "@amenda-domains/mutations";

import { PencilIcon } from "@heroicons/react/24/outline";
import { TimelineActivityTypes } from "@amenda-types";
import { TrashIcon } from "@heroicons/react/24/solid";
import { formatDate } from "@amenda-utils";
import { useGetUsersById } from "@amenda-domains/queries";
import { useInView } from "react-intersection-observer";

const getIconByActivity = (activity: TimelineActivityTypes) => {
  switch (activity) {
    case TimelineActivityTypes.Email:
      return EnvelopeIcon;
    case TimelineActivityTypes.PhoneCall:
      return PhoneIcon;
    case TimelineActivityTypes.Meeting:
      return ChatBubbleLeftRightIcon;
    case TimelineActivityTypes.Note:
      return DocumentTextIcon;
    case TimelineActivityTypes.Other:
      return Squares2X2Icon;
    default:
      return ChatBubbleOvalLeftEllipsisIcon;
  }
};

interface Props {
  timelineActivity: any;
  isLastActivity?: boolean;
  handleOpenDeleteModal: () => void;
  setIsEditing: (isEditing: boolean) => void;
}

export const TimelineItem: React.FC<Props> = ({
  timelineActivity,
  isLastActivity = false,
  setIsEditing,
  handleOpenDeleteModal,
}) => {
  const { ref, inView } = useInView();
  const [user, setUser] = useState<any>();
  const { getUsersById } = useGetUsersById();
  const setSelectedActivityTimeline = useAppStore(
    (state) => state.setSelectedActivityTimeline,
  );
  const currentUser = useUsersStore((state) => state.currentUser);

  const Icon = getIconByActivity(timelineActivity.activity);

  const handleClick = () => {
    setSelectedActivityTimeline(timelineActivity);
    handleOpenDeleteModal();
  };

  const handleEdit = () => {
    setSelectedActivityTimeline(timelineActivity);
    setIsEditing(true);
  };

  useEffect(() => {
    if (inView) {
      getUsersById([timelineActivity.createdById], (users) => {
        const user = users[0];
        setUser(flattenUserDetails(user));
      });
    }
  }, [inView, timelineActivity.createdById, getUsersById]);

  return (
    <div className="flex w-full flex-col items-start gap-px" ref={ref}>
      <div className="mr-0 flex w-full flex-row items-start gap-3">
        <div className="mt-1">
          <Avatar
            src={user?.photoURL}
            className="h-10 min-h-10 w-10 min-w-10"
            iconClassName="h-9 w-9"
            name={getUserName(user)}
          />
        </div>
        <div className="flex w-full flex-col items-start gap-1">
          <div className="flex flex-col items-start">
            <div className="flex w-full items-center space-x-1">
              <p className="flex text-gray-600">{getUserName(user)}</p>
              {timelineActivity.createdById === currentUser.id && (
                <>
                  <Tooltip id="edit-activity" message="Edit Activity">
                    <IconButton
                      iconSize={0.85}
                      label="Edit Activity"
                      Icon={PencilIcon}
                      onClick={handleEdit}
                    />
                  </Tooltip>
                  <Tooltip id="delete-activity" message="Delete Activity">
                    <IconButton
                      iconSize={0.85}
                      variant="danger"
                      label="Delete Activity"
                      Icon={TrashIcon}
                      onClick={handleClick}
                    />
                  </Tooltip>
                </>
              )}
            </div>
            <div className="flex items-center">
              <Icon className="mr-1 h-3 min-h-[1rem] w-3 min-w-[1rem] text-gray-500" />
              <span className="text-xs text-gray-600">
                {formatDate(timelineActivity.date, true)}
              </span>
            </div>
            <p className="flex items-center justify-center break-words text-sm text-gray-900">
              {timelineActivity.body}
            </p>
          </div>
        </div>
      </div>
      {!isLastActivity && (
        <div className="ml-5 h-6 w-px border-l border-dashed border-gray-300" />
      )}
    </div>
  );
};
