import { IconButton, Tooltip } from "@amenda-components/App";
import { InfoIcon, XIcon } from "lucide-react";
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Transition,
} from "@headlessui/react";

import { FC } from "react";
import { TemplatePhotoPicker } from ".";
import clsx from "clsx";
import { useTemplatesStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  loading?: boolean;
}

export const TemplateTabs: FC<Props> = ({ loading = false }) => {
  const { t } = useTranslation();
  const clearSelectedTemplateData = useTemplatesStore(
    (state) => state.clearSelectedTemplateData,
  );
  const setTemplateDesignModal = useTemplatesStore(
    (state) => state.setTemplateDesignModal,
  );
  const clearTemplateImage = useTemplatesStore(
    (state) => state.clearTemplateImage,
  );
  const photoSlots = useTemplatesStore((state) => state.photoSlots);

  const allPhotoSlotsAreLocked = Object.keys(photoSlots).every((key) =>
    Boolean(photoSlots[key]),
  );

  const handleClose = () => {
    clearSelectedTemplateData();
    clearTemplateImage();
    setTemplateDesignModal(false);
  };

  return (
    <div className="px-2 pb-3">
      <TabGroup defaultIndex={0}>
        <TabList className="amenda-tab-list sticky top-0 z-40 w-full overflow-y-auto overscroll-y-contain border-0 bg-white pt-4">
          <Tab
            className={({ selected }) =>
              clsx("amenda-tab", {
                "border-gray-900 text-gray-900": selected,
                "border-transparent hover:border-gray-600 hover:text-gray-700":
                  !selected,
              })
            }
          >
            <div className="flex w-full items-center space-x-2">
              <span className="h-4">{t("Project Media")}</span>
              <Transition
                as="div"
                show={allPhotoSlotsAreLocked}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Tooltip
                  className="normal-case"
                  id="templateTabInfo"
                  position="bottom"
                  positionStrategy="fixed"
                  message="Unlock slots to add images"
                >
                  <InfoIcon className="h-4 w-4 text-yellow-600" />
                </Tooltip>
              </Transition>
            </div>
          </Tab>
          <Tab as="div" className="w-[inherit]">
            <div className="flex w-full justify-end">
              <Tooltip id="closeBtn" message="Close">
                <IconButton
                  Icon={XIcon}
                  label="Close"
                  variant="default"
                  onClick={handleClose}
                />
              </Tooltip>
            </div>
          </Tab>
        </TabList>
        <TabPanels className="w-full pt-4">
          <TabPanel>
            <TemplatePhotoPicker loading={loading} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};
