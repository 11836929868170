import { Active, Over, useDndMonitor } from "@dnd-kit/core";
import { FC, ReactNode, useState } from "react";

import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { useSortable } from "@dnd-kit/sortable";

interface FormBuilderSortableItemProps {
  children: (isDragging: boolean) => ReactNode;
  id: string;
  data?: Record<string, any>;
  className?: string;
}

interface FormBuilderSortDroppableProps {
  children: ReactNode;
  className?: string;
  getClassName?: (isOver: boolean) => string;
}

interface FormBuilderDragOverlayItemProps {
  children: ReactNode;
  className?: string;
}

export const isDragValid = (active: Active, over: Over) => {
  return active.id !== over.id;
};

export const FormBuilderSortableItem: FC<FormBuilderSortableItemProps> = ({
  id,
  children,
  className,
  data = {},
}) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children(isDragging)}
    </div>
  );
};

export const FormBuilderSortDroppable: FC<FormBuilderSortDroppableProps> = ({
  children,
  className,
  getClassName,
}) => {
  const [isOver, setOver] = useState(false);
  useDndMonitor({
    onDragStart() {
      setOver(true);
    },
    onDragEnd() {
      setOver(false);
    },
    onDragCancel() {
      setOver(false);
    },
  });

  return (
    <div
      className={clsx(className, getClassName?.(isOver), {
        "bg-gray-100": isOver,
      })}
    >
      {children}
    </div>
  );
};

export const FormBuilderDragOverlayItem: FC<
  FormBuilderDragOverlayItemProps
> = ({
  children,
  className = "shadow-lg w-full px-1 py-2 cursor-pointer text-sm rounded-sm outline-none bg-gray-800 text-white",
}) => {
  return <div className={className}>{children}</div>;
};
