import Cal from "@calcom/embed-react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const RegistrationSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <>
        <Helmet title={t("Signup successfull")} />
        <div className="flex min-h-screen bg-white">
          <div className="flex flex-1 flex-col justify-center py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto mt-8 w-full max-w-sm lg:w-96">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Cal
                  calLink="niklaswohlgemuth/demo"
                  style={{ width: "100%", height: "100%", overflow: "scroll" }}
                />
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/images/amenda_lines.jpg"
              alt=""
            />
          </div>
        </div>
      </>
    </>
  );
};
