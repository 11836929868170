import {
  Button,
  HelperMessage,
  LoaderWrapper,
  Modal,
} from "@amenda-components/App";
import { FC, useEffect, useState } from "react";
import {
  useFormStore,
  useUpdateCollectionsResourceIds,
} from "@amenda-domains/mutations";

import { CollectionCard } from "./CollectionCard";
import { CollectionProps } from "./common";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";
import { useGetAllCollections } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    CollectionProps,
    "resourceIds" | "collectionType" | "clearSelectedResources"
  > {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddToCollection: FC<Props> = ({
  isOpen,
  resourceIds,
  collectionType,
  handleClose,
  clearSelectedResources,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { getAllCollections, loading } = useGetAllCollections();
  const collectionsByType = useFormStore((state) => state.collectionsByType);
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const { updateCollectionsResourceIds, loading: updateCollectionLoader } =
    useUpdateCollectionsResourceIds();

  const collections = collectionsByType[collectionType];

  const isSelected = (collection: any) =>
    selectedCollections.includes(collection.id);

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    await getAllCollections({
      collectionType,
      isDeleted: false,
      context: {
        requestPolicy: "network-only",
      },
      ...(isEmpty(searchTerm)
        ? {}
        : {
            name: searchTerm,
          }),
    });
  };

  const handleSelect = (collection: any) => () => {
    const hasCollection = selectedCollections.includes(collection.id);

    if (hasCollection) {
      setSelectedCollections(
        selectedCollections.filter((id) => id !== collection.id),
      );
    } else {
      setSelectedCollections([...selectedCollections, collection.id]);
    }
  };

  const handleSave = async () => {
    await updateCollectionsResourceIds({
      input: {
        resourceIds,
        collectionIds: selectedCollections,
      },
    });
    setSelectedCollections([]);
    clearSelectedResources();
    handleClose();
  };

  useEffect(() => {
    getAllCollections({
      collectionType,
      isDeleted: false,
    });
  }, [getAllCollections, collectionType]);

  return (
    <Modal
      size="md"
      successLabel="Submit"
      className="w-11/12 md:w-1/2 lg:w-4/12"
      title="Sort into collection"
      isOpen={isOpen}
      closeModalFromTitle={true}
      loading={updateCollectionLoader}
      onClose={handleClose}
      footerChildren={({ loading, onClose }) => {
        return (
          <>
            <Button type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              type="button"
              variant="primary"
              disabled={isEmpty(selectedCollections)}
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </>
        );
      }}
    >
      <div className="w-full">
        <MiniSearchField
          className="bg-gray-100"
          placeholder="Search collections"
          value={searchTerm}
          onChange={handleSearch}
        />
        {loading ? (
          <LoaderWrapper className="h-32" spinnerSize="sm" />
        ) : isEmpty(collections) ? (
          <HelperMessage
            className="h-32"
            iconClassName="h-8 w-8 text-gray-800"
            Icon={RectangleStackIcon}
            message="No collection found"
          />
        ) : (
          <div className="flex max-h-[70vh] flex-col space-y-2 overflow-y-auto pt-4">
            {collections.map((collection) => (
              <CollectionCard
                key={collection.id}
                collection={collection}
                isSelected={isSelected(collection)}
                handleSelect={handleSelect(collection)}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
