import {
  HoverSelector,
  Image,
  ProgressIndicator,
} from "@amenda-components/App";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { FC } from "react";
import { HoverVariants } from "@amenda-types";
import clsx from "clsx";
import { isRestricted } from "@amenda-utils";
import { useNavigate } from "react-router-dom";

interface Props {
  project: any;
  projectName: any;
  projectNumber: any;
  projectAddress: any;
}

export const ProjectCard: FC<Props> = ({
  project,
  projectName,
  projectNumber,
}) => {
  const navigate = useNavigate();
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const openSimilaritySearch = useProjectStore(
    (state) => state.openSimilaritySearch,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const toggleSelectedProjects = useProjectStore(
    (state) => state.toggleSelectedProjects,
  );
  const selectedProjects = useProjectStore((state) => state.selectedProjects);
  const similarityScoreById = useProjectStore(
    (state) => state.similarityScoreById,
  );
  const selectedSimilarityScore = useProjectStore(
    (state) => state.selectedSimilarityScore,
  );
  const setShiftSelectedProject = useProjectStore(
    (state) => state.setShiftSelectedProject,
  );

  const isSelected = selectedProjects.includes(project.id);
  const projectSimilarityScore = similarityScoreById[project.id];
  const isMasked =
    openSimilaritySearch && projectSimilarityScore < selectedSimilarityScore;

  const handleClick = () => {
    setSelectedProject(project);
    navigate(`/projekte/${project.id}`);
  };

  const handleSelect = () => {
    toggleSelectedProjects(project.id);
  };

  const handleShiftSelect = () => {
    setShiftSelectedProject(project);
  };

  return (
    <HoverSelector
      className="cursor-pointer"
      canSelect={true}
      variant={HoverVariants.Checkbox}
      id={project.id}
      isSelected={isSelected}
      handleSelect={handleSelect}
      handleClick={handleClick}
      handleShiftSelect={handleShiftSelect}
    >
      <div className="group pb-2 lg:pb-0">
        <Image
          isMasked={isMasked}
          isFullWidth={true}
          url={project.galleryUrl}
          size="pb-10/12"
          magicSize="galleryCropped"
        />
        <div className="flex w-full items-center justify-between overflow-hidden pt-1 group-hover:bg-gray-50">
          <div
            className={clsx("flex flex-col truncate px-2", {
              "w-40 lg:w-48 xxl:w-72": openSimilaritySearch,
              "w-96": !openSimilaritySearch,
            })}
          >
            {!isRestricted(projectName, permissions) && (
              <p
                className={clsx(
                  "text-md truncate text-left duration-300 ease-in-out xxl:text-lg",
                  {
                    "text-gray-900": !isMasked,
                    "text-gray-300": isMasked,
                  },
                )}
              >
                {project.name}
              </p>
            )}
            {!isRestricted(projectNumber, permissions) && (
              <p
                className={clsx(
                  "xxl:text-md truncate text-left text-sm duration-300 ease-in-out lg:text-sm",
                  {
                    "text-gray-500": !isMasked,
                    "text-gray-300": isMasked,
                  },
                )}
              >
                {project?.number}
              </p>
            )}
          </div>
          <div className="pr-4">
            {openSimilaritySearch && (
              <ProgressIndicator
                showDefaultColor={
                  projectSimilarityScore < selectedSimilarityScore
                }
                progress={projectSimilarityScore}
              />
            )}
          </div>
        </div>
      </div>
    </HoverSelector>
  );
};
