import { FC, ReactNode, memo } from "react";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { AvailablePermissions } from "@amenda-types";
import { CostGroupHeader } from "./CostGroupHeader";
import { CostGroupPermissionPath } from "@amenda-constants";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";
import { HelperMessage } from "@amenda-components/App";
import getPath from "lodash/get";

interface Props {
  isDisabled?: boolean;
  children: (isReadOnly: boolean) => ReactNode;
}

export const CostGroupHeaderWrapper: FC<Props> = memo(
  ({ isDisabled, children }) => {
    const clearSelectedProjectCostGroup = useProjectStore(
      (state) => state.clearSelectedProjectCostGroup,
    );
    const setOpenProjectCostGroupModal = useProjectStore(
      (state) => state.setOpenProjectCostGroupModal,
    );
    const permissions = useSettingsStore(
      (state) => state.currentUserSystemRole?.permissions || {},
    );

    const costGroupPermission = getPath(permissions, CostGroupPermissionPath);
    const isReadOnly = costGroupPermission === AvailablePermissions.Read;

    const handleNew = () => {
      clearSelectedProjectCostGroup();
      setOpenProjectCostGroupModal(true);
    };

    if (costGroupPermission === AvailablePermissions.Restricted) {
      return (
        <HelperMessage
          message="Restricted Form"
          helpText="You don't have permissions to view this form"
          Icon={CurrencyEuroIcon}
        />
      );
    }
    return (
      <>
        <CostGroupHeader readOnly={isReadOnly} />
        {isDisabled ? (
          <HelperMessage
            message="Create or edit cost groups"
            helpText="So far, no costs have been incurred for this project."
            actionText="Capture new costs"
            Icon={CurrencyEuroIcon}
            onClick={handleNew}
          />
        ) : (
          <>{children(isReadOnly)}</>
        )}
      </>
    );
  },
);
