import { Button, Image, LoaderWrapper } from "@amenda-components/App";

import { FC } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const ProjectSnippet: FC<{
  projects: any[];
  loader?: boolean;
}> = ({ projects, loader }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const latestProjects = projects.slice(0, 7);

  const handleClickProject = (project: any) => {
    navigate(`/projekte/${project?.id}`);
  };

  if (loader) {
    return <LoaderWrapper />;
  }

  return (
    <div className="flow-root">
      {latestProjects.map((project) => {
        return (
          <div className="grid w-full grid-cols-1" key={project.id}>
            <ul className="-my-5 divide-y divide-gray-200">
              <li className="mb-6 py-4" key={project.id}>
                <div className="flex items-center space-x-4">
                  <div className="h-12 w-12 shrink-0 rounded">
                    <Image
                      url={project.galleryUrl}
                      size="pb-10/12"
                      enableHighlighting={false}
                      magicSize="thumbnail"
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm text-gray-900">
                      {project?.name}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {project?.address?.name}
                    </p>
                  </div>
                  <div>
                    <Button
                      size="xs"
                      variant="default"
                      onClick={() => handleClickProject(project)}
                    >
                      {t("View")}
                    </Button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};
