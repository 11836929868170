import { gql, useMutation } from "urql";

import { AvailableNotificationTypes } from "@amenda-types";
import { IMAGE_DESCRIPTOR_FRAGMENT } from "@amenda-domains/fragments/descriptors";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { isEmpty } from "lodash";
import { useAppStore } from "./app";

interface ImageDescriptor {
  id: string;
  label: [];
  type: string;
  sourceURL: string;
  descriptor: any[];
}

type State = {
  detectedFaces: any[];
  faceImageDescriptors: ImageDescriptor[];
  detectedFaceModal: {
    isOpen: boolean;
    selectedFace: any;
  };
};

type Actions = {
  updateDetectedFaceLabels: (label: string) => void;
  setDetectedFaces: (detectedFaces: any[]) => void;
  upsertFaceImageDescriptor: (imageDescriptor: ImageDescriptor) => void;
  setDetectedFaceModal: (isOpen: boolean, selectedFace?: any) => void;
  setFaceImageDescriptors: (imageDescriptors: ImageDescriptor[]) => void;
};

export const useImageRecognitionStore = create(
  immer<State & Actions>((set, get) => ({
    detectedFaceModal: {
      isOpen: false,
      selectedFace: {},
    },
    faceImageDescriptors: [],
    detectedFaces: [],
    updateDetectedFaceLabels: (label) =>
      set((state) => {
        const selectedFace = get().detectedFaceModal.selectedFace;
        const detectedFaces = get().detectedFaces;

        if (!isEmpty(selectedFace)) {
          state.detectedFaces = detectedFaces.map((f) =>
            f.id === selectedFace.id
              ? {
                  ...f,
                  label,
                }
              : f,
          );
        }
      }),
    setDetectedFaces: (detectedFaces) =>
      set((state) => {
        state.detectedFaces = detectedFaces;
      }),
    upsertFaceImageDescriptor: (imageDescriptor) =>
      set((state) => {
        const index = state.faceImageDescriptors.findIndex(
          (descriptor) => descriptor.id === imageDescriptor.id,
        );

        if (index !== -1) {
          state.faceImageDescriptors[index] = imageDescriptor;
        } else {
          state.faceImageDescriptors.push(imageDescriptor);
        }
      }),
    setFaceImageDescriptors: (imageDescriptors) =>
      set((state) => {
        state.faceImageDescriptors = imageDescriptors;
      }),
    setDetectedFaceModal: (isOpen, selectedFace) =>
      set((state) => {
        const imageDescriptors = get().faceImageDescriptors;

        if (isOpen) {
          const foundDescriptor = imageDescriptors.find(
            (descriptor) => descriptor.id === selectedFace.id,
          );

          state.detectedFaceModal.isOpen = isOpen;
          state.detectedFaceModal.selectedFace = {
            ...selectedFace,
            hasDescriptor: !!foundDescriptor,
            originalLabel: foundDescriptor?.label,
          };
        } else {
          state.detectedFaceModal.isOpen = isOpen;
          state.detectedFaceModal.selectedFace = {};
        }
      }),
  })),
);

const CREATE_IMAGE_DESCRIPTOR = gql`
  ${IMAGE_DESCRIPTOR_FRAGMENT}
  mutation CreateImageDescriptor($input: ImageDescriptorInput!) {
    createImageDescriptor(input: $input) {
      ...ImageDescriptorFragment
    }
  }
`;

const UPDATE_IMAGE_DESCRIPTOR = gql`
  ${IMAGE_DESCRIPTOR_FRAGMENT}
  mutation UpdateImageDescriptor($input: UpdateImageDescriptorInput!) {
    updateImageDescriptor(input: $input) {
      ...ImageDescriptorFragment
    }
  }
`;

export const useCreateImageDescriptor = () => {
  const [result, callCreateImageDescriptor] = useMutation(
    CREATE_IMAGE_DESCRIPTOR,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const upsertFaceImageDescriptor = useImageRecognitionStore(
    (state) => state.upsertFaceImageDescriptor,
  );
  const updateDetectedFaceLabels = useImageRecognitionStore(
    (state) => state.updateDetectedFaceLabels,
  );

  const createImageDescriptor = async (variables: Record<string, any>) => {
    return callCreateImageDescriptor(variables)
      .then(({ data }) => {
        if (data?.createImageDescriptor) {
          upsertFaceImageDescriptor(data.createImageDescriptor);
          updateDetectedFaceLabels(data.createImageDescriptor.label);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };
  return {
    createImageDescriptor,
    loading: result.fetching,
  };
};

export const useUpdateImageDescriptor = () => {
  const [result, callUpdateImageDescriptor] = useMutation(
    UPDATE_IMAGE_DESCRIPTOR,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const upsertFaceImageDescriptor = useImageRecognitionStore(
    (state) => state.upsertFaceImageDescriptor,
  );
  const updateDetectedFaceLabels = useImageRecognitionStore(
    (state) => state.updateDetectedFaceLabels,
  );

  const upateImageDescriptor = (variables: Record<string, any>) => {
    return callUpdateImageDescriptor(variables)
      .then(({ data }) => {
        if (data?.updateImageDescriptor) {
          upsertFaceImageDescriptor(data.updateImageDescriptor);
          updateDetectedFaceLabels(data.updateImageDescriptor.label);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    upateImageDescriptor,
    loading: result.fetching,
  };
};
