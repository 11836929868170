import {
  AdminSettingsLayout,
  RegionalFactorsTable,
} from "@amenda-components/Settings";

import { Button } from "@amenda-components/App";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RegionalFactors: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => navigate("/admin/regional-factors/neu");

  return (
    <AdminSettingsLayout>
      <div className="sm:flex sm:items-center" style={{ height: "40px" }}>
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Regional Factors")}</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={handleClick}
            className="amenda-button hidden"
          >
            + {t("Regional Factors")}
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <RegionalFactorsTable />
      </div>
    </AdminSettingsLayout>
  );
};
