import { FC, memo } from "react";
import {
  FullScreenModal,
  HelperMessage,
  IconButton,
} from "@amenda-components/App";
import { UsersIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  flattenUserDetails,
  getContactRequestArgs,
  getDynamicContactColumns,
  getStaticContactColumns,
  getUserName,
  hasNoUserSearchAndGrouping,
} from "./common";
import {
  useFormStore,
  useProjectStore,
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";

import { ContactDirectoryProps } from "./types";
import { GroupByHeader } from "@amenda-components/Shared/GroupByHeader";
import { ReactTable } from "@amenda-components/Shared";
import { ReactTableKeys } from "@amenda-types";
import { getLinkedContactDataColumns } from "./ContactTableLinkedData";
import { isEmpty } from "lodash";
import { useRunOnMountOnly } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    ContactDirectoryProps,
    "collectionType" | "searchContacts" | "getAllContacts"
  > {
  users: any[];
  isCollection: boolean;
}

interface FullScreenTableProps {
  users: any[];
  isLoading: boolean;
  handleClose: () => void;
  handleUsers: () => void;
  handleNextPage: () => Promise<void>;
}

const FullScreenTable: FC<FullScreenTableProps> = ({
  users,
  isLoading,
  handleClose,
  handleUsers,
  handleNextPage,
}) => {
  const { t } = useTranslation();
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const pagination = useUsersStore((state) => state.pagination);
  const { groupingComponents, searchFilters } = useUserSearchFiltersWithPath();

  const dynamicTableColumns = getDynamicContactColumns(formsByContactType);
  const transformedUsers = users.map((user) => flattenUserDetails(user));

  useRunOnMountOnly(handleUsers);

  if (isEmpty(users) && !isLoading) {
    return (
      <HelperMessage
        Icon={UsersIcon}
        message="No contacts found"
        actionText="Close table view"
        hideActionIcon={true}
        onClick={handleClose}
      />
    );
  }
  return (
    <div className="h-full w-full p-4">
      <ReactTable
        isFullWidth
        isConfigurable
        canDownload={isEmpty(searchFilters.groupByComponentIds)}
        showGroupingColumn={!isEmpty(searchFilters.groupByComponentIds)}
        label="Contacts"
        maxEstimatedRowHeight={60}
        containerClass="h-[calc(100vh-5rem)] w-full"
        isLoading={isLoading}
        data={transformedUsers}
        pagination={pagination}
        tableId={ReactTableKeys.ContactsFullScreenTable}
        defaultPinnedColumns={{
          left: ["name"],
        }}
        tableConfigChildren={
          <IconButton
            label="Close table view"
            Icon={XMarkIcon}
            onClick={handleClose}
          />
        }
        groupingColumnChildren={(row) => {
          const { original } = row;
          return (
            <div className="ml-2 flex items-center space-x-1">
              <GroupByHeader
                componentId={original.componentId}
                componentValue={original.componentValue}
                components={groupingComponents}
              />
              <span className="text-gray-600">({original.count})</span>
            </div>
          );
        }}
        columns={[
          {
            id: "name",
            minSize: 240,
            header: t("Name"),
            meta: {
              label: t("Name"),
            },
            cell: ({ row }: any) => {
              const user = row?.original;
              return <div>{getUserName(user)}</div>;
            },
          },
          ...dynamicTableColumns,
          ...getStaticContactColumns(getLinkedContactDataColumns(t)),
        ]}
        fetchNextPage={handleNextPage}
      />
    </div>
  );
};

export const ContactsFullScreenTable: FC<Props> = memo(
  ({ users, isCollection, collectionType, searchContacts, getAllContacts }) => {
    const openUsersTableView = useUsersStore(
      (state) => state.openUsersTableView,
    );
    const setOpenUsersTableView = useUsersStore(
      (state) => state.setOpenUsersTableView,
    );
    const selectedCollectionByType = useFormStore(
      (state) => state.selectedCollectionByType,
    );
    const pagination = useUsersStore((state) => state.pagination);
    const isFetchingContacts = useUsersStore(
      (state) => state.isFetchingContacts,
    );
    const isSearchingContacts = useUsersStore(
      (state) => state.isSearchingContacts,
    );
    const { searchFilters } = useUserSearchFiltersWithPath();

    const isLoading = Boolean(isFetchingContacts ?? isSearchingContacts);
    const selectedCollection = selectedCollectionByType[collectionType];

    const handleUsers = async (isTableView = true) => {
      const args = getContactRequestArgs({
        ...searchFilters,
        isCollection,
        autoSelect: false,
        collectionResourceIds: selectedCollection?.resourceIds,
      });

      if (!hasNoUserSearchAndGrouping(searchFilters)) {
        await searchContacts({
          ...args,
          isTableView,
        });
      } else {
        await getAllContacts(args);
      }
    };

    const handleNextPage = async () => {
      if (pagination?.hasNext && hasNoUserSearchAndGrouping(searchFilters)) {
        const args = getContactRequestArgs({
          isCollection,
          autoSelect: false,
          contactType: searchFilters?.contactType,
          collectionResourceIds: selectedCollection?.resourceIds,
        });

        await getAllContacts({
          ...args,
          next: pagination?.next,
        });
      }
    };

    const handleClose = async () => {
      setOpenUsersTableView(false);
      await handleUsers(false);
    };

    return (
      <FullScreenModal isOpen={openUsersTableView} onClose={handleClose}>
        {openUsersTableView && (
          <FullScreenTable
            users={users}
            isLoading={isLoading}
            handleClose={handleClose}
            handleUsers={handleUsers}
            handleNextPage={handleNextPage}
          />
        )}
      </FullScreenModal>
    );
  },
);
