import { NotFoundRoute, PDF_TOKEN } from "@amenda-constants";
import { UIEvent, useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { dequal as deepEqual } from "dequal";
import { isMac } from "./string";
import isNil from "lodash/isNil";
import { useAppStore } from "@amenda-domains/mutations";

export const useRouteQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useStoreToken = (query: any) => {
  useEffect(() => {
    localStorage.setItem(PDF_TOKEN, query.get("token") || "");
  }, [query]);
};

export const useHandleNotFound = () => {
  const navigate = useNavigate();

  const handleNotFound = useCallback(() => {
    navigate(NotFoundRoute);
  }, [navigate]);

  return {
    handleNotFound,
  };
};

export const useVerticalScrollEvent = () => {
  const positionRef = useRef(0);

  return (callback: (e: EventTarget & HTMLElement) => void) =>
    ({ currentTarget }: UIEvent<HTMLElement>) => {
      if (currentTarget) {
        const { scrollTop } = currentTarget;

        if (scrollTop !== positionRef.current) {
          positionRef.current = scrollTop;
          callback(currentTarget);
        }
      }
    };
};

type UseEffectParams = Parameters<typeof useEffect>;
type UseEffectReturn = ReturnType<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];

function checkDeps(deps: DependencyList) {
  if (!deps || !deps.length) {
    throw new Error(
      "useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.",
    );
  }
  if (deps.every(isPrimitive)) {
    throw new Error(
      "useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.",
    );
  }
}

const isPrimitive = (val: unknown) => {
  // null, strings and numbers are primitive
  return val === null || /^[sbn]/.test(typeof val);
};

export const useDeepCompareMemoize = <T>(value: T) => {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
};

export const useDeepCompareEffect = (
  callback: EffectCallback,
  dependencies: DependencyList,
): UseEffectReturn => {
  if (process.env.NODE_ENV !== "production") {
    checkDeps(dependencies);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, useDeepCompareMemoize(dependencies));
};

export const useRunOnMountOnly = (callback: () => void, deps?: string) => {
  const mounted = useRef(false);
  const previousRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      previousRef.current = deps;
      callback();
    }

    if (mounted.current && previousRef.current !== deps) {
      previousRef.current = deps;
      callback();
    }
  }, [callback, deps]);
};

const isCmdPressed = (e: KeyboardEvent) => e.metaKey;
const isCtrlPressed = (e: KeyboardEvent) => e.ctrlKey;
const isShiftPressed = (e: KeyboardEvent) => e.shiftKey;

export const isCmdOrCtrlOrShiftPressed = (e?: KeyboardEvent) => {
  if (isMac()) {
    return !isNil(e) && (isCmdPressed(e) || isShiftPressed(e));
  }
  return !isNil(e) && (isCtrlPressed(e) || isShiftPressed(e));
};

export const useHandleKeyDown = () => {
  const setKeyPressed = useAppStore((state) => state.setPressedKey);

  useEffect(() => {
    const handleKeyPressed = (e: KeyboardEvent) => {
      setKeyPressed(e);
    };

    const handleKeyUp = () => {
      setKeyPressed(null);
    };

    window.addEventListener("keydown", handleKeyPressed);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyPressed);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [setKeyPressed]);
};
