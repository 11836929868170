import { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface TitleProps {
  className?: string;
  description?: string;
  label: string;
}

export const Title: FC<TitleProps> = ({
  className = "",
  description,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-1 mt-3">
      <h3 className={clsx(className, "h-full border-b text-lg text-gray-900")}>
        {t(label)}
      </h3>
      {description && (
        <p className="mt-1 text-sm text-gray-500">{t(description)}</p>
      )}
    </div>
  );
};
