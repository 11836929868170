import { Button, HeadlessTabs, Modal } from "@amenda-components/App";
import { Control, SetFieldValue, useForm } from "react-hook-form";
import { FormBuilderComponentTabs, getConditionComponents } from "../common";
import { devConsole, getComponentsFromForms } from "@amenda-utils";
import { useAppStore, useUpdateForm } from "@amenda-domains/mutations";

import { FC } from "react";
import { FormBuilderDisplayConditions } from "./FormBuilderDisplayConditions";
import { FormBuilderReadOnlyHeaders } from "./FormBuilderReadOnlyHeaders";
import { useGetFormsByCategory } from "../hooks";
import { useTranslation } from "react-i18next";

const showTabs = ({
  tab,
  ...rest
}: {
  components: any[];
  control: Control<any>;
  setValue: SetFieldValue<any>;
  tab: FormBuilderComponentTabs;
}) => {
  switch (tab) {
    case FormBuilderComponentTabs.Header:
      return <FormBuilderReadOnlyHeaders />;
    case FormBuilderComponentTabs.Display:
      return <FormBuilderDisplayConditions {...rest} />;
    default:
      return null;
  }
};

const FormId = "form-builder-edit-form-modal";

export const FormBuilderEditFormModal: FC = () => {
  const { t } = useTranslation();
  const openEditFormModal = useAppStore(
    (state) => state.formBuilderState?.openEditFormModal,
  );
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>({
    values: {
      displayConditions: selectedForm?.properties?.display ?? [],
    },
  });
  const { availableForms } = useGetFormsByCategory();
  const { updateForm, loading } = useUpdateForm();

  const availableComponents = getComponentsFromForms(availableForms);
  const components = getConditionComponents(availableComponents);

  const handleClose = () => {
    updateFormBuilderState("openEditFormModal", false);
  };

  const handleUpdateForm = async (displayConditions: any[]) => {
    if (selectedForm) {
      await updateForm({
        id: selectedForm.id,
        properties: {
          ...(selectedForm?.properties ?? {}),
          display: displayConditions,
        },
      });
    }
  };

  const onSubmit = async (data: any) => {
    if (isDirty) {
      await handleUpdateForm(data.displayConditions);
      reset({
        displayConditions: [],
      });
    }
    handleClose();
  };

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:project form error gat", errors);
  };

  return (
    <Modal
      isOpen={Boolean(openEditFormModal)}
      loading={loading}
      isElevated={true}
      closeModalFromTitle={true}
      withCancel={false}
      size="md"
      className="w-11/12 md:w-1/2 lg:w-5/12"
      title="Edit form"
      onClose={handleClose}
      footerClassName="w-full"
      footerChildren={({ loading, onClose }) => {
        return (
          <div className="flex w-full justify-end bg-white pb-2 pt-1">
            <Button type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form={FormId}
              loading={loading}
              variant="primary"
            >
              {t("Save")}
            </Button>
          </div>
        );
      }}
    >
      <form id={FormId} onSubmit={handleSubmit(onSubmit, onError)}>
        <HeadlessTabs
          tabListClassName="!pt-0"
          tabs={[
            {
              label: "Readonly header settings",
              value: FormBuilderComponentTabs.Header,
            },
            {
              label: "Display conditions",
              value: FormBuilderComponentTabs.Display,
              disabled: availableForms.length <= 1,
            },
          ]}
        >
          {(tab) =>
            showTabs({
              control,
              components,
              tab: tab.value,
              setValue,
            })
          }
        </HeadlessTabs>
      </form>
    </Modal>
  );
};
