import { FC, Fragment, ReactNode, useEffect } from "react";
import {
  HelperMessage,
  Link,
  LoaderWrapper,
  NavigationFilter,
} from "@amenda-components/App";

import { FormAutoSaveSubmitProps } from "@amenda-components/PageBuilder";
import { LayoutTypes } from "./common";
import { SidebarFilters } from "@amenda-constants";
import { isEmpty } from "lodash";
import { layoutTheme } from "@amenda-styles/theme";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

interface Props {
  resources?: any[];
  searchPlaceholder?: string;
  hasNextPage?: boolean;
  gridChildren?: (r: any) => ReactNode;
  listChildren?: (r: any) => ReactNode;
  tableChildren?: (resources?: any[]) => ReactNode;
  HelperIcon: FC<any>;
  helperActionText?: string;
  helperMessage: string;
  helperDescription: string;
  helperDescriptionSearch?: string;
  helperOnClick?: () => void;
  hasSearchFilters?: boolean;
  isSearching?: boolean;
  isFetching?: boolean;
  searchTerm?: string;
  sidebarFilters?: Record<string, SidebarFilters>;
  view?: LayoutTypes;
  components?: any[];
  hasNavigationSearch?: boolean;
  navigationChildren?: ReactNode;
  hasNoPages?: boolean;
  getNextPage?: () => Promise<void>;
  handleNavigationSearch?: (props: FormAutoSaveSubmitProps) => Promise<void>;
  handleClearNavigationSearch?: () => Promise<void>;
  collectionDropdownChildren?: ReactNode;
  clearSelectedResources?: () => void;
  selectAllResources?: () => void;
  totalSelectedResources?: number;
}

export const SharedLayout: FC<Props> = ({
  resources,
  HelperIcon,
  helperActionText,
  helperMessage,
  helperDescription,
  helperDescriptionSearch,
  gridChildren,
  listChildren,
  tableChildren,
  searchPlaceholder,
  navigationChildren,
  collectionDropdownChildren,
  searchTerm,
  sidebarFilters,
  totalSelectedResources,
  view = LayoutTypes.Grid,
  components = [],
  isFetching = false,
  isSearching = false,
  hasNextPage = false,
  hasSearchFilters = false,
  hasNavigationSearch = false,
  hasNoPages = false,
  getNextPage,
  helperOnClick,
  handleNavigationSearch,
  handleClearNavigationSearch,
  selectAllResources,
  clearSelectedResources,
}) => {
  const { ref, inView } = useInView();
  const { t } = useTranslation();

  const { gridCss } = layoutTheme({});

  useEffect(() => {
    const getNextPageAsync = async () => {
      if (inView && view !== "table" && hasNextPage && getNextPage) {
        await getNextPage();
      }
    };

    getNextPageAsync();
  }, [inView, view, hasNextPage, getNextPage]);

  return (
    <div className="h-full w-full">
      <div className="sticky top-0 z-30 w-full bg-white pt-4 lg:pt-0">
        {collectionDropdownChildren}
        {hasNavigationSearch && (
          <NavigationFilter
            className="pb-4 pt-7"
            searchTerm={searchTerm}
            sidebarFilters={sidebarFilters}
            components={components}
            searchPlaceholder={searchPlaceholder}
            handleSearch={handleNavigationSearch}
            handleClearSearch={handleClearNavigationSearch}
          >
            {navigationChildren}
          </NavigationFilter>
        )}
        <div className="h-6 md:h-7">
          {Number(totalSelectedResources) > 0 && (
            <div className="flex flex-row items-center space-x-2">
              <Link onClick={clearSelectedResources}>
                <span className="amenda-component-label text-sm">
                  {t("Deselect All")} ({totalSelectedResources})
                </span>
              </Link>
              <Link onClick={selectAllResources}>
                <span>{t("Select All")}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      {!isSearching && !isFetching && isEmpty(resources) && (
        <>
          {hasNoPages && !hasSearchFilters ? (
            <HelperMessage
              actionText={helperActionText}
              Icon={HelperIcon}
              message={helperMessage}
              helpText={helperDescription}
              onClick={helperOnClick}
            />
          ) : (
            <HelperMessage
              Icon={HelperIcon}
              message={helperMessage}
              helpText={helperDescriptionSearch ?? helperDescription}
            />
          )}
        </>
      )}
      {isSearching && <LoaderWrapper />}
      {!isSearching && view === LayoutTypes.Grid && gridChildren && (
        <div className={gridCss()}>
          {resources?.map((r) => (
            <Fragment key={r.id}>{gridChildren(r)}</Fragment>
          ))}
        </div>
      )}
      {!isSearching && view === LayoutTypes.List && listChildren && (
        <div className="h-full w-full space-y-2">
          {resources?.map((r) => (
            <Fragment key={r.id}>{listChildren(r)}</Fragment>
          ))}
        </div>
      )}
      {!isSearching &&
        view === LayoutTypes.Table &&
        tableChildren &&
        tableChildren(resources)}
      {isFetching && <LoaderWrapper className="h-44" />}
      <div ref={ref} />
    </div>
  );
};
