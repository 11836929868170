import { Button, LoaderWrapper, Modal } from "@amenda-components/App";
import {
  Control,
  Controller,
  UseFormSetValue,
  useForm,
  useWatch,
} from "react-hook-form";
import { FC, useEffect, useState } from "react";
import {
  GeneralAccessTypes,
  sharingPermissionsSchema,
} from "@amenda-constants";

import { PermissionSharePath } from "./common";
import { ShareableContactsSearch } from "./ShareableContactsSearch";
import { SharingPermissionsDropdown } from "./SharingPermissionsDropdown";
import { useGetUsersById } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  loading?: boolean;
  title: string;
  ownerId?: string;
  shareWith?: any[];
  shareType?: GeneralAccessTypes;
  openModal: boolean;
  handleClose: () => void;
  onSubmit: (type: GeneralAccessTypes, users: any[]) => void;
}

interface CreateResourceShareFormProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

export const CreateResourceShareForm: FC<CreateResourceShareFormProps> = ({
  control,
  setValue,
}) => {
  const currentUser = useUsersStore((state) => state.currentUser);

  const currentShareType = useWatch({
    control,
    exact: true,
    name: "shareType",
  });

  return (
    <>
      <ShareableContactsSearch
        id="contacts"
        ownerId={currentUser?.id}
        control={control}
        setValue={setValue}
        isHidden={currentShareType !== GeneralAccessTypes.Limited}
      />
      <div>
        <Controller
          name="shareType"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <SharingPermissionsDropdown
                className="text-md"
                label="General Access"
                hasMenuOverflow={true}
                selectedOption={value}
                onChange={onChange}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export const ShareModal: FC<Props> = ({
  title,
  ownerId,
  openModal,
  shareWith = [],
  loading = false,
  shareType = GeneralAccessTypes.Everyone,
  onSubmit,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState<any[]>([]);
  const { getUsersById, loading: isFetchingUsers } = useGetUsersById();
  const { control, setValue, handleSubmit } = useForm<any>({
    resolver: yupResolver(sharingPermissionsSchema),
    values: {
      shareType,
      contacts: contacts.map((contact) => {
        const foundContact = shareWith.find((user) => user.id === contact.id);

        return {
          ...contact,
          _id: contact.id || contact._id,
          [PermissionSharePath]: foundContact?.role,
        };
      }),
    },
  });
  const currentShareType = useWatch({
    control,
    exact: true,
    name: "shareType",
  });

  useEffect(() => {
    if (openModal && shareWith.length > 0) {
      getUsersById(
        shareWith.map((user) => user.id),
        setContacts,
      );
    }
  }, [shareWith, openModal, getUsersById]);

  useEffect(() => {
    if (currentShareType === GeneralAccessTypes.Private) {
      setContacts([]);
    }
  }, [currentShareType]);

  const onSubmitCallback = ({ contacts, shareType }: any) => {
    onSubmit(shareType, contacts);
  };

  return (
    <Modal
      title={title}
      isOpen={openModal}
      closeModalFromTitle={true}
      withCancel={false}
      isElevated={true}
      size="md"
      className="w-11/12 md:w-1/2 lg:w-4/12"
      onClose={handleClose}
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmitCallback)}>
        {isFetchingUsers ? (
          <LoaderWrapper className="h-32" spinnerSize="sm" />
        ) : (
          <>
            <ShareableContactsSearch
              id="contacts"
              ownerId={ownerId}
              control={control}
              setValue={setValue}
              isHidden={currentShareType !== GeneralAccessTypes.Limited}
            />
            <div>
              <Controller
                name="shareType"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <SharingPermissionsDropdown
                      className="text-md"
                      label="General Access"
                      selectedOption={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </div>
          </>
        )}
        <Button
          variant="primary"
          size="lg"
          className="mb-2 mt-6 w-full"
          type="submit"
          loading={loading || isFetchingUsers}
        >
          <span className="w-full text-center">{t("Save")}</span>
        </Button>
      </form>
    </Modal>
  );
};
