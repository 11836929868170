import { AttachmentActionBar, GalleryTabs } from "@amenda-components/Gallery";
import { AvailableImageVariants, RoundedVariants } from "@amenda-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  DetectedFaces,
  MainContainer,
  SidebarContainer,
} from "@amenda-components/Shared";
import { FC, KeyboardEvent } from "react";
import { IconButton, Image, ModalBaseTitle } from "@amenda-components/App";

import { ScanFace } from "lucide-react";
import { TitleAndSubtitle } from "@amenda-components/PdfBuilder";
import { formatFileSizeResult } from "@amenda-components/FileUploads/common";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useFaceAPI } from "@amenda-context";

interface Props {
  loading: boolean;
}

export const GalleryModalDesktopSidebar: FC<Props> = ({ loading }) => {
  return (
    <SidebarContainer>
      <GalleryTabs loading={loading} />
    </SidebarContainer>
  );
};

export const GalleryModalMainContent: FC<Props> = ({ loading }) => {
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const setIsEditingAttachment = useAttachmentStore(
    (state) => state.setIsEditingAttachmentDetails,
  );
  const attachments = useAttachmentStore((state) => state.attachments);
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );

  const {
    processing,
    detectedFaces,
    isModelLoaded,
    cleanUpFaceDetection,
    processImageForFaceRecognition,
  } = useFaceAPI();

  const currentAttachmentIndex = attachments.findIndex(
    (attachment) => attachment.id === selectedAttachment?.id,
  );

  const handlePrevious = () => {
    const attachment = attachments[currentAttachmentIndex - 1];

    cleanUpFaceDetection();
    setSelectedAttachment(attachment);
    setIsEditingAttachment(false);
  };
  const handleNext = () => {
    const attachment = attachments[currentAttachmentIndex + 1];

    cleanUpFaceDetection();
    setSelectedAttachment(attachment);
    setIsEditingAttachment(false);
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const targetKey = e.key;
    if (targetKey === "ArrowLeft" && currentAttachmentIndex > 0) {
      handlePrevious();
    } else if (
      targetKey === "ArrowRight" &&
      currentAttachmentIndex + 1 < attachments.length
    ) {
      handleNext();
    }
  };

  return (
    <MainContainer tabIndex={-1} onKeyDown={handleKeyDown}>
      <div>
        <div className="pb-2 lg:hidden">
          <AttachmentActionBar />
        </div>
        <div className="flex">
          <ModalBaseTitle
            className="mb-2 flex-1 pt-1 text-xl"
            title={selectedAttachment?.originalFilename}
          />
          <IconButton
            className="mr-auto"
            Icon={ScanFace}
            label="Detected faces"
            variant="base"
            onClick={() =>
              processImageForFaceRecognition(
                selectedAttachment?.originalFilename,
              )
            }
            disabled={!isModelLoaded}
          />
        </div>
      </div>
      <Image
        id={selectedAttachment?.originalFilename}
        url={selectedAttachment?.url}
        isLoading={loading}
        showLoader={true}
        size="pb-6/12"
        magicSize="fullscreen"
        lazyLoad={false}
        variant={AvailableImageVariants.scaleDown}
        rounded={RoundedVariants.none}
        enableHighlighting={false}
      >
        {(imageDimensions) => (
          <DetectedFaces
            isLoading={processing}
            detectedFaces={detectedFaces}
            imageDimensions={imageDimensions}
          />
        )}
      </Image>
      <div className="mt-4 flex items-center justify-center py-2 lg:px-8">
        <IconButton
          className="mr-auto"
          Icon={ChevronLeftIcon}
          label="Previous"
          variant="inverted"
          onClick={handlePrevious}
          disabled={currentAttachmentIndex <= 0}
        />
        <TitleAndSubtitle title="Dimensions">
          {selectedAttachment?.metadata?.height &&
          selectedAttachment?.metadata?.width
            ? `${selectedAttachment?.metadata?.height}px mal ${selectedAttachment?.metadata?.width}px`
            : "-"}
        </TitleAndSubtitle>
        <TitleAndSubtitle title="Size">
          {`${formatFileSizeResult(selectedAttachment?.metadata?.size)}`}
        </TitleAndSubtitle>
        <TitleAndSubtitle title="File">
          {selectedAttachment?.metadata?.type}
        </TitleAndSubtitle>
        <IconButton
          className="ml-auto"
          Icon={ChevronRightIcon}
          label="Next"
          variant="inverted"
          onClick={handleNext}
          disabled={currentAttachmentIndex + 1 >= attachments.length}
        />
      </div>
      <div className="lg:hidden">
        <GalleryTabs loading={loading} />
      </div>
    </MainContainer>
  );
};
