import { useAppStore, useProjectStore } from "@amenda-domains/mutations";

import { AvailableNotificationTypes } from "@amenda-types";
import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { EmptyIndicator } from "@amenda-components/Shared";
import React from "react";
import { Tooltip } from "@amenda-components/App";
import { roundAndFormatNumbers } from "@amenda-utils";

const CostGroupRowItem: React.FC<
  Pick<ComponentsTreeViewRowProps, "column" | "component" | "globalData">
> = ({ component, globalData, column: { id } }) => {
  const { projectCostGroupsByTypeAndDate, selectedCostGroupVersionByType } =
    useProjectStore((state) => state.projectCostGroupsReadonlyData);
  const showNotification = useAppStore((state) => state.showNotification);

  const selectedVersion = selectedCostGroupVersionByType[id];
  const { referenceQuantity } = component.properties || {};
  const { values, quantities } =
    projectCostGroupsByTypeAndDate[`${id}-${selectedVersion}`] || {};
  const quantity = quantities?.[referenceQuantity];
  const { totalCost } = values?.[component.id] || {};
  const { endAddOnText, label } =
    globalData?.quantityComponentsById?.[referenceQuantity]?.properties || {};

  const formattedQuantity = quantity
    ? roundAndFormatNumbers(quantity, endAddOnText)
    : "N/A";
  const formattedTotalCost = totalCost
    ? roundAndFormatNumbers(totalCost, "€")
    : "N/A";
  const formattedCostPerUnit =
    quantity && totalCost
      ? roundAndFormatNumbers(Number(totalCost / quantity), `€/${endAddOnText}`)
      : "N/A";

  const handleClick = async () => {
    const clipboardText = `Bezugsmenge: ${formattedQuantity}; Gesamtkosten: ${formattedTotalCost}; Einheitspreis: ${formattedCostPerUnit}`;
    try {
      await navigator.clipboard.writeText(clipboardText);
      showNotification(
        AvailableNotificationTypes.Success,
        "Successfully copied values to clipboard",
      );
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "Failed to copy values to clipboard",
      );
    }
  };

  return (
    <div
      key={`${id}_${component.id}`}
      onClick={handleClick}
      className="grid cursor-pointer grid-cols-3 gap-0 px-2 pt-2.5 text-sm hover:bg-gray-100 xl:gap-2"
    >
      <div className="col-span-1 mr-0 text-right xl:col-span-2 xl:mr-16">
        <span>{quantity && totalCost && formattedCostPerUnit}</span>
      </div>
      <div className="col-span-3 text-right xl:col-span-1">
        <span className="text-xs">
          {(totalCost && quantity && formattedQuantity) || <EmptyIndicator />}
        </span>
        <Tooltip
          id={`${id}_${component.id}_tooltip`}
          message={quantity && label}
        >
          <span className="cursor-help text-xs text-gray-500">
            {totalCost && formattedTotalCost}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export const ReadOnlyCostGroupRowBody: React.FC<ComponentsTreeViewRowProps> = ({
  column,
  component,
  globalData,
}) => {
  return (
    <CostGroupRowItem
      column={column}
      component={component}
      globalData={globalData}
    />
  );
};
