import {
  ComponentTreeRenderer,
  FormAutoSaveBaseChildProps,
  FormAutoSaveResourceProps,
  FormAutoSaveSubmitProps,
} from "@amenda-components/PageBuilder";
import { FC, ReactNode, memo } from "react";
import { FormComponentProps, NestedPageComponentProps } from "@amenda-types";
import { devConsole, sanitizeData, sanitizeNestedData } from "@amenda-utils";

import { DetectFormNavigation } from "@amenda-components/FormComponents";
import { ObjectSchema } from "yup";
import { getDirtyFieldsValue } from "./common";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props extends FormAutoSaveResourceProps {
  className?: string;
  inputSchema: ObjectSchema<any>;
  customFormComponents?: Record<string, FC<FormComponentProps>>;
  values: Record<string, any>;
  globalProps?: Record<string, any>;
  formComponentTree: NestedPageComponentProps;
  showFormNavigationAlert?: boolean;
  children: (
    props: Omit<
      FormAutoSaveBaseChildProps,
      "submitButtonRef" | "handleFormSubmit"
    >,
  ) => ReactNode;
  onSubmit: (props: FormAutoSaveSubmitProps) => Promise<void>;
}

export const FormWrapper: FC<Props> = memo(
  ({
    values,
    resourceId,
    inputSchema,
    children,
    formComponentTree,
    resourceIds = {},
    globalProps = {},
    className = "py-8",
    customFormComponents = {},
    showFormNavigationAlert = true,
    onSubmit: onSubmitCallback,
  }) => {
    const {
      control,
      handleSubmit,
      reset,
      setValue,
      trigger,
      formState: { dirtyFields, isSubmitted, isSubmitting },
    } = useForm<any>({
      values,
      resolver: yupResolver(inputSchema),
      resetOptions: {
        keepValues: true,
        keepIsSubmitted: true,
        keepDirtyValues: true, // if true user-interacted input will be retained
        keepErrors: true, // input errors will be retained with value update
      },
    });

    const onError = (errors: any, e: any) => {
      devConsole?.warn("amenda:errors gat", errors);
    };

    const onSubmit = async (data: any) => {
      const dirtyData = getDirtyFieldsValue(dirtyFields, data);
      const sanitizedData = sanitizeNestedData(data);
      const sanitizedDirtyData = sanitizeData(dirtyData);

      await onSubmitCallback({
        data,
        dirtyData,
        sanitizedData,
        sanitizedDirtyData,
        resourceId,
        resourceIds,
      });
      reset({}, { keepValues: true, keepIsSubmitted: true });
    };

    const handleSubmitManually = async () => {
      const isValid = await trigger();

      if (isValid) {
        await handleSubmit(onSubmit, onError)();
      }
      return isValid;
    };

    return (
      <>
        {showFormNavigationAlert && (
          <DetectFormNavigation
            isFormDirty={!isSubmitting && !isSubmitted && !isEmpty(dirtyFields)}
          />
        )}
        <form className={className} onSubmit={handleSubmit(onSubmit, onError)}>
          <ComponentTreeRenderer
            config={formComponentTree}
            customComponents={customFormComponents}
            globalProps={{
              ...globalProps,
              control,
              reset,
            }}
            readOnly={false}
          />
          {children({
            reset,
            setValue,
            control,
            resourceId,
            resourceIds,
            handleSubmitManually,
          })}
        </form>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.formComponentTree) !==
      JSON.stringify(nextProps.formComponentTree)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.globalProps) !==
      JSON.stringify(nextProps.globalProps)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.values) !== JSON.stringify(nextProps.values)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.resourceIds) !==
      JSON.stringify(nextProps.resourceIds)
    ) {
      return false;
    } else if (prevProps.resourceId !== nextProps.resourceId) {
      return false;
    } else {
      return true;
    }
  },
);
