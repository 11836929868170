import {
  AllowedAttachmentType,
  AvailableImageVariants,
  PdfTypes,
  RoundedVariants,
} from "@amenda-types";
import {
  Button,
  FullScreenModal,
  HelperMessage,
  HoverSelectorBase,
  Image,
  LoaderWrapper,
  ModalBaseTitle,
} from "@amenda-components/App";
import { FC, useEffect } from "react";
import { MainContainer, SidebarContainer } from "@amenda-components/Shared";
import {
  useAttachmentStore,
  useGeneratePdf,
  useProjectStore,
  useTemplatesStore,
  useUpsertTemplateData,
} from "@amenda-domains/mutations";
import {
  useFetchTemplateDataWithClient,
  useSearchAttachments,
} from "@amenda-domains/queries";

import { PdfTemplateScales } from "@amenda-constants";
import { ProjectTemplateBuilder } from "@amenda-components/Projects";
import { TemplateTabs } from "./TemplateTabs";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface TemplatePhotoPickerProps {
  loading?: boolean;
}

interface TemplateModalProps {
  projectId: any;
}

export const TemplatePhotoPicker: FC<TemplatePhotoPickerProps> = ({
  loading,
}) => {
  const attachments = useProjectStore(
    (state) => state.projectDesignerAttachments,
  );
  const setSelectedPhoto = useTemplatesStore((state) => state.setSelectedPhoto);
  const photoSlots = useTemplatesStore((state) => state.photoSlots);

  const allPhotoSlotsAreLocked = Object.keys(photoSlots).every((key) =>
    Boolean(photoSlots[key]),
  );

  const handleClick = (attachment: any) => {
    if (attachment && !allPhotoSlotsAreLocked) {
      setSelectedPhoto(attachment);
    }
  };

  if (loading) {
    return <LoaderWrapper />;
  }
  return (
    <div className="h-full overflow-y-auto pb-20">
      {isEmpty(attachments) ? (
        <HelperMessage message="No images found" />
      ) : (
        <ul className="grid grid-cols-2 gap-2 p-3">
          {attachments?.map?.((attachment: any) => (
            <HoverSelectorBase
              key={attachment.id}
              className={clsx("hover:bg-white", {
                "cursor-pointer": !allPhotoSlotsAreLocked,
                "cursor-not-allowed": allPhotoSlotsAreLocked,
              })}
              handleClick={() => handleClick(attachment)}
            >
              <Image
                url={attachment.url}
                magicSize="gallery"
                size="pb-10/12"
                variant={AvailableImageVariants.cover}
                rounded={RoundedVariants.none}
              />
            </HoverSelectorBase>
          ))}
        </ul>
      )}
    </div>
  );
};

export const TemplateModal: FC<TemplateModalProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const { searchAttachments, loading } = useSearchAttachments();
  const openTemplateDesignModal = useTemplatesStore(
    (state) => state.openTemplateDesignModal,
  );
  const selectedTemplate = useTemplatesStore((state) => state.selectedTemplate);
  const formTemplateProperties = useTemplatesStore(
    (state) => state.formTemplateProperties,
  );
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const clearSelectedTemplateData = useTemplatesStore(
    (state) => state.clearSelectedTemplateData,
  );
  const { handleGeneratePdf, loading: generatePdfLoader } = useGeneratePdf();
  const { fetchTemplateData, loading: fetchTemplateDataLoader } =
    useFetchTemplateDataWithClient();
  const setTemplateDesignModal = useTemplatesStore(
    (state) => state.setTemplateDesignModal,
  );
  const resetTemplateBuilder = useTemplatesStore(
    (state) => state.resetTemplateBuilder,
  );
  const clearTemplateImage = useTemplatesStore(
    (state) => state.clearTemplateImage,
  );
  const setAttachments = useAttachmentStore((state) => state.setAttachments);
  const setProjectDesignerAttachments = useProjectStore(
    (state) => state.setProjectDesignerAttachments,
  );

  const { upsertTemplateData, loading: upsertTemplateDataLoader } =
    useUpsertTemplateData((templateData) => {
      if (projectId) {
        handleGeneratePdf({
          resourceId: projectId,
          category: "projects",
          filename: `project_${projectId}_${selectedTemplate.format}`,
          format: selectedTemplate.format,
          path: `projekte/${projectId}/template/${templateId}/templateData/${templateData?.id}`,
          options: {
            scale: PdfTemplateScales[selectedTemplate.format],
            landscape: selectedTemplate.format === PdfTypes.A3,
          },
        });
      }
    });

  const templateId = selectedTemplate.templateId || selectedTemplate.id;

  const handleClose = () => {
    setTemplateDesignModal(false);
  };

  const handleExportPdf = async () => {
    await upsertTemplateData({
      input: {
        name: `Project_${selectedTemplate.format}_template_${templateId}`,
        projectId,
        templateId,
        values: formTemplateProperties,
      },
    });

    setTemplateDesignModal(false);
  };

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (projectId) {
        setAttachments({
          attachments: [],
        });
        await searchAttachments({
          filters: {
            type: AllowedAttachmentType.image,
            "formValues.project": projectId,
          },
          callback: setProjectDesignerAttachments,
        });
      }
    };

    searchAttachmentAsync();
  }, [
    projectId,
    setAttachments,
    searchAttachments,
    setProjectDesignerAttachments,
  ]);

  useEffect(() => {
    if (selectedAttachment?.id) {
      fetchTemplateData({ attachmentId: selectedAttachment?.id });
    }
  }, [selectedAttachment?.id, fetchTemplateData]);

  useEffect(() => {
    return () => {
      clearSelectedTemplateData();
      clearTemplateImage();
      resetTemplateBuilder();
    };
  }, [resetTemplateBuilder, clearTemplateImage, clearSelectedTemplateData]);

  return (
    <FullScreenModal isOpen={openTemplateDesignModal} onClose={handleClose}>
      <MainContainer>
        <ModalBaseTitle
          className="mb-2 pt-1 font-sans"
          title={selectedTemplate?.name}
        />
        {fetchTemplateDataLoader || loading ? (
          <LoaderWrapper className="h-full" />
        ) : (
          <ProjectTemplateBuilder selectedTemplate={selectedTemplate} />
        )}
        <div className="lg:hidden">
          <TemplateTabs loading={fetchTemplateDataLoader || loading} />
        </div>
      </MainContainer>
      <SidebarContainer className="relative w-96 border-l">
        <div className="h-full w-full overflow-y-auto">
          <TemplateTabs />
        </div>
        <div className="absolute bottom-0 mt-auto flex w-full justify-end border-t border-gray-200 bg-white px-3 py-3">
          <Button
            type="button"
            variant="primary"
            loading={generatePdfLoader || upsertTemplateDataLoader}
            onClick={handleExportPdf}
          >
            {t("Save")}
          </Button>
        </div>
      </SidebarContainer>
    </FullScreenModal>
  );
};
