import { FC } from "react";
import clsx from "clsx";
import { spinnerTheme } from "@amenda-styles/theme";

export const Spinner: FC<{
  borderWidth?: number;
  className?: string;
  spinnerSize?: "sm" | "md" | "lg" | "xs";
  variant?:
    | "primary"
    | "secondary"
    | "default"
    | "danger"
    | "warning"
    | "outline"
    | "dangerAlt";
}> = ({
  borderWidth = 2,
  className,
  spinnerSize = "lg",
  variant = "default",
}) => {
  return (
    <div
      className={spinnerTheme({
        variant,
        size: spinnerSize,
        className: clsx(
          className,
          "amenda-no-border-right mx-2 animate-spin rounded-full",
          `border-${borderWidth}`,
        ),
      })}
    ></div>
  );
};
