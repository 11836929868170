import { FC, useEffect, useState } from "react";
import { Image, Link } from "@amenda-components/App";

import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ProjectResultsProps {
  projects: any[];
}

const ProjectCard: FC<{ project: any }> = ({ project }) => {
  const navigate = useNavigate();
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const formValues = project?.formValues;
  const handleClickProject = (project: any) => {
    toggleSearchModal(false);
    navigate(`/projekte/${project?.id}`);
  };

  return (
    <div className="flex overflow-hidden">
      <Link onClick={() => handleClickProject(project)}>
        <div className="mr-2 inline-block h-14 w-14 shrink-0 rounded-md">
          <Image
            url={project.galleryUrl}
            size="pb-2/3"
            enableHighlighting={false}
            magicSize="thumbnail"
          />
        </div>
      </Link>
      <div className="text-left hover:no-underline">
        <div
          className="cursor-pointer hover:underline"
          onClick={() => handleClickProject(project)}
        >
          <span className="truncate text-sm text-gray-900">
            {project?.name}
          </span>
        </div>
        {project?.address && (
          <p className="line-clamp-1 text-sm text-gray-500">
            {project.address?.name}
          </p>
        )}
        {formValues?.description_short && (
          <p className="line-clamp-1 text-sm text-gray-500">
            {formValues?.description_short}
          </p>
        )}
      </div>
    </div>
  );
};

export const ProjectResults: FC<ProjectResultsProps> = ({
  projects: allProjects,
}) => {
  const { t } = useTranslation();
  const [projects, updateProjects] = useState<any[]>([]);

  useEffect(() => {
    updateProjects(allProjects.slice(0, 3));
  }, [allProjects]);

  if (isEmpty(allProjects)) {
    return null;
  }

  return (
    <>
      <div>
        <p className="mb-2 text-sm text-gray-900">{t("Found Projects")}</p>
      </div>
      <div className="grid gap-4 sm:grid-cols-1">
        {projects.map((project) => {
          return <ProjectCard project={project} key={project.id} />;
        })}
      </div>
    </>
  );
};
