import { FC, memo } from "react";
import {
  useAppStore,
  useCreateTimelineActivity,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedTimelineTypes } from "@amenda-types";
import { Button } from "@amenda-components/App";
import { TimelineBody } from "./TimelineBody";
import { activityTimelineSchema } from "@amenda-constants";
import { getActivityTimelineFormValues } from "./common";
import { sanitizeData } from "@amenda-utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  resourceId: string;
  type: AllowedTimelineTypes;
  textAreaClassName?: string;
}

export const EmptyTimelineTextArea: FC<Props> = memo(
  ({ type, resourceId: timelineResourceId, textAreaClassName }) => {
    const { t } = useTranslation();
    const currentUser = useUsersStore((state) => state.currentUser);
    const { createTimelineActivity, loading } = useCreateTimelineActivity();
    const { control, handleSubmit } = useForm({
      values: getActivityTimelineFormValues({}),
      resolver: yupResolver(activityTimelineSchema),
    });
    const clearSelectedActivityTimeline = useAppStore(
      (state) => state.clearSelectedActivityTimeline,
    );
    const {
      searchTerm,
      caretPosition,
      cursorPosition,
      showTimelineContactsSearch,
    } = useAppStore((state) => state.timelinePrimaryValues);
    const setTimelinePrimaryValues = useAppStore(
      (state) => state.setTimelinePrimaryValues,
    );

    const setTimelineContactsSearch = (show: boolean) =>
      setTimelinePrimaryValues("showTimelineContactsSearch", show);
    const setCursorPosition = (position: number) =>
      setTimelinePrimaryValues("cursorPosition", position);
    const setCaretPosition = (position: { top: number; left: number }) =>
      setTimelinePrimaryValues("caretPosition", position);
    const setSearchTerm = (searchTerm: string) =>
      setTimelinePrimaryValues("searchTerm", searchTerm);

    const onSubmit = async (data: any) => {
      let input = sanitizeData({
        ...data,
        type,
        date: new Date().toISOString(),
        resourceId: timelineResourceId,
        createdById: currentUser?.id,
      });

      await createTimelineActivity({ input });
      clearSelectedActivityTimeline();
    };

    return (
      <div className="flex w-full flex-col items-center gap-px px-1 pt-3">
        <div className="flex w-full max-w-md flex-col gap-1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <TimelineBody
              control={control}
              textAreaClassName={textAreaClassName}
              searchTerm={searchTerm}
              caretPosition={caretPosition}
              cursorPosition={cursorPosition}
              showTimelineContactsSearch={showTimelineContactsSearch}
              setTimelineContactsSearch={setTimelineContactsSearch}
              setCursorPosition={setCursorPosition}
              setCaretPosition={setCaretPosition}
              setSearchTerm={setSearchTerm}
            />
            <div className="flex justify-end">
              <Button
                withGroup
                type="submit"
                variant="primary"
                loading={loading}
              >
                {t("Submit")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  },
);
