import { Button, LoaderWrapper } from "@amenda-components/App";

import { FC } from "react";
import { UsersIcon } from "@heroicons/react/24/outline";
import { UsersTable } from "./UsersTable";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  userLoading: boolean;
}

interface AddTeamMembersProps {
  onClick: () => void;
}

const AddTeamMembers: FC<AddTeamMembersProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto max-w-lg">
      <div>
        <div className="text-center">
          <UsersIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h2 className="mt-2 text-lg text-gray-900">
            {t("Add team members")}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            {t(
              "You haven’t added any team members to your Organization yet. As the owner of this Organization, you can manage team member permissions.",
            )}
          </p>
          <div className="mb-10 mt-5">
            <Button
              type="button"
              variant="primary"
              className="px-4"
              size="md"
              onClick={onClick}
            >
              {t("Add New User")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UsersTableWrapper: FC<Props> = ({ userLoading }) => {
  const users = useUsersStore((state) => state.users);
  const pagination = useUsersStore((state) => state.pagination);
  const setCreateOfficeUserModal = useUsersStore(
    (state) => state.setCreateOfficeUserModal,
  );
  const openNewUserModal = () => setCreateOfficeUserModal(true);

  const hasNoPaginationUsers =
    !Number.isFinite(pagination?.docsCount) ||
    Number(pagination?.docsCount) <= 0;

  const showEmptyState = users.length <= 0 && hasNoPaginationUsers;

  if (userLoading) {
    return <LoaderWrapper className="bg-gray-50" variant="default" />;
  } else if (showEmptyState) {
    return <AddTeamMembers onClick={openNewUserModal} />;
  }
  return <UsersTable />;
};
