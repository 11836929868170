import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Divider } from "@amenda-components/App";
import { FC } from "react";
import { Option } from "@amenda-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  defaultLabel?: string;
  selectedOption?: Option | null; // Allow both null and undefined
  options: Option[];
  withDottedIcon?: boolean;
  onChangeSelectedOption: (option: Option) => void;
}

export const Dropdowns: FC<Props> = ({
  defaultLabel,
  selectedOption,
  options,
  withDottedIcon = false,
  onChangeSelectedOption,
}) => {
  const { t } = useTranslation();
  const getLabel = () => {
    if (selectedOption) {
      return selectedOption.label;
    } else if (defaultLabel) {
      return defaultLabel;
    } else {
      return "No selected option";
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {withDottedIcon ? (
        <MenuButton className="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      ) : (
        <MenuButton className="inline-flex w-full justify-center border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {t(getLabel())}
          <Divider />
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      )}
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <MenuItem key={option.value}>
                {({ focus }) => (
                  <span
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChangeSelectedOption(option);
                    }}
                    className={clsx("block cursor-pointer px-4 py-2 text-sm", {
                      "bg-gray-100 text-gray-900": focus,
                      "text-gray-700": !focus,
                    })}
                  >
                    {option.label}
                  </span>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
