import { FC, ReactNode } from "react";
import {
  ResourceSharingPermissionTypes,
  sharePermissionsOptions,
} from "./common";
import {
  flattenUserDetails,
  getUserName,
} from "@amenda-components/Contacts/common";

import { Avatar } from "@amenda-components/App";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { Option } from "@amenda-types";
import clsx from "clsx";

interface BaseProps {
  contact: any;
  disabled?: boolean;
  children?: ReactNode;
  inlineChildren?: boolean;
}

interface Props extends BaseProps {
  secondaryOptions?: any[];
  permission?: ResourceSharingPermissionTypes;
  handleChangePermission: (option: Option) => void;
}

export const ContactPermissionBaseCard: FC<BaseProps> = ({
  contact,
  children,
  disabled = false,
  inlineChildren = false,
}) => {
  const fContact = flattenUserDetails(contact);

  return (
    <div
      className={clsx(
        "flex w-full flex-wrap items-center justify-between border border-gray-300 p-1 pr-2",
        {
          "cursor-not-allowed bg-gray-50": disabled,
        },
      )}
    >
      <div className="flex flex-row items-center gap-4 overflow-hidden truncate">
        <Avatar
          shape="square"
          src={fContact?.photoURL}
          name={getUserName(fContact)}
          iconClassName="h-12 w-12"
          className="h-14 min-h-14 w-14 min-w-14"
        />
        <div className="flex flex-col items-start space-y-0.5">
          <div className="text-md text-gray-800">{getUserName(fContact)}</div>
          {fContact?.title && (
            <div className="text-sm text-gray-400">{fContact.title}</div>
          )}
          {inlineChildren && <>{children}</>}
        </div>
      </div>
      {!inlineChildren && (
        <div className="flex items-center space-x-2">{children}</div>
      )}
    </div>
  );
};

export const ContactPermissionCard: FC<Props> = ({
  contact,
  permission,
  secondaryOptions,
  disabled = false,
  handleChangePermission,
}) => {
  return (
    <ContactPermissionBaseCard contact={contact} disabled={disabled}>
      <OperationsMenu
        hasMenuOverflow={true}
        menuItemsClassName="z-[80]"
        labelClassName="text-xs text-gray-500"
        disabled={disabled}
        options={sharePermissionsOptions}
        secondaryOptions={secondaryOptions}
        selectedOption={permission || ResourceSharingPermissionTypes.View}
        onChange={handleChangePermission}
      />
    </ContactPermissionBaseCard>
  );
};
