import {
  AllowedContactType,
  AvailableNotificationTypes,
  FormTab,
  Pagination,
  ProjectCostGroup,
  ProjectView,
} from "@amenda-types";
import {
  COST_GROUP_FRAGMENT,
  PROJECT_FRAGMENT,
} from "@amenda-domains/fragments/projects";
import { createJSONStorage, persist } from "zustand/middleware";
import {
  getComponentsFromForms,
  processCostGroupEstimates,
  sortDates,
  transformFormComponents,
} from "@amenda-utils";
import { gql, useMutation } from "urql";
import { groupBy, isEmpty, isNil, keyBy, sortBy, uniq } from "lodash";

import { AvailableForms } from "@amenda-constants";
import { create } from "zustand";
import { groupComponentsById } from "@amenda-components/PageBuilder/common";
import { immer } from "zustand/middleware/immer";
import { useAppStore } from "@amenda-domains/mutations";
import { useOrganization } from "@frigade/react";

export type SimilarityScore = {
  id: string;
  similarity: number;
};

type CostEstimateResponse = {
  costGroupKey: number;
  costGroupCount: number;
  costGroupAverage: number;
};

export type CostEstimateValues = CostEstimateResponse & {
  costGroupTotal: number;
  totalProjects?: number;
};

export type TransformedCostEstimateValues = Omit<
  CostEstimateValues,
  "costGroupKey"
> & {
  percentageCost: number;
};

export interface SetCostEstimateProps {
  estimationMode?: boolean;
  totalProjects: number;
  considerationValues: Record<string, number>;
  inferredCalcCostGroups: number[];
  componentByCode: Record<string, any>;
  componentsByCode: Record<string, number | null>;
  costEstimateValues: CostEstimateResponse[];
  costGroupQuantityComponents?: any[];
}

interface CostGroupMissingQuantityCounter {
  missingQuantities: string[];
  quantityByCosts: Record<string, string[]>;
  quantityByValue: Record<string, number | undefined>;
}

type State = {
  projectDesignerAttachments: any[];
  shiftSelectedProjects: number[];
  bkiValue?: number;
  latestBKIDate: {
    date?: Date;
    dateIso?: string;
  };
  regionalFactors: any[];
  constructionPriceIndices: any[];
  regionalFactorsPagination: Pagination;
  projectRolesByComponent: Record<string, any>;
  costEstimateForm: Record<string, any>;
  selectedSimilarityScore: number;
  similarityResourceIds: string[];
  similarityScoreById: Record<string, number>;
  openSimilaritySearch: boolean;
  openSearchModal: boolean;
  openCostEstimateModal: boolean;
  openProjectCostGroupModal: boolean;
  isFetchingProject: boolean;
  originalForms: FormTab[];
  forms?: Record<string, FormTab[]>;
  projectCostGroupsByType: Record<string, ProjectCostGroup[]>;
  projectForms: FormTab[];
  formsByContactType: Record<AllowedContactType, FormTab[]>;
  projectFormComponents: any[];
  similaritySearchComponents: any[];
  projectCostGroups: ProjectCostGroup[];
  projectCostGroupForm?: any;
  selectedCostGroupId?: string;
  selectedProject: Record<string, any>;
  selectedProjects: string[];
  projectCostGroupsReadonlyData: {
    projectCostGroupsByTypeAndDate: Record<string, any>;
    projectCostGroupsVersionsByType: Record<string, string[]>;
    selectedCostGroupVersionByType: Record<string, string>;
  };
  isSavingProject: boolean;
  isFetching: boolean;
  projects: any[];
  pagination: Pagination;
  contactProjects: any[];
  projectView: ProjectView;
  minSimilarityScore: number;
  totalProjectsMatching: number;
  similaritySearchProjects: string[];
  isFetchingCostEstimate: boolean;
  isSearching: boolean;
  costEstimateTab?: "cost" | "quantity";
  costEstimateValues: Record<string, TransformedCostEstimateValues>;
  costEstimationModalValues: Record<string, any>;
  similaritySearchModalValues: any[];
  costGroupCounter: CostGroupMissingQuantityCounter;
  openProjectFormSection: boolean;
};

type Actions = {
  setProjectDesignerAttachments: (attachments?: any[]) => void;
  upsertOrDeleteForm: (form: FormTab) => void;
  setShiftSelectedProject: (project: any) => void;
  setConstructionPriceIndices: (constructionPriceIndices: any[]) => void;
  setSimilaritySearchModalValues: (values: any[]) => void;
  setCostEstimationModalValues: (costEstimationModalValues: any) => void;
  setOpenProjectCostGroupModal: (openProjectCostGroupModal: boolean) => void;
  setBKIValue: (bkiValue: number) => void;
  setLatestBKIDate: (latestBKIDate: string) => void;
  clearProjectValues: () => void;
  clearCostEstimateValues: () => void;
  setCostEstimateValues: (props: SetCostEstimateProps) => void;
  setIsFetchingCostEstimate: (isFetchingCostEstimate: boolean) => void;
  setCostEstimateForm: (costEstimateForm?: Record<string, any>) => void;
  setCostEstimateModal: (openCostEstimateModal: boolean) => void;
  setSelectedSimilarityScore: (selectedSimilarityScore: number) => void;
  clearProjects: () => void;
  resetSimilarityScores: () => void;
  setSimilarityScores: (similarity: SimilarityScore[]) => void;
  toggleSelectedProjects: (selectedProjects: string) => void;
  setSelectedProjects: (projectIds: string[]) => void;
  clearSelectedProjects: () => void;
  setSimilaritySearchComponents: (similaritySearchComponents: any[]) => void;
  toggleSimilaritySearchComponent: (component: any) => void;
  setOpenSearchModal: (openSearchModal: boolean) => void;
  setOpenSimilaritySearch: (openSimilaritySearch: boolean) => void;
  setProjectView: (projectView: ProjectView) => void;
  setContactProjects: (contactProjects: any[]) => void;
  setProjects: (
    props: Pagination & {
      projects: any[];
    },
  ) => void;
  setRegionalFactors: (data: Pagination & { regionalFactors: any[] }) => void;
  updateSelectedProjectUrl: (galleryUrl: string) => void;
  setIsFetchingProject: (isFetchingProject: boolean) => void;
  setIsFetching: (isFetching: boolean) => void;
  setIsSearching: (isSearchingProjects: boolean) => void;
  setIsSavingProject: (isSavingProject: boolean) => void;
  clearSelectedProjectCostGroup: () => void;
  setSelectedProject: (selectedProject: Record<string, any>) => void;
  setForms: (forms: FormTab[]) => void;
  setProjectCostGroups: (
    props: Pagination & {
      costGroups: ProjectCostGroup[];
    },
  ) => void;
  setSelectedProjectCostGroup: (projectCostGroup: ProjectCostGroup) => void;
  updateSelectedCostGroupVersionByType: (
    columnType: string,
    versionDate: string,
  ) => void;
  deleteCostGroups: (costGroups: { id: string }[]) => void;
  deleteProjects: (projects: { id: string }[]) => void;
  upsertProjectCostGroup: (projectCostGroup: ProjectCostGroup) => void;
  upsertOrFilterProject: (project: Record<string, any>) => void;
  updateCostGroupCounterCosts: (component: any, costValue?: number) => void;
  updateCostGroupCounterQuantity: (
    component: any,
    quantityValue?: number,
  ) => void;
  sortCostGroupCounterComponents: (components?: any[]) => any[];
  setInitialCostGroupCounter: (
    values: { quantities: any; cost: any },
    componentsById: Record<string, any>,
  ) => void;
  setCostEstimateTab: (tabIndex?: number) => void;
  clearPagination: () => void;
  setOpenProjectFormSection: (openProjectFormSection: boolean) => void;
};

const processForms = (forms: FormTab[]) => {
  const groupedForms = groupBy(forms, (form) => form.category);
  let availableForms: Record<string, FormTab[]> = {};
  Object.keys(groupedForms).forEach((key) => {
    const sortedForms = sortBy(groupedForms[key], "order");
    availableForms = {
      ...availableForms,
      [key]: sortedForms.map((f) => ({
        ...f,
        components: transformFormComponents(f.components, f.id),
      })),
    };
  });

  return availableForms;
};

const getCostGroupsByType = (projectCostGroups: ProjectCostGroup[]) => {
  const projectCostGroupsByType: Record<string, ProjectCostGroup[]> = {};

  projectCostGroups.forEach((costGroup) => {
    const { type } = costGroup;
    const projectCostGroups = projectCostGroupsByType[type];
    if (projectCostGroups) {
      projectCostGroupsByType[type] = [...projectCostGroups, costGroup];
    } else {
      projectCostGroupsByType[type] = [costGroup];
    }
  });

  return projectCostGroupsByType;
};

const getCostGroupsReadOnlyData = (projectCostGroups: ProjectCostGroup[]) => {
  const projectCostGroupsByTypeAndDate: Record<string, any> = {};
  const projectCostGroupsVersionsByType: Record<string, string[]> = {};
  const selectedCostGroupVersionByType: Record<string, string> = {};

  projectCostGroups.forEach((costGroup) => {
    const { type, versionDate } = costGroup;
    projectCostGroupsByTypeAndDate[`${type}-${versionDate}`] = {
      ...costGroup,
      values: keyBy(costGroup.values, "componentId"),
    };
    if (projectCostGroupsVersionsByType[type]) {
      projectCostGroupsVersionsByType[type] = sortDates([
        ...projectCostGroupsVersionsByType[type],
        versionDate,
      ]);
    } else {
      projectCostGroupsVersionsByType[type] = [versionDate];
    }
  });

  Object.keys(projectCostGroupsVersionsByType).forEach((type) => {
    selectedCostGroupVersionByType[type] =
      projectCostGroupsVersionsByType[type][0];
  });

  return {
    projectCostGroupsByTypeAndDate,
    projectCostGroupsVersionsByType,
    selectedCostGroupVersionByType,
  };
};

const transformRegionalFactors = (regionalFactors: any[]) => {
  return regionalFactors.map((regionalFactor) => {
    const { id, city, factors } = regionalFactor;
    return {
      id,
      city,
      ...factors,
    };
  });
};

export const updateSelectedItem = <
  T extends {
    id?: string;
    isDeleted?: boolean;
  },
>(
  existingItem: T,
  updatedItem: T,
) => {
  if (
    existingItem?.id &&
    updatedItem?.id &&
    existingItem.id === updatedItem.id
  ) {
    return Boolean(updatedItem?.isDeleted) ? {} : updatedItem;
  }
  return existingItem;
};

const upsertOrFilterProjects = (projects: any[], project: any) => {
  const hasProject = projects.find((p) => p.id === project.id);

  return Boolean(project?.isDeleted)
    ? projects.filter((p) => p.id !== project.id)
    : hasProject
      ? projects.map((p) => (p.id === project.id ? project : p))
      : [project, ...projects];
};

const hasComponent = (componentId: string, componentIds: string[]) => {
  return componentIds.some((id) => id === componentId);
};

const persistedStateVersion = 0.2;

export const useProjectStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      shiftSelectedProjects: [],
      isFetchingProject: false,
      openProjectCostGroupModal: false,
      projectForms: [],
      regionalFactors: [],
      regionalFactorsPagination: {},
      projectRolesByComponent: {},
      formsByContactType: {
        [AllowedContactType.office]: [],
        [AllowedContactType.person]: [],
        [AllowedContactType.company]: [],
      },
      projectFormComponents: [],
      projectCostGroups: [],
      projectCostGroupsByType: {},
      projectCostGroupsReadonlyData: {
        projectCostGroupsByTypeAndDate: {},
        selectedCostGroupVersionByType: {},
        projectCostGroupsVersionsByType: {},
      },
      selectedProject: {},
      isSavingProject: false,
      projects: [],
      pagination: {},
      isFetching: false,
      isSearching: false,
      contactProjects: [],
      openSimilaritySearch: false,
      openSearchModal: false,
      projectView: ProjectView.Gallery,
      similaritySearchComponents: [],
      selectedProjects: [],
      similarityScoreById: {},
      minSimilarityScore: 0,
      similarityResourceIds: [],
      selectedSimilarityScore: 0,
      totalProjectsMatching: 0,
      openCostEstimateModal: false,
      costEstimateForm: {},
      similaritySearchProjects: [],
      isFetchingCostEstimate: false,
      costEstimateValues: {},
      costEstimationModalValues: {},
      similaritySearchModalValues: [],
      constructionPriceIndices: [],
      searchTerm: "",
      originalForms: [],
      costGroupCounter: {
        missingQuantities: [],
        quantityByCosts: {},
        quantityByValue: {},
      },
      projectDesignerAttachments: [],
      latestBKIDate: {},
      openProjectFormSection: false,
      setOpenProjectFormSection: (openProjectFormSection: boolean) =>
        set((state) => {
          state.openProjectFormSection = openProjectFormSection;
        }),
      clearPagination() {
        set((state) => {
          state.pagination = {};
        });
      },
      setCostEstimateTab(tabIndex) {
        set((state) => {
          if (!isNil(tabIndex) && Number.isFinite(tabIndex)) {
            state.costEstimateTab = tabIndex > 0 ? "quantity" : "cost";
          } else {
            state.costEstimateTab = undefined;
          }
        });
      },
      setProjectDesignerAttachments(attachments) {
        set((state) => {
          state.projectDesignerAttachments = attachments ?? [];
        });
      },
      setShiftSelectedProject: (project) =>
        set((state) => {
          const index = get().projects.findIndex((p) => p.id === project.id);
          const projectsIndex = [...get().shiftSelectedProjects, index];

          if (projectsIndex.length > 1) {
            const start = Math.min(...projectsIndex);
            const end = Math.max(...projectsIndex);

            let selectedProjects = get()
              .projects.slice(start, end)
              .map((p) => p.id);
            selectedProjects = uniq([
              ...selectedProjects,
              ...get().selectedProjects,
              project.id,
            ]);

            state.shiftSelectedProjects = [];
            state.selectedProjects = selectedProjects;
          } else {
            state.shiftSelectedProjects = [index];
            state.selectedProjects = [project.id];
          }
        }),
      updateCostGroupCounterCosts: (component, costValue) =>
        set((state) => {
          if (component?.properties?.referenceQuantity) {
            const { referenceQuantity } = component.properties;
            const { quantityByCosts, quantityByValue, missingQuantities } =
              get().costGroupCounter;
            let costComponentIds = quantityByCosts[referenceQuantity] ?? [];
            let quantities = missingQuantities;

            if (Number.isFinite(costValue)) {
              costComponentIds = hasComponent(component.id, costComponentIds)
                ? costComponentIds
                : [...costComponentIds, component.id];
              quantities =
                !Number.isFinite(quantityByValue[referenceQuantity]) &&
                !hasComponent(referenceQuantity, quantities)
                  ? [referenceQuantity, ...quantities]
                  : quantities;
            } else {
              costComponentIds = costComponentIds.filter(
                (id) => id !== component.id,
              );
              quantities = isEmpty(costComponentIds)
                ? quantities.filter((q) => q !== referenceQuantity)
                : quantities;
            }

            state.costGroupCounter.quantityByCosts[referenceQuantity] =
              costComponentIds;
            state.costGroupCounter.missingQuantities = quantities;
          }
        }),
      updateCostGroupCounterQuantity: (component, quantityValue) =>
        set((state) => {
          if (component?.id) {
            const referenceQuantity = component.id;
            const { missingQuantities, quantityByCosts } =
              get().costGroupCounter;
            let quantities = missingQuantities;

            if (Number.isFinite(quantityValue)) {
              quantities = quantities.filter((q) => q !== referenceQuantity);
            } else {
              quantities =
                !isEmpty(quantityByCosts[referenceQuantity]) &&
                !hasComponent(referenceQuantity, quantities)
                  ? [...quantities, referenceQuantity]
                  : quantities;
            }

            state.costGroupCounter.quantityByValue[referenceQuantity] =
              quantityValue;
            state.costGroupCounter.missingQuantities = quantities;
          }
        }),
      setInitialCostGroupCounter: (values, componentsById) =>
        set((state) => {
          const { quantities, cost } = values;
          const quantityByValue: Record<string, number | undefined> = {};
          const quantityByCosts: Record<string, string[]> = {};
          const missingQuantities: string[] = [];

          if (quantities) {
            Object.keys(quantities).forEach((key) => {
              const value = quantities[key];
              if (Number.isFinite(value)) {
                quantityByValue[key] = value;
                quantityByCosts[key] = [];
              }
            });
          }
          if (cost) {
            Object.keys(cost).forEach((key) => {
              const value = cost[key];
              const component = componentsById[key];
              const referenceQuantity =
                component?.properties?.referenceQuantity;

              if (!Number.isFinite(value) || !referenceQuantity) {
                return;
              }
              if (
                !Number.isFinite(quantityByValue[referenceQuantity]) &&
                !hasComponent(referenceQuantity, missingQuantities)
              ) {
                missingQuantities.push(referenceQuantity);
              }

              quantityByCosts[referenceQuantity] = [
                ...(quantityByCosts[referenceQuantity] ?? []),
                key,
              ];
            });
          }

          state.costGroupCounter.quantityByValue = quantityByValue;
          state.costGroupCounter.quantityByCosts = quantityByCosts;
          state.costGroupCounter.missingQuantities = missingQuantities;
        }),
      sortCostGroupCounterComponents: (components = []) => {
        const missingQuantities = get().costGroupCounter.missingQuantities;

        const topComponents: any[] = [];
        const componentsById = groupComponentsById(components, {});

        missingQuantities.forEach((id) => {
          const component = componentsById[id];
          if (component) {
            topComponents.push(component);
          }
        });
        const componentIds = topComponents.map((c) => c.id);

        return topComponents.concat(
          components.filter((c) => !componentIds.includes(c.id)),
        );
      },
      setConstructionPriceIndices: (constructionPriceIndices) =>
        set((state) => {
          const transformedConstructionPriceIndices = constructionPriceIndices
            .map((constructionPriceIndex) => {
              const { bauleistungen_am_bauwerk, ...rest } =
                constructionPriceIndex;
              return {
                ...rest,
                q1: bauleistungen_am_bauwerk?.february,
                q2: bauleistungen_am_bauwerk?.may,
                q3: bauleistungen_am_bauwerk?.august,
                q4: bauleistungen_am_bauwerk?.november,
              };
            })
            .sort((a, b) => parseInt(b.year) - parseInt(a.year));

          state.constructionPriceIndices = transformedConstructionPriceIndices;
        }),
      setSimilaritySearchModalValues: (values) =>
        set((state) => {
          state.similaritySearchModalValues = values;
        }),
      setCostEstimationModalValues: (updatedValues) =>
        set((state) => {
          const existingValues = state.costEstimationModalValues;
          state.costEstimationModalValues = {
            ...existingValues,
            ...updatedValues,
          };
        }),
      setIsFetchingProject: (isFetchingProject: boolean) =>
        set((state) => {
          state.isFetchingProject = isFetchingProject;
        }),
      setRegionalFactors: ({ regionalFactors, ...rest }) =>
        set((state) => {
          let factors = transformRegionalFactors(regionalFactors);

          state.regionalFactorsPagination = rest;
          state.regionalFactors = factors;
        }),
      setOpenProjectCostGroupModal: (openProjectCostGroupModal) =>
        set((state) => {
          state.openProjectCostGroupModal = openProjectCostGroupModal;
        }),
      deleteCostGroups: (deletedCostGroups) =>
        set((state) => {
          const costGroupIds = deletedCostGroups.map(
            (costGroup) => costGroup.id,
          );
          const costGroups = state.projectCostGroups.filter(
            (costGroup) => !costGroupIds.includes(costGroup.id),
          );

          state.projectCostGroupForm = undefined;
          state.selectedCostGroupId = undefined;
          state.projectCostGroups = costGroups;
          state.projectCostGroupsByType = getCostGroupsByType(costGroups);
          state.projectCostGroupsReadonlyData =
            getCostGroupsReadOnlyData(costGroups);
        }),
      deleteProjects: (deletedProjects) =>
        set((state) => {
          const projectIds = deletedProjects.map((p) => p.id);

          state.projects = state.projects.filter(
            (p) => !projectIds.includes(p.id),
          );
          state.selectedProject = projectIds.includes(state.selectedProject?.id)
            ? {}
            : state.selectedProject;
        }),
      setBKIValue: (bkiValue) =>
        set((state) => {
          state.bkiValue = bkiValue;
        }),
      setLatestBKIDate: (latestBKIDate) =>
        set((state) => {
          if (latestBKIDate) {
            state.latestBKIDate = {
              date: new Date(latestBKIDate),
              dateIso: latestBKIDate,
            };
          } else {
            state.latestBKIDate = {};
          }
        }),
      upsertOrFilterProject: (project) =>
        set((state) => {
          state.projects = upsertOrFilterProjects(state.projects, project);
          state.selectedProject = updateSelectedItem<Record<string, any>>(
            state.selectedProject,
            project,
          );
        }),
      clearCostEstimateValues: () =>
        set((state) => {
          state.costEstimateValues = {};
        }),
      clearProjectValues: () =>
        set((state) => {
          state.selectedProject = {};
          state.projectCostGroups = [];
          state.projectCostGroupsReadonlyData = {
            selectedCostGroupVersionByType: {},
            projectCostGroupsByTypeAndDate: {},
            projectCostGroupsVersionsByType: {},
          };
        }),
      setCostEstimateValues: (args) =>
        set((state) => {
          const costEstimateValues = processCostGroupEstimates(args);
          state.costEstimateValues = costEstimateValues;
        }),
      setIsFetchingCostEstimate: (isFetchingCostEstimate) =>
        set((state) => {
          state.isFetchingCostEstimate = isFetchingCostEstimate;
        }),
      setCostEstimateForm: (costEstimateForm = {}) =>
        set((state) => {
          state.costEstimateForm = costEstimateForm;
        }),
      setCostEstimateModal: (openCostEstimateModal: boolean) =>
        set((state) => {
          state.openCostEstimateModal = openCostEstimateModal;
        }),
      setSelectedProjects: (projectIds) =>
        set((state) => {
          state.selectedProjects = projectIds;
        }),
      setSelectedSimilarityScore: (selectedSimilarityScore) =>
        set((state) => {
          const similaritySearchProjects = Object.keys(
            state.similarityScoreById,
          ).filter((key) => {
            const score = state.similarityScoreById[key];
            return score >= selectedSimilarityScore;
          });

          state.similaritySearchProjects = similaritySearchProjects;
          state.selectedSimilarityScore = selectedSimilarityScore;
          state.totalProjectsMatching = similaritySearchProjects.length;
        }),
      clearProjects: () =>
        set((state) => {
          state.projects = [];
          state.pagination = {};
        }),
      resetSimilarityScores: () =>
        set((state) => {
          state.minSimilarityScore = 0;
          state.similarityScoreById = {};
          state.similarityResourceIds = [];
          state.totalProjectsMatching = 0;
          state.selectedSimilarityScore = 0;
          state.similaritySearchProjects = [];
        }),
      setSimilarityScores: (similarityScores) =>
        set((state) => {
          const scores = similarityScores.map(({ similarity, id }) => ({
            id,
            similarity: Math.trunc(similarity * 100),
          }));
          const minSimilarityScore = Math.min(
            ...scores.map((s) => s.similarity),
          );

          state.totalProjectsMatching = scores.length;
          state.minSimilarityScore = minSimilarityScore;
          state.selectedSimilarityScore = minSimilarityScore;
          state.similaritySearchProjects = scores.map((s) => s.id);
          state.similarityScoreById = scores.reduce(
            (acc: any, { id, similarity }) => {
              acc[id] = similarity;
              return acc;
            },
            {},
          );
          state.similarityResourceIds = scores.reverse().map((s) => s.id);
        }),
      toggleSelectedProjects: (id) =>
        set((state) => {
          const hasProject = state.selectedProjects.includes(id);
          state.selectedProjects = hasProject
            ? state.selectedProjects.filter((pId) => pId !== id)
            : [...state.selectedProjects, id];
        }),
      clearSelectedProjects: () =>
        set((state) => {
          state.selectedProjects = [];
        }),
      setSimilaritySearchComponents: (similaritySearchComponents) =>
        set((state) => {
          state.similaritySearchComponents = similaritySearchComponents;
        }),
      toggleSimilaritySearchComponent: (component) =>
        set((state) => {
          const components = get().similaritySearchComponents;
          const hasComponent = components.some((c) => c.id === component.id);

          state.similaritySearchComponents = hasComponent
            ? components.filter((c) => c.id !== component.id)
            : [...components, component];
        }),
      setOpenSearchModal: (openSearchModal) =>
        set((state) => {
          state.openSearchModal = openSearchModal;
        }),
      setOpenSimilaritySearch: (openSimilaritySearch) =>
        set((state) => {
          state.openSimilaritySearch = openSimilaritySearch;
        }),
      setProjectView: (projectView) =>
        set((state) => {
          state.projectView = projectView;
        }),
      setContactProjects: (contactProjects) =>
        set((state) => {
          state.contactProjects = contactProjects;
        }),
      updateSelectedProjectUrl: (galleryUrl) =>
        set((state) => {
          state.selectedProject.galleryUrl = galleryUrl;
        }),
      setIsFetching: (isFetching) =>
        set((state) => {
          state.isFetching = isFetching;
        }),
      setIsSearching: (isSearching) =>
        set((state) => {
          state.isSearching = isSearching;
        }),
      setProjects: ({ projects, ...pagination }) =>
        set((state) => {
          state.projects = projects || [];

          const docsCount =
            pagination?.docsCount ?? get().pagination.docsCount ?? 0;
          state.pagination = {
            docsCount,
            ...pagination,
          };
        }),
      setIsSavingProject: (isSavingProject) =>
        set((state) => {
          state.isSavingProject = isSavingProject;
        }),
      setSelectedProject: (selectedProject) =>
        set((state) => {
          state.selectedProject = selectedProject;
        }),
      updateSelectedCostGroupVersionByType: (columnType, versionDate) =>
        set((state) => {
          state.projectCostGroupsReadonlyData.selectedCostGroupVersionByType[
            columnType
          ] = versionDate;
        }),
      clearSelectedProjectCostGroup: () =>
        set((state) => {
          state.projectCostGroupForm = undefined;
          state.selectedCostGroupId = undefined;
        }),
      setForms: (forms) =>
        set((state) => {
          const groupedForms = processForms(forms);
          state.originalForms = [...forms];
          state.forms = groupedForms;
          state.projectForms = groupedForms[AvailableForms.Project];
          state.projectFormComponents = getComponentsFromForms(
            groupedForms[AvailableForms.Project],
          );
          state.formsByContactType = {
            [AllowedContactType.office]: groupedForms[AvailableForms.Office],
            [AllowedContactType.person]: groupedForms[AvailableForms.Persons],
            [AllowedContactType.company]: groupedForms[AvailableForms.Company],
          };
        }),
      upsertOrDeleteForm: (form) =>
        set((state) => {
          let forms = get().originalForms;
          const hasForm = forms.find((f) => f.id === form.id);

          forms = !hasForm
            ? [form, ...forms]
            : form.isDeleted
              ? forms.filter((f) => f.id !== form.id)
              : forms.map((f) => (f.id === form.id ? form : f));
          const groupedForms = processForms(forms);

          state.originalForms = forms;
          state.forms = groupedForms;
          state.projectForms = groupedForms[AvailableForms.Project];
          state.projectFormComponents = getComponentsFromForms(
            groupedForms[AvailableForms.Project],
          );
          state.formsByContactType = {
            [AllowedContactType.office]: groupedForms[AvailableForms.Office],
            [AllowedContactType.person]: groupedForms[AvailableForms.Persons],
            [AllowedContactType.company]: groupedForms[AvailableForms.Company],
          };
        }),
      setProjectCostGroups: ({ costGroups }) =>
        set((state) => {
          state.projectCostGroups = costGroups;
          state.projectCostGroupsByType = getCostGroupsByType(costGroups);
          state.projectCostGroupsReadonlyData =
            getCostGroupsReadOnlyData(costGroups);
        }),
      upsertProjectCostGroup: (costGroup) =>
        set((state) => {
          const costGroupExists = state.projectCostGroups.some(
            (cg) => cg.id === costGroup.id,
          );
          const costGroups = costGroupExists
            ? state.projectCostGroups.map((cg) =>
                cg.id === costGroup.id ? costGroup : cg,
              )
            : [costGroup, ...state.projectCostGroups];

          state.projectCostGroups = costGroups;
          state.projectCostGroupsByType = getCostGroupsByType(costGroups);
          state.projectCostGroupsReadonlyData =
            getCostGroupsReadOnlyData(costGroups);
        }),
      setSelectedProjectCostGroup: ({ id, resourceId, ...rest }) =>
        set((state) => {
          state.projectCostGroupForm = {
            ...rest,
          };
          state.selectedCostGroupId = id;
        }),
    })),
    {
      name: "projects-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        projectView: state.projectView,
        selectedProjects: state.selectedProjects,
        projectFormComponents: state.projectFormComponents,
        costEstimationModalValues: state.costEstimationModalValues,
        similaritySearchComponents: state.similaritySearchComponents,
        similaritySearchModalValues: state.similaritySearchModalValues,
      }),
    },
  ),
);

const ASSIGN_CONTACTS_PROJECT = gql`
  mutation ($input: ProjectAssignInput!) {
    assignContactsProject(input: $input)
  }
`;

const UNASSIGN_CONTACTS_PROJECT = gql`
  mutation ($input: ProjectUnassignInput!) {
    unassignContactsProject(input: $input)
  }
`;

const CREATE_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  mutation CreateProject($input: ProjectInput!) {
    createProject(input: $input) {
      ...ProjectFragment
    }
  }
`;

const UPDATE_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  mutation UpdateProject($input: ProjectUpdateInput!) {
    updateProject(input: $input) {
      ...ProjectFragment
    }
  }
`;

export const useCreateProject = () => {
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const [upsertResult, callCreateProject] = useMutation(CREATE_PROJECT);
  const { trackEventForOrganization } = useOrganization();

  const createProject = (variables: Record<string, any>) => {
    return callCreateProject(variables).then(({ data }) => {
      const project = data?.createProject ?? {};
      setSelectedProject(project);
      trackEventForOrganization("project-created", {
        projectName: project?.name,
      });
      return project;
    });
  };

  const { fetching } = upsertResult;

  return {
    createProject,
    createProjectLoader: fetching,
  };
};

export const useUpdateProject = () => {
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const [upsertResult, callUpdateProject] = useMutation(UPDATE_PROJECT);

  const updateProject = (variables: Record<string, any>) => {
    return callUpdateProject(variables).then(({ data }) => {
      if (data?.updateProject) {
        setSelectedProject(data.updateProject);
      }
      return data?.updateProject;
    });
  };

  const { fetching } = upsertResult;

  return {
    updateProject,
    updateProjectLoader: fetching,
  };
};

export const useAssignContactsProject = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callAssignContactsProject] = useMutation(
    ASSIGN_CONTACTS_PROJECT,
  );

  const assignContactsProject = (variables: Record<string, any>) => {
    return callAssignContactsProject(variables).catch((error) => {
      showNotification(AvailableNotificationTypes.Error, error.message);
    });
  };

  return {
    assignContactsProject,
    loading: result.fetching,
  };
};

export const useUnassignContactsProject = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callUnassignContactsProject] = useMutation(
    UNASSIGN_CONTACTS_PROJECT,
  );

  const unassignContactsProject = (variables: Record<string, any>) => {
    return callUnassignContactsProject(variables).catch((error) => {
      showNotification(AvailableNotificationTypes.Error, error.message);
    });
  };

  return {
    unassignContactsProject,
    loading: result.fetching,
  };
};

const CREATE_COST_GROUP = gql`
  ${COST_GROUP_FRAGMENT}
  mutation CreateCostGroup($input: CostGroupInput!) {
    createCostGroup(input: $input) {
      ...CostGroupFragment
    }
  }
`;

const UPDATE_COST_GROUP = gql`
  ${COST_GROUP_FRAGMENT}
  mutation UpdateCostGroup(
    $input: CostGroupUpdateInput!
    $options: CostGroupUpdateOptions!
  ) {
    updateCostGroup(input: $input, options: $options) {
      ...CostGroupFragment
    }
  }
`;

export const useCreateCostGroup = () => {
  const setSelectedProjectCostGroup = useProjectStore(
    (state) => state.setSelectedProjectCostGroup,
  );
  const upsertProjectCostGroup = useProjectStore(
    (state) => state.upsertProjectCostGroup,
  );
  const [result, callCreateCostGroup] = useMutation(CREATE_COST_GROUP);

  const createCostGroup = (variables: Record<string, any>) => {
    return callCreateCostGroup(variables).then(({ data }) => {
      const { createCostGroup = {} } = data;
      setSelectedProjectCostGroup(createCostGroup);
      if (!isEmpty(createCostGroup)) {
        upsertProjectCostGroup(createCostGroup);
      }
      return data;
    });
  };

  return {
    createCostGroup,
    loading: result.fetching,
  };
};

export const useUpdateCostGroup = () => {
  const setSelectedProjectCostGroup = useProjectStore(
    (state) => state.setSelectedProjectCostGroup,
  );
  const upsertProjectCostGroup = useProjectStore(
    (state) => state.upsertProjectCostGroup,
  );
  const [result, callUpdateCostGroup] = useMutation(UPDATE_COST_GROUP);

  const updateCostGroup = (variables: Record<string, any>) => {
    return callUpdateCostGroup(variables).then(({ data }) => {
      setSelectedProjectCostGroup(data?.updateCostGroup ?? {});
      if (!isEmpty(data?.updateCostGroup)) {
        upsertProjectCostGroup(data.updateCostGroup);
      }
      return data;
    });
  };

  return {
    updateCostGroup,
    loading: result.fetching,
  };
};

/***
 * write the following mutation
deleteCostGroups(
ids: [ID!]!
): [DeleteCostGroupResponse!]!
 */

const DELETE_COST_GROUPS = gql`
  mutation DeleteCostGroups($ids: [ID!]!) {
    deleteCostGroups(ids: $ids) {
      id: _id
      isDeleted
    }
  }
`;

export const useDeleteCostGroups = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callDeleteCostGroups] = useMutation(DELETE_COST_GROUPS);
  const handleDeleteCostGroups = useProjectStore(
    (state) => state.deleteCostGroups,
  );

  const deleteCostGroups = async (variables: Record<string, any>) => {
    return callDeleteCostGroups(variables)
      .then(({ data }) => {
        if (data?.deleteCostGroups) {
          handleDeleteCostGroups(data.deleteCostGroups);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error?.message);
      });
  };

  return {
    deleteCostGroups,
    loading: result.fetching,
  };
};

/**
 * write the following mutation
 * Mutation.deleteProjects(
deleteRelatedResources: Boolean!
ids: [ID!]!
): [DeleteProjectResponse!]!
type DeleteProjectResponse {
_id: ID
isDeleted: Boolean
}
*/

const DELETE_PROJECTS = gql`
  mutation DeleteProjects($deleteRelatedResources: Boolean!, $ids: [ID!]!) {
    deleteProjects(deleteRelatedResources: $deleteRelatedResources, ids: $ids) {
      id: _id
      isDeleted
    }
  }
`;

export const useDeleteProjects = () => {
  const [result, callDeleteProjects] = useMutation(DELETE_PROJECTS);
  const handleDeleteProjects = useProjectStore((state) => state.deleteProjects);

  const deleteProjects = async (variables: Record<string, any>) => {
    return callDeleteProjects(variables).then(({ data }) => {
      if (data?.deleteProjects) {
        handleDeleteProjects(data.deleteProjects);
      }
    });
  };

  return {
    deleteProjects,
    loading: result.fetching,
  };
};
