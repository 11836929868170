import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import {
  AggregationWidget,
  CostGroupWidget,
} from "@amenda-components/Dashboard";
import { FC, SyntheticEvent } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import { IconButton } from "@amenda-components/App";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { getWidgetLayouts } from "./common";
import { useDashboardStore } from "@amenda-domains/mutations";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const layoutConfig = {
  minW: 1,
  maxW: 4,
  minH: 1,
  maxH: 4,
  isDraggable: true,
  isResizable: true,
};

interface Props {
  isEditing: boolean;
  isLoading: boolean;
  componentCodeById: Record<
    string,
    {
      code: string;
      referenceQuantity: string;
    }
  >;
}

export const AggregationViewerGrid: FC<Props> = ({
  isEditing,
  isLoading,
  componentCodeById,
}) => {
  const removeWidget = useDashboardStore((state) => state.removeWidget);
  const availableWidgets = useDashboardStore((state) => state.availableWidgets);
  const updateWidgetsLayout = useDashboardStore(
    (state) => state.updateWidgetsLayout,
  );

  const handleModify = (layouts: any[], layout: any) => {
    if (isEditing) {
      updateWidgetsLayout(layouts);
    }
  };

  const handleMouseDown = (e: SyntheticEvent<HTMLDivElement>) => {
    if (!isEditing) {
      e.stopPropagation();
    }
  };

  const handleRemoveWidget = (widget: any) => () => {
    removeWidget(widget);
  };

  return (
    <ResponsiveReactGridLayout
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 400, xxs: 0 }}
      cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 1 }}
      rowHeight={90}
      onLayoutChange={handleModify}
      preventCollision={false}
      verticalCompact={true}
      layouts={getWidgetLayouts(availableWidgets)}
      className="mx-auto"
      autoSize={true}
      isBounded={true}
      isResizable={isEditing}
    >
      {availableWidgets?.map((widget) => {
        return (
          <div
            className={clsx(
              "select-none border border-gray-200 bg-white px-2 py-2 hover:z-10",
              {
                "amenda-widget": isEditing,
                "amenda-disable-dragging": !isEditing,
              },
            )}
            key={widget.id}
            data-grid={{
              ...layoutConfig,
              ...widget.layout,
            }}
            onMouseDownCapture={handleMouseDown}
          >
            {widget.properties.isCostGroup ? (
              <CostGroupWidget
                widget={widget}
                isEditingWidgets={isEditing}
                componentCodeById={componentCodeById}
              />
            ) : (
              <AggregationWidget
                loading={isLoading}
                widget={widget}
                isEditingWidgets={isEditing}
              />
            )}
            {isEditing && (
              <IconButton
                Icon={XMarkIcon}
                iconSize={0.5}
                label="Delete"
                variant="dangerAlt"
                onClick={handleRemoveWidget(widget)}
                className="absolute -left-2 -top-2"
              />
            )}
          </div>
        );
      })}
    </ResponsiveReactGridLayout>
  );
};
