import { BlockedRoutes, PaginationLimit } from "@amenda-constants";
import { FC, useEffect } from "react";

import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { HelperMessage } from "@amenda-components/App";
import { Link } from "react-router-dom";
import { ProjectSnippet } from "@amenda-components/Projects";
import { useGetAllProjects } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const RecentProjects: FC = () => {
  const { t } = useTranslation();
  const { getAllProjects, loading } = useGetAllProjects();
  const projects = useProjectStore((state) => state.projects);

  useEffect(() => {
    getAllProjects({
      paginationProps: {
        limit: PaginationLimit.dashboardPreviews,
      },
      selectProjectFields: {
        _id: 1,
        name: 1,
        galleryUrl: 1,
        tenantId: 1,
        address: 1,
      },
    });
  }, [getAllProjects]);

  return (
    <div className="h-full overflow-hidden bg-white">
      <div className="px-4 py-6">
        <h2 className="text-base text-gray-900">
          {t("Recent Project Updates")}
        </h2>
        <div className="mt-6 flow-root">
          <div className="flex justify-center py-2">
            <div className="grid w-full grid-cols-1">
              {projects.length >= 1 ? (
                <ProjectSnippet projects={projects} loader={loading} />
              ) : (
                <HelperMessage
                  Icon={BuildingOffice2Icon}
                  message="Get started by creating a new project."
                  helpText="Please create a Project first"
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-6">
          {projects.length >= 1 ? (
            <Link to="/projekte" className="amenda-button">
              {t("View all")}
            </Link>
          ) : (
            <Link
              to={BlockedRoutes.ProjectNew}
              className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50"
            >
              {t("Create New Project")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
