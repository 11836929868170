import { FC, ReactNode } from "react";

import { PlusIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface HelperMessageProps {
  Icon?: FC<any>;
  iconClassName?: string;
  message: string;
  helpText?: string;
  actionText?: string;
  className?: string;
  children?: ReactNode;
  hideActionIcon?: boolean;
  onClick?: () => void;
}

export const HelperMessage: FC<HelperMessageProps> = ({
  Icon,
  message,
  helpText,
  children,
  actionText,
  iconClassName,
  className = "h-80",
  hideActionIcon = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "flex w-full items-center justify-center rounded-lg hover:border-gray-400",
        className,
      )}
    >
      <div className="p-2 text-center">
        {Icon && (
          <Icon
            className={clsx("mx-auto text-gray-400", iconClassName, {
              "h-10 w-10": !iconClassName,
            })}
          />
        )}
        <h3 className="mt-2 text-sm text-gray-900">{t(message)}</h3>
        {helpText && (
          <p className="mt-1 text-sm text-gray-500">{t(helpText)}</p>
        )}
        {children}
        {onClick && (
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center border border-transparent bg-gray-800 px-3 py-2 text-sm text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2"
              onClick={onClick}
            >
              {!hideActionIcon && (
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              )}
              {actionText && t(actionText)}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
