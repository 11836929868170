import { FC, useEffect, useMemo } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { MainContainer, SidebarToggle } from "@amenda-components/Shared";
import {
  ProjectActionBar,
  ProjectFormTabs,
  ProjectSectionSidebar,
  ProjectSectionSidebarMobile,
} from "@amenda-components/Projects";
import {
  useAppStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { getQueryParams } from "@amenda-utils";
import { getStaticProjectTabs } from "./common";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  projectId?: string;
}

export const CreateOrEditProject: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const projectForms = useProjectStore((state) => state.projectForms);
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);

  const resourceId = projectId || selectedProject?.id;
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const availableForms = useMemo(() => projectForms || [], [projectForms]);
  const availableTabs = useMemo(() => {
    const tabs =
      availableForms?.map(({ name, id, order }) => ({
        value: id,
        label: name,
      })) || [];

    tabs.push(...getStaticProjectTabs(projectsGeneralPermissions));

    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const query = getQueryParams(search);
  const defaultTab = query.tab || availableTabs[0].value;

  useEffect(() => {
    if (sidebarOpen) {
      setOpenSidebar(false);
    }
  }, [sidebarOpen, setOpenSidebar]);

  return (
    <div className="flex h-full w-full flex-row">
      <ProjectSectionSidebar
        resourceId={resourceId}
        availableForms={availableForms}
        projectsGeneralPermissions={projectsGeneralPermissions}
      />
      <ProjectSectionSidebarMobile
        resourceId={resourceId}
        availableForms={availableForms}
        projectsGeneralPermissions={projectsGeneralPermissions}
      />
      <div className="h-screen w-full overflow-y-auto overscroll-y-contain pr-4">
        <MainContainer className="pt-6">
          {!isFetchingProject && (
            <div className="sticky top-0 z-30 w-full bg-white">
              <div className="flex w-full items-center justify-between">
                <div className="flex grow items-center">
                  <SidebarToggle />
                  <h3 className="amenda-page-title truncate">
                    {selectedProject?.name || t("New Project")}
                  </h3>
                </div>
                <div className="flex justify-end">
                  <ProjectActionBar isEditing={true} />
                </div>
              </div>
            </div>
          )}
          <div className="h-auto w-full pb-20 lg:pb-8">
            <ProjectFormTabs
              defaultTab={defaultTab}
              projectId={resourceId}
              projectForms={availableForms}
              projectFormTabs={availableTabs}
            />
          </div>
        </MainContainer>
      </div>
    </div>
  );
};
