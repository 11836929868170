import { FC, ReactNode } from "react";

import { ModalBase } from "@amenda-components/App";

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

export const FullScreenModal: FC<Props> = ({ isOpen, onClose, children }) => {
  return (
    <ModalBase size="full" isOpen={isOpen} onClose={onClose}>
      <div className="flex h-full w-full">{children}</div>
    </ModalBase>
  );
};
