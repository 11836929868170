import { FC, ReactNode } from "react";

import { IconButton } from "@amenda-components/App";

interface ChildrenProps {
  IconButton: typeof IconButton;
}
interface Props {
  className?: string;
  children: (props: ChildrenProps) => ReactNode;
}

export const ActionBar: FC<Props> = ({
  children,
  className = "flex flex-row justify-end py-2",
}) => {
  return (
    <div className={className}>
      <div className="flex flex-row items-center justify-end gap-x-1.5">
        {children({
          IconButton,
        })}
      </div>
    </div>
  );
};
