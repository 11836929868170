import { Box, Column, Fragment } from "@amenda-components/PdfBuilder";
import { FC, memo, useEffect } from "react";
import {
  templateComponents,
  templateLayoutComponents,
} from "@amenda-components/Templates";

import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { PanPinchZoom } from "@amenda-components/App";
import { groupComponentsByParent } from "@amenda-utils";
import { useTemplatesStore } from "@amenda-domains/mutations";

interface Props {
  template?: any;
  globalProps: any;
  format?: string;
}

interface TemplateLayoutConfig {
  height: number;
  width?: number;
  minScale: number;
  centerOnInit: boolean;
}

const ComponentTemplateLayout = memo(
  ({ template, globalProps }: Pick<Props, "template" | "globalProps">) => {
    if (!template) {
      return null;
    }
    const pdfComponentTree = groupComponentsByParent(template);
    return (
      <ComponentTreeRenderer
        config={pdfComponentTree}
        globalProps={globalProps}
        customComponents={templateComponents}
        customLayoutComponents={{
          ...templateLayoutComponents,
          Box,
          Column,
          Fragment,
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.template) !== JSON.stringify(nextProps.template)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.globalProps) !==
      JSON.stringify(nextProps.globalProps)
    ) {
      return false;
    } else {
      return true;
    }
  },
);

const getStyle = (templateLayoutConfig: TemplateLayoutConfig) => {
  const height = templateLayoutConfig.height;
  const width = templateLayoutConfig.width;

  return {
    height: height
      ? Number(height * templateLayoutConfig.minScale) + "px"
      : "fit-content",
    width: width
      ? Number(width * templateLayoutConfig.minScale) + "px"
      : "100%",
  };
};

export const TemplateBuilder: FC<Props> = ({
  template,
  globalProps,
  format = "a4",
}) => {
  const setInitialPhotoSlots = useTemplatesStore(
    (state) => state.setInitialPhotoSlots,
  );

  const templateLayoutConfigByType: Record<string, TemplateLayoutConfig> = {
    a4: {
      height: 3508,
      width: 2480,
      minScale: 0.3,
      centerOnInit: true,
    },
    a3: {
      height: 3508,
      width: 4861,
      minScale: 0.21,
      centerOnInit: true,
    },
  };
  const templateLayoutConfig = templateLayoutConfigByType[format];

  useEffect(() => {
    if (template) {
      setInitialPhotoSlots(template);
    }
  }, [template, setInitialPhotoSlots]);

  return (
    <PanPinchZoom
      key={globalProps.templateId}
      maximumScale={1.0}
      limitToBounds={true}
      centerZoomedOut={true}
      initialScale={templateLayoutConfig.minScale}
      minimumScale={templateLayoutConfig.minScale}
      centerOnInit={templateLayoutConfig.centerOnInit}
      disabled={true}
      wrapperClass="shadow-sm border border-gray-100"
      wrapperStyle={getStyle(templateLayoutConfig)}
    >
      <ComponentTemplateLayout
        template={template}
        globalProps={{
          ...globalProps,
          scale: templateLayoutConfig.minScale,
        }}
      />
    </PanPinchZoom>
  );
};
