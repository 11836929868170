import { Tooltip } from "@amenda-components/App";
import { useAppStore } from "@amenda-domains/mutations";

export const SidebarToggle = () => {
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);

  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  return (
    <>
      {sidebarCollapsed && (
        <Tooltip id="sidebar-toggle" message="Toggle Sidebar">
          <div className="flex flex-row items-center pr-2">
            <img
              alt="Toggle Sidebar"
              className="h-4 w-4 cursor-pointer"
              src={
                sidebarCollapsed
                  ? "/images/sidebarExpand.svg"
                  : "/images/sidebarCollapse.svg"
              }
              onClick={toggleSidebar}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};
