import { FC, useEffect, useState } from "react";

import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { CostGroupSelectItem } from "./CostGroupSelectItem";
import { Select } from "@amenda-components/FormComponents";
import { formatDate } from "@amenda-utils";
import { useGetUsersById } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";

export const ReadOnlySelectCostGroupVersion: FC<
  Pick<ComponentsTreeViewRowProps, "column">
> = ({ column: { id } }) => {
  const {
    selectedCostGroupVersionByType,
    projectCostGroupsVersionsByType,
    projectCostGroupsByTypeAndDate,
  } = useProjectStore((state) => state.projectCostGroupsReadonlyData);
  const updateSelectedCostGroupVersionByType = useProjectStore(
    (state) => state.updateSelectedCostGroupVersionByType,
  );
  const [users, setUsers] = useState<any[]>([]);
  const { getUsersById } = useGetUsersById();
  const projectCostGroups = useProjectStore((state) => state.projectCostGroups);

  const selectedVersion = selectedCostGroupVersionByType[id];
  const versions =
    projectCostGroupsVersionsByType[id]?.map((versionDate) => {
      const costGroup = projectCostGroupsByTypeAndDate[`${id}-${versionDate}`];

      return {
        value: versionDate,
        label: formatDate(versionDate),
        ownerId: costGroup?.ownerId,
        bkiDate: costGroup?.bkiDate,
      };
    }) || [];

  useEffect(() => {
    const ownerIds = projectCostGroups
      ?.map((costGroup) => costGroup?.ownerId)
      ?.filter(Boolean) as string[];

    if (ownerIds) {
      getUsersById(ownerIds, setUsers);
    }
  }, [projectCostGroups, getUsersById]);

  return (
    <div className="py-2">
      <Select
        className="h-8 py-1"
        selectedOption={selectedVersion}
        options={versions}
        showErrorMessage={false}
        optionChildren={(option, selected) => (
          <CostGroupSelectItem
            users={users}
            option={option}
            selected={selected}
          />
        )}
        onChange={(versionDate) =>
          updateSelectedCostGroupVersionByType(id, versionDate)
        }
      />
    </div>
  );
};
