import {
  AttachmentDetailsForm,
  ReadonlyAttachmentDetails,
} from "@amenda-components/Gallery";
import { HelperMessage, LoaderWrapper } from "@amenda-components/App";

import { FC } from "react";
import { useAttachmentStore } from "@amenda-domains/mutations";

interface Props {
  loading: boolean;
}

export const AttachmentDetails: FC<Props> = ({ loading }) => {
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const isEditing = useAttachmentStore(
    (state) => state.isEditingAttachmentDetails,
  );

  if (!selectedAttachment) {
    return (
      <HelperMessage
        helpText="Attachment is missing"
        message="No results found"
        className="mt-4 h-8 px-2"
      />
    );
  } else if (loading) {
    return <LoaderWrapper />;
  } else if (isEditing) {
    return <AttachmentDetailsForm />;
  }
  return <ReadonlyAttachmentDetails />;
};
