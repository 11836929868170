import { Avatar, HelperMessage, LoaderWrapper } from "@amenda-components/App";
import { FC, useEffect, useState } from "react";

import { AppRoutes } from "@amenda-types";
import { flattenUserDetails } from "./common";
import { isEmpty } from "lodash";
import { useGetContact } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "@amenda-domains/mutations";

export const CompaniesByContact: FC = () => {
  const [company, setCompany] = useState<any>({});
  const { getContact, loading } = useGetContact();
  const selectedUser = useUsersStore((state) => state.selectedUser);
  const navigate = useNavigate();

  const fUser = flattenUserDetails(selectedUser);
  const fCompany = flattenUserDetails(company);

  const viewCompany = () => {
    const path = `${AppRoutes.Contacts}/${company?.id}`;

    navigate(path);
  };

  useEffect(() => {
    const id = Array.isArray(fUser?.company)
      ? fUser?.company[0]
      : fUser?.company;

    if (id) {
      getContact({
        id,
        skipLoading: true,
        callback: setCompany,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }

    return () => {
      setCompany({});
    };
  }, [fUser?.company, getContact]);

  if (loading) {
    return <LoaderWrapper className="h-32" />;
  } else if (isEmpty(company)) {
    return (
      <HelperMessage message="Contact has not been linked to a company yet" />
    );
  }
  return (
    <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div
        onClick={viewCompany}
        className="flex cursor-pointer space-x-2 border border-gray-300 bg-white px-4 py-5 hover:bg-gray-50"
      >
        <div className="px-2">
          <Avatar
            className="h-12 w-12 text-base"
            name={fCompany?.companyName || fCompany?.email}
          />
        </div>
        <div>
          <p className="text-left text-base text-gray-900 hover:text-gray-600">
            {fCompany?.companyName}
          </p>
          <p className="text-left text-sm text-gray-500">
            {fCompany?.address?.name ?? ""}
          </p>
        </div>
      </div>
    </div>
  );
};
