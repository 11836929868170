import { Control, Controller, useController } from "react-hook-form";
import { FC, memo, useRef } from "react";

import { TimelineActivityPicker } from "./TimelineActivityPicker";
import { TimelineContactsSearch } from "./TimelineContactsSearch";
import getCaretCoordinates from "textarea-caret";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
  searchTerm: string;
  textAreaClassName?: string;
  caretPosition: { top: number; left: number };
  cursorPosition: number | null;
  showTimelineContactsSearch: boolean;
  setTimelineContactsSearch: (value: boolean) => void;
  setSearchTerm: (searchTerm: string) => void;
  setCursorPosition: (position: number) => void;
  setCaretPosition: (position: { top: number; left: number }) => void;
  onBlur?: () => void;
}

export const TimelineBody: FC<Props> = memo(
  ({
    control,
    searchTerm,
    caretPosition,
    cursorPosition,
    textAreaClassName,
    showTimelineContactsSearch,
    onBlur,
    setSearchTerm,
    setCursorPosition,
    setCaretPosition,
    setTimelineContactsSearch,
  }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation();
    const {
      field: { value, onChange },
    } = useController({
      control,
      name: "body",
    });

    const handleInputChange = (e: any) => {
      const inputValue = e.target.value;
      onChange(inputValue);
      setCursorPosition(e.target.selectionStart);

      const words = inputValue?.split(/\s+/);
      const lastWord = words[words.length - 1];
      const caret = getCaretCoordinates(e.target, e.target.selectionEnd);
      setCaretPosition({ top: caret.top, left: caret.left });

      if (lastWord?.startsWith("@")) {
        setTimelineContactsSearch(true);
        setSearchTerm(lastWord.replace("@", ""));
      } else {
        setTimelineContactsSearch(false);
      }
    };

    const onChangeTransform = (name: string) => {
      const words = value.split(/\s+/);
      const lastWordIndex = words.length - 1;
      const lastWord = words[lastWordIndex];

      if (lastWord?.startsWith("@")) {
        words[lastWordIndex] = "@" + name;
        return words.join(" ");
      }
      return value;
    };

    const shiftFocus = (name: string) => {
      if (textAreaRef?.current) {
        textAreaRef.current.focus();
        const newPosition = cursorPosition
          ? cursorPosition + name.length
          : name.length;
        textAreaRef.current.setSelectionRange(newPosition, newPosition);
      }
    };

    const handleSelectMentions = (name: string) => {
      onChange(onChangeTransform(name));
      setTimelineContactsSearch(false);
      shiftFocus(name);
    };

    return (
      <div className="relative grow">
        <TimelineContactsSearch
          className={textAreaClassName}
          show={showTimelineContactsSearch}
          searchTerm={searchTerm}
          caretPosition={caretPosition}
          setSearchTerm={setSearchTerm}
          selectContacts={handleSelectMentions}
        />
        <div className="relative w-full">
          <textarea
            ref={textAreaRef}
            className="min-h-[4rem] w-full border border-gray-200 bg-gray-100 pb-2 pr-24 text-sm text-gray-600 focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-gray-200"
            placeholder={t("Activity") + "..."}
            value={value}
            onBlur={onBlur}
            onChange={handleInputChange}
          />
          <div className="absolute bottom-4 right-0 mr-4 mt-2">
            <Controller
              name="activity"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TimelineActivityPicker
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);
