import { Bars4Icon, CubeIcon, UsersIcon } from "@heroicons/react/24/outline";

import { FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { getNotFoundWidgetOptions } from "./common";
import { useSettingsStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const NotFoundWidget: FC = () => {
  const { t } = useTranslation();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const options = getNotFoundWidgetOptions(
    {
      contacts: UsersIcon,
      projects: Bars4Icon,
      unitPrice: CubeIcon,
    },
    permissions,
  );

  return (
    <div className="h-full w-full max-w-7xl bg-white px-4 py-4">
      <h2 className="text-lg text-gray-900">{t("Not found")}</h2>
      <p className="mt-1 text-sm text-gray-500">
        {t(
          "We were not able to find what you were looking for. Please go back and try again or select from one of the below Options",
        )}
      </p>
      <ul className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
        {options.map(
          ({
            key,
            link,
            Icon,
            title,
            description,
            iconBackground,
            iconForeground,
          }) => (
            <li key={key} className="flow-root">
              <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                <div
                  className={clsx(
                    iconBackground,
                    iconForeground,
                    "flex h-16 w-16 shrink-0 items-center justify-center rounded-lg",
                  )}
                >
                  <Icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-sm text-gray-900">
                    <Link className="focus:outline-none" to={link}>
                      <span className="absolute inset-0" aria-hidden="true" />
                      {t(title)}
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{t(description)}</p>
                </div>
              </div>
            </li>
          ),
        )}
      </ul>
      <div className="mt-4 flex">
        <Link
          className={clsx("text-sm text-indigo-600 hover:text-indigo-500")}
          to="/projekte"
        >
          {t("Or Browse All Projects")}
          <span aria-hidden="true"> &rarr;</span>
        </Link>
      </div>
    </div>
  );
};
