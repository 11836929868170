import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { IconButtonBase, Tooltip } from "@amenda-components/App";
import { PlusIcon, SheetIcon } from "lucide-react";
import { useSettingsStore, useUsersStore } from "@amenda-domains/mutations";

import { ContactDirectoryProps } from "./types";
import { CreateContactModal } from "./CreateContactModal";
import { FC } from "react";
import { GeneralPermissionTypes } from "@amenda-types";
import { useLocation } from "react-router-dom";

interface Props extends ContactDirectoryProps {
  isCollection: boolean;
}

export const ContactListFooter: FC<Props> = ({ canCreateUser, ...props }) => {
  const { pathname } = useLocation();
  const selectedUser = useUsersStore((state) => state.selectedUser);
  const setCreateContactModal = useUsersStore(
    (state) => state.setCreateContactModal,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );

  const isEditingUser = pathname.endsWith(`/${selectedUser?.id}/bearbeiten`);

  const handleOpenUsersTableView = () => {
    setOpenUsersTableView(true);
  };

  const handleOpenModal = () => {
    setCreateContactModal(true);
  };

  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );

  return (
    <>
      <CreateContactModal {...props} />
      <div className="sticky bottom-0 flex w-full justify-end px-4 py-2">
        <div className="flex flex-col space-y-2">
          <div className="mt-0 flex items-center space-x-2">
            {!isEditingUser && (
              <div className="flex justify-end">
                <Tooltip id="tableView" message="Open table view">
                  <IconButtonBase
                    size="sm"
                    className="hidden p-1 lg:block"
                    variant="secondary"
                    label="Open table view"
                    onClick={handleOpenUsersTableView}
                  >
                    <SheetIcon className="h-6 w-6 text-gray-900" />
                  </IconButtonBase>
                </Tooltip>
                {canCreateUser &&
                  contactsGeneralPermissions[GeneralPermissionTypes.Create] && (
                    <div className="ml-2 flex">
                      <Tooltip id="newContact" message="Add new Contact">
                        <IconButtonBase
                          size="sm"
                          variant="round"
                          className="p-1"
                          label="Open table view"
                          onClick={handleOpenModal}
                        >
                          <PlusIcon className="h-6 w-6 text-white" />
                        </IconButtonBase>
                      </Tooltip>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
