import { FC, ReactNode } from "react";

import { Spinner } from "@amenda-components/App";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface LoaderWrapperProps {
  className?: string;
  size?: number;
  message?: string;
  borderWidth?: number;
  children?: ReactNode;
  spinnerSize?: "sm" | "md" | "lg" | "xs";
  variant?:
    | "primary"
    | "secondary"
    | "default"
    | "danger"
    | "warning"
    | "outline"
    | "dangerAlt";
}
export const LoaderWrapper: FC<LoaderWrapperProps> = ({
  className = "h-96",
  children,
  borderWidth,
  message = "Loading",
  variant = "secondary",
  spinnerSize = "md",
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("flex flex-col items-center justify-center", className)}
    >
      <Spinner
        spinnerSize={spinnerSize}
        variant={variant}
        borderWidth={borderWidth}
      />
      <span className="pt-2 text-sm">{t(message)}...</span>
      {children}
    </div>
  );
};
