import { FC, useEffect, useState } from "react";

import { AppRoutes } from "@amenda-types";
import { ContactProfileCardBase } from "@amenda-components/Contacts";
import { Link } from "@amenda-components/App";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  contacts: any[];
}

export const ContactResults: FC<Props> = ({ contacts }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [results, setResults] = useState<any[]>([]);
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const handleClick = (contact: any) => {
    let path: string = contact?.type
      ? AppRoutes.Contacts
      : AppRoutes.Colleagues;
    path = `${path}/${contact.id}`;

    toggleSearchModal(false);
    navigate(path);
  };

  useEffect(() => {
    setResults(contacts.slice(0, 4));
  }, [contacts]);

  if (isEmpty(contacts)) {
    return null;
  }
  return (
    <div className="mt-2">
      <div>
        <p className="mb-1 mt-2 text-sm text-gray-900">{t("Found Contacts")}</p>
      </div>
      <div className="grid grid-cols-1">
        {results?.map((c) => (
          <div key={c.id} className="truncate px-2 py-2">
            <div className="relative flex w-full items-center space-x-3 py-3">
              <Link
                className="flex items-center hover:no-underline"
                onClick={() => handleClick(c)}
              >
                <ContactProfileCardBase contact={c} />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
