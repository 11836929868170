import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Button } from "@amenda-components/App";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ErrorDetectionFallbackProps {
  resetError: () => void;
}

export const ErrorDetectionFallback: FC<ErrorDetectionFallbackProps> = ({
  resetError,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    resetError();
    window?.location.reload();
  };

  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm text-yellow-800">
            {t("Something went wrong")}
          </h3>
          <p className="font-display text-sm text-yellow-700">
            {t(
              "An error has caused the app to crash, click refresh to restore the app",
            )}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <Button variant="danger" onClick={handleClick}>
              {t("Refresh")}
              <ArrowPathIcon className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
