import { Button, SimilarityRangeSlider } from "@amenda-components/App";

import { ArrowRightIcon } from "lucide-react";
import { FC } from "react";
import { Transition } from "@headlessui/react";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  bottomSheetHeight: number;
}

export const SimilaritySearchResultsFilter: FC<Props> = ({
  bottomSheetHeight,
}) => {
  const { t } = useTranslation();
  const openSimilaritySearch = useProjectStore(
    (state) => state.openSimilaritySearch,
  );
  const minSimilarityScore = useProjectStore(
    (state) => state.minSimilarityScore,
  );
  const selectedSimilarityScore = useProjectStore(
    (state) => state.selectedSimilarityScore,
  );
  const setSelectedSimilarityScore = useProjectStore(
    (state) => state.setSelectedSimilarityScore,
  );
  const totalProjectsMatching = useProjectStore(
    (state) => state.totalProjectsMatching,
  );
  const setSelectedProjects = useProjectStore(
    (state) => state.setSelectedProjects,
  );
  const similarityScoreById = useProjectStore(
    (state) => state.similarityScoreById,
  );
  const setCostEstimateModal = useProjectStore(
    (state) => state.setCostEstimateModal,
  );

  const handleCostEstimate = () => {
    setCostEstimateModal(true);
  };

  const handleSelectProjects = (threshHold: number) => {
    const qualifiedProjectIds = Object.keys(similarityScoreById).filter(
      (key) => similarityScoreById[key] >= threshHold,
    );

    setSelectedSimilarityScore(threshHold);
    setSelectedProjects(qualifiedProjectIds);
  };

  return (
    <Transition
      show={openSimilaritySearch && bottomSheetHeight < 125}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="mb-4 flex w-full flex-col items-center px-2 md:px-4 lg:px-8">
        <div className="grid w-full grid-cols-3 gap-4">
          <div className="col-span-1" />
          <div className="col-span-1">
            <SimilarityRangeSlider
              mini={true}
              min={minSimilarityScore}
              range={selectedSimilarityScore}
              className="w-full"
              onChange={handleSelectProjects}
            >
              {({ range }) => (
                <p className="font-apercu pt-2 text-sm">
                  {totalProjectsMatching} {t("projects with min")} {range}%{" "}
                  {t("similarity")}
                </p>
              )}
            </SimilarityRangeSlider>
          </div>
          <div className="col-span-1 flex justify-end">
            <div>
              <Button
                className="px-1 text-left text-sm"
                size="md"
                onClick={handleCostEstimate}
              >
                {t("Cost estimate")}
                <ArrowRightIcon className="ml-1 h-7 w-7" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
