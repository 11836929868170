import { FC, useState } from "react";

import { BCFImage } from "./BCFImage";
import { Topic } from "@parametricos/bcf-js";
import { UploadBCFModal } from "./UploadBCFModal";
import { ViewSelectedBCF } from "./ViewSelectedBCF";
import { isEmpty } from "lodash";

export const BCFDemo: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<null | string>(null);
  const [bcfTopics, setBcfTopics] = useState<Topic[]>([]);
  const [loading, setLoading] = useState(false);
  const [openFullScreenModal, setOpenFullScreenModal] = useState(false);
  const [selectedBcfTopic, setSelectedBcfTopic] = useState<Topic>();

  const handleSelectBcfTopic = (bcfTopic: Topic) => () => {
    setSelectedBcfTopic(bcfTopic);
    setOpenFullScreenModal(true);
  };

  return (
    <div className="w-full">
      <ViewSelectedBCF
        openModal={openFullScreenModal}
        selectedBcfTopic={selectedBcfTopic}
        setSelectedBcfTopic={setSelectedBcfTopic}
        setOpenModal={setOpenFullScreenModal}
      />
      {!isEmpty(bcfTopics) && (
        <div className="grid-cols sm:cols-2 my-3 grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
          {bcfTopics.map((bcfTopic, i) => (
            <div
              className="w-full pb-4"
              key={`${bcfTopic.markup?.topic.guid}+${i}`}
              onClick={handleSelectBcfTopic(bcfTopic)}
            >
              <BCFImage bcfTopic={bcfTopic} size="pb-10/12" magicSize="xl" />
              <p className="truncate text-left text-lg text-gray-900">
                {bcfTopic.markup?.topic.description}
              </p>
              <p className="truncate text-left text-sm text-gray-500">
                {bcfTopic.markup?.topic.title}
              </p>
              <p className="truncate text-left text-sm text-gray-500">
                {bcfTopic.markup?.topic.creation_author}
              </p>
              <p className="truncate text-left text-sm text-gray-500">
                Status: Open
              </p>
            </div>
          ))}
        </div>
      )}
      <UploadBCFModal
        openModal={openModal}
        loading={loading}
        rejectionReason={rejectionReason}
        setOpenModal={setOpenModal}
        setBcfTopics={setBcfTopics}
        setLoading={setLoading}
        setRejectionReason={setRejectionReason}
      />
    </div>
  );
};
