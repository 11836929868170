import { FC, useRef } from "react";

import { TimelineInlineContactSearch } from "./TimelineInlineContactSearch";
import { Transition } from "@headlessui/react";
import clsx from "clsx";

interface Props {
  show: boolean;
  searchTerm: string;
  caretPosition: { top: number; left: number };
  className?: string;
  selectContacts: (name: string) => void;
  setSearchTerm: (searchTerm: string) => void;
}

export const TimelineContactsSearch: FC<Props> = ({
  show,
  searchTerm,
  caretPosition,
  className = "w-80",
  setSearchTerm,
  selectContacts,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const menuWidth = menuRef?.current?.getBoundingClientRect()?.width;
  const parentWidth = parentRef?.current?.getBoundingClientRect()?.width;
  const maximumLeftPosition = Number(parentWidth) - Number(menuWidth);

  const left =
    caretPosition.left > maximumLeftPosition
      ? maximumLeftPosition
      : caretPosition.left;

  return (
    <>
      <div className="w-full" ref={parentRef} />
      <div className={className} ref={menuRef} />
      <Transition
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          ref={containerRef}
          className={clsx(
            "absolute bottom-0 z-50 mb-[4.25rem] divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            className,
          )}
          style={{
            left,
          }}
        >
          <TimelineInlineContactSearch
            menuIsOpen={show}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectContacts={selectContacts}
          />
        </div>
      </Transition>
    </>
  );
};
