import { ContactProfileCardBase } from "./ContactProfileCard";
import { FC } from "react";
import { IconButton } from "@amenda-components/App";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { Option } from "@amenda-types";
import { TrashIcon } from "lucide-react";

interface Props {
  selectedRoles?: string[];
  availableLabels: Option[];
  contact: any;
  disabled?: boolean;
  readOnly?: boolean;
  handleRemove?: () => void;
  handleUpdate?: (roles: string[]) => void;
}

export const ContactLabelledInput: FC<Props> = ({
  contact,
  readOnly,
  selectedRoles,
  availableLabels,
  handleUpdate,
  handleRemove,
}) => {
  const getLabel = () => {
    if (selectedRoles && availableLabels) {
      const foundOption = availableLabels.find(({ value }) =>
        selectedRoles?.includes(value),
      );

      return selectedRoles.length > 1
        ? `${foundOption?.label}, ...`
        : foundOption?.label;
    }
  };

  const handleOption = (option: Option) => {
    const roles = selectedRoles?.includes(option.value)
      ? selectedRoles.filter((role) => role !== option.value)
      : [...(selectedRoles ?? []), option.value];

    handleUpdate?.(roles);
  };

  return (
    <div className="border border-gray-300 bg-white px-3 py-2 hover:border-gray-500">
      <div className="w-full pb-2">
        {readOnly ? (
          <div className="font-apercu text-xs text-gray-500">{getLabel()}</div>
        ) : (
          <OperationsMenu
            label="Select role"
            menuItemsClassName=""
            options={availableLabels}
            selectedOption={selectedRoles}
            labelClassName="text-xs text-gray-500"
            onChange={handleOption}
          />
        )}
      </div>
      <div className="flex w-full flex-row items-center justify-between">
        <ContactProfileCardBase contact={contact} />
        <div className="h-7 items-center justify-between">
          {handleRemove && (
            <IconButton
              label="Delete item"
              iconSize={1}
              className="mr-4 text-gray-600 md:mr-0"
              Icon={TrashIcon}
              onClick={handleRemove}
            />
          )}
        </div>
      </div>
    </div>
  );
};
