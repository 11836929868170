import {
  ArrowUpRightIcon,
  BuildingOfficeIcon,
  InformationCircleIcon,
  PhotoIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import { FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const quickActions = [
  {
    icon: PhotoIcon,
    name: "Medien",
    href: "/medien",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    text: "Alle Medien strukturiert und durchsuchbar an einem Ort.",
  },
  {
    icon: BuildingOfficeIcon,
    name: "Projekte",
    href: "/projekte",
    iconForeground: "text-blue-700",
    iconBackground: "bg-blue-50",
    text: "Alle Ihre Projekte an einem Ort.",
  },
  {
    icon: UsersIcon,
    name: "Kontaktverzeichnis",
    href: "/kontaktverzeichnis",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    text: "Ihre Bürokontakte und Firmen.",
  },
  {
    icon: InformationCircleIcon,
    name: "Hilfe",
    href: "#",
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
    text: "Das Hilfe Zentrum rund um die Funktionen von amenda.",
  },
];

export const QuickActions: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-grow border-b border-gray-200 md:border">
      <div className="divide-y divide-gray-200 overflow-hidden sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <h2 className="sr-only" id="quick-links-title">
          {t("Quick links")}
        </h2>
        {quickActions.map((action, i) => (
          <div key={action.name} className="group relative bg-white p-6">
            <div>
              <span
                className={clsx(
                  action.iconBackground,
                  action.iconForeground,
                  "inline-flex p-3 ring-4 ring-white",
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg">
                <Link to={action.href} className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.name}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{action.text}</p>
            </div>
            <ArrowUpRightIcon className="pointer-events-none absolute right-6 top-6 h-6 w-6 text-gray-300 group-hover:text-gray-400" />
          </div>
        ))}
      </div>
    </div>
  );
};
