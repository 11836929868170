import { FC, useEffect, useState } from "react";

import { CloudUploadIcon } from "lucide-react";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  isSubmitting: boolean;
}

export const SaveIndicator: FC<Props> = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const isSubmitted = useAppStore((state) => state.isSubmitted);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const setIsSubmitted = useAppStore((state) => state.setIsSubmitted);

  useEffect(() => {
    if (isSubmitted) {
      setShowSavedMessage(true);

      const timer = setTimeout(() => {
        setShowSavedMessage(false);
        setIsSubmitted(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitted, setIsSubmitted]);

  return (
    <div className="flex items-center gap-1 text-gray-700">
      {isSubmitting && <span className="text-xs">{t("Saving...")}</span>}
      <span
        className={clsx("text-xs transition duration-200 ease-in-out", {
          hidden: !showSavedMessage,
          block: showSavedMessage,
        })}
      >
        {t("Data saved")}
      </span>
      <CloudUploadIcon className="h-5 w-5 transition duration-200 ease-in-out" />
    </div>
  );
};
