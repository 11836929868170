import {
  CountryCallingCode,
  CountryCode,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { FC, forwardRef, useEffect, useRef, useState } from "react";
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";

import { ChevronRightIcon } from "lucide-react";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { PaddingRow } from "@amenda-components/PageBuilder/PaddingRow";
import PhoneInput from "react-phone-number-input/input";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { getSearchCountries } from "./common";
import { inputFieldTheme } from "@amenda-styles/theme";
import isEmpty from "lodash/isEmpty";
import labels from "react-phone-number-input/locale/de.json";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";

interface Props {
  id: string;
  className?: string;
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  inputClassName?: string;
  inputWrapperClassName?: string;
  onChange: (value: string) => void;
}

interface CountryInfo {
  code: CountryCallingCode;
  country: CountryCode;
  formattedCode: string;
  label: string;
}

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return <input ref={ref} {...props} />;
});

const countriesInfo: CountryInfo[] = getCountries().map((c) => {
  const code = getCountryCallingCode(c);

  return {
    code,
    country: c,
    formattedCode: `+${code}`,
    label: labels[c],
  };
});

export const PhoneInputBase: FC<Props> = ({
  id,
  value,
  autoFocus,
  inputClassName,
  inputWrapperClassName,
  placeholder = "",
  className = "w-full",
  onChange,
}) => {
  const { t } = useTranslation();
  const { searchInputCss } = inputFieldTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [country, setCountry] = useState<CountryCode | undefined>();
  const [showMenu, setShowMenu] = useState(false);
  const [countryCodes, setCountryCodes] = useState(countriesInfo);
  const [searchTerm, setSearchTerm] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: countryCodes.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 37, // Estimate each row to be 37px high
    overscan: 5,
  });

  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);
  const countryCode = countriesInfo.find(
    (c) => c.country === country,
  )?.formattedCode;

  const handleChange = (value = "") => {
    onChange(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setCountryCodes(countriesInfo);
  };

  const handleCloseMenu = () => {
    handleClearSearch();
    setShowMenu(false);
  };

  const handleSelect = (country: CountryCode, formattedCode: string) => () => {
    onChange(formattedCode);
    setCountry(country);
    setCountryCodes(countriesInfo);
    handleCloseMenu();
  };

  const handleToggleMenu = () => {
    if (showMenu) {
      handleCloseMenu();
    } else {
      setShowMenu(true);
    }
  };

  const handleSearch = (value: string) => {
    const filteredCountries = isEmpty(value)
      ? countriesInfo
      : getSearchCountries<CountryInfo>(countriesInfo, value);

    setSearchTerm(value);
    setCountryCodes(filteredCountries);
  };

  const getMenuStyle = (divEl: HTMLDivElement | null) => {
    if (!divEl) return;

    const boundingRect = divEl.getBoundingClientRect();
    const top = boundingRect.top + boundingRect.height;
    const bottom = window.innerHeight - top;
    const totalHeight = boundingRect.height + boundingRect.y + 192;

    if (totalHeight < window.innerHeight) {
      return {
        top,
      };
    }
    return {
      bottom,
    };
  };

  useEffect(() => {
    if (value && isValidPhoneNumber(value) && !country) {
      setCountry(parsePhoneNumber(value)?.country ?? "DE");
    } else if (!value && !country) {
      setCountry("DE");
    }
  }, [value, country]);

  return (
    <div ref={wrapperRef} className={clsx("relative", className)}>
      <div className={clsx("flex w-full items-center", inputWrapperClassName)}>
        <button className="flex items-center" onClick={handleToggleMenu}>
          {countryCode && <span className="text-sm">{countryCode}</span>}
          <ChevronRightIcon
            className={clsx("h-3 w-3 text-gray-400", {
              "rotate-90 transform": !showMenu,
            })}
          />
        </button>
        <PhoneInput
          id={id}
          autoFocus={autoFocus}
          className={clsx(
            "w-full border-0 p-0 focus:outline-none focus:ring-0",
            inputClassName,
          )}
          country={country}
          value={value}
          international={true}
          autoComplete="off"
          inputComponent={CustomInput}
          placeholder={t(placeholder)}
          onChange={handleChange}
        />
      </div>
      <div
        ref={containerRef}
        className={clsx(
          "fixed z-[60] max-h-48 w-48 overflow-y-auto overflow-x-hidden overscroll-contain bg-white shadow-lg",
          {
            "mb-6 mt-1 focus:outline-none": showMenu,
          },
        )}
        style={getMenuStyle(wrapperRef?.current)}
        onPointerLeave={handleCloseMenu}
      >
        <Transition
          show={showMenu}
          enter="transition ease-in duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-out duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-75"
        >
          <div className="h-full w-full">
            <MiniSearchField
              value={searchTerm}
              placeholder="Suchen..."
              className={searchInputCss({
                size: "sm",
                class:
                  "sticky top-0 mb-1 w-full border-b border-gray-100 bg-white",
              })}
              onChange={handleSearch}
              onClear={handleClearSearch}
            />
            <PaddingRow padding={paddingTop} />
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const { formattedCode, country } = countryCodes[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  className="w-full cursor-pointer truncate p-2 text-sm hover:bg-gray-200"
                  onClick={handleSelect(country, formattedCode)}
                >
                  {formattedCode} <span>{labels[country]}</span>
                </div>
              );
            })}
            <PaddingRow padding={paddingBottom} />
          </div>
        </Transition>
      </div>
    </div>
  );
};
