import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { MegaphoneIcon, XIcon } from "lucide-react";
import toast, { Toast } from "react-hot-toast";

import { AvailableNotificationTypes } from "@amenda-types";
import { FC } from "react";
import { IconButtonBase } from "./IconButton";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { useAppStore } from "@amenda-domains/mutations";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const defaultTitle: Record<string, string> = {
  general: "Info",
  default: "Info",
  success: "Success",
  warning: "Attention needed",
  error: "Something went wrong",
};

interface NotificationCardProps {
  title?: string;
  toaster: Toast;
  message?: string;
  variant?: "success" | "warning" | "error" | "default";
  onClick?: () => void;
}

const NotificationIcon: FC<Pick<NotificationCardProps, "variant">> = ({
  variant = "default",
}) => {
  switch (variant) {
    case "success":
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-600"
          aria-hidden="true"
        />
      );
    case "error":
      return <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />;
    case "warning":
      return (
        <ExclamationTriangleIcon
          className="h-6 w-6 text-orange-600"
          aria-hidden="true"
        />
      );
    default:
      return (
        <MegaphoneIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
      );
  }
};

export const NotificationCardBase: FC<NotificationCardProps> = ({
  title,
  toaster,
  message,
  variant = "default",
  onClick,
}) => {
  const { t } = useTranslation();

  const headerTitle = title ?? defaultTitle[variant];

  const handleClose = () => toast.dismiss(toaster.id);

  return (
    <div
      className={clsx(
        "group z-[100] w-96 select-none rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5",
        {
          "translate-y-0 transform opacity-100": toaster.visible,
          "-translate-y-4 transform opacity-0": !toaster.visible,
        },
      )}
    >
      <div className="cursor-pointer px-2 py-1" onClick={onClick}>
        <div className="flex items-start">
          <div className="shrink-0">
            <div
              className={clsx(
                "mx-auto flex h-8 w-8 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-8 sm:w-8",
                {
                  "bg-green-100": variant === "success",
                  "bg-red-100": variant === "error",
                  "bg-orange-100": variant === "warning",
                  "bg-gray-100": variant === "default",
                },
              )}
            >
              <NotificationIcon variant={variant} />
            </div>
          </div>
          <div className="ml-3 w-0 flex-1 text-wrap pt-0.5">
            <p className="mb-1 font-sans text-sm text-gray-500">
              {t(headerTitle)}
            </p>
            {message && (
              <p
                className={clsx("text-sm text-gray-900", {
                  "group-hover:text-blue-500": onClick,
                })}
              >
                {t(message)}
              </p>
            )}
          </div>
          <div className="ml-4 flex h-6 shrink-0">
            <div className="hidden group-hover:block">
              <IconButtonBase size="xss" onClick={handleClose}>
                <XIcon className="h-5 w-5" />
              </IconButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationCard: FC = () => {
  const { t } = useTranslation();
  const notification = useAppStore((state) => state.notification);
  const closeNotification = useAppStore((state) => state.closeNotification);

  const { openNotification = false, type, message } = notification || {};
  const Icon =
    type === AvailableNotificationTypes.Success
      ? CheckCircleIcon
      : type === AvailableNotificationTypes.Error
        ? XMarkIcon
        : AvailableNotificationTypes.Warning
          ? ExclamationTriangleIcon
          : null;

  return (
    <Transition
      show={openNotification}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto fixed right-0 top-0 z-[100] mt-2 w-96 overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:mx-1 lg:mr-2">
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              {Icon && (
                <div
                  className={clsx(
                    "mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
                    {
                      "bg-green-100":
                        type === AvailableNotificationTypes.Success,
                      "bg-red-100": type === AvailableNotificationTypes.Error,
                      "bg-orange-100":
                        type === AvailableNotificationTypes.Warning,
                    },
                  )}
                >
                  <Icon
                    className={clsx("h-6 w-6", {
                      "text-green-600":
                        type === AvailableNotificationTypes.Success,
                      "text-red-600": type === AvailableNotificationTypes.Error,
                      "text-orange-600":
                        type === AvailableNotificationTypes.Warning,
                    })}
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {type && (
                <p className="text-base text-gray-500">
                  {t(defaultTitle[type])}
                </p>
              )}
              {message && (
                <p className="mt-1 text-sm text-gray-900">{t(message)}</p>
              )}
            </div>
            <div className="ml-4 flex shrink-0">
              <button
                type="button"
                className="z-20 inline-flex rounded-md bg-white text-gray-600 hover:text-gray-800"
                onClick={closeNotification}
              >
                <span className="sr-only">{t("Close")}</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const Notifications: FC = () => {
  const notification = useAppStore((state) => state.notification);
  const closeNotification = useAppStore((state) => state.closeNotification);

  useEffect(() => {
    const timeout = () => setTimeout(closeNotification, 6000);

    if (notification?.openNotification) {
      timeout();
    }

    return () => {
      clearTimeout(timeout());
    };
  }, [notification?.openNotification, closeNotification]);

  return <>{createPortal(<NotificationCard />, document.body)}</>;
};
