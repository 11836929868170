import { FC } from "react";
import { MaterialListHeader } from "./MaterialListHeader";
import { MaterialListView } from "./MaterialListView";
import { SlideOver } from "@amenda-components/App";
import { useMaterialsStore } from "@amenda-domains/mutations";

interface Props {
  isCollection?: boolean;
}

export const MaterialList: FC<Props> = ({ isCollection = false }) => {
  return (
    <aside className="hidden h-full flex-shrink-0 border-r border-gray-200 md:order-first md:flex md:w-72 md:flex-col lg:w-96">
      <MaterialListHeader isCollection={isCollection} />
      <MaterialListView isCollection={isCollection} />
    </aside>
  );
};

export const MaterialListMobile: FC<Props> = ({ isCollection = false }) => {
  const openMaterialListSlideOver = useMaterialsStore(
    (state) => state.openMaterialListSlideOver,
  );
  const setOpenMaterialListSlideOver = useMaterialsStore(
    (state) => state.setOpenMaterialListSlideOver,
  );

  const handleClose = () => {
    setOpenMaterialListSlideOver(false);
  };

  return (
    <SlideOver show={openMaterialListSlideOver} onClose={handleClose}>
      <div className="relative h-screen w-full overflow-y-auto">
        <div className="sticky top-0 z-20 w-full bg-white">
          <MaterialListHeader isCollection={isCollection} />
        </div>
        <MaterialListView isCollection={isCollection} />
      </div>
    </SlideOver>
  );
};
