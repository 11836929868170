import { FC, ReactNode } from "react";
import { ReactSelect, ReactSelectProps } from "./ReactSelect";
import { getResolvedValues, resolveValuesToArray } from "./common";

import { Option } from "@amenda-types";

interface Props extends ReactSelectProps {
  id: string;
  optional?: ReactNode;
  values: string[];
  onChange: (value: any[]) => void;
  onBlur?: () => void;
}

type MultiSelectProps = Omit<Props, "onChange"> & {
  onChange: (value: Option[]) => void;
};

const getOptionsFromGroup = (group: any[]) => {
  const options: any[] = [];

  group.forEach((g) => {
    options.push(...g.options);
  });
  return options;
};

export const MultiSelectBase: FC<MultiSelectProps> = ({
  onChange,
  options,
  values,
  isNested = false,
  ...rest
}) => {
  const getValue = (option: Option) => {
    return rest?.getOptionValue?.(option) ?? option?.value ?? "";
  };
  const resolvedOptions = Boolean(rest?.formatGroupLabel)
    ? getOptionsFromGroup(options)
    : options;

  const resolvedValues = isNested
    ? resolveValuesToArray(values)
    : (values ?? []).map((value) => {
        return resolvedOptions.find((option) => value === getValue(option));
      });

  return (
    <ReactSelect
      isMulti={true}
      isNested={isNested}
      options={options}
      value={resolvedValues}
      onChange={onChange}
      {...rest}
    />
  );
};

export const MultiSelect: FC<Props> = ({
  values,
  isNested = false,
  onChange,
  ...rest
}) => {
  const resolvedValues = isNested
    ? resolveValuesToArray(values)
    : getResolvedValues(rest.options, values);

  const handleChange = (options: Option[]) => {
    onChange(options.map((option) => option?.value));
  };

  return (
    <ReactSelect
      isMulti={true}
      isNested={isNested}
      value={resolvedValues}
      onChange={handleChange}
      {...rest}
    />
  );
};
