import {
  ContactResults,
  ProjectResults,
} from "@amenda-components/SearchComponents";
import { FC, useState } from "react";
import {
  SearchField,
  SearchResults,
} from "@amenda-components/SearchComponents";

import { useAppStore } from "@amenda-domains/mutations";
import { useSearchProjectsAndUsers } from "@amenda-domains/queries";

export const SearchArea: FC = () => {
  const { searchProjectsAndUsers, loading } = useSearchProjectsAndUsers();
  const setSearchResults = useAppStore((state) => state.setSearchResults);
  const searchResults = useAppStore((state) => state.searchResults);

  const [startSearch, setStartSearch] = useState(false);
  const handleSearch = async (searchTerm: string) => {
    if (!searchTerm) {
      setSearchResults({ projects: [], users: [] });
    } else {
      setStartSearch(true);
      await searchProjectsAndUsers(searchTerm);
    }
  };
  return (
    <>
      <div className="relative w-full max-w-2xl scale-100 transform opacity-100 transition-all">
        <div className="relative">
          <SearchField
            borderBottom
            placeHolder="Datenbank durchsuchen..."
            onSearch={handleSearch}
            autoFocus={true}
          />
          <SearchResults
            startSearch={startSearch}
            loading={loading}
            results={searchResults}
          >
            {({ projects, users }) => {
              return (
                <div className="px-4 py-2">
                  <ProjectResults projects={projects} />
                  <ContactResults contacts={users} />
                </div>
              );
            }}
          </SearchResults>
        </div>
      </div>
    </>
  );
};
