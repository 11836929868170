import { HelperMessage, LoaderWrapper } from "@amenda-components/App";

import { FC } from "react";
import { TemplateThumbnail } from "@amenda-components/Templates";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export const TemplateThumbnails: FC<{
  defaultTemplates: any[];
  loading: boolean;
  templates: any[];
  handleSelectThumbnail: (thumbnail: any) => void;
}> = ({ defaultTemplates, loading, templates, handleSelectThumbnail }) => {
  const { t } = useTranslation();
  const allTemplates = [...templates];
  const getParentTemplateName = (template: any) => {
    if (template.templateId) {
      return defaultTemplates.find(
        (defTemplate) => defTemplate.id === template.templateId,
      )?.name;
    }
    return "";
  };

  return (
    <>
      <h3 className="amenda-form-heading">{t("Project Templates")}</h3>
      {loading && <LoaderWrapper />}
      {!loading && isEmpty(templates) && (
        <div className="flex h-full w-full items-center justify-center">
          <HelperMessage message="No templates found" />
        </div>
      )}
      {!loading && !isEmpty(templates) && (
        <ul className="flex items-end gap-4 py-3">
          {allTemplates.map((template: any) => (
            <li key={template.id}>
              <TemplateThumbnail
                thumbnail={{
                  ...template,
                  isUserTemplate: !!template.templateId,
                  parentTemplateName: getParentTemplateName(template),
                }}
                handleSelectThumbnail={handleSelectThumbnail}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
