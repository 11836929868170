import {
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PhoneIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import { FC } from "react";
import { TimelineActivityTypes } from "@amenda-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const timelineActivityOptions = [
  {
    label: "Email",
    value: TimelineActivityTypes.Email,
    icon: EnvelopeIcon,
  },
  {
    label: "Phone Call",
    value: TimelineActivityTypes.PhoneCall,
    icon: PhoneIcon,
  },
  {
    label: "Meeting",
    value: TimelineActivityTypes.Meeting,
    icon: ChatBubbleLeftRightIcon,
  },
  {
    label: "Comment",
    value: TimelineActivityTypes.Comment,
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    label: "Note",
    value: TimelineActivityTypes.Note,
    icon: DocumentTextIcon,
  },
  {
    label: "Other",
    value: TimelineActivityTypes.Other,
    icon: Squares2X2Icon,
  },
];

interface Props {
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const TimelineActivityPicker: FC<Props> = ({
  value,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();

  const getLabel = () => {
    const option = timelineActivityOptions.find(
      (option) => option.value === value,
    );
    if (option) {
      return option.label;
    }
    return "Activity type";
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open, close }) => (
        <>
          <MenuButton className="amenda-menu-button">
            {t(getLabel())}
            <ChevronRightIcon
              className={clsx("ml-1 h-3 w-3", {
                "rotate-90 transform": open,
              })}
              aria-hidden="true"
            />
          </MenuButton>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="amenda-menu-items w-36">
              <div className="py-1">
                {timelineActivityOptions.map((option) => (
                  <MenuItem key={option.value}>
                    {({ focus }) => (
                      <span
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onChange(option.value);
                          close();
                          onBlur?.();
                        }}
                        className={clsx(
                          "font-apercu block cursor-pointer px-4 py-1 text-sm",
                          {
                            "bg-gray-100 text-gray-900": focus,
                            "text-gray-700": !focus,
                          },
                        )}
                      >
                        {option.icon && (
                          <option.icon className="mr-1 inline h-4 w-4" />
                        )}
                        {t(option.label)}
                      </span>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};
