import {
  Control,
  Controller,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useController,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import {
  DatePicker,
  SingleSelect,
  Switch,
  TextField,
} from "@amenda-components/FormComponents";
import {
  FormBuilderConditionExpectedValue,
  FormBuilderCreateComponentCondition,
} from "./FormBuilderDisplayConditions";
import { IconButtonBase, Tooltip } from "@amenda-components/App";
import { getConditionComponents, getConditionOptions } from "../common";

import { FC } from "react";
import { FormComponentTypes } from "@amenda-constants";
import { XIcon } from "lucide-react";
import { getComponentsById } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
  component?: any;
}

interface ComponentValidationWithConditionsProps extends Props {
  index: number;
  field: any;
  components: any[];
  componentsById: Record<string, any>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<any, "conditionalValidations">;
}

type ComponentValidationProps = Props & {
  className?: string;
  getFieldId?: (label: string) => string;
};

const ComponentValidation: FC<ComponentValidationProps> = ({
  control,
  component,
  className = "flex flex-col space-y-2",
  getFieldId = (label: string) => label,
}) => {
  const type = useWatch({
    control,
    name: "type",
  });

  return (
    <div className={className}>
      {[
        FormComponentTypes.MultiSelect,
        FormComponentTypes.Badges,
        FormComponentTypes.Keyword,
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.Checkbox,
        FormComponentTypes.LabelledInput,
        FormComponentTypes.LabelledContactInputs,
        FormComponentTypes.Textarea,
      ].includes(component?.component) && (
        <>
          <Controller
            name={getFieldId("min")}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id={getFieldId("min")}
                label="Minimum value"
                type="number"
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name={getFieldId("max")}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id={getFieldId("max")}
                label="Maximum value"
                type="number"
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </>
      )}
      {[
        FormComponentTypes.DatePicker,
        FormComponentTypes.DatePickerRange,
      ].includes(component?.component) && (
        <>
          <Controller
            name={getFieldId("min")}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                id={getFieldId("min")}
                label="Earliest date"
                withPortal={true}
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name={getFieldId("max")}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                id={getFieldId("max")}
                label="Latest date"
                withPortal={true}
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </>
      )}
      {[FormComponentTypes.Input].includes(component?.component) &&
        type === "text" && (
          <>
            <Controller
              name={getFieldId("min")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  id={getFieldId("min")}
                  label="Minimum value"
                  type="number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name={getFieldId("max")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  id={getFieldId("max")}
                  label="Maximum value"
                  type="number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name={getFieldId("url")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Switch
                    label="Is URL?"
                    checked={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </>
        )}
      {[FormComponentTypes.Input].includes(component?.component) &&
        type === "number" && (
          <>
            <Controller
              name={getFieldId("min")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  id={getFieldId("min")}
                  label="Minimum value"
                  type="number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name={getFieldId("max")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  id={getFieldId("max")}
                  label="Maximum value"
                  type="number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name={getFieldId("integer")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Switch
                    label="Is Integer?"
                    checked={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
            <Controller
              name={getFieldId("positive")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Switch
                    label="Is Positive?"
                    checked={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
            <Controller
              name={getFieldId("negative")}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Switch
                    label="Is Negative?"
                    checked={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </>
        )}
      {![FormComponentTypes.Hidden, FormComponentTypes.Switch].includes(
        component?.component,
      ) && (
        <Controller
          name={getFieldId("required")}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Switch
                label="Is required?"
                checked={value}
                error={error?.message}
                onChange={onChange}
              />
            );
          }}
        />
      )}
    </div>
  );
};

const ComponentValidationWithConditions: FC<
  ComponentValidationWithConditionsProps
> = ({
  field,
  index,
  component,
  components,
  componentsById,
  remove,
  control,
}) => {
  const { t } = useTranslation();

  const getFieldId = (path: string) => {
    return `conditionalValidations.${index}.${path}`;
  };

  const onClick = () => remove(index);

  return (
    <div className="group/conditionalValidation grid w-full grid-cols-4 gap-1 p-2">
      <div className="invisible col-span-4 flex w-full justify-end group-hover/conditionalValidation:visible">
        <Tooltip
          id="deleteCondition"
          positionStrategy="fixed"
          message="Remove condition?"
        >
          <IconButtonBase variant="dangerAlt" onClick={onClick}>
            <XIcon className="h-5 w-5" />
          </IconButtonBase>
        </Tooltip>
      </div>
      <div className="col-span-2">
        <Controller
          name={getFieldId("when")}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SingleSelect
              id={getFieldId("when")}
              label="When"
              isClearable={false}
              value={value}
              getOptionValue={(c) => c.id}
              getOptionLabel={(c) => c.properties?.label ?? ""}
              hasMenuOverflow={true}
              onChange={onChange}
              options={components}
              disabled={true}
              error={error?.message}
            />
          )}
        />
      </div>
      <div className="col-span-2">
        <Controller
          name={getFieldId("condition")}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SingleSelect
              id={getFieldId("condition")}
              label="Condition"
              isClearable={false}
              value={value}
              getOptionLabel={(option) => t(option.label)}
              hasMenuOverflow={true}
              error={error?.message}
              onChange={onChange}
              options={getConditionOptions(componentsById[field.when])}
            />
          )}
        />
      </div>
      <div className="col-span-4">
        <Controller
          name={getFieldId("value")}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormBuilderConditionExpectedValue
              id={getFieldId("value")}
              value={value}
              componentId={field.when}
              error={error?.message}
              components={components}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="col-span-4 pb-4 pt-2">
        <div className="border-b-2 border-dashed border-gray-300">
          {t("Then")}
        </div>
      </div>
      <ComponentValidation
        component={component}
        control={control}
        className="col-span-4 flex w-full flex-col space-y-2"
        getFieldId={(label: string) => getFieldId(`then.${label}`)}
      />
      <div className="col-span-4 pb-4 pt-2">
        <div className="border-b-2 border-dashed border-gray-300">
          {t("Else")}
        </div>
      </div>
      <ComponentValidation
        component={component}
        control={control}
        className="col-span-4 flex w-full flex-col space-y-2"
        getFieldId={(label: string) => getFieldId(`otherwise.${label}`)}
      />
    </div>
  );
};

const ComponentValidationWrapper: FC<Props> = ({ control, component }) => {
  const { fields, remove, append, update } = useFieldArray({
    control,
    keyName: "arrId",
    name: "conditionalValidations",
  });
  const formBuilderState = useAppStore((state) => state.formBuilderState);

  const { selectedForm } = formBuilderState ?? {};
  const components = getConditionComponents(
    selectedForm?.components,
    component,
  );
  const componentsById = getComponentsById(components);

  return (
    <div className="w-full divide-y divide-y-2 divide-dashed divide-gray-300 overflow-y-auto">
      <FormBuilderCreateComponentCondition
        append={append}
        components={components}
        componentsById={componentsById}
      />
      {fields.map((field, index) => {
        return (
          <ComponentValidationWithConditions
            key={field.arrId}
            index={index}
            field={field}
            control={control}
            component={component}
            components={components}
            componentsById={componentsById}
            update={update}
            remove={remove}
          />
        );
      })}
    </div>
  );
};

export const FormBuilderComponentValidationForm: FC<Props> = ({
  control,
  component,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    defaultValue: false,
    name: "hasConditionalValidations",
  });

  return (
    <div className="w-full">
      <Switch
        label="Has conditional validations?"
        checked={value}
        onChange={onChange}
      />
      {value ? (
        <ComponentValidationWrapper control={control} component={component} />
      ) : (
        <ComponentValidation control={control} component={component} />
      )}
    </div>
  );
};
