import {
  ContactPermissionBaseCard,
  ContactPermissionCard,
} from "./ContactPermissionCard";
import { Control, UseFormSetValue, useFieldArray } from "react-hook-form";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  PermissionSharePath,
  sharePermissionsSecondaryOptions,
} from "./common";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
  selectStylesControlOverride,
} from "@amenda-styles/customStyling";
import { debounce, isEmpty } from "lodash";
import { useGetUser, useSearchUsers } from "@amenda-domains/queries";

import AsyncSelect from "react-select/async";
import { DebounceTimes } from "@amenda-constants";
import { getUserName } from "@amenda-components/Contacts/common";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  ownerId?: string;
  isHidden?: boolean;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  ignoreStylesOverride?: boolean;
}

const DropdownIndicator = () => {
  return null;
};

const ClearIndicator = () => {
  return null;
};

const IndicatorSeparator = () => {
  return null;
};

export const ShareableContactsSearch: FC<Props> = ({
  id,
  ownerId,
  control,
  setValue,
  isHidden = false,
  ignoreStylesOverride = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { fields, update, remove } = useFieldArray({
    control,
    name: id,
    keyName: "arrId",
  });
  const { getUser } = useGetUser();
  const { searchUsers, loading } = useSearchUsers();
  const [resourceOwner, setResourceOwner] = useState<Record<string, any>>({});

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm: string, resolve: (users: any[]) => void) => {
        await searchUsers({
          searchTerm,
          callback: resolve,
        });
      }, DebounceTimes.Search),
    [searchUsers],
  );

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        await debouncedSearch(inputValue, resolve);
      }
    });

  const handleChangePermission = (index: number) => (option: any) => {
    if (option.value === sharePermissionsSecondaryOptions[0].value) {
      remove(index);
      return;
    }
    update(index, {
      ...fields[index],
      [PermissionSharePath]: option.value,
    });
  };

  useEffect(() => {
    if (ownerId) {
      getUser({
        id: ownerId,
        skipLoading: true,
        callback: setResourceOwner,
      });
    }

    return () => {
      setResourceOwner({});
    };
  }, [ownerId, getUser]);

  if (isHidden) {
    return null;
  }
  return (
    <div className="pb-6 pt-2" ref={ref}>
      <div className="w-full">
        <AsyncSelect
          cacheOptions
          isMulti={true}
          value={fields}
          onChange={(value) => {
            setValue(id, value);
          }}
          menuPlacement="auto"
          isClearable={false}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          placeholder={t("Search Contacts")}
          className={customStyling.select.containerClass}
          isLoading={loading}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => getUserName(option)}
          loadOptions={loadOptions}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? t("No results found") : t("Start typing")
          }
          components={{
            DropdownIndicator,
            ClearIndicator,
            IndicatorSeparator,
          }}
          styles={{
            ...customStyling.select.styleOverride,
            ...selectMenuOverflow(true, ref),
            ...(ignoreStylesOverride ? {} : selectStylesControlOverride),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          {...selectMenuOverflowProps(true)}
        />
      </div>
      <div className="pt-4">
        <ul className="flex flex-col space-y-1">
          <li>
            {!isEmpty(resourceOwner) && (
              <ContactPermissionBaseCard
                disabled={true}
                contact={resourceOwner}
              >
                <span className="text-xs text-gray-500">{t("Owner")}</span>
              </ContactPermissionBaseCard>
            )}
          </li>
          {fields.map((contact: any, i) => {
            return (
              <li key={contact.id}>
                <ContactPermissionCard
                  contact={contact}
                  secondaryOptions={sharePermissionsSecondaryOptions}
                  permission={contact?.[PermissionSharePath]}
                  handleChangePermission={handleChangePermission(i)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
