import { ExchangeIO, ExchangeInput } from "urql";
import { pipe, tap } from "wonka";

import { devConsole } from "@amenda-utils";

// Custom 401 Error Handling Exchange
export const errorExchange =
  ({ forward }: ExchangeInput): ExchangeIO =>
  (ops$) => {
    return pipe(
      forward(ops$),
      tap((props) => {
        const { error } = props;
        if (
          error?.response?.status === 401 ||
          error?.message?.endsWith("secrete on headers")
        ) {
          devConsole?.info("401 Error", error);
          window.location.href = "/logout";
        }
      }),
    );
  };
