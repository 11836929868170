import {
  ClearFiltersButton,
  NavigationFilterSearchField,
  RemoveNavigationFilterButton,
} from "./NavigationFilterSearchComponents";
import { FC, ReactNode } from "react";
import {
  FormAutoSaveProvider,
  FormAutoSaveSubmitProps,
  useFormAutoSave,
} from "@amenda-components/PageBuilder";
import { SidebarFilters, sidebarFiltersSchema } from "@amenda-constants";
import { Tag, Tags } from "./Tag";
import {
  getComponentsById,
  getSelectSearchComponents,
  getSelectedNavigationFilters,
  sanitizeData,
} from "@amenda-utils";

import { Controller } from "react-hook-form";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { PageComponentProps } from "@amenda-types";
import { ReactTreeSelectBreadCrumb } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { getOptionsTree } from "@amenda-components/FormComponents/common";
import { inputFieldTheme } from "@amenda-styles/theme";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  children?: ReactNode;
  searchTerm?: string;
  sidebarFilters?: Record<string, SidebarFilters>;
  components: PageComponentProps[];
  searchPlaceholder?: string;
  handleSearch?: (props: FormAutoSaveSubmitProps) => Promise<void>;
  handleClearSearch?: () => {};
}

type NavigationFormProps = Pick<Props, "components" | "searchPlaceholder">;

const NavigationForm: FC<NavigationFormProps> = ({
  components,
  searchPlaceholder = "Search",
}) => {
  const { t } = useTranslation();
  const { searchInputCss } = inputFieldTheme();
  const { control, submitButtonRef, handleFormSubmit } = useFormAutoSave();

  return (
    <form className="w-full" onSubmit={handleFormSubmit}>
      <div className="flex w-full items-center space-x-2">
        <Controller
          name="searchTerm"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <div className="min-w-64 lg:min-w-80">
                <MiniSearchField
                  className={searchInputCss({
                    size: "sm",
                    class: "w-full border-gray-100 bg-gray-50",
                  })}
                  placeholder={t(searchPlaceholder) + "..."}
                  value={value ?? ""}
                  onChange={onChange}
                  onClear={() => onChange("")}
                />
              </div>
            );
          }}
        />
        {control &&
          components.map((component) => (
            <NavigationFilterSearchField
              key={component.id}
              component={component}
              control={control}
            />
          ))}
      </div>
      <button type="submit" hidden ref={submitButtonRef} />
    </form>
  );
};

export const NavigationFilter: FC<Props> = ({
  children,
  className,
  components,
  searchTerm,
  searchPlaceholder,
  sidebarFilters = {},
  handleSearch = async (props) => {},
  handleClearSearch = async () => {},
}) => {
  const { t } = useTranslation();

  const searchComponents = getSelectSearchComponents(components);
  const values = sanitizeData({
    ...sidebarFilters,
    searchTerm,
  });
  const selectedFilters = getSelectedNavigationFilters(values);
  const componentsById = getComponentsById(searchComponents);

  return (
    <div className={clsx("w-full overflow-hidden", className)}>
      <FormAutoSaveProvider
        ignoreResourceIdAlways={true}
        values={values}
        inputSchema={sidebarFiltersSchema}
        onSubmit={handleSearch}
      >
        <div className="flex w-full items-center gap-x-2 overflow-x-auto overflow-y-hidden overscroll-x-contain">
          <div className="w-auto">
            <NavigationForm
              searchPlaceholder={searchPlaceholder}
              components={searchComponents}
            />
          </div>
          {children}
        </div>
        <Tags className="w-full pt-4">
          {selectedFilters.map((filters) => {
            if (filters.isNested) {
              const options =
                componentsById[filters.componentId]?.properties?.options ?? [];
              const optionsTree = getOptionsTree(options);

              return (
                <Tag variant="secondaryAlt" key={filters.value}>
                  <ReactTreeSelectBreadCrumb
                    value={filters.value}
                    optionsTree={optionsTree}
                  />
                  <RemoveNavigationFilterButton
                    id={filters.id}
                    value={filters.value}
                    sidebarFilters={sidebarFilters}
                  />
                </Tag>
              );
            }
            return (
              <Tag variant="secondaryAlt" key={filters.value}>
                <span>{t(filters.label)}</span>
                <RemoveNavigationFilterButton
                  id={filters.id}
                  value={filters.value}
                  sidebarFilters={sidebarFilters}
                />
              </Tag>
            );
          })}
          <ClearFiltersButton
            show={selectedFilters.length > 0}
            handleClearSearch={handleClearSearch}
          />
        </Tags>
      </FormAutoSaveProvider>
    </div>
  );
};
