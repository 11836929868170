import { Dialog, DialogPanel } from "@headlessui/react";
import { FC, useRef } from "react";

import { SearchArea } from "@amenda-components/SearchComponents";
import { useAppStore } from "@amenda-domains/mutations";

export const SearchModal: FC = () => {
  const modals = useAppStore((state) => state.modals);
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);
  const dialogRef = useRef(null);

  return (
    <Dialog
      initialFocus={dialogRef}
      as="div"
      className="relative z-50"
      open={modals.openSearchModal}
      onClose={() => toggleSearchModal(false)}
    >
      <div
        className="fixed inset-0 bg-black/10 backdrop-blur-sm"
        aria-hidden="true"
      />
      <div className="opacity-98 fixed inset-0 flex items-start overflow-y-auto pt-16 sm:pt-24">
        <div className="flex w-full items-center justify-center text-center">
          <DialogPanel
            ref={dialogRef}
            className="h-full w-full max-w-sm transform overflow-hidden border-white bg-white text-left align-middle shadow-xl transition-all md:max-w-2xl"
          >
            <SearchArea />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
