import { getInitials, processMagicLinks } from "@amenda-utils";

import { FC } from "react";
import { UserRoundIcon } from "lucide-react";
import clsx from "clsx";
import { isEmpty } from "lodash";

type Shape = "circle" | "square" | "rounded";
interface Props {
  src?: string;
  name?: string;
  placeHolder?: string;
  shape?: Shape;
  className?: string;
  iconClassName?: string;
  ignoreMagicLinks?: boolean;
  Icon?: FC<{
    className?: string;
  }>;
}

const getShapeClassName = (shape: Shape) => {
  return shape === "circle" ? "rounded-full" : "rounded-md";
};

export const Avatar: FC<Props> = ({
  src,
  className,
  placeHolder,
  iconClassName,
  name = "",
  shape = "circle",
  ignoreMagicLinks = false,
  Icon = UserRoundIcon,
}) => {
  if (!isEmpty(src)) {
    return (
      <span
        className={clsx(
          "inline-block overflow-hidden",
          className,
          getShapeClassName(shape),
        )}
      >
        <img
          alt={name}
          className="object-fill"
          src={
            ignoreMagicLinks
              ? src
              : processMagicLinks(src || "/images/logoIconOnly.svg", {
                  c: "c",
                  size: "avatar",
                })
          }
        />
      </span>
    );
  } else if (!isEmpty(name) || !isEmpty(placeHolder)) {
    const text = name ? getInitials(name) : placeHolder;

    return (
      <span
        className={clsx(
          "inline-flex items-center justify-center bg-gray-900 text-white",
          className,
          getShapeClassName(shape),
        )}
      >
        <span className="font-apercu">{text}</span>
      </span>
    );
  }
  return (
    <div
      className={clsx(
        "inline-flex items-center justify-center rounded-full bg-gray-900 text-white",
        className,
        getShapeClassName(shape),
      )}
    >
      <Icon className={clsx("rounded-full text-white", iconClassName)} />
    </div>
  );
};
