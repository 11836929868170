import { ErrorMessage } from "./ErrorMessage";
import { FC } from "react";
import { Option } from "@amenda-types";
import clsx from "clsx";
import { onChangeOptions } from "@amenda-components/PageBuilder/common";
import { useTranslation } from "react-i18next";

interface BadgesProps {
  error?: string;
  label: string;
  options: Option[];
  selectedOptions: string[];
  isMulti?: boolean;
  badgeClassName?: string;
  onChange: (value: string[]) => void;
  onBlur?: () => void;
  readOnly?: boolean;
}

export const Badges: FC<BadgesProps> = ({
  error,
  label,
  options,
  selectedOptions,
  isMulti = true,
  badgeClassName = "rounded-none",
  onChange,
  onBlur,
  readOnly,
}) => {
  const { t } = useTranslation();

  const getSelectedOption = (option: Option) => {
    const options = selectedOptions ?? [];
    return options.find((selectedOption) => selectedOption === option.value);
  };

  const handleClick = (option: Option) => () => {
    if (isMulti) {
      onChangeOptions({
        onChange,
        option,
        selectedOptions,
      });
    } else {
      onChange([option.value]);
    }
    onBlur?.();
  };

  return (
    <div className="mb-2">
      <legend className="amenda-component-label">{t(label)}</legend>
      <div className="mt-1 flex flex-wrap space-x-4">
        {options.map((option) => {
          const foundOption = getSelectedOption(option);

          return (
            <button
              tabIndex={-1}
              disabled={Boolean(readOnly)}
              type="button"
              key={option.value}
              className={clsx(
                "mb-2 inline-flex cursor-pointer items-center border px-3 py-1 text-sm hover:border-gray-900 hover:bg-gray-900 hover:text-white",
                badgeClassName,
                {
                  "bg-white text-gray-900 hover:bg-gray-900": !foundOption,
                  "bg-gray-900 text-white": !!foundOption,
                },
              )}
              onClick={handleClick(option)}
            >
              {t(option.label)}
            </button>
          );
        })}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};
