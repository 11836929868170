import { QuickActions, RecentProjects } from "@amenda-components/Dashboard";

import { FC } from "react";
import { FrigadeBanner } from "@frigade/react";
import { Helmet } from "react-helmet-async";
import { MaxWidthLayout, SidebarToggle } from "@amenda-components/Shared";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations/users";

export const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const currentUser = useUsersStore((state) => state.currentUser);

  return (
    <MaxWidthLayout>
      <Helmet title={t("Dashboard")} />
      <div className="grid h-full grid-cols-1 items-start md:grid-cols-4">
        <div className="grid grid-cols-1 md:col-span-3 md:h-screen md:border-r">
          <section className="flex flex-col justify-center text-gray-600">
            <div className="mx-auto h-full w-full sm:px-2">
              <div className="flex flex-row items-center">
                <SidebarToggle />
                <h1 className="amenda-page-title px-2 py-1 lg:py-6">
                  Hallo, {currentUser?.firstName}!
                </h1>
              </div>
              <div className="col-span-full flex flex-col bg-white pt-2 xl:col-span-8">
                <FrigadeBanner flowId="flow_iVss7YKuIMfjRHvb" />
                <QuickActions />
              </div>
            </div>
          </section>
        </div>
        <div className="grid h-full grid-cols-1">
          <RecentProjects />
        </div>
      </div>
    </MaxWidthLayout>
  );
};
