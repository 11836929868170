import {
  FormsEarlyLoader,
  ProjectPrintView,
  ReadonlyProjectHeader,
} from "@amenda-components/Projects";

import { PdfLayout } from "@amenda-components/PdfBuilder";
import { useEffect } from "react";
import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router-dom";

export const PrintProjectPage = () => {
  const { projectId }: any = useParams<{
    projectId?: string;
  }>();
  const { getProject, loading } = useGetProject();

  useEffect(() => {
    getProject({
      id: projectId,
    });
  }, [getProject, projectId]);

  return (
    <PdfLayout>
      <div className="m-5">
        <FormsEarlyLoader>
          <div className="w-full rounded-md bg-white p-5 shadow">
            <ReadonlyProjectHeader hideMenuOptions={true} />
            <div className="w-full pt-4">
              <ProjectPrintView loading={loading} />
            </div>
          </div>
        </FormsEarlyLoader>
      </div>
    </PdfLayout>
  );
};
