import { Control, useWatch } from "react-hook-form";

import { FC } from "react";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { Option } from "@amenda-types";
import { SystemRolesSelect } from "./SystemRolesSelect";
import { isEmpty } from "lodash";
import { sharePermissionsOptions } from "./common";

interface RoleProfileCardProps {
  systemRole: any;
  handleChangePermission: (option: Option) => void;
}

const RoleProfileCard: FC<RoleProfileCardProps> = ({
  systemRole,
  handleChangePermission,
}) => {
  return (
    <div className="flex w-full items-center justify-between border border-gray-300 p-2">
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-col items-start space-y-1">
          <div className="text-sm text-gray-800">{systemRole.name}</div>
          <div className="max-w-64 text-xs text-gray-400">
            {systemRole.description}
          </div>
        </div>
      </div>
      <OperationsMenu
        labelClassName="text-xs text-gray-500"
        menuItemsClassName="right-0"
        options={sharePermissionsOptions}
        selectedOption={systemRole?.permission}
        onChange={handleChangePermission}
      />
    </div>
  );
};

interface Props {
  id: string;
  control: Control<any>;
}

export const CollectionRoles: FC<Props> = ({ id, control }) => {
  const isShared = useWatch({
    control,
    exact: true,
    name: "shareable",
  });

  const handleChangePermission =
    ({
      value,
      fieldIndex,
      update,
    }: {
      value: any;
      fieldIndex: number;
      update: (index: number, value: any) => void;
    }) =>
    (option: Option) => {
      update(fieldIndex, { ...value, permission: option.value });
    };

  if (!isShared) {
    return null;
  }
  return (
    <div className="w-100 mt-2 block">
      <SystemRolesSelect
        id={id}
        control={control}
        isMulti={true}
        label="Share with Roles"
      >
        {({ fields, update }) => (
          <>
            {!isEmpty(fields) && (
              <ul className="flex flex-col space-y-2">
                {fields.map((field, i) => {
                  return (
                    <li key={field.id}>
                      <RoleProfileCard
                        systemRole={field}
                        handleChangePermission={handleChangePermission({
                          value: field,
                          fieldIndex: i,
                          update,
                        })}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </SystemRolesSelect>
    </div>
  );
};
