import {
  AllowedContactType,
  AllowedTimelineTypes,
  FormTab,
  TabItem,
} from "@amenda-types";
import { ContactsForm, ReadOnlyContactsForm } from "./ContactsForm";
import { FC, ReactNode } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  UserTabs,
  flattenUserDetails,
  getUserTabs,
  staticContactLabels,
} from "./common";
import {
  useProjectStore,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { CompaniesByContact } from "./CompaniesByContact";
import { ContactDirectoryProps } from "./types";
import { HeadlessTabs } from "@amenda-components/App";
import { LinkedPersonsCard } from "./LinkedPersonsCard";
import { ProjectsByContact } from "./ProjectsByContact";
import { Timeline } from "@amenda-components/Timeline";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

interface ContactFormProps {
  type: AllowedContactType;
  components: any[];
}

const ContactFormTab: FC<ContactFormProps> = ({ type, components }) => {
  const { pathname } = useLocation();
  const user = useUsersStore((state) => state.selectedUser);

  if (pathname.endsWith(`/${user?.id}/bearbeiten`)) {
    return (
      <ContactsForm user={user} contactType={type} components={components} />
    );
  }
  return (
    <ReadOnlyContactsForm
      user={user}
      contactType={type}
      components={components}
    />
  );
};

const TimelineWrapper: FC = () => {
  const selectedUser = useUsersStore((state) => state.selectedUser);

  return (
    <Timeline
      resourceId={selectedUser?.id}
      className="w-full lg:w-3/4"
      type={AllowedTimelineTypes.Contact}
    />
  );
};

const showPanel = ({
  tab,
  type,
  contactForms,
}: {
  tab: TabItem;
  type: AllowedContactType;
  contactForms: FormTab[];
}) => {
  const tabComponents: Record<string, ReactNode> = {
    [UserTabs.projects]: <ProjectsByContact />,
    [UserTabs.relatedCompanies]: <CompaniesByContact />,
    [UserTabs.relatedPersons]: <LinkedPersonsCard />,
    [staticContactLabels.activity.value]: <TimelineWrapper />,
  };
  contactForms.forEach((form) => {
    tabComponents[form.id] = (
      <ContactFormTab components={form.components} type={type} />
    );
  });
  return tabComponents[tab.value];
};

type Props = Pick<ContactDirectoryProps, "defaultContactType">;

export const ContactOverviewCard: FC<Props> = ({ defaultContactType }) => {
  const { pathname } = useLocation();
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const selectedUser = useUsersStore((state) => state.selectedUser);

  const contact = flattenUserDetails(selectedUser);
  const contactType = (contact?.type ??
    defaultContactType) as AllowedContactType;
  const contactForms = formsByContactType[contactType] || [];
  const tabs = getUserTabs({
    contactForms,
    type: contactType,
    disabled: pathname.endsWith(`/${contact?.id}/bearbeiten`),
    contactGeneralPermissions: getFromGeneralPermissions(
      permissions,
      GeneralPermissionKeys.Contacts,
    ),
  });

  if (isEmpty(contactForms)) {
    return null;
  }
  return (
    <HeadlessTabs
      tabs={tabs}
      tabListClassName="px-4 sm:px-6 xxl:px-0 !pt-1 max-w-full"
    >
      {(tab) => (
        <div className="w-full px-8 py-4 xxl:px-0">
          {showPanel({
            tab,
            contactForms,
            type: contactType,
          })}
        </div>
      )}
    </HeadlessTabs>
  );
};
