import { FC, ReactNode, memo } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  title?: string;
  children: ReactNode;
}

export const AuthLayout: FC<Props> = memo(({ children, title, label = "" }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t(title || label)} />
      <div className="flex min-h-screen bg-white">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="text-center">
              <h2 className="mt-6 text-3xl text-gray-900">{t(label)}</h2>
            </div>
            <div className="mt-8">
              <div className="mt-6">{children}</div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/amenda_lines.jpg"
            alt="Amenda Lines"
          />
        </div>
      </div>
    </>
  );
});
