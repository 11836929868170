import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, Spinner } from "@amenda-components/App";
import {
  useAuthStore,
  useGenerateAndEmailPasswordResetLink,
} from "@amenda-domains/mutations";

import { AuthFormType } from "@amenda-types";
import { FC } from "react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ResetPasswordSchema } from "@amenda-constants";
import { TextField } from "@amenda-components/FormComponents";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface ResetPasswordInput {
  email: string;
}

export const RequestPasswordResetLink: FC = () => {
  const { t } = useTranslation();
  const toggleAuthFormType = useAuthStore((state) => state.toggleAuthFormType);
  const { handleSubmit, control } = useForm<ResetPasswordInput>({
    resolver: yupResolver(ResetPasswordSchema),
  });
  const { loading, generateAndEmailPasswordResetLink } =
    useGenerateAndEmailPasswordResetLink();

  const onSubmit: SubmitHandler<ResetPasswordInput> = async (data) => {
    await generateAndEmailPasswordResetLink(data);
    toggleAuthFormType(AuthFormType.CheckYourEmail);
  };

  return (
    <div className="px-4">
      <div className="mb-6">
        {t(
          "Please enter the E-Mail address you have registered with. You'll receive a link to reset your password.",
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="email"
                error={error?.message}
                value={value}
                placeholder="E-Mail"
                type="email"
                StartIcon={LockClosedIcon}
                onChange={onChange}
              />
            );
          }}
        />
        <div className="mb-4 mt-6 text-sm">
          <Link
            onClick={() => toggleAuthFormType(AuthFormType.Login)}
            className="text-indigo-600 hover:text-indigo-700"
          >
            {t("< Back to Login")}
          </Link>
        </div>

        <div>
          <button type="submit" className="amenda-button">
            {loading && <Spinner variant="primary" spinnerSize="xs" />}
            {t("Send Password Reset Email")}
          </button>
        </div>
      </form>
    </div>
  );
};
