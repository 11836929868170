import { AllowedContactType, AppRoutes, Option } from "@amenda-types";
import { Avatar, HelperMessage, LoaderWrapper } from "@amenda-components/App";
import { FC, useEffect, useState } from "react";
import { MailIcon, PhoneIcon } from "lucide-react";

import { flattenUserDetails } from "./common";
import { getMailOrPhoneRef } from "@amenda-components/FormComponents/common";
import { isEmpty } from "lodash";
import { useGetAllContacts } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  values?: string | Option[];
  isEmail?: boolean;
}

const iconClassName = "h-5 w-5 text-gray-800";

const DisplayPhoneOrEmail: FC<Props> = ({ isEmail = false, values }) => {
  const value = Array.isArray(values) ? values[0]?.value : values;

  if (!value) return null;
  return (
    <div className="flex items-center space-x-2">
      {isEmail ? (
        <MailIcon className={iconClassName} />
      ) : (
        <PhoneIcon className={iconClassName} />
      )}

      <a
        className="amenda-contact-subline truncate hover:text-blue-600"
        href={getMailOrPhoneRef(isEmail ? "email" : "phone", value)}
      >
        {value}
      </a>
    </div>
  );
};

export const LinkedPersonsCard: FC = () => {
  const navigate = useNavigate();
  const [companyContacts, setCompanyContacts] = useState<any[]>([]);
  const { getAllContacts, loading } = useGetAllContacts();
  const selectedUser = useUsersStore((state) => state.selectedUser);

  const viewUser = (user: any) => {
    const path = `${AppRoutes.Contacts}/${user.id}`;

    navigate(path);
  };

  useEffect(() => {
    if (selectedUser?.id) {
      getAllContacts({
        isDeleted: false,
        type: AllowedContactType.person,
        contactDetails: {
          company: selectedUser.id,
        },
        context: {
          requestPolicy: "cache-and-network",
        },
        callback: (contacts = []) => setCompanyContacts(contacts),
      });
    }
  }, [getAllContacts, selectedUser?.id]);

  if (loading) {
    return <LoaderWrapper className="h-80" />;
  } else if (isEmpty(companyContacts)) {
    return <HelperMessage message="Keine verknüpften Personen vorhanden." />;
  }
  return (
    <div className="mx-auto mt-8">
      <div className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {companyContacts.map((c) => {
          const contact = flattenUserDetails(c);

          return (
            <div
              key={contact.id}
              className="flex cursor-pointer space-x-2 border border-gray-300 bg-white px-4 py-5 hover:bg-gray-50"
              onClick={() => viewUser(contact)}
            >
              <div className="px-2">
                <Avatar
                  className="h-12 w-12 text-base"
                  name={`${contact?.firstName} ${contact?.lastName}`}
                />
              </div>
              <div className="overflow-hidden">
                <p className="text-base text-gray-900">
                  {`${contact.firstName ?? ""} ${contact.lastName ?? ""}`}
                </p>
                <DisplayPhoneOrEmail isEmail values={contact?.email} />
                <DisplayPhoneOrEmail values={contact?.phone} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
