import { AdminSettingsLayout, Integrations } from "@amenda-components/Settings";

import { FC } from "react";
import { useTranslation } from "react-i18next";

export const IntegrationsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <AdminSettingsLayout>
      <div className="sm:flex sm:items-center" style={{ height: "40px" }}>
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Integrations")}</h1>
        </div>
      </div>
      <Integrations />
    </AdminSettingsLayout>
  );
};
