import { FC, ReactNode, useEffect, useState } from "react";
import { useGetContactsById, useGetUsersById } from "@amenda-domains/queries";

import { AllowedContactType } from "@amenda-types";
import { Spinner } from "@amenda-components/App";
import isNil from "lodash/isNil";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

interface Props {
  children: (contact: any) => ReactNode;
  className?: string;
  userId: string;
  contactType?: AllowedContactType;
}

export const ContactCardWrapper: FC<Props> = ({
  children,
  className,
  userId,
  contactType,
}) => {
  const { t } = useTranslation();
  const { getUsersById, loading: loadingGetUsersById } = useGetUsersById();
  const { getContactsById, loading: loadingGetContactsById } =
    useGetContactsById();
  const [contact, setContact] = useState<any>(null);
  const { ref, inView } = useInView({
    skip: !isNil(contact),
  });

  const isLoading = loadingGetUsersById || loadingGetContactsById;

  useEffect(() => {
    if (userId && inView) {
      const cb =
        contactType === AllowedContactType.office
          ? getUsersById
          : getContactsById;

      cb([userId], (users: any) => {
        setContact(users[0]);
      });
    }
  }, [inView, userId, contactType, getUsersById, getContactsById]);

  return (
    <div className={className} ref={ref}>
      {isLoading && (
        <div className="flex w-full flex-col items-center py-3">
          <Spinner spinnerSize="xs" />
          <span className="mt-2 text-xs text-gray-800">{t("Loading")}...</span>
        </div>
      )}
      {!isLoading && contact && children(contact)}
    </div>
  );
};
