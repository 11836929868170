import { FC, ReactNode } from "react";

import clsx from "clsx";

interface Props {
  className?: string;
  children: ReactNode;
}

export const CenteredLayout: FC<Props> = ({ className, children }) => {
  return (
    <div className="flex w-full md:mx-auto md:w-1/2 md:justify-center">
      <div className={clsx("w-full", className)}>{children}</div>
    </div>
  );
};
