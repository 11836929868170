import { FC, memo } from "react";

import { CollectionDropdown } from "@amenda-components/Shared";
import { ContactDirectoryProps } from "./types";
import { IconButtonBase } from "@amenda-components/App";
import { XIcon } from "lucide-react";
import { useUsersStore } from "@amenda-domains/mutations";

export const ContactCollectionDropdown: FC<ContactDirectoryProps> = memo(
  ({
    rootRoute,
    pluralTitle,
    singularTitle,
    collectionRoute,
    collectionType,
    getAllContacts,
  }) => {
    const selectedUsers = useUsersStore((state) => state.selectedUsers);
    const clearSelectedUsers = useUsersStore(
      (state) => state.clearSelectedUsers,
    );
    const setOpenContactListSlideOver = useUsersStore(
      (state) => state.setOpenContactListSlideOver,
    );

    const handleClose = () => {
      setOpenContactListSlideOver(false);
    };

    const handleGetResourceIds = async (args: any) => {
      const users = await getAllContacts({
        userDetails: args,
        skipStorage: true,
      });
      return users?.map((user: any) => user.id);
    };

    return (
      <div className="flex w-full items-center justify-between pb-4">
        <CollectionDropdown
          label={`${singularTitle} Directory`}
          goBackLabel={`All ${pluralTitle}`}
          resourceIds={selectedUsers}
          collectionType={collectionType}
          routes={{
            goBack: rootRoute,
            collection: collectionRoute,
          }}
          getResourceIds={handleGetResourceIds}
          clearSelectedResources={clearSelectedUsers}
        />
        <div className="block md:hidden">
          <IconButtonBase variant="clean" label="Close" onClick={handleClose}>
            <XIcon className="h-6 w-6" />
          </IconButtonBase>
        </div>
      </div>
    );
  },
);
