import { AllowedTimelineTypes, FormTab, TabItem } from "@amenda-types";
import { FC, ReactNode } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  getStaticMaterialTabs,
  isAddingNewMaterial,
  staticMaterialLabels,
  transformMaterialToForm,
} from "./common";
import { isFormTabDisabled, processFormTabsDisplay } from "@amenda-utils";
import { useLocation, useParams } from "react-router-dom";
import {
  useMaterialsStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { HeadlessTabs } from "@amenda-components/App";
import { MaterialsForm } from "./MaterialsForm";
import { Timeline } from "@amenda-components/Timeline";

const showPanel = ({
  tab,
  materialForms,
  materialId,
}: {
  tab: TabItem;
  materialForms: FormTab[];
  materialId?: string;
}) => {
  const tabComponents: Record<string, ReactNode> = {};

  materialForms.forEach((form) => {
    tabComponents[form.id] = <MaterialsForm components={form.components} />;
  });
  tabComponents[staticMaterialLabels.activity.value] = (
    <Timeline
      type={AllowedTimelineTypes.Material}
      resourceId={materialId}
      className="w-full lg:w-3/4"
    />
  );

  return tabComponents[tab.value];
};

export const MaterialDetailsTabs: FC = () => {
  const { pathname } = useLocation();
  const { materialId } = useParams<{ materialId: string }>();
  const selectedMaterial = useMaterialsStore((state) => state.selectedMaterial);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const forms = useProjectStore((state) => state.forms) ?? {};
  const materialForms = forms[AvailableForms.Materials] ?? [];

  const materialGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );

  const selectedMaterialId = selectedMaterial?.id || materialId;
  const tabs = [
    ...processFormTabsDisplay(
      materialForms,
      transformMaterialToForm(selectedMaterial),
    ).map(({ order, shouldDisplay, ...rest }) => ({
      ...rest,
      disabled: isFormTabDisabled(
        shouldDisplay,
        order !== 0 && isAddingNewMaterial(pathname) && !selectedMaterialId,
      ),
    })),
    ...getStaticMaterialTabs(
      materialGeneralPermissions,
      isAddingNewMaterial(pathname) && !selectedMaterialId,
    ),
  ];

  return (
    <HeadlessTabs
      tabs={tabs}
      tabListClassName="mx-auto w-full px-4 sm:px-6 lg:px-8"
      panelsClassName="mt-2 pb-6"
    >
      {(tab) => (
        <div className="px-8 py-4">
          {showPanel({ tab, materialForms, materialId: selectedMaterialId })}
        </div>
      )}
    </HeadlessTabs>
  );
};
