import { Control, useWatch } from "react-hook-form";
import { FC, useEffect, useMemo } from "react";
import { TextField, TextFieldProps } from "@amenda-components/FormComponents";

import { isNil } from "lodash";

export interface CalculatorFieldBaseProps
  extends Omit<TextFieldProps, "type" | "readOnly"> {
  control: Control;
  autoCalculate?: boolean;
  mathTokens: string;
  transformInputFieldNames?: (fieldName: string) => string;
}

const tokensBySymbols: Record<string, any> = {
  left_bracket: "(",
  right_bracket: ")",
  divide: "/",
  minus: "-",
  multiply: "*",
  sum: "+",
};

export const CalculatorFieldBase: FC<CalculatorFieldBaseProps> = ({
  value,
  control,
  mathTokens,
  autoCalculate,
  onChange,
  transformInputFieldNames = (fieldName) => fieldName,
  ...props
}) => {
  const inputFieldNames = useMemo(
    () =>
      mathTokens
        .split(".")
        .filter((token) => isNil(tokensBySymbols[token]))
        .map(transformInputFieldNames),
    [mathTokens, transformInputFieldNames],
  );

  const inputValues = useWatch({
    control,
    exact: true,
    name: inputFieldNames,
  });

  useEffect(() => {
    const evaluateMathTokens = () => {
      let evaluation = "";
      mathTokens.split(".").forEach((token) => {
        if (isNil(tokensBySymbols[token])) {
          const index = inputFieldNames.findIndex((inputField) =>
            inputField.includes(token),
          );
          const value = inputValues[index]
            ? Number(inputValues[index]).toString()
            : "0";
          evaluation = evaluation + value;
        } else {
          evaluation = evaluation + tokensBySymbols[token];
        }
      });
      return evaluation;
    };

    const calculateResults = (evaluation: string) => {
      try {
        // eslint-disable-next-line
        const total = Function(`return ${evaluation}`)();
        return total;
      } catch (e) {}
    };

    const hasSomeValuesFilled = (values: any[]) => values.some((v) => !!v);

    const hasResultMatchingValue = (results: any, value: any) =>
      Number(value) === Number(results);

    const handleCalculation = () => {
      if (autoCalculate && hasSomeValuesFilled(inputValues)) {
        const evaluation = evaluateMathTokens();
        const results = calculateResults(evaluation);
        if (
          isFinite(Number(results)) &&
          !hasResultMatchingValue(results, value)
        ) {
          onChange?.(results);
        }
      }
    };

    handleCalculation();
  }, [
    autoCalculate,
    inputValues,
    inputFieldNames,
    mathTokens,
    value,
    onChange,
  ]);

  return (
    <TextField
      value={value}
      type="number"
      readOnly={autoCalculate}
      onChange={onChange}
      {...props}
    />
  );
};
