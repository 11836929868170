import {
  ChangeEvent,
  FC,
  ForwardRefExoticComponent,
  ReactNode,
  RefObject,
  useRef,
} from "react";
import { isUrl, reverseNumberFormatting } from "@amenda-utils";

import { ErrorMessage } from "./ErrorMessage";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { NumericFormat } from "react-number-format";
import { Spinner } from "@amenda-components/App";
import clsx from "clsx";
import { handleWheelChange } from "./common";
import { inputFieldTheme } from "@amenda-styles/theme";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  inputRef: RefObject<HTMLInputElement>;
  autoComplete?: string;
  error?: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
  name: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  type?: "number" | "email" | "password" | "text";
  value?: string;
  readOnly?: boolean;
  withBorder?: boolean;
  startAddOnText?: string;
  StartIcon?: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  onChange?: (value: any) => void;
  onInputClick?: () => void;
  onBlur?: () => void;
}
export interface TextFieldProps
  extends Omit<Props, "name" | "setFocus" | "inputRef"> {
  size?: "xs" | "sm" | "md";
  endAddOnText?: string;
  EndButtonIcon?: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  endButtonText?: string;
  EndIcon?: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  EndElement?: ReactNode;
  label?: string;
  optional?: ReactNode;
  value?: string;
  withEndButton?: boolean;
  endButtonLoading?: boolean;
  hideErrorMessage?: boolean;
  onClick?: () => void;
}

const Input = ({
  inputRef,
  type,
  error,
  disabled,
  StartIcon,
  startAddOnText,
  maxLength,
  value = "",
  placeholder = "",
  inputClassName,
  withBorder = true,
  readOnly = false,
  autoComplete = "off",
  onChange,
  onInputClick,
  onBlur,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { inputCss } = inputFieldTheme();

  const className = inputCss({
    className: clsx(inputClassName, {
      "pl-10": !!StartIcon || !!startAddOnText,
      "cursor-default": readOnly || disabled,
      "bg-gray-100": disabled || readOnly,
      "border-white focus:outline-none focus:ring-white focus:border-white":
        !error && !withBorder,
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        !!error,
    }),
  });

  const handleInputClick = (e: any) => {
    if (onInputClick) {
      onInputClick();
      e.target.blur();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const updatedValue =
      type === "number" ? reverseNumberFormatting(value) : value;
    onChange?.(updatedValue);
  };

  if (type === "number") {
    return (
      <NumericFormat
        getInputRef={inputRef}
        className={className}
        placeholder={t(placeholder)}
        value={value}
        readOnly={readOnly}
        autoComplete={autoComplete}
        onChange={handleChange}
        onWheel={handleWheelChange}
        onBlur={onBlur}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        disabled={disabled}
        {...props}
      />
    );
  }
  return (
    <input
      ref={inputRef}
      className={className}
      placeholder={isUrl(placeholder) ? placeholder : t(placeholder)}
      value={value}
      type={type}
      readOnly={readOnly}
      maxLength={maxLength}
      disabled={disabled}
      autoComplete={autoComplete}
      onChange={handleChange}
      onWheel={handleWheelChange}
      onClick={handleInputClick}
      onBlur={onBlur}
      {...props}
    />
  );
};

export const TextField: FC<TextFieldProps> = ({
  id,
  optional,
  error,
  endAddOnText,
  maxLength,
  EndIcon,
  EndElement,
  startAddOnText,
  StartIcon,
  withEndButton,
  EndButtonIcon,
  endButtonText,
  inputWrapperClassName,
  size = "md",
  endButtonLoading = false,
  disabled = false,
  label = "",
  hideErrorMessage = false,
  onClick,
  type = "text",
  ...props
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputLabelCss, inputWrapperCss } = inputFieldTheme();

  const getRemainingChars = () => {
    const value = props?.value ?? "";
    if (maxLength) {
      return maxLength - value.length;
    }
  };

  const handleClick = () => {
    onClick?.();
    if (props?.readOnly) {
      inputRef?.current?.focus();
    }
  };

  return (
    <div className="amenda-no-wheel-for-input-number group/textField">
      {label && (
        <div className="flex justify-between">
          <label htmlFor={id} className={inputLabelCss()}>
            {t(label)}
          </label>
          {optional}
        </div>
      )}
      <div
        className={clsx({
          relative: !withEndButton,
          flex: !!withEndButton,
        })}
      >
        <div className="relative flex flex-grow items-stretch">
          {StartIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <StartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          )}
          {startAddOnText && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="font-apercu normal-case text-gray-500 sm:text-sm">
                {t(startAddOnText)}
              </span>
            </div>
          )}
          <div
            className={inputWrapperCss({
              size,
              class: inputWrapperClassName,
            })}
          >
            <Input
              id={id}
              name={label}
              inputRef={inputRef}
              maxLength={maxLength}
              StartIcon={StartIcon}
              startAddOnText={startAddOnText}
              disabled={disabled}
              error={error}
              type={type}
              {...props}
            />
          </div>
          {error && !endAddOnText && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
          {EndElement && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {EndElement}
            </div>
          )}
          {EndIcon && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {<EndIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            </div>
          )}
          {endAddOnText && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex w-6 items-center pr-3">
              <span
                className={clsx(
                  "font-apercu start-0 normal-case text-gray-500 sm:text-sm",
                  { "text-red-500": error },
                )}
                id={`${label}-end-addon`}
              >
                {t(endAddOnText)}
              </span>
            </div>
          )}
        </div>
        {withEndButton && (
          <button
            type="button"
            disabled={disabled}
            onClick={handleClick}
            className={clsx(
              "relative -ml-px flex items-center bg-gray-900 px-2 py-1 text-sm text-white hover:bg-gray-700 focus:outline-none",
              {
                "cursor-not-allowed": disabled,
              },
            )}
          >
            {EndButtonIcon && (
              <EndButtonIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
            {endButtonText && <span>{t(endButtonText)}</span>}
            {endButtonLoading && <Spinner spinnerSize="xs" variant="primary" />}
          </button>
        )}
      </div>
      <div className="flex w-full justify-between">
        {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
        {!isNil(getRemainingChars()) && (
          <div className="flex justify-end pt-[2px] text-xs text-gray-500">
            {getRemainingChars()}/{maxLength} {t("characters left")}
          </div>
        )}
      </div>
    </div>
  );
};
