import { FC, useEffect, useState } from "react";
import { HelperMessage, LoaderWrapper } from "@amenda-components/App";
import { flattenUserDetails, getUserComponentFilters } from "./common";
import { getComponentsById, isRestricted } from "@amenda-utils";
import {
  useProjectStore,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { ProjectMiniCard } from "@amenda-components/Projects";
import { isEmpty } from "lodash";
import { useSearchProjects } from "@amenda-domains/queries";

export const ProjectsByContact: FC = () => {
  const components = useProjectStore((state) => state.projectFormComponents);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const selectedUser = useUsersStore((state) => state.selectedUser);
  const { searchProjects, loading } = useSearchProjects();
  const [projects, setProjects] = useState<any[]>([]);

  const user = flattenUserDetails(selectedUser);
  const componentsById = getComponentsById(components);
  const projectName = componentsById["name"];
  const projectDescription = componentsById["description"];

  useEffect(() => {
    const searchProjectsAsync = () => {
      const filters = getUserComponentFilters({
        components,
        userId: user?.id,
        userType: user?.type,
        company: user?.company,
      });

      if (filters) {
        searchProjects({
          filters,
          callback: setProjects,
        });
      }
    };

    searchProjectsAsync();
  }, [components, user?.id, user?.type, user?.company, searchProjects]);

  if (loading) {
    return <LoaderWrapper spinnerSize="sm" className="h-80" />;
  } else if (
    isEmpty(projects) ||
    (isRestricted(projectName, permissions) &&
      isRestricted(projectDescription, permissions))
  ) {
    return (
      <HelperMessage
        message={
          isEmpty(projects)
            ? "Contact is not linked to any project yet"
            : "You don't have the permissions necessary to view linked projects"
        }
      />
    );
  }
  return (
    <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
      {projects?.map((project: any) => (
        <ProjectMiniCard
          key={project?.id}
          componentsById={componentsById}
          permissions={permissions}
          project={project}
        />
      ))}
    </div>
  );
};
