import { FC, useCallback } from "react";
import {
  GroupByKeysView,
  GroupView,
  ListView,
  ListWrapper,
} from "@amenda-components/Shared";
import { HelperMessage, Spinner } from "@amenda-components/App";
import { isSafeCollection, useVerticalScrollEvent } from "@amenda-utils";
import {
  useFormStore,
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";

import { ContactDirectoryProps } from "./types";
import { ContactListCard } from "./ContactListCard";
import { UsersIcon } from "@heroicons/react/24/solid";
import { getContactRequestArgs } from "./common";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";

interface Props
  extends Pick<
    ContactDirectoryProps,
    "collectionType" | "getAllContacts" | "rootRoute" | "collectionRoute"
  > {
  users: any[];
  groupedContacts: any;
  isCollection: boolean;
  isSelectedContact: (contact: any) => boolean;
}

export const ContactListView: FC<Props> = ({
  users,
  groupedContacts,
  collectionType,
  isCollection,
  isSelectedContact,
  getAllContacts,
  ...rest
}) => {
  const { ref, inView } = useInView({
    skip: isEmpty(groupedContacts),
  });
  const pagination = useUsersStore((state) => state.pagination);
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const isSearchingContacts = useUsersStore(
    (state) => state.isSearchingContacts,
  );
  const isFetchingContacts = useUsersStore((state) => state.isFetchingContacts);
  const handleVerticalScroll = useVerticalScrollEvent();
  const { searchFilters, groupingComponents } = useUserSearchFiltersWithPath();

  const selectedCollection = selectedCollectionByType[collectionType];

  const handleNextPage = useCallback(() => {
    if (
      inView &&
      !isFetchingContacts &&
      pagination?.hasNext &&
      isEmpty(searchFilters?.searchTerm) &&
      isEmpty(searchFilters?.groupByComponentIds) &&
      isSafeCollection(isCollection, selectedCollection?.resourceIds)
    ) {
      const args = getContactRequestArgs({
        isCollection,
        autoSelect: false,
        contactType: searchFilters.contactType,
        collectionResourceIds: selectedCollection?.resourceIds,
      });

      getAllContacts({
        ...args,
        next: pagination?.next,
      });
    }
  }, [
    inView,
    isFetchingContacts,
    isCollection,
    pagination?.hasNext,
    pagination?.next,
    selectedCollection?.resourceIds,
    searchFilters,
    getAllContacts,
  ]);

  return (
    <nav
      className="min-h-0 w-full flex-1 space-y-1 overflow-y-auto"
      aria-label="Directory"
      onScroll={handleVerticalScroll(handleNextPage)}
    >
      {isSearchingContacts ? (
        <div className="flex h-96 flex-row items-center justify-center">
          <Spinner spinnerSize="sm" variant="secondary" />
        </div>
      ) : isEmpty(groupedContacts) ? (
        <HelperMessage Icon={UsersIcon} message="No contacts found" />
      ) : (
        <>
          {!isEmpty(groupingComponents) ? (
            <GroupByKeysView
              label="Contacts"
              field="users"
              resources={users}
              components={groupingComponents}
              isSelected={isSelectedContact}
            >
              {(r) => (
                <ContactListCard
                  contact={r}
                  isCollection={isCollection}
                  {...rest}
                />
              )}
            </GroupByKeysView>
          ) : !isEmpty(searchFilters?.searchTerm) ? (
            <ListWrapper>
              <ListView resources={users} isSelected={isSelectedContact}>
                {(r) => (
                  <ContactListCard
                    contact={r}
                    isCollection={isCollection}
                    {...rest}
                  />
                )}
              </ListView>
            </ListWrapper>
          ) : (
            <GroupView
              groupedResources={groupedContacts}
              isSelected={isSelectedContact}
            >
              {(r) => (
                <ContactListCard
                  contact={r}
                  isCollection={isCollection}
                  {...rest}
                />
              )}
            </GroupView>
          )}
        </>
      )}
      {isFetchingContacts && (
        <div className="flex flex-row justify-center py-4">
          <Spinner spinnerSize="sm" variant="secondary" />
        </div>
      )}
      <div ref={ref} className="h-12" />
    </nav>
  );
};
