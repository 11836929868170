import { Link2, Unlink } from "lucide-react";

import { FC } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  message?: string;
  isOpen?: boolean;
  openFaceDetectionModal: () => void;
}

export const LabelDetectedFace: FC<Props> = ({
  id,
  isOpen,
  message,
  openFaceDetectionModal,
}) => {
  const { t } = useTranslation();

  return (
    <ReactTooltip
      id={id}
      clickable={true}
      positionStrategy="fixed"
      place="top-start"
      isOpen={isOpen}
      className="amenda-tooltip !rounded-none !bg-white !p-0 !text-gray-800 !opacity-100 hover:z-[60] hover:shadow-xl group-hover:z-[60] group-hover:shadow-xl"
    >
      <div className="flex h-full w-full items-center">
        <span className="px-2">{message || t("Add contact")}</span>
        <button
          type="button"
          className="h-full bg-gray-800 px-2.5 py-1.5 text-white"
          onClick={openFaceDetectionModal}
        >
          {message ? (
            <Unlink className="h-5 w-5" />
          ) : (
            <Link2 className="h-5 w-5" />
          )}
        </button>
      </div>
    </ReactTooltip>
  );
};
