import { ComponentTreeRendererProps } from "@amenda-types";
import { FC } from "react";
import { FormComponent } from "./FormComponent";

const buildChildComponents = ({
  config,
  ...rest
}: ComponentTreeRendererProps) => {
  return (
    config.components &&
    config.components.map((childComponent: any) => (
      <FormComponent
        key={childComponent.id}
        config={childComponent}
        buildChildComponents={buildChildComponents}
        {...rest}
      />
    ))
  );
};

export const ComponentTreeRenderer: FC<ComponentTreeRendererProps> = (
  props,
) => {
  const { config, ...rest } = props;
  if (!config.id) {
    return null;
  }
  return (
    <FormComponent
      key={config.id}
      config={config}
      buildChildComponents={buildChildComponents}
      {...rest}
    />
  );
};
