import { Avatar, HelperMessage, LoaderWrapper } from "@amenda-components/App";
import { Building2Icon, ExternalLinkIcon, MapPinIcon } from "lucide-react";
import {
  ContactActionBar,
  ContactOverviewCard,
} from "@amenda-components/Contacts";
import { DeleteModal, MaxWidthLayout } from "@amenda-components/Shared";
import { FC, useEffect, useMemo, useState } from "react";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import { flattenUserDetails, getUserName } from "./common";
import { getComponentsFromForms, isRestricted } from "@amenda-utils";
import {
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useUpdateCollection,
  useUpdateContact,
  useUpdateUser,
  useUsersStore,
} from "@amenda-domains/mutations";
import { useNavigate, useParams } from "react-router-dom";

import { AllowedContactType } from "@amenda-types";
import { ContactDirectoryProps } from "./types";
import { GeneralAccessTypes } from "@amenda-constants";
import { ReadOnlyHeaders } from "@amenda-components/PageBuilder";
import { ShareModal } from "@amenda-components/Shared/ShareModal";
import { UsersIcon } from "@heroicons/react/24/solid";
import isEmpty from "lodash/isEmpty";
import { useGetContact } from "@amenda-domains/queries";

interface ContactOverviewProps extends ContactDirectoryProps {
  isCollection: boolean;
}

interface Props extends ContactOverviewProps {
  contact: Record<string, any>;
}

type OverviewAddressProps = Pick<Props, "contact"> & {
  components: any[];
};

const OverviewAddress: FC<OverviewAddressProps> = ({ contact, components }) => {
  const { getContact } = useGetContact();
  const [company, setCompany] = useState<any>({});
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const companyId = contact?.company;
  const companyDetails = flattenUserDetails(company);
  const contactType = contact?.type as AllowedContactType;
  const addressComponent = components.find((c) => c.id === "address");
  const isAddressRestricted = useMemo(
    () => isRestricted(addressComponent, permissions),
    [addressComponent, permissions],
  );

  useEffect(() => {
    const getContactAsync = async () => {
      setCompany({});
      if (companyId && !isAddressRestricted) {
        await getContact({
          id: companyId,
          skipLoading: true,
          callback: setCompany,
        });
      }
    };

    getContactAsync();
  }, [getContact, companyId, isAddressRestricted]);

  if (!contactType || isAddressRestricted) {
    return null;
  } else if (
    AllowedContactType.person === contactType &&
    Boolean(companyDetails?.address?.name) &&
    !Boolean(contact?.address?.name)
  ) {
    return (
      <div className="flex items-center space-x-2">
        <Building2Icon className="h-5 w-5" />
        <h3 className="text-md text-gray-600">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(companyDetails?.address?.name)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#2286e0] hover:underline"
          >
            {companyDetails?.address?.name}{" "}
            <ExternalLinkIcon className="inline h-3 w-3" />
          </a>
        </h3>
      </div>
    );
  }
  return (
    <div className="flex items-center space-x-2">
      <MapPinIcon className="h-5 w-5" />
      <h3 className="text-md text-gray-600">
        {contact?.address?.name && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(contact.address.name)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#2286e0] hover:underline"
          >
            {contact.address.name}{" "}
            <ExternalLinkIcon className="inline h-3 w-3" />
          </a>
        )}
      </h3>
    </div>
  );
};

const Overview: FC<Props> = ({
  contact,
  isCollection,
  canDeleteUsers,
  rootRoute,
  collectionRoute,
  collectionType,
  defaultContactType,
}) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { updateContact, loading } = useUpdateContact();
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const { updateUser, loading: updateUserLoader } = useUpdateUser();
  const currentUser = useUsersStore((state) => state.currentUser);
  const { updateCollection, loading: updateCollectionLoader } =
    useUpdateCollection();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const [openShareModal, setOpenShareModal] = useState(false);

  const name = getUserName(contact);
  const isLoading = loading || updateUserLoader;
  const components = getComponentsFromForms(
    formsByContactType[contact?.type as AllowedContactType],
  );
  const selectedCollection = selectedCollectionByType[collectionType];
  const isContactOwner = currentUser?.id === contact?.ownerId;
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseShare = () => setOpenShareModal(false);

  const handleDeletePermanently = async () => {
    let input: any = {
      _id: contact.id,
      isDeleted: true,
    };
    let handleSave = updateContact;

    if (AllowedContactType.office === (contact?.type as AllowedContactType)) {
      input = {
        ...input,
        uid: contact.uid,
      };
      handleSave = updateUser;
    }

    await handleSave({
      input,
    });
  };

  const handleDeleteFromCollection = async () => {
    await updateCollection({
      input: {
        _id: selectedCollection.id,
        aggregation: {
          $pull: { resourceIds: { $in: [contact.id] } },
        },
      },
    });
  };

  const handleDelete = async () => {
    let path;
    if (isCollection) {
      path = `${collectionRoute}/${selectedCollection.id}`;
      if (isContactOwner) {
        await handleDeletePermanently();
      }
      await handleDeleteFromCollection();
    } else {
      path = rootRoute;
      await handleDeletePermanently();
    }
    setOpenModal(false);
    navigate(path);
  };

  const handleShare = async (type: GeneralAccessTypes, users: any[]) => {
    let input: any = {
      _id: contact.id,
      share: {
        type,
        users: users.map((u) => ({
          id: u._id,
          role: u[PermissionSharePath] || ResourceSharingPermissionTypes.View,
        })),
      },
    };
    let handleSave = updateContact;

    if (AllowedContactType.office === (contact?.type as AllowedContactType)) {
      input = {
        ...input,
        uid: contact.uid,
      };

      handleSave = updateUser;
    }

    await handleSave({
      input,
    });
    setOpenShareModal(false);
  };

  return (
    <>
      <DeleteModal
        title="Delete contact"
        description={
          isCollection
            ? "Are you sure you want to remove this contact from this collection?"
            : "Are you sure you want to delete this contact?"
        }
        loading={isLoading || updateCollectionLoader}
        openModal={openModal}
        handleDelete={handleDelete}
        handleCloseModal={handleCloseModal}
      />
      <ShareModal
        title="Share contact with"
        loading={loading}
        openModal={openShareModal}
        ownerId={contact?.ownerId}
        shareType={contact?.share?.type}
        shareWith={contact?.share?.users || []}
        onSubmit={handleShare}
        handleClose={handleCloseShare}
      />
      <div className="flex-1 focus:outline-none xl:order-last">
        <div className="flex w-full flex-col">
          <MaxWidthLayout className="xxl:w-[1200px]">
            <div className="mx-auto flex w-full justify-between bg-white px-4 pt-4 md:pt-8 xl:px-6 xxl:px-0">
              <div className="flex items-center space-x-3">
                {contact?.photoURL && (
                  <Avatar
                    name={name}
                    src={contact?.photoURL}
                    className="h-24 w-24"
                  />
                )}
                <span className="text-2xl text-gray-900 xxl:text-3xl">
                  {name}
                </span>
              </div>
            </div>
            <div className="flex w-full flex-col bg-white px-4 md:flex-row md:items-center md:justify-between xl:px-6 xxl:px-0">
              <div className="flex flex-col">
                <div className="block w-full flex-1 pb-2">
                  <OverviewAddress contact={contact} components={components} />
                </div>
                <ReadOnlyHeaders components={components} formValues={contact} />
              </div>
              <ContactActionBar
                isCollection={isCollection}
                contact={contact}
                canDeleteUsers={canDeleteUsers}
                rootRoute={rootRoute}
                collectionRoute={collectionRoute}
                setOpenModal={setOpenModal}
                setOpenShareModal={setOpenShareModal}
              />
            </div>
          </MaxWidthLayout>
        </div>
        <MaxWidthLayout className="xxl:w-[1200px]">
          <ContactOverviewCard defaultContactType={defaultContactType} />
        </MaxWidthLayout>
      </div>
    </>
  );
};

export const ContactOverview: FC<ContactOverviewProps> = ({
  isCollection,
  ...props
}) => {
  const { userId } = useParams<{ userId?: string }>();
  const selectedUserId = useUsersStore((state) => state.selectedUserId);
  const selectedUser = useUsersStore((state) => state.selectedUser);
  const isFetchingContact = useUsersStore((state) => state.isFetchingContact);
  const clearSelectedUser = useUsersStore((state) => state.clearSelectedUser);

  const contact = flattenUserDetails(selectedUser);
  const { singularTitle, getContact } = props;

  useEffect(() => {
    const getUserAsync = async () => {
      const id = userId ?? selectedUserId;

      if (id) {
        await getContact({
          id,
          context: {
            requestPolicy: "cache-and-network",
          },
        });
      } else {
        clearSelectedUser();
      }
    };

    getUserAsync();
  }, [userId, selectedUserId, getContact, clearSelectedUser]);

  if (isFetchingContact) {
    return (
      <MaxWidthLayout className="overflow-y-auto">
        <LoaderWrapper />
      </MaxWidthLayout>
    );
  }
  if (isEmpty(contact)) {
    return (
      <MaxWidthLayout className="relative xxl:w-[1200px]">
        <HelperMessage Icon={UsersIcon} message={`No ${singularTitle} found`} />
      </MaxWidthLayout>
    );
  }
  return <Overview isCollection={isCollection} contact={contact} {...props} />;
};
