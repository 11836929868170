import { Image, Link } from "@amenda-components/App";

import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { Controller } from "react-hook-form";
import { EmptyIndicator } from "@amenda-components/Shared";
import { FC } from "react";
import { FormComponentProps } from "@amenda-types";
import { TextField } from "@amenda-components/FormComponents";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";

const visitUrl = (path: string) => window.open(path);

const ImageWrapper: FC<{
  url: string;
}> = ({ url }) => {
  return (
    <div className="w-full">
      <Link
        className="flex w-full items-center space-x-2 pb-2 text-blue-500"
        onClick={() => visitUrl(url)}
      >
        <span className="w-3/4 truncate text-xs">{url}</span>
        <ArrowUpRightIcon className="h-5 w-5" />
      </Link>
      <Image url={url} ignoreMagicLinks={true} />
    </div>
  );
};

const ReadOnlyMaterialPhoto: FC<{ url: string; label: string }> = ({
  url,
  label,
}) => {
  return (
    <TitleAndDescription
      container="column"
      title={label}
      description={url ? <ImageWrapper url={url} /> : <EmptyIndicator />}
    />
  );
};

const MaterialPhotoInput: FC<
  FormComponentProps & {
    value: string;
    error?: string;
    onChange: (value: string) => void;
  }
> = ({ config, global, value, error, onChange, getComponentIcons }) => {
  const { id, properties } = config;
  const { StartIcon, ...rest } = properties || {};

  const onBlur = () => {
    global?.onBlur?.();
  };

  return (
    <div className="w-full">
      <TextField
        id={id}
        value={value}
        error={error}
        disabled={properties?.readOnly}
        onBlur={onBlur}
        onChange={onChange}
        StartIcon={getComponentIcons?.(StartIcon)}
        {...rest}
      />
      {value && <ImageWrapper url={value} />}
    </div>
  );
};

export const MaterialPhoto: FC<FormComponentProps> = ({
  config,
  global,
  readOnly,
  getComponentIcons,
}) => {
  const { id, properties } = config;

  if (readOnly) {
    return (
      <ReadOnlyMaterialPhoto label={properties.label} url={global.data?.[id]} />
    );
  }
  return (
    <Controller
      name={id}
      control={global.control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <MaterialPhotoInput
            config={config}
            global={global}
            value={value}
            error={error?.message}
            onChange={onChange}
            getComponentIcons={getComponentIcons}
          />
        );
      }}
    />
  );
};
