import {
  ComponentTreeRenderer,
  FormAutoSaveBaseProps,
  FormAutoSaveWrapperBase,
} from "@amenda-components/PageBuilder";
import { Control, UseFormReset, useWatch } from "react-hook-form";
import { FC, memo } from "react";
import {
  getFormDisplayComponentIds,
  getValuesFromWatcher,
  groupComponentsByParent,
  processDisplayComponents,
} from "@amenda-utils";

import { FormComponentProps } from "@amenda-types";

interface Props extends Omit<FormAutoSaveBaseProps, "children"> {
  formId?: string;
  components: any[];
  className?: string;
  globalProps?: Record<string, any>;
  customFormComponents?: Record<string, FC<FormComponentProps>>;
  processFormPermissions?: (components: any[]) => any[];
}

type ComponentTreeRendererWrapperProps = Omit<
  Props,
  "values" | "onSubmit" | "inputSchema" | "resourceId" | "resourceIds"
> & {
  control?: Control<any>;
  reset?: UseFormReset<any>;
};

const ComponentTreeRendererWrapper: FC<ComponentTreeRendererWrapperProps> = ({
  reset,
  control,
  components,
  globalProps,
  customFormComponents,
  processFormPermissions,
}) => {
  const { displayComponentIds } = getFormDisplayComponentIds(components);
  const watched = useWatch({
    control,
    name: displayComponentIds,
  });

  const values = getValuesFromWatcher(displayComponentIds, watched);
  const availableComponents = processDisplayComponents(values, components);
  const formComponentTree = groupComponentsByParent(
    availableComponents,
    processFormPermissions,
  );

  return (
    <ComponentTreeRenderer
      config={formComponentTree}
      customComponents={customFormComponents}
      globalProps={{
        ...globalProps,
        control,
        reset,
      }}
      readOnly={false}
    />
  );
};

export const FormAutoSaveWrapperWithConditions: FC<Props> = memo(
  ({
    resourceId,
    resourceIds,
    components,
    globalProps,
    customFormComponents,
    processFormPermissions,
    ...rest
  }) => {
    return (
      <FormAutoSaveWrapperBase
        resourceId={resourceId}
        resourceIds={resourceIds}
        {...rest}
      >
        {({ control, reset }) => (
          <ComponentTreeRendererWrapper
            reset={reset}
            control={control}
            components={components}
            globalProps={globalProps}
            customFormComponents={customFormComponents}
            processFormPermissions={processFormPermissions}
          />
        )}
      </FormAutoSaveWrapperBase>
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.components) !==
      JSON.stringify(nextProps.components)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.globalProps) !==
      JSON.stringify(nextProps.globalProps)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.values) !== JSON.stringify(nextProps.values)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.resourceIds) !==
      JSON.stringify(nextProps.resourceIds)
    ) {
      return false;
    } else if (prevProps.resourceId !== nextProps.resourceId) {
      return false;
    } else {
      return true;
    }
  },
);
