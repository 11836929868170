import { AddComponentButton, SortDroppable } from "./DragAndDropWrapper";

import { FC } from "react";
import { LayoutProps } from "./common";

export const DefaultLayout: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
}) => {
  return (
    <SortDroppable
      config={config}
      isFormBuilder={isFormBuilder}
      className="group relative space-y-2"
    >
      <AddComponentButton
        className="justify-center"
        isFormBuilder={isFormBuilder}
        config={config}
      />
      {children}
    </SortDroppable>
  );
};
