import { AuthFormType } from "@amenda-types";
import { Button } from "@amenda-components/App";
import { FC } from "react";
import { useAuthStore } from "@amenda-domains/mutations";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CheckYourEmailComponent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toggleAuthFormType = useAuthStore((state) => state.toggleAuthFormType);

  const goToLogin = () => {
    toggleAuthFormType(AuthFormType.Login);
    navigate("/login");
  };

  return (
    <div>
      <div className="mb-6">
        {t(
          "Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.",
        )}
      </div>
      <Button
        className="mt-6 w-full justify-center text-center"
        onClick={goToLogin}
      >
        <span>{t("Return to Sign In")}</span>
      </Button>
    </div>
  );
};
