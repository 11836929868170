import { CheckCircle2 } from "lucide-react";
import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  isChecked: boolean;
  onChange?: () => void;
}

export const CheckMarkRadio: React.FC<Props> = ({
  isChecked,
  className,
  onChange,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        "flex h-6 min-h-6 w-6 min-w-6 items-center justify-center rounded-full border text-gray-900",
        className,
        {
          "border-transparent bg-[#2286E0]": isChecked,
          "border-gray-400 bg-white hover:border-gray-900": !isChecked,
        },
      )}
      onClick={(e) => {
        e.stopPropagation();
        onChange?.();
      }}
    >
      {isChecked && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <div
            className={`rounded-full text-white ${
              isChecked ? "bg-[#2286E0]" : "hover:bg-[#2286E0]"
            }`}
          >
            {isChecked ? (
              <CheckIcon className="h-5 w-5 stroke-[3]" />
            ) : (
              <CheckCircle2 className="h-5 w-5 stroke-[2]" />
            )}
          </div>
        </div>
      )}
    </button>
  );
};
