import {
  ComponentSearchModal,
  SearchComponentItemProps,
} from "@amenda-components/SearchComponents/ComponentDropdownSearch";
import { FC, SyntheticEvent } from "react";
import { Option, PageComponentProps } from "@amenda-types";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SimilarityConfigKey, sortSimilaritySearchOptions } from "./common";

import { UseFormResetField } from "react-hook-form";
import clsx from "clsx";
import { getFormNameById } from "@amenda-components/SearchComponents/common";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

type OptionType = PageComponentProps & Option;

const getSearchComponent =
  (resetField: UseFormResetField<any>) =>
  ({
    position,
    formLabel,
    options,
    clearSearchTerm,
  }: SearchComponentItemProps) => {
    const { t } = useTranslation();
    const similaritySearchComponents = useProjectStore(
      (state) => state.similaritySearchComponents,
    );
    const toggleSimilaritySearchComponent = useProjectStore(
      (state) => state.toggleSimilaritySearchComponent,
    );

    const selectedComponentsIds = similaritySearchComponents.map((c) => c.id);

    const handleClick =
      ({ value, label, ...rest }: OptionType) =>
      (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        toggleSimilaritySearchComponent(rest);
        clearSearchTerm();
        resetField(value);
        resetField(`${value}${SimilarityConfigKey}`);
      };

    return (
      <div className="w-full">
        <div
          className={clsx(
            "w-full border-b border-gray-100 pb-2 pl-3 pt-4 text-sm text-gray-500",
            {
              "pt-4": position !== 0,
              "pt-2": position === 0,
            },
          )}
        >
          {formLabel}
        </div>
        {[...options]
          .sort(sortSimilaritySearchOptions(selectedComponentsIds))
          .map((option) => {
            const isSelected = selectedComponentsIds.includes(option.value);
            return (
              <button
                key={option.value}
                onClick={handleClick(option)}
                className={clsx(
                  "flex w-full items-center justify-between py-2 pl-6 text-gray-700 hover:bg-gray-900 hover:text-white",
                  {
                    "bg-gray-200 pr-4": isSelected,
                  },
                )}
              >
                <span className="font-apercu text-sm">{t(option.label)}</span>
                {isSelected && <XMarkIcon className="h-4 w-4" />}
              </button>
            );
          })}
      </div>
    );
  };

interface Props {
  components: PageComponentProps[];
  resetField: UseFormResetField<any>;
}

export const SimilaritySearchDropdown: FC<Props> = ({
  components,
  resetField,
}) => {
  const projectForms = useProjectStore((state) => state.projectForms);
  const { formNameById } = getFormNameById(projectForms);

  return (
    <ComponentSearchModal
      components={components}
      formNameById={formNameById}
      label="Add Parameter"
      Icon={PlusIcon}
      SearchComponentItem={getSearchComponent(resetField)}
      className="mt-1 rounded-2xl border bg-white px-1 py-[1px] text-sm text-gray-900 hover:bg-gray-900 hover:text-white"
    />
  );
};
