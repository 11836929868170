import { Logo, SidebarFooter, SidebarMenuItems } from "./SidebarComponents";
import { FC } from "react";
import { Link } from "react-router-dom";
import { NavigationProps } from "@amenda-types";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { Tooltip } from "@amenda-components/App";

interface Props {
  navigation: NavigationProps[];
}

export const Sidebar: FC<Props> = ({ navigation }) => {
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);
  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  return (
    <div
      className={clsx(
        "transition-width group relative z-40 hidden h-full flex-col duration-300 ease-in-out lg:flex",
        {
          "w-[210px]": !sidebarCollapsed,
          "w-0": sidebarCollapsed,
        },
      )}
    >
      <div className="absolute right-2 top-0 z-20 lg:top-[40px]">
        {!sidebarCollapsed && (
          <Tooltip
            id="toggleSidebar"
            message="Toggle Sidebar"
            position="bottom"
          >
            <img
              alt="Toggle Sidebar"
              className="h-4 w-4 cursor-pointer"
              onClick={toggleSidebar}
              src={
                sidebarCollapsed
                  ? "/images/sidebarExpand.svg"
                  : "/images/sidebarCollapse.svg"
              }
            />
          </Tooltip>
        )}
      </div>
      <div className="flex h-full flex-grow flex-col bg-[#F3F4F6] pb-2 pt-[15px]">
        <div
          className={clsx("relative flex h-full flex-col overflow-y-auto", {
            "items-center": sidebarCollapsed,
          })}
        >
          <div
            className={clsx("py-1", {
              "px-4": !sidebarCollapsed,
            })}
          >
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <SidebarMenuItems navigation={navigation} />
          <SidebarFooter
            className={clsx("sticky bottom-0 mt-auto", {
              "h-12": !sidebarCollapsed,
            })}
          />
        </div>
      </div>
    </div>
  );
};
