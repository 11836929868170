import { FC, ReactNode, useEffect, useRef } from "react";
import { IconButton, IconButtonBase, Tooltip } from "@amenda-components/App";

import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  label: string;
  closeOnRouteChange?: boolean;
  children?: (props: {
    bottomSheetHeight: number;
    setBottomSheetHeight: (height: number) => void;
  }) => ReactNode;
  handleClose: () => void;
  handleCloseOnMount?: () => void;
}

export const BottomSheet: FC<Props> = ({
  isOpen,
  label,
  children,
  closeOnRouteChange = false,
  handleClose,
  handleCloseOnMount,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const ref = useRef<string | null>(null);
  const bottomSheetHeight = useAppStore((state) => state.bottomSheetHeight);
  const setBottomSheetHeight = useAppStore(
    (state) => state.setBottomSheetHeight,
  );

  const minHeight = 120;
  const maxHeight = window.innerHeight;
  const allowedMaxHeight = maxHeight - maxHeight * 0.35;
  const bottomSheetdirection = bottomSheetHeight > minHeight ? "down" : "up";

  const handleClick = () => {
    const height = bottomSheetHeight > minHeight ? minHeight : allowedMaxHeight;

    setBottomSheetHeight(height);
  };

  useEffect(() => {
    if (closeOnRouteChange && pathname !== ref.current && handleCloseOnMount) {
      handleCloseOnMount();
    }
    ref.current = pathname;
  }, [pathname, closeOnRouteChange, handleCloseOnMount]);

  return (
    <div className="fixed bottom-0 left-0 right-0 z-[60] flex w-full justify-center">
      <div
        className={clsx("z-[90] w-full xl:w-1/2", {
          flex: isOpen,
          hidden: !isOpen,
        })}
      >
        <div
          className="w-full border border-b-0 border-gray-200 bg-white shadow-2xl transition-[height] duration-150 ease-linear"
          style={{
            height: `${bottomSheetHeight}px`,
          }}
        >
          {bottomSheetdirection === "up" && (
            <div className="group -mt-[19px] flex w-full flex-col items-center justify-center">
              <Tooltip
                id="expandBottomSheet"
                position="top"
                message={bottomSheetdirection === "up" ? "adjust" : ""}
              >
                <IconButtonBase
                  label="Slide"
                  variant="clean"
                  onClick={handleClick}
                  className="border-bg-gray-600 rounded-full border bg-white p-2 shadow-md"
                >
                  <ChevronUpIcon className="h-5 w-5" />
                </IconButtonBase>
              </Tooltip>
            </div>
          )}
          <div className="grid w-full grid-cols-3">
            <div />
            <span className="text-md pt-2 text-center text-gray-900">
              {t(label)}
            </span>
            <div className="flex items-center justify-end pr-1">
              <IconButton
                label="Close"
                variant="clean"
                Icon={XMarkIcon}
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="h-full w-full py-1">
            {children?.({
              bottomSheetHeight,
              setBottomSheetHeight,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
