import { HelperMessage, LoaderWrapper } from "@amenda-components/App";

import { FC } from "react";
import { ListView } from "@amenda-components/FileUploads";
import { isEmpty } from "lodash";

interface Props {
  loading: boolean;
  uploadedFiles: any[];
  canUpdateGallery?: boolean;
  handleDeleteFile?: (file: any) => void;
}

export const PdfListView: FC<Props> = ({ loading, uploadedFiles, ...rest }) => {
  if (loading) {
    return <LoaderWrapper />;
  }
  if (isEmpty(uploadedFiles)) {
    return (
      <div className="relative mt-4 block w-full rounded-lg p-2 text-center hover:border-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <HelperMessage className="h-48" message="No Files Uploaded" />
      </div>
    );
  }
  return (
    <div>
      <ListView files={uploadedFiles} {...rest} />
    </div>
  );
};
