import { FormTab } from "@amenda-types";
import { isArray } from "lodash";

export const getValuesByComponent = (
  formValues: any[],
  componentId: string,
) => {
  let values: any[] = [];
  formValues
    .filter((formValue) => formValue[componentId])
    .forEach((formValue) => {
      const value = formValue[componentId];
      if (isArray(value)) {
        values.push(...value);
      } else {
        values.push(value);
      }
    });
  return values;
};

export const getFormNameById = (forms: FormTab[]) => {
  const formNameById: Record<string, string> = {};
  forms.forEach((form) => {
    formNameById[form.id] = form.name;
  });
  return { formNameById };
};
