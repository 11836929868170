import { FC, useEffect } from "react";
import {
  GalleryModalDesktopSidebar,
  GalleryModalMainContent,
} from "@amenda-components/Gallery";

import { AllowedAttachmentType } from "@amenda-types";
import { FullScreenModal } from "@amenda-components/App";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useGetAttachment } from "@amenda-domains/queries";

export const GalleryModal: FC = () => {
  const { getAttachment, loading } = useGetAttachment();
  const isGalleryModalOpen = useAttachmentStore(
    (state) => state.isGalleryModalOpen,
  );
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );

  useEffect(() => {
    if (isGalleryModalOpen && selectedAttachment?.id) {
      getAttachment({
        id: selectedAttachment?.id,
        type: AllowedAttachmentType.image,
        selectedAttachmentFields: { metadata: 1, url: 1, name: 1 },
      });
    }
  }, [selectedAttachment?.id, isGalleryModalOpen, getAttachment]);

  return (
    <FullScreenModal isOpen={isGalleryModalOpen} onClose={() => {}}>
      <GalleryModalMainContent loading={loading} />
      <GalleryModalDesktopSidebar loading={loading} />
    </FullScreenModal>
  );
};
