import { IconButtonBase, Tag, Tags } from "@amenda-components/App";

import { FC } from "react";
import { FormBuilderSortableItem } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { FormComponentTypes } from "@amenda-constants";
import { GripIcon } from "lucide-react";
import { PageComponentProps } from "@amenda-types";
import { ReactTreeSelectBreadCrumb } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { formatDescription } from "@amenda-utils";
import { getOptionsTree } from "@amenda-components/FormComponents/common";
import { useTranslation } from "react-i18next";

interface HeaderTitleAndDescriptionProps {
  title: string;
  description: string;
  titleClassName?: string;
  descriptionClassName?: string;
}

interface FormBuilderReadOnlyHeaderProps {
  component?: PageComponentProps;
}

interface HeaderSelectsProps {
  values: any[];
  component?: PageComponentProps;
}

interface HeaderTextProps {
  value: any;
  component?: PageComponentProps;
}

const HeaderTitleAndDescription: FC<HeaderTitleAndDescriptionProps> = ({
  title,
  description,
  titleClassName = "text-sm text-gray-900",
  descriptionClassName = "text-sm text-gray-500 mt-1",
}) => {
  return (
    <>
      <dt className={titleClassName}>{title}</dt>
      <dd className={descriptionClassName}>{description}</dd>
    </>
  );
};

export const HeaderSelects: FC<HeaderSelectsProps> = ({
  component,
  values,
}) => {
  const { t } = useTranslation();
  const isNested = Boolean(component?.properties?.isNested);
  const optionsTree = getOptionsTree(component?.properties?.options ?? []);

  return (
    <div className="w-full">
      {component?.headerProperties?.properties?.withTitle && (
        <span
          className={
            component?.headerProperties?.properties.titleClassName ??
            "amenda-component-label mb-2"
          }
        >
          {t(component?.properties?.label ?? "")}
        </span>
      )}
      {isNested ? (
        <Tags>
          {values.map((value) => (
            <Tag key={value}>
              <ReactTreeSelectBreadCrumb
                value={value}
                optionsTree={optionsTree}
              />
            </Tag>
          ))}
        </Tags>
      ) : (
        <Tags>
          {values.map((v) => (
            <Tag key={v.value}>{v.label}</Tag>
          ))}
        </Tags>
      )}
    </div>
  );
};

export const HeaderText: FC<HeaderTextProps> = ({ component, value }) => {
  const { t } = useTranslation();
  const description = formatDescription(value);

  if (component?.headerProperties?.properties?.withTitle) {
    return (
      <div className="w-full">
        <HeaderTitleAndDescription
          title={component?.properties?.label}
          description={description}
          titleClassName={
            component?.headerProperties?.properties?.titleClassName
          }
          descriptionClassName={
            component?.headerProperties?.properties?.descriptionClassName
          }
        />
      </div>
    );
  }
  return description ? (
    <div className="w-full">
      <div
        className={clsx(
          component?.headerProperties?.properties?.descriptionClassName ??
            "mb-8 max-w-2xl text-sm text-gray-500",
          {
            "overflow-y-auto overscroll-contain whitespace-pre-line":
              component?.component === FormComponentTypes.Textarea,
          },
        )}
      >
        {t(description)}
      </div>
    </div>
  ) : null;
};

const ReadOnlyHeaderComponent: FC<FormBuilderReadOnlyHeaderProps> = ({
  component,
}) => {
  switch (component?.component as FormComponentTypes) {
    case FormComponentTypes.Badges:
    case FormComponentTypes.Keyword:
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.Checkbox:
    case FormComponentTypes.RadioButton:
      return (
        <div className="flex w-full flex-col space-y-1">
          <span className="font-apercu text-sm">
            {component?.properties?.label}
          </span>
          <HeaderSelects
            values={(
              component?.properties?.options ?? [
                { value: "a", label: "A" },
                { value: "b", label: "B" },
                { value: "c", label: "C" },
              ]
            ).slice(0, 4)}
            component={component}
          />
        </div>
      );
    default:
      const value = !Boolean(component?.headerProperties?.properties?.withTitle)
        ? component?.properties?.label
        : "Placeholder";
      return <HeaderText value={value} component={component} />;
  }
};

export const FormBuilderReadOnlyHeader: FC<FormBuilderReadOnlyHeaderProps> = ({
  component,
}) => {
  return (
    <FormBuilderSortableItem
      id={component?.id ?? ""}
      data={{
        component,
      }}
    >
      {(isDragging) => (
        <div
          className={clsx(
            "flex items-baseline border border-dashed border-transparent hover:border-gray-300",
            {
              invisible: isDragging,
            },
          )}
        >
          <div>
            <IconButtonBase
              size="sm"
              className="invisible mr-1 cursor-grab px-0.5 py-1 group-hover/formBuilderReadOnlyHeader:visible"
            >
              <GripIcon className="h-4 w-4 stroke-[2]" />
            </IconButtonBase>
          </div>
          <ReadOnlyHeaderComponent component={component} />
        </div>
      )}
    </FormBuilderSortableItem>
  );
};
