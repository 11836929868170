import { FC } from "react";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  label: string;
  value?: boolean;
  onChange: (value: boolean) => void;
}

export const MiniSwitch: FC<Props> = ({
  className,
  label,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Switch
        checked={value}
        onChange={onChange}
        className={clsx(
          "relative inline-flex h-4 w-7 shrink-0 cursor-pointer items-center rounded-full border transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75",
          {
            "border-gray-800 bg-gray-800": value,
            "border-gray-300 bg-white pl-[1px]": !value,
          },
        )}
      >
        <span className="sr-only">{t(label)}</span>
        <span
          aria-hidden="true"
          className={clsx(
            "pointer-events-none inline-block h-3 w-3 transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out",
            {
              "translate-x-3 bg-white": value,
              "translate-x-0 bg-gray-300": !value,
            },
          )}
        />
      </Switch>
    </div>
  );
};
