import { ChangeEvent, FC } from "react";

import { IconButtonBase } from "@amenda-components/App";
import { SearchIcon } from "lucide-react";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import { inputFieldTheme } from "@amenda-styles/theme";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface SearchFieldProps {
  value: string;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
}

export const MiniSearchField: FC<SearchFieldProps> = ({
  value,
  placeholder,
  className = "border-b border-gray-300 bg-white",
  onChange,
  onClear,
}) => {
  const { t } = useTranslation();
  const { inputCss } = inputFieldTheme();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={clsx("relative flex", className)}>
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
      </div>
      <input
        autoFocus
        className={inputCss({
          className: "border-none bg-[inherit] py-2 pl-9 pr-4 focus:ring-0",
        })}
        type="text"
        value={value ?? ""}
        autoComplete="off"
        tabIndex={0}
        placeholder={placeholder && t(placeholder)}
        onChange={handleChange}
      />
      {onClear && !isEmpty(value) && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <IconButtonBase label="Close Search" onClick={onClear}>
            <XIcon className="h-3 w-3" />
          </IconButtonBase>
        </div>
      )}
    </div>
  );
};
