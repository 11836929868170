import { FC, useEffect, useState } from "react";
import { useProjectStore, useTemplatesStore } from "@amenda-domains/mutations";

import { TemplateBuilder } from "@amenda-components/Templates";
import { getComponentsById } from "@amenda-utils";
import { processTemplateData } from "@amenda-domains/queries";

interface Props {
  selectedTemplate: any;
}

export const ProjectTemplateBuilder: FC<Props> = ({ selectedTemplate }) => {
  const [template, setTemplate] = useState<any>([]);
  const attachments = useProjectStore(
    (state) => state.projectDesignerAttachments,
  );
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const setFormTemplateProperties = useTemplatesStore(
    (state) => state.setFormTemplateProperties,
  );
  const projectForms = useProjectStore((state) => state.projectForms);

  const handleAutoSave = (values: any) => {
    setFormTemplateProperties(values, true);
  };

  const componentsById = getComponentsById(selectedTemplate?.structure);

  useEffect(() => {
    const processTemplateDataAsync = async () => {
      const processedTemplate = await processTemplateData({
        projectForms,
        selectedProject,
        processMagicLinks: true,
        templateValues: selectedTemplate?.values,
        templateStructure: selectedTemplate?.structure,
      });
      setTemplate(processedTemplate);
    };

    processTemplateDataAsync();
  }, [
    projectForms,
    selectedProject,
    selectedTemplate?.values,
    selectedTemplate?.structure,
  ]);

  return (
    <TemplateBuilder
      template={template}
      format={selectedTemplate.format}
      globalProps={{
        attachments,
        componentsById,
        templateId: selectedTemplate.id,
        handleAutoSave,
      }}
    />
  );
};
