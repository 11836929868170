import { SimilaritySearchDefaultValues, getMatchTypeOptions } from "./common";
import {
  SingleCheckbox,
  SingleSelect,
} from "@amenda-components/FormComponents";

import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { FormComponentTypes } from "@amenda-constants";
import { SimilarityRangeSlider } from "@amenda-components/App";
import { SimilaritySearchConfigColumns } from "@amenda-types";
import { getSimilaritySearchConfigName } from "@amenda-components/Settings/common";
import { isNil } from "lodash";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const MatchSelect: FC<ComponentsTreeViewRowProps> = ({
  control,
  component,
  componentsById,
}) => {
  const { t } = useTranslation();
  const name = getSimilaritySearchConfigName(
    component,
    SimilaritySearchConfigColumns.Match,
  );
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: SimilaritySearchDefaultValues.matchType,
  });

  const parentComponent = componentsById[component.id];
  const hasChildren = Boolean(parentComponent?.components);

  if (hasChildren || isNil(component.component)) {
    return null;
  }
  return (
    <div className="mb-1 flex w-full justify-end">
      <SingleSelect
        id={name}
        className="w-full lg:w-2/3 xl:w-1/2"
        isClearable={false}
        hasMenuOverflow={true}
        hideErrorMessage={true}
        value={value}
        options={getMatchTypeOptions(
          [
            FormComponentTypes.MultiSelect,
            FormComponentTypes.Keyword,
            FormComponentTypes.Badges,
            FormComponentTypes.Checkbox,
          ].includes(component.component),
        )}
        onChange={onChange}
        getOptionLabel={(option) => t(option.label)}
      />
    </div>
  );
};

const WeightsSlider: FC<ComponentsTreeViewRowProps> = ({
  control,
  component,
  componentsById,
}) => {
  const name = getSimilaritySearchConfigName(
    component,
    SimilaritySearchConfigColumns.Weight,
  );
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: SimilaritySearchDefaultValues.weight,
  });

  const parentComponent = componentsById[component.id];
  const hasChildren = Boolean(parentComponent?.components);

  if (hasChildren || isNil(component.component)) {
    return null;
  }
  return (
    <div className="mb-1 flex w-full justify-end">
      <SimilarityRangeSlider
        mini={true}
        range={value}
        className="w-full lg:w-2/3 xl:w-1/2"
        onChange={onChange}
      />
    </div>
  );
};

const IsDefautCheckbox: FC<ComponentsTreeViewRowProps> = ({
  control,
  component,
  componentsById,
}: ComponentsTreeViewRowProps) => {
  const name = getSimilaritySearchConfigName(
    component,
    SimilaritySearchConfigColumns.IsDefault,
  );
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: SimilaritySearchDefaultValues.isDefault,
  });

  const parentComponent = componentsById[component.id];
  const hasChildren = Boolean(parentComponent?.components);

  if (hasChildren || isNil(component.component)) {
    return null;
  }
  return (
    <div className="mb-1 flex w-full justify-end">
      <SingleCheckbox id={name} checked={value} onChange={onChange} />
    </div>
  );
};

export const SimilaritySearchConfigRow = ({
  column,
  ...rest
}: ComponentsTreeViewRowProps) => {
  let key = getSimilaritySearchConfigName(
    rest.component,
    SimilaritySearchConfigColumns.Match,
  );

  if (column.id === SimilaritySearchConfigColumns.Match) {
    return <MatchSelect key={key} column={column} {...rest} />;
  } else if (column.id === SimilaritySearchConfigColumns.Weight) {
    key = getSimilaritySearchConfigName(
      rest.component,
      SimilaritySearchConfigColumns.Weight,
    );

    return <WeightsSlider key={key} column={column} {...rest} />;
  }
  key = getSimilaritySearchConfigName(
    rest.component,
    SimilaritySearchConfigColumns.IsDefault,
  );

  return <IsDefautCheckbox key={key} column={column} {...rest} />;
};
