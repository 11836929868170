import { Control, useController } from "react-hook-form";
import { GeneralAccessTypes, PermissionComponentKey } from "@amenda-constants";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getGeneralPermissionsName,
} from "../common";

import { FC } from "react";
import { MiniSwitch } from "@amenda-components/FormComponents";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any, object>;
  component: {
    id: string;
    label: string;
    defaultValue?: any;
  };
  selectedTab: GeneralPermissionKeys;
  show: boolean;
}

interface GeneralAccessProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
}

const GeneralAccess: FC<GeneralAccessProps> = ({ label, value, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (value: boolean) => {
    const access = value
      ? GeneralAccessTypes.Everyone
      : GeneralAccessTypes.Limited;
    onChange(access);
  };

  return (
    <div className="pl-5 text-sm text-gray-900">
      <span className="bold">{t(label)}</span>
      <div className="flex flex-row items-center space-x-2 pb-1 pt-1">
        <span>{t("Limited")}</span>
        <MiniSwitch
          label={t(label)}
          value={value === GeneralAccessTypes.Everyone}
          onChange={handleChange}
        />
        <span>{t("Everyone")}</span>
      </div>
    </div>
  );
};

export const PermissionComponentsWrapper: FC<Props> = ({
  control,
  component: { id, label, defaultValue },
  selectedTab,
  show,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    defaultValue,
    name: getGeneralPermissionsName(
      selectedTab,
      `${PermissionComponentKey}.${id}`,
    ),
  });

  if (id === PermissionComponents.GeneralAccess) {
    return (
      <Transition
        as="div"
        show={show ?? false}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-150"
        leaveTo="opacity-0"
      >
        <GeneralAccess value={value} label={label} onChange={onChange} />
      </Transition>
    );
  }
  return null;
};
