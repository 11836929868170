import { FC, useRef } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { LoaderWrapper, Spinner } from "@amenda-components/App";
import {
  useAppStore,
  useAttachmentStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { GalleryCard } from "@amenda-components/Gallery";
import { GeneralPermissionTypes } from "@amenda-types";
import { MediaGalleryProps } from "./common";
import { PaddingRow } from "@amenda-components/PageBuilder/PaddingRow";
import clsx from "clsx";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";

type Props = Pick<
  MediaGalleryProps,
  "canUpload" | "attachments" | "fetchPaginatedAttachments"
>;

const GridView: FC<Props> = ({ attachments, fetchPaginatedAttachments }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const virtualizer = useVirtualizer({
    lanes: 6,
    overscan: 6,
    count: attachments.length,
    getScrollElement: () => wrapperRef.current,
    estimateSize: () => 1,
  });

  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);
  const { ref } = useInView({
    threshold: 0.5,
    onChange(inView, entry) {
      if (inView && !isFetching && fetchPaginatedAttachments) {
        fetchPaginatedAttachments();
      }
    },
  });
  const setIsScrolling = useAppStore((state) => state.setIsScrolling);
  let scrollTimeout: any = null;

  const handleScroll = () => {
    setIsScrolling(true);
    // Clear the timeout if scrolling continues
    clearTimeout(scrollTimeout);
    // Set a timeout to reset the isScrolling state after scrolling stops
    scrollTimeout = setTimeout(() => setIsScrolling(false), 200);
  };

  return (
    <div
      ref={containerRef}
      className="relative h-[calc(100vh-96px)] w-full overflow-y-auto overscroll-y-contain"
      onScroll={handleScroll}
    >
      {isSearching && <LoaderWrapper />}
      {isFetching && isEmpty(attachments) && <LoaderWrapper className="h-44" />}
      {containerRef?.current && !isSearching && attachments.length > 0 && (
        <div
          ref={wrapperRef}
          className={clsx(
            "grid-cols relative grid w-full gap-2 transition-all duration-500 md:gap-2 lg:gap-2 xxl:gap-8",
            {
              "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6":
                !sidebarOpen,
              "grid-cols-2 md:grid-cols-2 lg:grid-cols-4": sidebarOpen,
            },
          )}
          style={{
            height: containerRef.current.clientHeight,
          }}
        >
          <div className="col-span-full">
            <PaddingRow padding={paddingTop} />
          </div>
          {virtualizer.getVirtualItems().map((virtualRow, i) => {
            const attachment = attachments[virtualRow.index];
            return <GalleryCard key={attachment.id} attachment={attachment} />;
          })}
          <div className="col-span-full">
            {isFetching && (
              <div className="flex w-full items-center justify-center space-x-2">
                <Spinner spinnerSize="xs" variant="default" />
                <span className="font-apercu text-sm">
                  {t("Loading more photos...")}
                </span>
              </div>
            )}
            <div ref={ref} className="h-8 w-full" />
            <PaddingRow padding={paddingBottom} />
          </div>
        </div>
      )}
    </div>
  );
};

export const GalleryGridView: FC<Props> = (props) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const showMediaGeneralPermissions =
    mediaGeneralPermissions[GeneralPermissionTypes.Create];

  if (showMediaGeneralPermissions && Boolean(props?.canUpload)) {
    return <GridView {...props} />;
  }
  return <GridView {...props} />;
};
