import { FC, ReactNode } from "react";

import { DatePickerBase } from "@amenda-components/FormComponents";
import { ErrorMessage } from "./ErrorMessage";
import { Sizes } from "@amenda-styles/theme";
import clsx from "clsx";
import { resolveDate } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  error?: string;
  label?: string;
  placeholder?: string;
  fromLabel?: string;
  toLabel?: string;
  value: any;
  size?: keyof Sizes;
  disabled?: boolean;
  withPortal?: boolean;
  optional?: ReactNode;
  onBlur?: () => void;
  onChange: (value: any) => void;
}

export const DatePickerRange: FC<Props> = ({
  id,
  error,
  label,
  value,
  fromLabel = "von",
  toLabel = "bis",
  optional,
  onBlur,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className="group/datePickerRange mb-2">
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <div className="flex flex-wrap -space-x-px rounded-md bg-white shadow-sm">
        <div
          className={clsx(
            "relative h-11 w-1/2 border border-gray-300 p-2 pr-3 focus-within:border-gray-900 focus-within:ring-0 focus-within:ring-gray-900",
            { "bg-gray-100": rest.disabled },
          )}
        >
          <DatePickerBase
            id={`fromDate-${id}`}
            placeholder={fromLabel}
            className="amenda-component block w-full border-0 p-0 placeholder-gray-500 sm:text-sm"
            value={value?.from}
            startDate={value?.from}
            endDate={value?.to}
            label={fromLabel}
            selectsStart
            maxDate={resolveDate(value?.to)}
            onChange={(date) =>
              onChange({
                ...value,
                from: date,
              })
            }
            onBlur={onBlur}
            {...rest}
          />
        </div>
        <div
          className={clsx(
            "relative h-11 w-1/2 border border-gray-300 p-2 pr-3 focus-within:border-gray-900 focus-within:ring-0 focus-within:ring-gray-900",
            { "bg-gray-100": rest.disabled },
          )}
        >
          <DatePickerBase
            id={`toDate-${id}`}
            placeholder={toLabel}
            className="amenda-component block w-full border-0 p-0 placeholder-gray-500 sm:text-sm"
            value={value?.to}
            startDate={value?.from}
            endDate={value?.to}
            label={toLabel}
            selectsEnd
            minDate={resolveDate(value?.from)}
            onChange={(date) =>
              onChange({
                ...value,
                to: date,
              })
            }
            onBlur={onBlur}
            {...rest}
          />
        </div>
      </div>
      <ErrorMessage id={id} error={error} />
    </div>
  );
};
