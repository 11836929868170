import { FC, ReactNode } from "react";

import clsx from "clsx";

interface AvatarGroupWrapperProps {
  className?: string;
  children: ReactNode;
}

export const AvatarGroupWrapper: FC<AvatarGroupWrapperProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={clsx("float-right flex -space-x-3 overflow-hidden", className)}
    >
      {children}
    </div>
  );
};
