import { FC } from "react";
import clsx from "clsx";
import { handleWheelChange } from "./common";
import { useTranslation } from "react-i18next";

interface InputFieldProps {
  addonText?: string;
  id: string;
  inputType: "from" | "to";
  onChange?: (value: string) => void;
  placeholder?: string;
  StartIcon?: FC<{ className: string }>;
  type: string;
  value: string;
  disabled: boolean;
}

const InputField: FC<InputFieldProps> = ({
  id,
  type,
  value,
  disabled,
  inputType,
  addonText,
  placeholder,
  StartIcon,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx("min-w-0 flex-1", {
        "w-1/2 border-r": inputType === "from",
      })}
    >
      <label htmlFor={id} className="sr-only">
        {placeholder}
      </label>
      <div className="relative flex flex-grow items-stretch">
        {StartIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 shadow-sm">
            <StartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <input
          type={type}
          name={id}
          id={id}
          className={clsx("amenda-component", {
            "pl-10": StartIcon,
          })}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          onWheel={handleWheelChange}
          disabled={disabled}
        />
        {addonText && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="sm:text-sm" id={`${id}-end-addon`}>
              {t(addonText)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

interface InputRangePickerProps {
  control?: any;
  fromAddonText?: string;
  fromPlaceholder?: string;
  id: string;
  label?: string;
  onChange: (value: any) => void;
  StartIcon?: FC<{ className: string }>;
  type?: string;
  toAddonText?: string;
  toPlaceholder?: string;
  value: any;
  disabled?: boolean;
}

export const InputRangePicker: FC<InputRangePickerProps> = ({
  id,
  label,
  value,
  StartIcon,
  toAddonText,
  fromAddonText,
  onChange,
  type = "text",
  toPlaceholder = "to",
  fromPlaceholder = "from",
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-2">
      {label && <label className="amenda-component-label">{t(label)}</label>}
      <div className="amenda-no-wheel-for-input-number flex">
        <div className="flex -space-x-1">
          <InputField
            id={`from_${id}`}
            inputType="from"
            type={type}
            onChange={(fromValue) =>
              onChange({
                ...value,
                from: fromValue,
              })
            }
            value={value?.from || ""}
            StartIcon={StartIcon}
            addonText={fromAddonText}
            placeholder={fromPlaceholder}
            disabled={disabled}
          />
          <InputField
            id={`to_${id}`}
            inputType="to"
            type={type}
            onChange={(toValue) =>
              onChange({
                ...value,
                to: toValue,
              })
            }
            value={value?.to || ""}
            StartIcon={StartIcon}
            addonText={toAddonText}
            placeholder={toPlaceholder}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
