import { AddComponentButton, SortItem } from "./DragAndDropWrapper";
import { GripIcon, PencilIcon } from "lucide-react";

import { FC } from "react";
import { GridWrapper } from "./GridWrapper";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import clsx from "clsx";
import { isChildrenEmpty } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const TwoColumnsFullWidth: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
}) => {
  const { t } = useTranslation();
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const { label, description } = config?.properties ?? {};

  const handleEdit = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
    });
  };

  return (
    <SortItem
      className="group/two-col-full-width relative border-b border-gray-200 py-5 md:grid md:grid-cols-3 md:gap-6"
      config={config}
      isFormBuilder={isFormBuilder}
      showBorder={isChildrenEmpty(children)}
    >
      <div className="md:col-span-1">
        {isFormBuilder && (
          <div
            className={clsx("flex items-center space-x-2 pb-2", {
              "invisible group-hover/two-col-full-width:visible":
                !isChildrenEmpty(children),
            })}
          >
            <IconButtonBase
              size="sm"
              className="cursor-grab px-0.5 py-1"
              variant="clean"
            >
              <GripIcon className="h-5 w-5 stroke-[2]" />
            </IconButtonBase>
            <IconButtonBase
              size="sm"
              className="p-1"
              variant="clean"
              onClick={handleEdit}
            >
              <PencilIcon className="h-5 w-5" />
            </IconButtonBase>
            <AddComponentButton
              className="absolute right-0 top-0 -mt-5 flex w-full justify-end"
              isFormBuilder={isFormBuilder}
              config={config}
            />
          </div>
        )}
        {label && (
          <h3
            className={clsx({
              "amenda-form-heading": !isFormBuilder,
              "text-lg": isFormBuilder,
            })}
          >
            {t(label)}
          </h3>
        )}
        {description && (
          <p
            className={clsx("font-apercu mt-1 text-gray-600", {
              "text-sm": !isFormBuilder,
              "text-lg": isFormBuilder,
            })}
          >
            {t(description)}
          </p>
        )}
      </div>
      <div className="mt-5 md:col-span-2 md:mt-0">
        <GridWrapper isFormBuilder={isFormBuilder} config={config}>
          {children}
        </GridWrapper>
      </div>
    </SortItem>
  );
};
