import { Button, Counter, HeadlessTabs, Modal } from "@amenda-components/App";
import { Control, UseFormSetValue, useForm } from "react-hook-form";
import { FC, ReactNode, useEffect, useState } from "react";
import {
  FormBuilderComponentTabs,
  generateLayoutComponents,
  getConditionComponents,
  getFormBuilderComponentTabs,
  transformFormBuilderClonedComponents,
  transformFormBuilderComponent,
  transformFormBuilderComponents,
} from "../common";
import { FormComponentTypes, getFormBuilderSchema } from "@amenda-constants";
import { PageComponentProps, availableLayouts } from "@amenda-types";
import {
  useAppStore,
  useDeleteFormComponents,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { FormBuilderComponentForm } from "./FormBuilderComponentForm";
import { FormBuilderComponentHeaderConfig } from "./FormBuilderComponentHeaderConfig";
import { FormBuilderComponentOptions } from "./FormBuilderComponentOptions";
import { FormBuilderComponentValidationForm } from "./FormBuilderComponentValidationForm";
import { FormBuilderDisplayConditions } from "./FormBuilderDisplayConditions";
import { FormBuilderLayoutComponentSettings } from "./FormBuilderLayoutComponentSettings";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { devConsole } from "@amenda-utils";
import { sanitizeData } from "@amenda-utils";
import { useFormBuilderModifySystemRole } from "../hooks";
import { useGetKeywords } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface ModalWrapperProps {
  isOpen?: boolean;
  config?: PageComponentProps;
  handleClose: () => void;
  children: (
    control: Control<any>,
    setValue: UseFormSetValue<any>,
  ) => ReactNode;
  cleanUpAfterClosing: () => void;
}

const formId = "formBuilderComponentForm";

const ModalWrapper: FC<ModalWrapperProps> = ({
  isOpen = false,
  config,
  handleClose,
  children,
  cleanUpAfterClosing,
}) => {
  const { t } = useTranslation();
  const [preventClosing, setPreventClosing] = useState(false);
  const [count, setCount] = useState(0);
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const { updateForm, loading } = useUpdateForm();
  const originalForms = useProjectStore((state) => state.originalForms);

  const { control, handleSubmit, reset, setValue } = useForm<any>({
    resolver: yupResolver(getFormBuilderSchema(config as PageComponentProps)),
    values: transformFormBuilderComponents(config as PageComponentProps),
  });
  const {
    loading: isUpdatingPermissions,
    addComponentToPermissions,
    addComponentsToPermissions,
  } = useFormBuilderModifySystemRole();
  const { deleteFormComponents, loading: isDeletingComponents } =
    useDeleteFormComponents();

  const { selectedForm, selectedFormComponent } = formBuilderState ?? {};
  const parentComponent = (selectedForm?.components ?? []).find(
    (c) => c.id === config?.parentId,
  );

  const handleCloseModal = () => {
    setCount(0);
    setPreventClosing(false);
    handleClose();
  };

  const createComponent = async (form: any, component: any) => {
    await updateForm({
      id: form.id,
      components: [component],
    });
    await addComponentToPermissions(form, component);

    if (!preventClosing) {
      reset({});
      setCount(0);
      handleClose();
      cleanUpAfterClosing();
    } else {
      reset({});
      setCount((prev) => prev + 1);
    }
  };

  const updateComponent = async (form: any, component: any) => {
    await updateForm({
      id: form.id,
      components: [component],
    });
    reset({});
    handleClose();
    cleanUpAfterClosing();
  };

  const handleDeleteComponent = async (form: any) => {
    const { config } = selectedFormComponent ?? {};
    const componentIds = [
      config?.id,
      ...(config?.components ?? []).map((c: any) => c?.id),
    ].filter(Boolean);

    await deleteFormComponents({
      componentIds,
      formId: form.id,
    });
    reset({});
    handleClose();
    cleanUpAfterClosing();
  };

  const handleCloneComponent = async (form: any) => {
    const { config } = selectedFormComponent ?? {};
    const components = transformFormBuilderClonedComponents(
      config,
      config?.components ?? [],
    );

    await updateForm({
      id: form.id,
      components: components,
    });
    await addComponentsToPermissions(form, components);
    reset({});
    handleClose();
    cleanUpAfterClosing();
  };

  const handleCreateLayoutAndComponents = async ({
    form,
    component,
    layoutComponents,
  }: {
    form: any;
    component: any;
    layoutComponents: any[];
  }) => {
    let components = generateLayoutComponents(
      layoutComponents,
      component?.componentId,
    );
    components = [component, ...components];

    await updateForm({
      id: form.id,
      components: components,
    });
    await addComponentsToPermissions(form, components);
    reset({});
    handleClose();
    cleanUpAfterClosing();
  };

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:project form error gat", errors);
  };

  const onSubmit = async ({
    cloneLayout,
    deleteLayout,
    layoutComponents,
    ...data
  }: any) => {
    const form =
      selectedForm?.id &&
      originalForms.find((form) => form.id === selectedForm.id);
    const sanitizedData = sanitizeData(data);
    const component =
      form && form.components.find((c) => c.componentId === config?.id);
    const formComponent = transformFormBuilderComponent({
      componentConfig: config,
      values: sanitizedData,
      existingComponent: component,
      isNewComponent: !Boolean(component),
    });

    if (layoutComponents?.length > 0) {
      await handleCreateLayoutAndComponents({
        form,
        layoutComponents,
        component: formComponent,
      });
    } else if (cloneLayout) {
      await handleCloneComponent(form);
    } else if (deleteLayout) {
      await handleDeleteComponent(form);
    } else if (component) {
      await updateComponent(form, formComponent);
    } else if (form) {
      await createComponent(form, formComponent);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      loading={loading || isUpdatingPermissions}
      isElevated={true}
      closeModalFromTitle={true}
      withCancel={false}
      size="lg"
      className="lg:w-8/12"
      title={config?.id ? "Edit component" : "Add component"}
      message={
        config?.id && `${t("Field type: ")} ${config?.component ?? "Layout"}`
      }
      onClose={handleCloseModal}
      footerClassName="w-full border-t"
      footerChildren={({ loading, onClose }) => {
        return (
          <div className="flex w-full items-center justify-end space-x-1 bg-white pb-2 pt-1">
            {!Boolean(config?.id) &&
              parentComponent?.layout !== availableLayouts.default && (
                <div className="mr-auto flex items-center space-x-1">
                  <SingleCheckbox
                    id="createAnother"
                    label="Create another component"
                    checked={preventClosing}
                    onChange={(value) => setPreventClosing(Boolean(value))}
                  />
                  <Counter
                    variant="success"
                    count={count}
                    message="Components added"
                  />
                </div>
              )}
            <Button type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              loading={loading || isDeletingComponents}
              type="submit"
              variant="primary"
              form={formId}
            >
              {t("Save")}
            </Button>
          </div>
        );
      }}
    >
      <form
        id={formId}
        className="b-8 max-h-[calc(100vh-10rem)] min-h-[50vh] overflow-y-auto px-2"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {children(control, setValue)}
      </form>
    </Modal>
  );
};

export const FormBuilderComponentModal: FC = () => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  const {
    selectedForm,
    selectedFormComponent,
    openEditComponentModal,
    openCreateComponentModal,
  } = formBuilderState ?? {};
  const { config } = selectedFormComponent ?? {};
  const parentComponent = (selectedForm?.components ?? []).find(
    (c) => c.id === config?.parentId,
  );

  const handleClose = () => {
    updateFormBuilderState("openEditComponentModal", false);
    updateFormBuilderState("openCreateComponentModal", false);
  };

  const cleanUpAfterClosing = () => {
    updateFormBuilderState("selectedFormComponent", undefined);
  };

  useEffect(() => {
    if (
      config?.id &&
      [
        FormComponentTypes.Keyword,
        FormComponentTypes.LabelledContactInputs,
      ].includes(config?.component)
    ) {
      getKeywords({
        componentIds: [config.id],
        setKeywords,
      });
    }
  }, [config?.id, config?.component, getKeywords]);

  return (
    <ModalWrapper
      isOpen={Boolean(openEditComponentModal || openCreateComponentModal)}
      config={config as PageComponentProps}
      handleClose={handleClose}
      cleanUpAfterClosing={cleanUpAfterClosing}
    >
      {(control, setValue) => {
        return (
          <HeadlessTabs
            tabListClassName="!pt-0"
            tabs={getFormBuilderComponentTabs({
              component: config,
              parentComponent,
              isCreatingComponent: openCreateComponentModal,
            })}
          >
            {(tab) => {
              switch (tab.value) {
                case FormBuilderComponentTabs.Details:
                  return <FormBuilderComponentForm control={control} />;
                case FormBuilderComponentTabs.Settings:
                case FormBuilderComponentTabs.Components:
                  return (
                    <FormBuilderLayoutComponentSettings
                      isCreating={openCreateComponentModal}
                      control={control}
                      component={config}
                    />
                  );
                case FormBuilderComponentTabs.Validation:
                  return (
                    <FormBuilderComponentValidationForm
                      control={control}
                      component={config}
                    />
                  );
                case FormBuilderComponentTabs.Header:
                  return (
                    <FormBuilderComponentHeaderConfig
                      control={control}
                      component={config}
                    />
                  );
                case FormBuilderComponentTabs.Options:
                  return (
                    <FormBuilderComponentOptions
                      id="options"
                      control={control}
                      component={config}
                      isLoading={loading}
                      keywords={keywords}
                      updateKeywordsState={(keyword, action) =>
                        setKeywords((prev) => {
                          if (action === "delete") {
                            return prev.filter((k) => k.id !== keyword.id);
                          } else if (action === "update") {
                            return prev.map((k) =>
                              k.id === keyword.id ? keyword : k,
                            );
                          }
                          return [...prev, keyword];
                        })
                      }
                    />
                  );
                case FormBuilderComponentTabs.Display:
                  return (
                    <FormBuilderDisplayConditions
                      control={control}
                      components={getConditionComponents(
                        selectedForm?.components,
                        config,
                      )}
                      setValue={setValue}
                    />
                  );
                default:
                  return null;
              }
            }}
          </HeadlessTabs>
        );
      }}
    </ModalWrapper>
  );
};
