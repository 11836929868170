import { AvailableForms, PermissionComponentKey } from "@amenda-constants";
import { Button, Modal, NotificationCardBase } from "@amenda-components/App";
import {
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperWithConditions,
} from "@amenda-components/PageBuilder";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import {
  getComponentsDefaultValues,
  getComponentsFromForms,
  getFullName,
  getValidationSchema,
} from "@amenda-utils";
import { hasNoUserSearchAndGrouping, isUserSearchFiltersEmpty } from "./common";
import toast, { Toast } from "react-hot-toast";
import {
  useCreateContact,
  useProjectStore,
  useSettingsStore,
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";
import { useNavigate, useParams } from "react-router-dom";

import { AllowedContactType } from "@amenda-types";
import { ContactDirectoryProps } from "./types";
import { CreateResourceShareForm } from "@amenda-components/Shared/ShareModal";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const formId = "create-contact-form";

interface Props
  extends Pick<ContactDirectoryProps, "rootRoute" | "collectionRoute"> {
  isCollection: boolean;
}

export const CreateContactModal: FC<Props> = ({
  isCollection,
  rootRoute,
  collectionRoute,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const modals = useUsersStore((state) => state.modals);
  const setCreateContactModal = useUsersStore(
    (state) => state.setCreateContactModal,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const forms = useProjectStore((state) => state.forms ?? {});
  const { createContact, loading } = useCreateContact();
  const { searchFilters, userComponentFilters } =
    useUserSearchFiltersWithPath();

  const createContactForm = forms[AvailableForms.ContactsCreation];
  const components = getComponentsFromForms(createContactForm);
  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );
  const defaultShareType =
    contactsGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];
  const validationSchema = getValidationSchema(components);
  const defaultValues = getComponentsDefaultValues(components);
  const isSearchEmpty =
    hasNoUserSearchAndGrouping(searchFilters) &&
    isUserSearchFiltersEmpty(userComponentFilters);

  const { control, setValue, trigger, getValues, reset } = useForm<any>({
    values: {
      shareType: defaultShareType,
    },
  });

  const goToContact = (contactId?: string) => {
    let path = isCollection ? `${collectionRoute}/${collectionId}` : rootRoute;
    path = contactId ? `${path}/${contactId}` : path;

    navigate(path);
  };

  const onNotificationClick = (toaster: Toast, contactId?: string) => {
    goToContact(contactId);
    toast.dismiss(toaster.id);
  };

  const handleClose = () => {
    setCreateContactModal(false);
  };

  const onSubmit = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const isValid = await trigger();

    const { shareType, contacts } = getValues();
    const { contactType, companyName, firstName, lastName, ...rest } =
      sanitizedData;

    const share = {
      type: shareType,
      users: contacts.map((c: any) => ({
        id: c.id,
        role: c[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };
    let input: any = {
      share: isValid ? share : { type: defaultShareType },
    };

    if (contactType === AllowedContactType.person) {
      input = {
        firstName,
        lastName,
        type: contactType,
        fullName: getFullName(firstName, lastName),
        contactDetails: rest,
        ...input,
      };

      const contact = await createContact({
        input,
      });
      if (isSearchEmpty) {
        goToContact(contact.id);
      } else {
        toast.custom((toaster) => (
          <NotificationCardBase
            variant="success"
            toaster={toaster}
            title="Contact created successfully"
            message={`${t("Click to view")} ${contact.fullName}`}
            onClick={() => onNotificationClick(toaster, contact.id)}
          />
        ));
      }
    } else if (contactType === AllowedContactType.company) {
      input = {
        type: contactType,
        fullName: companyName,
        contactDetails: {
          companyName,
          ...rest,
        },
        ...input,
      };

      const contact = await createContact({
        input,
      });
      if (isSearchEmpty) {
        goToContact(contact.id);
      } else {
        toast.custom((toaster) => (
          <NotificationCardBase
            variant="success"
            toaster={toaster}
            title="Contact created successfully"
            message={`${t("Click to view")} ${contact.fullName}`}
            onClick={() => onNotificationClick(toaster, contact.id)}
          />
        ));
      }
    } else if (contactType === "both") {
      const companyInput = {
        type: AllowedContactType.company,
        fullName: companyName,
        contactDetails: {
          companyName,
          ...rest,
        },
        ...input,
      };

      const company = await createContact({
        input: companyInput,
      });
      const personsInput = {
        firstName,
        lastName,
        type: AllowedContactType.person,
        fullName: getFullName(firstName, lastName),
        contactDetails: {
          ...rest,
          company: company?.id,
        },
        ...input,
      };
      const persons = await createContact({
        input: personsInput,
      });
      if (isSearchEmpty) {
        goToContact(persons.id);
        toast.custom((toaster) => (
          <NotificationCardBase
            variant="success"
            toaster={toaster}
            title="Contact created successfully"
            message={`${t("Click to view")} ${company.fullName}`}
            onClick={() => onNotificationClick(toaster, company.id)}
          />
        ));
      } else {
        toast.custom((toaster) => (
          <NotificationCardBase
            variant="success"
            toaster={toaster}
            title="Contact created successfully"
            message={`${t("Click to view")} ${persons.fullName}`}
            onClick={() => onNotificationClick(toaster, persons.id)}
          />
        ));
        toast.custom((toaster) => (
          <NotificationCardBase
            variant="success"
            toaster={toaster}
            title="Contact created successfully"
            message={`${t("Click to view")} ${company.fullName}`}
            onClick={() => onNotificationClick(toaster, company.id)}
          />
        ));
      }
    }

    reset({});
    handleClose();
  };

  return (
    <Modal
      isOpen={modals.openCreateContactModal}
      onClose={handleClose}
      size="md"
      className="w-11/12 lg:w-8/12 xl:w-5/12"
      contentClassName="flex flex-col max-h-[calc(100vh-10rem)] min-h-[500px] overflow-y-auto"
      title="Contact Info"
      closeModalFromTitle={true}
      loading={loading}
      footerChildren={({ loading }) => (
        <div className="w-full">
          <Button
            className="flex w-full justify-center"
            withGroup
            variant="primary"
            form={formId}
            loading={loading}
            type="submit"
          >
            {t("Save")}
          </Button>
        </div>
      )}
    >
      <div className="flex-grow">
        <FormAutoSaveWrapperWithConditions
          formId={formId}
          className="py-0"
          ignoreResourceIdAlways={true}
          defaultValues={defaultValues}
          inputSchema={validationSchema}
          components={components}
          values={{}}
          disableAutoSave={true}
          showFormNavigationAlert={false}
          onSubmit={onSubmit}
        />
      </div>
      <div className="w-full">
        <CreateResourceShareForm control={control} setValue={setValue} />
      </div>
    </Modal>
  );
};
