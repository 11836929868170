import { Control, UseFormSetValue } from "react-hook-form";
import { FC, memo, useEffect, useState } from "react";

import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { CostGroupHeaderWrapper } from "./CostGroupHeaderWrapper";
import { CostParameterBodyCostComponents } from "./CostGroupRowBody";
import { NestedPageComponentProps } from "@amenda-types";
import { costGroupCostColumnHeaders } from "./common";

interface Props {
  formComponentTree: NestedPageComponentProps;
  control: Control<any, object>;
  isDisabled: boolean;
  setValue: UseFormSetValue<any>;
  onBlur?: () => void;
}

export const CostGroupCostForm: FC<Props> = memo(
  ({ formComponentTree, control, isDisabled, onBlur, setValue }) => {
    const [key, setKey] = useState(1);

    useEffect(() => {
      setKey((prev) => prev + 1);
    }, []);

    return (
      <CostGroupHeaderWrapper isDisabled={isDisabled}>
        {(isReadOnly) => (
          <ComponentsTreeView
            key={key}
            readOnly={isReadOnly}
            disabled={isDisabled}
            columnClassName="pt-1"
            components={formComponentTree.components as any[]}
            control={control}
            columns={costGroupCostColumnHeaders}
            className="mt-2 max-h-[80vh]"
            rowClassName="group/treeRow hover:bg-gray-100 border-b border-transparent hover:border-gray-200 mb-2"
            searchPlaceholder="Kostengruppe filtern..."
            globalData={{
              onBlur,
              setValue,
            }}
            RowBody={CostParameterBodyCostComponents}
          />
        )}
      </CostGroupHeaderWrapper>
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.formComponentTree) !==
      JSON.stringify(nextProps.formComponentTree)
    ) {
      return false;
    }
    return true;
  },
);
