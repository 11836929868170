import { FC, useState } from "react";
import { Dropdowns } from "@amenda-components/App";
import { Option } from "@amenda-types";

interface FieldMapping {
  fieldName: string;
  mappedTo: Option | null;
}

const fields = ["firstname", "lastname", "email", "phone"];

const contactOptions: Option[] = [
  { label: "Assistant Name", value: "assistantName" },
  { label: "Birthday", value: "birthday" },
  { label: "Business Address", value: "businessAddress" },
  { label: "Business Homepage", value: "businessHomePage" },
  { label: "Business Phones", value: "businessPhones" },
  { label: "Categories", value: "categories" },
  { label: "Company Name", value: "companyName" },
  { label: "Department", value: "department" },
  { label: "Display Name", value: "displayName" },
  { label: "Email Addresses", value: "emailAddresses" },
  { label: "Given Name", value: "givenName" },
  { label: "Home Address", value: "homeAddress" },
  { label: "Home Phones", value: "homePhones" },
  { label: "IM Addresses", value: "imAddresses" },
  { label: "Job Title", value: "jobTitle" },
  { label: "Manager", value: "manager" },
  { label: "Middle Name", value: "middleName" },
  { label: "Mobile Phone", value: "mobilePhone" },
  { label: "Office Location", value: "officeLocation" },
  { label: "Profession", value: "profession" },
  { label: "Spouse Name", value: "spouseName" },
  { label: "Surname", value: "surname" },
  { label: "Title", value: "title" },
];

export const MicrosoftExchangeModalContent: FC = () => {
  const [mappings, setMappings] = useState<FieldMapping[]>(
    fields.map((field) => ({ fieldName: field, mappedTo: null })),
  );

  const handleMappingChange = (fieldName: string, selectedOption: Option) => {
    setMappings((prevMappings) =>
      prevMappings.map((mapping) =>
        mapping.fieldName === fieldName
          ? { ...mapping, mappedTo: selectedOption }
          : mapping,
      ),
    );
  };

  return (
    <div className="p-4">
      <h3 className="mb-2 text-lg font-bold">Microsoft Exchange Integration</h3>
      <p className="mb-4 text-gray-600">
        Map your internal fields to Microsoft Exchange fields to ensure data
        synchronization.
      </p>
      <div className="grid grid-cols-2 gap-4">
        {mappings.map((mapping) => (
          <div key={mapping.fieldName} className="flex items-center space-x-4">
            <span className="w-1/2 text-gray-700">{mapping.fieldName}</span>
            <Dropdowns
              options={contactOptions}
              defaultLabel="Select a field"
              selectedOption={mapping.mappedTo}
              onChangeSelectedOption={(option) =>
                handleMappingChange(mapping.fieldName, option)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
