import {
  DefaultLayout,
  FullColumn,
  FullColumnGrid,
  HalfColumn,
  NestedFormLayout,
  OneThirdColumn,
  TwoColumnsFullWidth,
  TwoThirdsColumn,
} from "@amenda-components/PageLayouts";

import { FC } from "react";
import { availableLayouts } from "@amenda-types";

export * from "./FormAutoSaveWrapper";
export * from "./FormAutoSaveWrapperBase";
export * from "./FormAutoSaveWithConditions";
export * from "./FormAutoSaveProvider";
export * from "./ComponentTreeRenderer";
export * from "./FormWrapper";
export * from "./ComponentsTreeView";
export * from "./ReadOnlyHeader";
export * from "./FormBuilderProviders";

export const getLayoutComponents = (
  customLayoutComponents: Record<string, FC<any>> = {},
) => {
  let layoutComponents = {
    [availableLayouts.default]: DefaultLayout,
    [availableLayouts.fullColumn]: FullColumn,
    [availableLayouts.halfColumn]: HalfColumn,
    [availableLayouts.oneThirdColumn]: OneThirdColumn,
    [availableLayouts.fullColumnGrid]: FullColumnGrid,
    [availableLayouts.twoThirdColumn]: TwoThirdsColumn,
    [availableLayouts.twoColumnsFullWidth]: TwoColumnsFullWidth,
    [availableLayouts.nestedForm]: NestedFormLayout,
  };
  return {
    ...layoutComponents,
    ...customLayoutComponents,
  };
};
