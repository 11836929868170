import { FC, ReactNode } from "react";

import clsx from "clsx";

interface ColumnProps {
  children: ReactNode;
  alignItems: "start" | "stretch" | "baseline" | "center" | "end";
  columnSpan?: number | string;
  columnClassName?: string;
  justifyContent?: "start" | "center" | "end" | "between" | "around" | "evenly";
  flexDirection?: "row" | "row-reverse" | "col" | "col-reverse";
}

export const Column: FC<ColumnProps> = ({
  alignItems = "center",
  columnSpan = "auto",
  columnClassName = "",
  children,
  justifyContent = "start",
  flexDirection = "row",
}) => {
  return (
    <div className={clsx(`col-span-${columnSpan}`, columnClassName)}>
      <div
        className={clsx(`flex w-full flex-${flexDirection} h-full`, {
          [`justify-${justifyContent} items-${alignItems} `]:
            flexDirection.includes("row"),
          [`flex-wrap content-${justifyContent}`]:
            flexDirection.includes("col"),
        })}
      >
        {children}
      </div>
    </div>
  );
};
