import {
  Control,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  useForm,
  useWatch,
} from "react-hook-form";
import React, {
  FC,
  Fragment,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  devConsole,
  sanitizeData,
  sanitizeNestedData,
  useDeepCompareEffect,
} from "@amenda-utils";

import { DebounceTimes } from "@amenda-constants";
import { DetectFormNavigation } from "@amenda-components/FormComponents";
import { ObjectSchema } from "yup";
import debounce from "lodash/debounce";
import { getDirtyFieldsValue } from "./common";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "@amenda-domains/mutations";
import { yupResolver } from "@hookform/resolvers/yup";

export interface FormAutoSaveResourceProps {
  resourceId?: string;
  resourceIds?: Record<string, string | string[]>;
}
interface ChildProps extends FormAutoSaveResourceProps {
  reset?: UseFormReset<any>;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  getValues?: UseFormGetValues<any>;
}

export interface FormAutoSaveBaseChildProps extends ChildProps {
  handleSubmitManually?: () => Promise<boolean>;
  handleFormSubmit?: (e: any) => Promise<void>;
  submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

export interface FormAutoSaveBaseProps extends FormAutoSaveResourceProps {
  formId?: string;
  children: (props: FormAutoSaveBaseChildProps) => ReactNode;
  disableAutoSave?: boolean;
  ignoreResourceIdAlways?: boolean;
  inputSchema: ObjectSchema<any>;
  onSubmit: (props: FormAutoSaveSubmitProps) => Promise<void>;
  values: Record<string, any>;
  defaultValues?: Record<string, any>;
  showFormNavigationAlert?: boolean;
}

export interface FormAutoSaveSubmitProps extends FormAutoSaveResourceProps {
  data: Record<string, any>;
  dirtyData: Record<string, any>;
  sanitizedData: Record<string, any>;
  sanitizedDirtyData: Record<string, any>;
}

interface Props extends Omit<FormAutoSaveBaseProps, "children"> {
  className?: string;
  children: (props: ChildProps) => ReactNode;
}

interface AutoSaverProps {
  control: Control<any>;
  dirtyFields: Record<string, any>;
  submitButtonRef: RefObject<HTMLButtonElement>;
}

const AutoSaver: FC<AutoSaverProps> = ({
  control,
  submitButtonRef,
  dirtyFields,
}) => {
  const watchedValues = useWatch({ control });

  const runSubmit = useRef(
    debounce(async () => {
      submitButtonRef?.current?.click();
    }, DebounceTimes.Autosave),
  ).current;

  useDeepCompareEffect(() => {
    const keys = Object.keys(dirtyFields);

    if (!isEmpty(watchedValues) && !isEmpty(keys)) {
      runSubmit();
    }
  }, [watchedValues, dirtyFields, runSubmit]);

  return null;
};

const AutoSaverWrapper: FC<
  AutoSaverProps & {
    disableAutoSave: boolean;
  }
> = ({ disableAutoSave, ...rest }) => {
  if (disableAutoSave) {
    return null;
  }
  return <AutoSaver {...rest} />;
};

export const FormAutoSaveBase: FC<FormAutoSaveBaseProps> = ({
  ignoreResourceIdAlways,
  inputSchema,
  onSubmit,
  resourceId,
  resourceIds,
  values,
  children,
  defaultValues,
  disableAutoSave = false,
  showFormNavigationAlert = true,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const {
    reset,
    trigger,
    control,
    setValue,
    getValues,
    formState: { dirtyFields, isSubmitted, errors },
    handleSubmit: handleSubmitCallback,
  } = useForm<any>({
    values,
    defaultValues,
    resolver: yupResolver(inputSchema),
    resetOptions: {
      keepValues: true,
      keepIsSubmitted: true,
      keepDirtyValues: true, // if true user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const hasResourceIds = () => {
    const labels = resourceIds && Object.keys(resourceIds);
    const hasAllResourceIds =
      labels &&
      labels.length > 0 &&
      labels.every((label) => !isEmpty(resourceIds[label]));

    return !isEmpty(resourceId) || hasAllResourceIds;
  };

  const onSubmitCallback = async (data: any) => {
    const isInitialSubmit = !isEmpty(data) && !hasSubmitted;
    const isSubsequentSubmit =
      !isEmpty(data) && hasSubmitted && hasResourceIds();

    const dirtyData = getDirtyFieldsValue(dirtyFields, data);
    const sanitizedData = sanitizeNestedData(data);
    const sanitizedDirtyData = sanitizeData(dirtyData);

    if (ignoreResourceIdAlways || isInitialSubmit || isSubsequentSubmit) {
      setHasSubmitted(true);
      await onSubmit({
        resourceId,
        resourceIds,
        data,
        dirtyData,
        sanitizedData,
        sanitizedDirtyData,
      });
      reset({}, { keepValues: true, keepIsSubmitted: true });
    }
  };

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:form autosave", errors);
  };

  const handleSubmitManually = async () => {
    const isValid = await trigger();
    if (isValid) {
      await handleSubmitCallback(onSubmitCallback, onError)();
    }
    return isValid;
  };

  useEffect(() => {
    if (isSubmitted && isEmpty(errors)) {
      setHasSubmitted(true);
    }
  }, [isSubmitted, errors]);

  return (
    <Fragment>
      {showFormNavigationAlert && (
        <DetectFormNavigation
          isFormDirty={!isSubmitted && !isEmpty(dirtyFields)}
        />
      )}
      <AutoSaverWrapper
        control={control}
        submitButtonRef={submitButtonRef}
        disableAutoSave={disableAutoSave}
        dirtyFields={dirtyFields}
      />
      {children({
        submitButtonRef,
        reset,
        setValue,
        getValues,
        control,
        resourceId,
        resourceIds,
        handleSubmitManually,
        handleFormSubmit: handleSubmitCallback(onSubmitCallback, onError),
      })}
    </Fragment>
  );
};

export const FormAutoSaveWrapperBase: FC<Props> = ({
  formId,
  children,
  className = "py-8",
  ...rest
}) => {
  return (
    <FormAutoSaveBase {...rest}>
      {({ handleFormSubmit, submitButtonRef, ...rest }) => {
        return (
          <form id={formId} className={className} onSubmit={handleFormSubmit}>
            {submitButtonRef && children(rest)}
            <button hidden type="submit" ref={submitButtonRef}>
              Submit
            </button>
          </form>
        );
      }}
    </FormAutoSaveBase>
  );
};

export const PerformantFormAutoSaveBase: FC<Props> = ({
  formId,
  children,
  inputSchema,
  onSubmit,
  resourceId,
  resourceIds,
  values,
  defaultValues,
  ignoreResourceIdAlways,
  showFormNavigationAlert = true,
  className = "py-8",
}) => {
  const idleTimeout = 20000; // 20 seconds of inactivity to trigger autosave
  const idleTimerRef = useRef<any>(null); // Store the idle timer reference
  const wrapperRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const setIsSubmitted = useAppStore((state) => state.setIsSubmitted);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const {
    reset,
    control,
    setValue,
    getValues,
    formState: { dirtyFields, isSubmitted },
    handleSubmit: handleSubmitCallback,
  } = useForm<any>({
    values,
    defaultValues,
    resolver: yupResolver(inputSchema),
    resetOptions: {
      keepValues: true,
      keepIsSubmitted: true,
      keepDirtyValues: true, // if true user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const hasResourceIds = () => {
    const labels = resourceIds && Object.keys(resourceIds);
    const hasAllResourceIds =
      labels &&
      labels.length > 0 &&
      labels.every((label) => !isEmpty(resourceIds[label]));

    return !isEmpty(resourceId) || hasAllResourceIds;
  };

  const resetIdleTimer = useCallback(() => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
    idleTimerRef.current = setTimeout(() => {
      submitButtonRef.current?.click();
    }, idleTimeout);
  }, [submitButtonRef]);

  const onSubmitCallback = async (data: any) => {
    const hasDirtyData = !isEmpty(dirtyFields) && !isEmpty(data);
    const isInitialSubmit = hasDirtyData && !hasSubmitted;
    const isSubsequentSubmit = hasDirtyData && hasSubmitted && hasResourceIds();

    const dirtyData = getDirtyFieldsValue(dirtyFields, data);
    const sanitizedData = sanitizeNestedData(data);
    const sanitizedDirtyData = sanitizeData(dirtyData);

    if (ignoreResourceIdAlways || isInitialSubmit || isSubsequentSubmit) {
      setHasSubmitted(true);
      await onSubmit({
        resourceId,
        resourceIds,
        data,
        dirtyData,
        sanitizedData,
        sanitizedDirtyData,
      });
      setIsSubmitted(true);
      reset({}, { keepValues: true, keepIsSubmitted: true });
    }
  };

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:form autosave", errors);
  };

  useEffect(() => {
    // Define the activity handler to reset the idle timer
    const handleActivity = () => resetIdleTimer();

    // Add event listeners to the specific div for various user actions
    const divEl = wrapperRef.current;
    if (divEl) {
      divEl.addEventListener("mousemove", handleActivity);
      divEl.addEventListener("keypress", handleActivity);
      divEl.addEventListener("click", handleActivity);
      divEl.addEventListener("touchstart", handleActivity); // When user touches the screen
      divEl.addEventListener("touchend", handleActivity); // Lifting finger off screen
    }

    // Cleanup function to remove event listeners on component unmount
    return () => {
      if (divEl) {
        divEl.removeEventListener("mousemove", handleActivity);
        divEl.removeEventListener("keypress", handleActivity);
        divEl.removeEventListener("click", handleActivity);
        divEl.removeEventListener("touchstart", handleActivity);
        divEl.removeEventListener("touchend", handleActivity);
      }
      clearTimeout(idleTimerRef.current); // Clear the timer on unmount
    };
  }, [resetIdleTimer]);

  return (
    <Fragment>
      {showFormNavigationAlert && (
        <DetectFormNavigation
          isFormDirty={!isSubmitted && !isEmpty(dirtyFields)}
        />
      )}
      <div
        ref={wrapperRef}
        className="h-full w-full"
        onPointerLeave={() => {
          submitButtonRef.current?.click();
        }}
      >
        <form
          id={formId}
          className={className}
          onSubmit={handleSubmitCallback(onSubmitCallback, onError)}
        >
          {children({
            reset,
            setValue,
            getValues,
            control,
            resourceId,
            resourceIds,
          })}
          <button hidden type="submit" ref={submitButtonRef}>
            Submit
          </button>
        </form>
      </div>
    </Fragment>
  );
};
