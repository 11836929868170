import { FC } from "react";
import { GripIcon } from "lucide-react";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import { SortItem } from "./DragAndDropWrapper";

export const TwoThirdsColumn: FC<LayoutProps> = ({
  config,
  children,
  isFormBuilder,
}) => {
  return (
    <SortItem
      className="group/two-thirds-col col-span-6 md:col-span-4"
      config={config}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <IconButtonBase
          size="sm"
          className="invisible cursor-grab px-0.5 py-1 group-hover/two-thirds-col:visible"
          variant="clean"
        >
          <GripIcon className="h-5 w-5 stroke-[2]" />
        </IconButtonBase>
      )}
      {children}
    </SortItem>
  );
};
