import { FC, useCallback, useRef } from "react";
import {
  generateSearchComponentTree,
  generateSearchValidation,
  getProjectArgs,
  getSimilaritySearchComponents,
  transformSimilaritySearchToFormValues,
} from "./common";
import {
  useGetAllProjects,
  useGetNeighborProjects,
} from "@amenda-domains/queries";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App";
import { PaginationLimit } from "@amenda-constants";
import { SimilaritySearchForm } from "./SimilaritySearchForm";
import { transformSimilaritySearchData } from "./common";
import { useTranslation } from "react-i18next";

interface Props {
  bottomSheetHeight: number;
}

export const SimilaritySearchFormWrapper: FC<Props> = ({
  bottomSheetHeight,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLButtonElement>(null);
  const permissionsByForm = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const projectFormComponents = useProjectStore(
    (state) => state.projectFormComponents,
  );
  const similaritySearchComponents = useProjectStore(
    (state) => state.similaritySearchComponents,
  );
  const { loading, getNeighborProjects } = useGetNeighborProjects();
  const similaritySearchModalValues = useProjectStore(
    (state) => state.similaritySearchModalValues,
  );
  const setSimilaritySearchModalValues = useProjectStore(
    (state) => state.setSimilaritySearchModalValues,
  );
  const { getAllProjects } = useGetAllProjects();

  const { components, parentComponent } = getSimilaritySearchComponents(
    permissionsByForm,
    projectFormComponents,
  );
  const formComponentTree = generateSearchComponentTree(
    similaritySearchComponents,
    parentComponent,
  );
  const inputSchema = generateSearchValidation(similaritySearchComponents);
  const componentIds = similaritySearchComponents.map((c) => c.id);

  const handleSubmit = useCallback(
    async (data: any) => {
      const formValues = transformSimilaritySearchData(data, componentIds);

      setSimilaritySearchModalValues(formValues);
      const neighborProjects = await getNeighborProjects({
        args: {
          formValues,
        },
        context: {
          requestPolicy: "network-only",
        },
      });
      if (neighborProjects) {
        const similarProjectIds = neighborProjects
          .map((p: any) => p.id)
          .reverse();

        await getAllProjects({
          ...getProjectArgs({
            similarProjectIds,
          }),
          limit: PaginationLimit.projects,
          context: {
            requestPolicy: "cache-and-network",
          },
        });
      }
    },
    [
      componentIds,
      getNeighborProjects,
      getAllProjects,
      setSimilaritySearchModalValues,
    ],
  );

  const onSuccess = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  return (
    <div className="relative h-full w-full">
      <SimilaritySearchForm
        ref={ref}
        inputSchema={inputSchema}
        components={components}
        formComponentTree={formComponentTree}
        values={transformSimilaritySearchToFormValues(
          similaritySearchModalValues,
        )}
        handleSubmit={handleSubmit}
      />
      {bottomSheetHeight > 220 && (
        <div className="absolute bottom-4 mb-7 flex w-full justify-center border-t bg-white px-2 py-2 md:px-4 lg:px-8">
          <div>
            <Button
              loading={loading}
              size="lg"
              variant="primary"
              className="px-10"
              onClick={onSuccess}
            >
              {t("Evaluate")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
