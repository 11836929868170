/*
GitHub Copilot: 
The code you've selected is a common pattern used in JavaScript and TypeScript to delay the execution 
of a function until the current call stack has cleared. This is done using the `setTimeout` function 
with a delay of `0` milliseconds.In this specific case, the function being delayed is `e.target.focus()`. 
This is a method that sets focus on a specific DOM element, `e.target`, where `e` is the event object passed 
to an event handler function.The reason for using `setTimeout` here is to ensure that the `focus()` method is 
called after any other pending JavaScript operations have completed. This can be necessary in certain situations where, 
for example, the element you want to focus is being rendered or modified by another JavaScript operation. 
By delaying the `focus()` call, you ensure that the element is in the correct state when the method is called.
This pattern is commonly used in React and other JavaScript libraries to handle timing issues related to the 
rendering and modification of DOM elements.
*/

import { Queue, generateReadableId } from "@amenda-utils";

import Fuse from "fuse.js";
import { Option } from "@amenda-types";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

export const handleWheelChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();
  // Prevent the page/container scrolling
  e.stopPropagation();
  // Refocus immediately
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const getMailOrPhoneRef = (type: "email" | "phone", value: string) => {
  if (type === "email") {
    return `mailto:${value}`;
  }

  return `tel:${value}`;
};

export const getOptionsFromGroup = (group: Option[] = []) => {
  const options: Option[] = [];

  group.forEach((g) => {
    options.push(...(g.children ?? []));
  });

  return options;
};

export const resolveValuesToArray = (values?: string[] | string) => {
  return Array.isArray(values) ? values : isNil(values) ? [] : [values];
};

export const getResolvedValues = (
  options: Option[],
  values?: string[] | string,
) => {
  const selectedValues = resolveValuesToArray(values);
  let resolvedValues: Option[] = [];

  selectedValues.forEach((value) => {
    const resolvedValue = options.find((option) => option?.value === value);
    if (resolvedValue) {
      resolvedValues.push(resolvedValue);
    }
  });

  return resolvedValues;
};

export type OptionAndParent = {
  parent?: string;
  index?: number;
  option: Option;
};

export const getFlattenedOptions = (options: Option[]) => {
  const flatOptions: Option[] = [];
  const stack: Option[] = [...options];

  while (stack.length > 0) {
    const { children, ...option } = stack.pop()!;
    flatOptions.push(option);

    if (children) {
      stack.push(...children);
    }
  }

  return flatOptions;
};

export const getOptionChildren = (option: Option) => {
  const childrenOptions: Option[] = [];
  const stack: Option[] = [...(option.children ?? [])];

  while (stack.length > 0) {
    const { children, ...option } = stack.pop()!;
    childrenOptions.push(option);

    if (children) {
      stack.push(...children);
    }
  }

  return childrenOptions;
};

interface OptionTreeNode {
  option: Option;
  ogOption: Option;
  parentId?: string;
  childrenIds?: string[];
}

export const getOptionsTree = (options: Option[]) => {
  const optionsTree: Record<string, OptionTreeNode> = {};
  const stack: Option[] = [...options];

  while (stack.length > 0) {
    const foundOption = stack.pop()!;
    const { children, value, label } = foundOption;
    const parentId = (foundOption as any)?.parentId;
    const depth = (foundOption as any)?.depth ?? 0;

    optionsTree[value] = {
      parentId,
      ogOption: { children, value, label },
      option: { value, label, depth },
      childrenIds: children?.map((c) => c.value),
    };

    if (children) {
      stack.push(
        ...children.map((c) => ({
          ...c,
          parentId: value,
          depth: depth + 1,
        })),
      );
    }
  }
  return optionsTree;
};

export const getAvailableOptions = (
  optionsHierarchy: Record<string, OptionTreeNode>,
  matchedOptionIds: string[],
) => {
  const optionsById: Record<string, Option> = {};
  const stack: string[] = [...matchedOptionIds];

  while (stack.length > 0) {
    const currentId = stack.pop()!;
    const currentOption = optionsHierarchy[currentId];

    if (!currentOption) {
      continue;
    }
    if (currentOption?.parentId && !optionsById[currentOption.parentId]) {
      stack.push(currentId, currentOption.parentId);
    } else {
      const { option } = currentOption;
      const clonedOption = cloneDeep(option);

      optionsById[currentId] = clonedOption;
    }
  }

  const queue = new Queue<string>(Object.keys(optionsById));
  const optionsByIndex: Record<string, number> = {};
  let availableOptions: Option[] = [];
  let expandedOptions: Record<string, boolean> = {};

  while (!queue.isEmpty()) {
    const optionId = queue.dequeue()!;
    const { option, parentId } = optionsHierarchy[optionId];
    const clonedOption = cloneDeep(option);

    if (parentId) {
      const parentIndex = optionsByIndex[parentId];
      if (parentIndex >= 0) {
        if (
          isEmpty(availableOptions[parentIndex].children) ||
          !(availableOptions[parentIndex].children ?? []).some(
            (o) => o.value === option.value,
          )
        ) {
          availableOptions[parentIndex].children = [
            ...(availableOptions[parentIndex]?.children ?? []),
            clonedOption,
          ];
        }
        expandedOptions[parentId] = true;

        availableOptions = [
          ...availableOptions.slice(0, parentIndex + 1),
          clonedOption,
          ...availableOptions.slice(parentIndex + 1),
        ];
        optionsByIndex[option.value] = parentIndex + 1;
      } else {
        queue.enqueue(optionId);
      }
    } else {
      optionsByIndex[option.value] = availableOptions.length;
      availableOptions.push(clonedOption);
    }
  }

  return { availableOptions, expandedOptions };
};

export const getOptionDescendants = (
  value: string,
  optionsTree: Record<string, OptionTreeNode>,
) => {
  const children: string[] = [];
  const stack: string[] = [value];

  while (stack.length > 0) {
    const currentId = stack.pop()!;
    const currentItem = optionsTree[currentId];

    if (currentItem?.childrenIds) {
      currentItem.childrenIds.forEach((id) => {
        children.push(id);
        stack.push(id);
      });
    }
  }

  return children;
};

export const getOptionAncestors = (
  value: string,
  optionsTree: Record<string, OptionTreeNode>,
) => {
  const ancestors: string[] = [];
  let currentId = value;

  while (true) {
    const parentId = optionsTree[currentId]?.parentId;
    if (!parentId) break;

    ancestors.push(parentId);
    currentId = parentId;
  }

  return ancestors.reverse();
};

export const getSearchFlatOptions = (
  flatOptions: Option[],
  searchTerm: string,
) => {
  const fuse = new Fuse(flatOptions, {
    includeScore: true,
    keys: ["label", "value"],
    threshold: 0.3,
  });
  const results = fuse.search(searchTerm);
  return results.map((res) => res.item);
};

export const getExpandedOptions = (
  options: any[],
  isOpen: Record<string, boolean>,
) => {
  const expandedOptions: any[] = [];
  const stack: [any, number][] = options.map((option) => [option, 0]);

  while (stack.length > 0) {
    const [option, level] = stack.pop()!;
    expandedOptions.push({ ...option, level });

    if (isOpen[option.value] && option.children) {
      // Add children to the stack in reverse order to maintain original order when popped
      for (let i = option.children.length - 1; i >= 0; i--) {
        stack.push([option.children[i], level + 1]);
      }
    }
  }

  return expandedOptions;
};

export const getSearchCountries = <T>(
  countriesInfo: T[],
  searchTerm: string,
) => {
  const fuse = new Fuse(countriesInfo, {
    includeScore: true,
    keys: ["label", "formattedCode"],
    threshold: 0.5,
  });
  const results = fuse.search(searchTerm);
  return results.map((res) => res.item);
};

export const separateOptionFromParent = (options: Option[], option: Option) => {
  const updatedOptionsTree = getOptionsTree(options);
  updatedOptionsTree[option.value] = {
    option,
    ogOption: option,
    childrenIds: option.children?.map((c) => c.value),
  };

  return updatedOptionsTree;
};

export const removeOption = ({
  option,
  options,
  optionsTree,
}: {
  options: Option[];
  option: Option;
  optionsTree: Record<string, OptionTreeNode>;
}) => {
  let updatedOptions = cloneDeep(options);
  const ancestors = getOptionAncestors(option.value, optionsTree);
  const firstParentId = ancestors[0];
  const firstParentIndex = updatedOptions.findIndex(
    (o) => o.value === firstParentId,
  );

  if (firstParentIndex > -1) {
    let rootOption = updatedOptions[firstParentIndex];
    let nextParentIndex = 1;

    while (ancestors.length - 1 >= nextParentIndex) {
      const nextAncestor = ancestors[nextParentIndex];
      const nextAncestorIndex = rootOption.children!.findIndex(
        (o) => o.value === nextAncestor,
      );

      if (nextAncestorIndex > -1) {
        rootOption = rootOption.children![nextAncestorIndex];
      }
      nextParentIndex++;
    }
    rootOption.children = rootOption.children?.filter(
      (o) => o.value !== option.value,
    );
  } else {
    updatedOptions = updatedOptions.filter((o) => o.value !== option.value);
  }
  return updatedOptions;
};

export const updateOption = ({
  option,
  options,
  optionsTree,
  expandedOption,
}: {
  options: Option[];
  option: Option;
  optionsTree: Record<string, OptionTreeNode>;
  expandedOption: Record<string, boolean>;
}) => {
  let updatedOptions = cloneDeep(options);
  let updatedExpandedOption = {
    ...expandedOption,
  };
  const ancestors = getOptionAncestors(option.value, optionsTree);
  const firstParentId = ancestors[0];
  const firstParentIndex = updatedOptions.findIndex(
    (o) => o.value === firstParentId,
  );

  if (firstParentIndex > -1) {
    let rootOption = updatedOptions[firstParentIndex];
    let nextParentIndex = 1;

    while (ancestors.length - 1 >= nextParentIndex) {
      const nextAncestor = ancestors[nextParentIndex];
      const nextAncestorIndex = rootOption.children!.findIndex(
        (o) => o.value === nextAncestor,
      );

      if (nextAncestorIndex > -1) {
        rootOption = rootOption.children![nextAncestorIndex];
      }
      nextParentIndex++;
    }
    updatedExpandedOption[rootOption.value] = true;
    rootOption.children = rootOption.children?.map((o) =>
      o.value === option.value ? option : o,
    );
  } else {
    updatedOptions = updatedOptions.map((o) =>
      o.value === option.value ? option : o,
    );
    updatedExpandedOption[option.value] = true;
  }
  return { updatedOptions, updatedExpandedOption };
};

export const addOption = ({
  label,
  option,
  options,
  optionsTree,
  expandedOption,
}: {
  label: string;
  option: Option | null;
  options: Option[];
  optionsTree: Record<string, OptionTreeNode>;
  expandedOption: Record<string, boolean>;
}) => {
  const newOption: Option = {
    label,
    value: generateReadableId(label),
  };
  let updatedOptions = cloneDeep(options);
  let updatedExpandedOption = {
    ...expandedOption,
  };

  if (!option?.value) {
    return {
      updatedExpandedOption,
      updatedOptions: [...updatedOptions, newOption],
    };
  }

  const ancestors = getOptionAncestors(option.value, optionsTree);
  const firstParentId = ancestors[0];
  const firstParentIndex = updatedOptions.findIndex(
    (o) => o.value === firstParentId,
  );

  if (firstParentIndex > -1) {
    let rootOption = updatedOptions[firstParentIndex];
    let nextParentIndex = 1;

    while (ancestors.length - 1 >= nextParentIndex) {
      const nextAncestor = ancestors[nextParentIndex];
      const nextAncestorIndex = rootOption.children!.findIndex(
        (o) => o.value === nextAncestor,
      );

      if (nextAncestorIndex > -1) {
        rootOption = rootOption.children![nextAncestorIndex];
      }
      nextParentIndex++;
    }

    const children = rootOption.children!;
    const childIndex = children.findIndex((o) => o.value === option?.value);
    if (childIndex > -1) {
      updatedExpandedOption[children[childIndex].value] = true;
      children[childIndex].children = [
        ...(children[childIndex].children ?? []),
        newOption,
      ];
    }
  } else {
    const parentIndex = updatedOptions.findIndex(
      (o) => o.value === option?.value,
    );

    if (parentIndex > -1) {
      updatedExpandedOption[updatedOptions[parentIndex].value] = true;
      updatedOptions[parentIndex].children = [
        ...(updatedOptions[parentIndex].children ?? []),
        newOption,
      ];
    }
  }
  return {
    updatedOptions,
    updatedExpandedOption,
  };
};

export const getUpdatedAvailableOptions = (
  options: Option[],
  expandedOptions: Record<string, boolean>,
) => {
  let availableOptions: Option[] = [...options];
  const queue = new Queue<string>(
    Object.keys(expandedOptions).filter((k) => Boolean(expandedOptions[k])),
  );
  const missedAttempts: Record<string, number> = {};

  while (!queue.isEmpty()) {
    const currentId = queue.dequeue()!;
    const currIndex = availableOptions.findIndex((o) => o.value === currentId);

    if (currIndex > -1) {
      const option = availableOptions[currIndex];
      const depth = option.depth ?? 0;

      availableOptions = [
        ...availableOptions.slice(0, currIndex + 1),
        ...(option.children ?? []).map((o) => ({
          ...o,
          depth: depth + 1,
        })),
        ...availableOptions.slice(currIndex + 1),
      ];
    } else if (!missedAttempts[currentId] || missedAttempts[currentId] < 3) {
      queue.enqueue(currentId);
      missedAttempts[currentId] = (missedAttempts[currentId] ?? 0) + 1;
    }
  }

  return availableOptions;
};
