import { FC, forwardRef, useState } from "react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { DateQuarterPickerBase } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const DateQuarterPickerInput = forwardRef<HTMLButtonElement, any>(
  ({ value, disabled, onClick }, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onFocus = () => setIsOpen(true);
    const onBlur = () => setIsOpen(false);

    return (
      <button
        className={clsx(
          "flex flex-row items-baseline space-x-1 px-1 text-xxs hover:bg-gray-900 hover:text-white",
          {
            "cursor-not-allowed": disabled,
          },
        )}
        ref={ref}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
      >
        <span>{value || t("Select Quater")}</span>
        <ChevronRightIcon
          className={clsx("h-2 w-2", {
            "rotate-90": isOpen,
          })}
          aria-hidden="true"
        />
      </button>
    );
  },
);

interface Props {
  value: string;
  disabled?: boolean;
  maxDate?: Date;
  onChange: (value: string) => void;
}

export const DateQuarterPicker: FC<Props> = ({
  value,
  maxDate,
  disabled = false,
  onChange,
}) => {
  return (
    <DateQuarterPickerBase
      id="quarter"
      label="Quarter"
      hideIcon={true}
      value={value}
      maxDate={maxDate}
      disabled={disabled}
      customInput={<DateQuarterPickerInput />}
      onChange={onChange}
    />
  );
};
