import { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
}

export const ContactsLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t("Contacts")} />
      <div className="relative flex h-full">{children}</div>
    </>
  );
};
