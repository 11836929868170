import {
  AdminSettingsLayout,
  RolesSettingsTable,
} from "@amenda-components/Settings";
import { FC, useEffect } from "react";

import { Button } from "@amenda-components/App";
import { useGetAllSystemRoles } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useSettingsStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const Roles: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, getAllSystemRoles } = useGetAllSystemRoles();
  const systemRoles = useSettingsStore((state) => state.systemRoles);

  const handleClick = () => navigate("/admin/rollen/neu");

  useEffect(() => {
    getAllSystemRoles();
  }, [getAllSystemRoles]);

  return (
    <AdminSettingsLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Roles")}</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={handleClick}
            className="amenda-button"
          >
            + {t("New Role")}
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <RolesSettingsTable loading={loading} roles={systemRoles} />
      </div>
    </AdminSettingsLayout>
  );
};
