import { FC, useEffect } from "react";
import {
  TemplateThumbnailProps,
  TemplateThumbnails,
} from "@amenda-components/Templates";

import { useGetAllTemplates } from "@amenda-domains/queries";
import { useTemplatesStore } from "@amenda-domains/mutations";

interface Props {
  projectId: any;
}

export const ProjectTemplates: FC<Props> = ({ projectId }) => {
  const { getTemplates, loading } = useGetAllTemplates();
  const defaultTemplates = useTemplatesStore((state) => state.defaultTemplates);
  const setTemplateDesignModal = useTemplatesStore(
    (state) => state.setTemplateDesignModal,
  );
  const setSelectedTemplate = useTemplatesStore(
    (state) => state.setSelectedTemplate,
  );

  useEffect(() => {
    const getTemplatesAsync = async () => {
      if (projectId) {
        await getTemplates();
      }
    };

    getTemplatesAsync();
  }, [getTemplates, projectId]);

  const handleSelectThumbnail = (thumbnail: TemplateThumbnailProps) => {
    setSelectedTemplate(thumbnail);
    setTemplateDesignModal(true);
  };

  return (
    <div className="mb-2">
      <div className="w-full">
        <div className="overflow-hidden">
          <div className="w-full">
            <TemplateThumbnails
              loading={loading}
              templates={defaultTemplates}
              defaultTemplates={defaultTemplates}
              handleSelectThumbnail={handleSelectThumbnail}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
