import { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
}

export const SettingsLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("Settings")} />
      <div className="flex justify-center">
        <div className="grid w-full grid-cols-1">
          <div className="overflow-hidden rounded bg-white px-4 py-1 pb-5 sm:rounded-lg lg:py-6">
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
