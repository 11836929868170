import { FileJson2Icon, LayoutTemplateIcon, PlusIcon } from "lucide-react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import { FC } from "react";
import { FormCategories } from "@amenda-constants";
import { ReactSVG } from "react-svg";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const FormBuilderCreateFormButton: FC = () => {
  const { t } = useTranslation();
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const { selectedCategory } = formBuilderState ?? {};

  const handleOpenCreateForm = (source: "template" | "json" | "xlsx") => () => {
    updateFormBuilderState("createNewFormModal", {
      isOpen: true,
      source,
    });
  };

  if (
    selectedCategory === FormCategories.CostGroups ||
    selectedCategory === FormCategories.Contacts
  ) {
    return null;
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center rounded-sm bg-gray-800 px-4 py-2 text-sm text-white hover:bg-black/90 focus:outline-none">
          <div className="flex w-full items-center justify-center">
            <span>{t("New form")}</span>
            <PlusIcon className="ml-2 h-6 w-6" />
          </div>
        </MenuButton>
      </div>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 mt-0.5 w-full origin-top-right rounded-sm bg-white text-gray-800 shadow-lg focus:outline-none">
          <MenuItem>
            <button
              onClick={handleOpenCreateForm("template")}
              className="flex w-full items-center rounded-sm px-2 py-2 text-sm hover:bg-gray-800 hover:text-white"
            >
              <LayoutTemplateIcon className="mr-2 h-6 w-6" />
              {t("Create from template")}
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={handleOpenCreateForm("xlsx")}
              className="flex w-full items-center rounded-sm fill-gray-800 stroke-gray-800 px-2 py-2 text-sm hover:bg-gray-800 hover:fill-white hover:stroke-white hover:text-white"
            >
              <ReactSVG src="/svg/excel.svg" className="mr-2 h-6 w-6" />
              {t("Create from Excel")}
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={handleOpenCreateForm("json")}
              className="flex w-full items-center rounded-sm px-2 py-2 text-sm hover:bg-gray-800 hover:text-white"
            >
              <FileJson2Icon className="mr-2 h-6 w-6" />
              {t("Create from JSON")}
            </button>
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
