import { AllowedContactType } from "@amenda-types";
import { Avatar } from "@amenda-components/App";
import { FC } from "react";
import { flattenUserDetails } from "./common";

interface BaseProps {
  contact: any;
}

const CompanyProfileCard: FC<BaseProps> = ({ contact }) => {
  return (
    <>
      <div className="shrink-0">
        <Avatar name={contact?.companyName} className="h-10 w-10" />
      </div>
      <div className="min-w-0 flex-1">
        <div className="w-full pl-4">
          {contact.companyName && (
            <p className="text-left text-sm text-gray-900">
              {contact.companyName}
            </p>
          )}
          <p className="amenda-contact-subline">{contact.address?.name}</p>
        </div>
      </div>
    </>
  );
};

const DefaultProfileCard: FC<BaseProps> = ({ contact }) => {
  const hasName = contact?.firstName || contact?.lastName;
  const name = `${contact?.firstName ?? ""} ${contact?.lastName ?? ""}`;
  const email = Array.isArray(contact?.email)
    ? contact?.email[0]?.value
    : contact?.email;

  return (
    <>
      <div className="shrink-0">
        <Avatar
          name={name}
          src={contact?.photoURL}
          className="h-10 w-10 text-base"
        />
      </div>
      <div className="min-w-0 flex-1">
        <div className="w-full pl-4">
          {hasName && <p className="text-left text-sm text-gray-900">{name}</p>}
          <p className="amenda-contact-subline">{email}</p>
        </div>
      </div>
    </>
  );
};

export const ContactProfileCardBase: FC<BaseProps> = ({ contact }) => {
  const contactDetails = flattenUserDetails(contact);

  return (
    <>
      {contact?.type === AllowedContactType.company ? (
        <CompanyProfileCard contact={contactDetails} />
      ) : (
        <DefaultProfileCard contact={contactDetails} />
      )}
    </>
  );
};
