import {
  AggregationLoader,
  AggregationPickerWrapper,
} from "./AggregationWidgetComponents";
import { AllowedWidgetTypes, AvailableForms } from "@amenda-constants";
import { FC, useEffect, useState } from "react";
import {
  getCostGroupOptions,
  getNumericOptions,
  getValuesFromWidgets,
} from "@amenda-components/Dashboard/common";
import {
  useDashboardStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";
import { useGetAllProjects, useGetUserWidgets } from "@amenda-domains/queries";

import { AggregationViewerGrid } from "./AggregationViewerGrid";
import clsx from "clsx";
import { getComponentCodeById } from "@amenda-components/CostGroup/common";
import { getComponentsFromForms } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

interface Props {
  widgetType: AllowedWidgetTypes;
  isSubmitting: boolean;
  onSubmit: (widgets: any[]) => Promise<void>;
}

export const AggregationWidgetViewer: FC<Props> = ({
  widgetType,
  isSubmitting,
  onSubmit,
}) => {
  const { pathname } = useLocation();
  const forms = useProjectStore((state) => state.forms);
  const projects = useProjectStore((state) => state.projects);
  const { getUserWidgets, loading } = useGetUserWidgets();
  const [isEditingWidgets, setIsEditingWidgets] = useState(false);
  const showAggregations = useDashboardStore((state) => state.showAggregations);
  const availableWidgets = useDashboardStore((state) => state.availableWidgets);
  const setAggregationData = useDashboardStore(
    (state) => state.setAggregationData,
  );
  const { getAllProjects, loading: getAllProjectsLoader } = useGetAllProjects();
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole,
  );

  const projectForms = forms?.[AvailableForms.Project];
  const costGroupForms = forms?.[AvailableForms.CostGroupsCost];
  const permissions = currentUserSystemRole?.permissions || {};
  const projectComponents = getComponentsFromForms(projectForms);
  const numericOptions = getNumericOptions(projectComponents, permissions);
  const costGroupOptions = getCostGroupOptions(permissions);
  const shouldShowWidgets = pathname === "/" || Boolean(showAggregations);
  const options = [...numericOptions, ...costGroupOptions];
  const { componentCodeById } = getComponentCodeById(
    costGroupForms?.[0]?.components || [],
  );

  const handleSubmit = async () => {
    if (isEditingWidgets) {
      await onSubmit(availableWidgets);
    }
    setIsEditingWidgets(!isEditingWidgets);
  };

  useEffect(() => {
    getUserWidgets({ type: widgetType });
  }, [widgetType, getUserWidgets]);

  useEffect(() => {
    if (!isEditingWidgets && !isEmpty(availableWidgets) && !isEmpty(projects)) {
      const values = getValuesFromWidgets(availableWidgets);

      getAllProjects({
        ids: projects.map((p) => p.id),
        selectProjectFormValueFields: values,
        callback: setAggregationData,
      });
    }
  }, [
    projects,
    availableWidgets,
    shouldShowWidgets,
    isEditingWidgets,
    setAggregationData,
    getAllProjects,
  ]);

  return (
    <div
      className={clsx(
        "group sticky top-0 z-50 mb-2 min-h-[15rem] border bg-gray-50",
        {
          "flex items-center": loading,
        },
      )}
    >
      <AggregationLoader isLoading={loading} />
      <AggregationPickerWrapper
        isLoading={isSubmitting}
        isEditing={isEditingWidgets}
        aggregateOptions={options}
        handleSubmit={handleSubmit}
      />
      <AggregationViewerGrid
        isLoading={getAllProjectsLoader}
        isEditing={isEditingWidgets}
        componentCodeById={componentCodeById}
      />
    </div>
  );
};
