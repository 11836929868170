import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FC, useEffect } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { getComponentsById, groupComponentsByParent } from "@amenda-utils";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { CostGroupCostForm } from "./CostGroupCostForm";
import { CostGroupQuantityForm } from "./CostGroupQuantityForm";
import { Counter } from "@amenda-components/App";
import { FormTab } from "@amenda-types";
import clsx from "clsx";
import { processCostGroupPermissions } from "./common";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any, object>;
  isDisabled: boolean;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onBlur?: () => void;
}

interface ShowCurrentTabProps extends Props {
  selectedForm?: FormTab;
  permissions: Record<string, any>;
}

const showCurrentTab = ({
  selectedForm,
  permissions,
  ...rest
}: ShowCurrentTabProps) => {
  const components = selectedForm?.components || [];
  const formComponentTree = groupComponentsByParent(
    components,
    processCostGroupPermissions(permissions),
  );

  if (selectedForm?.category === AvailableForms.CostGroupsCost) {
    return (
      <CostGroupCostForm formComponentTree={formComponentTree} {...rest} />
    );
  } else if (selectedForm?.category === AvailableForms.CostGroupsQuantity) {
    return (
      <CostGroupQuantityForm formComponentTree={formComponentTree} {...rest} />
    );
  }
  return <div />;
};

export const CostGroupFormWrapper: FC<Props> = (props) => {
  const { t } = useTranslation();
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole,
  );
  const forms = useProjectStore((state) => state.forms);
  const missingQuantities = useProjectStore(
    (state) => state.costGroupCounter.missingQuantities,
  );
  const setInitialCostGroupCounter = useProjectStore(
    (state) => state.setInitialCostGroupCounter,
  );

  const { getValues } = props;
  const permissions = currentUserSystemRole?.permissions || {};
  const costGroupCostForm = forms?.[AvailableForms.CostGroupsCost]?.[0];
  const costGroupQuantityForm = forms?.[AvailableForms.CostGroupsQuantity]?.[0];

  useEffect(() => {
    const values = getValues();
    if (values && costGroupCostForm?.components) {
      const componentsById = getComponentsById(costGroupCostForm.components);
      setInitialCostGroupCounter(values, componentsById);
    }
  }, [getValues, costGroupCostForm?.components, setInitialCostGroupCounter]);

  return (
    <TabGroup defaultIndex={0}>
      <TabList className="amenda-tab-list sticky top-0 z-40 w-full overflow-y-auto overscroll-y-contain border-0 bg-white pt-4">
        <Tab
          className={({ selected }) =>
            clsx(
              "flex items-center space-x-1 whitespace-nowrap px-3 py-2 text-sm focus:outline-none",
              {
                "bg-gray-900 text-white": selected,
                "text-gray-900 hover:bg-gray-900 hover:text-white": !selected,
              },
            )
          }
        >
          {costGroupCostForm?.name && t(costGroupCostForm.name)}
        </Tab>
        <Tab
          className={({ selected }) =>
            clsx(
              "relative flex items-center space-x-1 whitespace-nowrap px-3 py-2 text-sm focus:outline-none",
              {
                "bg-gray-900 text-white": selected,
                "text-gray-900 hover:bg-gray-900 hover:text-white": !selected,
              },
            )
          }
        >
          {costGroupQuantityForm?.name && t(costGroupQuantityForm.name)}
          <Counter
            count={missingQuantities.length}
            className="absolute -right-2 -top-2 h-5 w-5"
          />
        </Tab>
      </TabList>
      <TabPanels className="w-full pt-4">
        <TabPanel>
          {showCurrentTab({
            permissions,
            selectedForm: costGroupCostForm,
            ...props,
          })}
        </TabPanel>
        <TabPanel>
          {showCurrentTab({
            permissions,
            selectedForm: costGroupQuantityForm,
            ...props,
          })}
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};
