import { FC, MouseEvent, ReactNode } from "react";

import clsx from "clsx";
import { iconButtonTheme } from "@amenda-styles/theme";
import { useTranslation } from "react-i18next";

interface BaseProps {
  label?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  size?: "xss" | "xs" | "sm" | "md" | "lg" | "xl";
  variant?:
    | "primary"
    | "default"
    | "danger"
    | "dangerAlt"
    | "secondary"
    | "base"
    | "clean"
    | "round"
    | "inverted"
    | "primaryAlt";
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
}

interface Props extends BaseProps {
  label: string;
  iconSize?: number;
  buttonSize?: number;
  Icon: FC<{
    className?: string;
    style?: any;
  }>;
}

export const IconButtonBase: FC<BaseProps> = ({
  label,
  children,
  className,
  variant,
  size = "md",
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      disabled={disabled}
      className={iconButtonTheme({
        variant,
        size,
        disabled: !!disabled,
        className: clsx("justify-center", className),
      })}
      onClick={onClick}
    >
      {children}
      {label && <span className="sr-only">{t(label)}</span>}
    </button>
  );
};

export const IconButton: FC<Props> = ({
  Icon,
  className,
  label,
  iconSize = 1.25,
  variant,
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={iconButtonTheme({
        variant,
        disabled: !!disabled,
        className: clsx("justify-center", className),
      })}
      onClick={onClick}
      style={{
        width: `${iconSize + 0.75}rem`,
        height: `${iconSize + 0.75}rem`,
      }}
      disabled={disabled}
    >
      <Icon
        style={{
          width: `${iconSize}rem`,
          height: `${iconSize}rem`,
        }}
        aria-hidden="true"
      />
      <span className="sr-only">{t(label)}</span>
    </button>
  );
};
