import { Controller, useWatch } from "react-hook-form";
import { CostEstimateColumns, useCheckSelectedCostGroups } from "./common";
import { FC, ReactNode } from "react";
import { isEmpty, isFinite, isNil } from "lodash";

import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { Skeleton } from "@amenda-components/App";
import { roundAndFormatNumbers } from "@amenda-utils";
import { useProjectStore } from "@amenda-domains/mutations";

interface EstimationProps {
  value?: number;
  endAddOnText?: string;
  isLoading?: boolean;
}

const Estimation: FC<EstimationProps> = ({
  value,
  endAddOnText,
  isLoading,
}) => {
  if (isLoading) {
    return <Skeleton width={16} height={6} />;
  } else if (!isFinite(value) || isNil(value)) {
    return null;
  }
  return (
    <span className="font-apercu">
      {roundAndFormatNumbers(value, endAddOnText)}
    </span>
  );
};

interface CellProps {
  children: ReactNode;
}

const Cell: FC<CellProps> = ({ children }) => {
  return <div className="flex items-start justify-end text-sm">{children}</div>;
};

const displayAmount = (values: Record<string, any>, value?: number) => {
  return !isFinite(values.costGroupCount) ? undefined : value;
};

export const CostEstimateCheckbox: FC<ComponentsTreeViewRowProps> = ({
  control,
  component,
  globalData,
  componentsById,
  level = 0,
  closeAllComponentsInGroup,
}) => {
  const form =
    useWatch({
      control,
      exact: true,
      name: ["depth", "considerationDate"],
    }) || [];

  const parentComponent = componentsById[component.id];
  const hasAllFields = form.every((f) => !!f);
  const hasNoChildren = isEmpty(parentComponent?.components);
  const hideCheckbox = level > 0 || hasNoChildren || !hasAllFields;

  return (
    <div className="flex flex-col justify-evenly px-2">
      {hideCheckbox ? null : (
        <Controller
          name="selectedCostGroups"
          control={control}
          render={({ field: { onChange, value = [] } }) => {
            const handleChange = (checked?: boolean) => {
              if (checked) {
                onChange([component.id, ...value]);
              } else {
                onChange(value.filter((v: string) => v !== component.id));
                closeAllComponentsInGroup();
              }
            };

            return (
              <div tabIndex={-1} onClick={globalData?.onBlur}>
                <SingleCheckbox
                  id={component.id}
                  checked={value.includes(component.id)}
                  onChange={handleChange}
                />
              </div>
            );
          }}
        />
      )}
    </div>
  );
};

export const CostEstimateBody: FC<ComponentsTreeViewRowProps> = ({
  control,
  component,
  componentsById,
  column: { id },
}) => {
  const depths = useWatch({
    control,
    exact: true,
    name: "depth",
    defaultValue: ["1"],
  });
  const selectedCostGroups = useWatch({
    control,
    exact: true,
    name: "selectedCostGroups",
    defaultValue: [],
  });
  const checkSelectedCostGroups = useCheckSelectedCostGroups({
    selectedCostGroups,
    depth: Number(depths[0]),
    stringifiedComponentsById: JSON.stringify(componentsById),
  });
  const isFetchingCostEstimate = useProjectStore(
    (state) => state.isFetchingCostEstimate,
  );
  const costEstimateValues = useProjectStore(
    (state) => state.costEstimateValues,
  );

  const key = `${id}_${component.id}`;
  const isSelectedCostGroup = checkSelectedCostGroups(component.id);
  const values = costEstimateValues[component?.properties?.code] || {};
  const isLoading = isSelectedCostGroup && isFetchingCostEstimate;

  if (id === CostEstimateColumns.TotalCost) {
    return (
      <Cell key={key}>
        <Estimation
          endAddOnText="%"
          value={values.percentageCost}
          isLoading={isLoading}
        />
      </Cell>
    );
  } else if (id === CostEstimateColumns.UnitPrice) {
    return (
      <Cell key={key}>
        <Estimation
          endAddOnText="€"
          isLoading={isLoading}
          value={displayAmount(values, values.costGroupAverage)}
        />
      </Cell>
    );
  } else if (id === CostEstimateColumns.AveragePrice) {
    return (
      <Cell key={key}>
        <Estimation
          endAddOnText="€"
          isLoading={isLoading}
          value={displayAmount(values, values.costGroupTotal)}
        />
      </Cell>
    );
  }
  return (
    <Cell key={key}>
      {isLoading ? (
        <Skeleton width={16} height={6} />
      ) : (
        isSelectedCostGroup &&
        isFinite(values.costGroupCount) && (
          <>
            {values.costGroupCount}/{values.totalProjects}
          </>
        )
      )}
    </Cell>
  );
};
