import { FC, useMemo } from "react";
import {
  useDashboardStore,
  useUpsertUserWidgets,
} from "@amenda-domains/mutations";

import { AggregationWidgetViewer } from "./AggregationWidgetViewer";
import { AllowedWidgetTypes } from "@amenda-constants";
import { AppRoutes } from "@amenda-types";
import { useLocation } from "react-router-dom";

export const AggregationWidgetViewerWrapper: FC = () => {
  const { pathname } = useLocation();
  const showAggregations = useDashboardStore((state) => state.showAggregations);
  const { upsertUserWidgets, loading } = useUpsertUserWidgets();
  const widgetType: AllowedWidgetTypes = useMemo(
    () =>
      pathname === AppRoutes.Root
        ? AllowedWidgetTypes.Dashboard
        : AllowedWidgetTypes.Project,
    [pathname],
  );

  const showAggregationWidgets =
    pathname === AppRoutes.Root || showAggregations;

  const onSubmit = async (widgets: any[]) => {
    const input = {
      widgets,
      type: widgetType,
    };

    await upsertUserWidgets({ input });
  };

  if (!showAggregationWidgets) {
    return null;
  }
  return (
    <AggregationWidgetViewer
      widgetType={widgetType}
      isSubmitting={loading}
      onSubmit={onSubmit}
    />
  );
};
