import { FC, ReactNode, useMemo } from "react";
import {
  ProjectFormPrintView,
  ReadOnlyProjectMedia,
} from "@amenda-components/Projects";

import { AvailableForms } from "@amenda-constants";
import { ProjectTabRoutes } from "./common";
import { useParams } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Section {
  id: string;
  label: string;
  components: any[];
}

interface TitleProps {
  children: string;
}

const Title: FC<TitleProps> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <h3 className="w-full pb-4 pt-2 text-2xl text-gray-900">{t(children)}</h3>
  );
};

interface SectionWrapperProps {
  label: string;
  children: ReactNode;
}

const SectionWrapper: FC<SectionWrapperProps> = ({ label, children }) => {
  return (
    <div
      style={{
        breakAfter: "page",
      }}
    >
      <Title>{label}</Title>
      {children}
    </div>
  );
};

interface SectionPickerProps {
  section: Section;
  projectId: string;
  loading: boolean;
}

const SectionPicker: FC<SectionPickerProps> = ({
  section,
  projectId,
  loading,
}) => {
  if (section.id === ProjectTabRoutes.Attachments) {
    return (
      <SectionWrapper label={section.label}>
        <ReadOnlyProjectMedia projectId={projectId} />
      </SectionWrapper>
    );
  }
  return (
    <SectionWrapper label={section.label}>
      <ProjectFormPrintView components={section.components} loading={loading} />
    </SectionWrapper>
  );
};

export const ProjectPrintView: FC<{ loading: boolean }> = ({ loading }) => {
  const params: any = useParams();
  const forms = useProjectStore((state) => state.forms);

  const projectForms = forms?.[AvailableForms.Project];
  const { projectId } = params || {};

  const availableSections = useMemo(() => {
    const sections: Section[] =
      projectForms?.map(({ id, components, name }) => ({
        id,
        components,
        label: name,
      })) || [];

    sections.push({
      id: ProjectTabRoutes.Attachments,
      label: "Project Specific Attachments",
      components: [],
    });
    return sections;
  }, [projectForms]);

  return (
    <div className="w-full">
      {availableSections.map((section) => {
        return (
          <SectionPicker
            key={section.id}
            projectId={projectId}
            loading={loading}
            section={section}
          />
        );
      })}
    </div>
  );
};
