import { getComponentsFromForms, groupComponentsByParent } from "@amenda-utils";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { processFormPermissions } from "@amenda-components/Shared/common";

export const ReadonlyAttachmentDetails: FC = () => {
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const componentTree = groupComponentsByParent(
    components,
    processFormPermissions(permissions),
  );
  const data = selectedAttachment.formValues || {};

  return (
    <div className="py-6">
      <ComponentTreeRenderer
        config={componentTree}
        globalProps={{
          data,
        }}
        readOnly={true}
      />
    </div>
  );
};
