import { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  id: string;
  className?: string;
  onChange?: () => void;
}

export const SingleRadioButton: FC<Props> = ({
  id,
  disabled,
  label,
  checked = false,
  className = "h-5 w-5",
  onChange = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center">
      <input
        id={id}
        type="radio"
        checked={checked}
        disabled={disabled}
        className={clsx("text-gray-900 focus:ring-0", className)}
        onChange={onChange}
      />
      {label && (
        <label htmlFor={label} className="ml-3 text-sm text-gray-700">
          {t(label)}
        </label>
      )}
    </div>
  );
};
