import { CheckIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { FC, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { formatDate, formatQuarter } from "@amenda-utils";
import { getOptionsFromCostGroups, getSelectedCostGroup } from "./common";

import clsx from "clsx";
import { isEmpty } from "lodash";
import { useGetBKIValue } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const CostGroupPicker: FC = () => {
  const { t } = useTranslation();
  const { getBKIValue, loading } = useGetBKIValue();
  const projectCostGroups = useProjectStore((state) => state.projectCostGroups);
  const bkiValue = useProjectStore((state) => state.bkiValue);
  const projectCostGroupsByType = useProjectStore(
    (state) => state.projectCostGroupsByType,
  );
  const selectedCostGroupId = useProjectStore(
    (state) => state.selectedCostGroupId,
  );
  const setSelectedProjectCostGroup = useProjectStore(
    (state) => state.setSelectedProjectCostGroup,
  );
  const openProjectCostGroupModal = useProjectStore(
    (state) => state.openProjectCostGroupModal,
  );

  const options = getOptionsFromCostGroups(projectCostGroupsByType);
  const { type, bkiDate, versionDate } =
    getSelectedCostGroup(projectCostGroups, selectedCostGroupId) || {};

  const handleClick = (costGroup: any, onClose: () => void) => () => {
    setSelectedProjectCostGroup(costGroup);
    onClose();
  };

  useEffect(() => {
    if (
      !selectedCostGroupId &&
      !isEmpty(projectCostGroups) &&
      !openProjectCostGroupModal
    ) {
      const costGroup = projectCostGroups[0];
      setSelectedProjectCostGroup(costGroup);
    }
  }, [
    selectedCostGroupId,
    openProjectCostGroupModal,
    projectCostGroups,
    setSelectedProjectCostGroup,
  ]);

  useEffect(() => {
    if (bkiDate) {
      getBKIValue({
        date: bkiDate,
      });
    }
  }, [bkiDate, getBKIValue]);

  if (isEmpty(projectCostGroups)) {
    return null;
  }
  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <MenuButton className="border-1 group inline-flex items-center space-x-2 border bg-white px-2 py-1 text-sm text-gray-900 hover:bg-gray-900 hover:text-white focus:outline-none">
            <span>
              {selectedCostGroupId
                ? t("CostGroupPicker", {
                    type: type ? t(type) : "",
                    bkiValue: loading ? "..." : bkiValue,
                    bkiDate: bkiDate ? formatQuarter(bkiDate) : "",
                    versionDate: versionDate ? formatDate(versionDate) : "",
                  })
                : t("Select cost group")}
            </span>
            <ChevronRightIcon
              className={clsx("h-3 w-3", {
                "rotate-90": open,
              })}
              aria-hidden="true"
            />
          </MenuButton>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              static
              className="absolute right-0 z-50 ml-2 mt-1 w-36 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {options.map((option) => (
                  <MenuItem key={option.label}>
                    {({ focus, close }) => (
                      <button
                        disabled={option.isGroup}
                        onClick={handleClick(option.costGroup, close)}
                        className={clsx("group flex w-full justify-between", {
                          "bg-gray-900 text-white": focus,
                          "text-gray-700": !focus,
                          "cursor-pointer pl-3 hover:text-white":
                            !option.isGroup,
                          "text-gray-900":
                            selectedCostGroupId &&
                            selectedCostGroupId === option?.costGroup?.id,
                        })}
                      >
                        <div className={clsx("px-4 py-2 text-sm")}>
                          {t(option.label)}
                        </div>
                        {selectedCostGroupId &&
                          selectedCostGroupId === option?.costGroup?.id && (
                            <div className="mr-3 mt-2">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-4 w-4 text-gray-900 group-hover:text-white"
                              />
                            </div>
                          )}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};
