import {
  ActionBar,
  IconButtonBase,
  SaveIndicator,
  Tooltip,
} from "@amenda-components/App";
import {
  AllowedContactType,
  AvailableNotificationTypes,
  GeneralPermissionTypes,
} from "@amenda-types";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { NotebookTabsIcon, Share2Icon, SheetIcon, XIcon } from "lucide-react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  useAppStore,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ContactDirectoryProps } from "./types";
import { FC } from "react";
import clsx from "clsx";
import { downloadVCard } from "@amenda-utils";
import { flattenUserDetails } from "./common";
import { useGetContact } from "@amenda-domains/queries";

interface Props
  extends Pick<
    ContactDirectoryProps,
    "canDeleteUsers" | "rootRoute" | "collectionRoute"
  > {
  isCollection: boolean;
  contact: any;
  setOpenModal: (open: boolean) => void;
  setOpenShareModal: (open: boolean) => void;
}

export const ContactActionBar: FC<Props> = ({
  contact,
  canDeleteUsers,
  rootRoute,
  collectionRoute,
  isCollection,
  setOpenModal,
  setOpenShareModal,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const currentUser = useUsersStore((state) => state.currentUser);
  const isSubmitting = useUsersStore((state) => state.isSubmitting);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const { getContact, loading } = useGetContact();
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );

  const isEditing = pathname.endsWith(`/${contact.id}/bearbeiten`);
  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );

  const handleOpenUsersTableView = () => {
    setOpenUsersTableView(true);
  };

  const goToEdit = () => {
    const path = isCollection
      ? `${collectionRoute}/${collectionId}`
      : rootRoute;

    navigate(`${path}/${contact.id}/bearbeiten`);
  };

  const goBack = () => {
    const path = isCollection
      ? `${collectionRoute}/${collectionId}`
      : rootRoute;

    navigate(`${path}/${contact.id}`);
  };

  const handleDelete = () => setOpenModal(true);

  const handleDownloadVCard = async () => {
    try {
      let company = {};
      if (contact?.company) {
        company = await getContact({
          id: contact.company,
          callback: () => {},
        });
        company = flattenUserDetails(company);
      }
      const name = [contact?.lastName, contact?.firstName]
        .filter(Boolean)
        .join(", ");

      downloadVCard(
        {
          ...contact,
          company,
        },
        name,
      );
      showNotification(AvailableNotificationTypes.Success, "VCard Downloaded");
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "Issue whilte downloading VCard",
      );
    }
  };

  return (
    <ActionBar className="flex flex-row py-2 md:justify-end">
      {() => {
        return (
          <>
            {isEditing ? (
              <>
                <SaveIndicator isSubmitting={isSubmitting} />
                <Tooltip id="close" message="Close">
                  <IconButtonBase
                    size="sm"
                    label="Close"
                    variant="default"
                    onClick={goBack}
                  >
                    <XIcon className="h-5 w-5" />
                  </IconButtonBase>
                </Tooltip>
              </>
            ) : (
              <>
                <div className="block lg:hidden">
                  <Tooltip id="tableView" message="Open table view">
                    <IconButtonBase
                      size="sm"
                      label="Open table view"
                      variant="default"
                      onClick={handleOpenUsersTableView}
                    >
                      <SheetIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                </div>
                {contact?.type === AllowedContactType.person && (
                  <Tooltip id="notebook" message="Open contact as .vcf">
                    <IconButtonBase
                      size="sm"
                      label="VCF Download"
                      variant="default"
                      className={clsx({
                        "animate-pulse text-green-600": loading,
                      })}
                      onClick={handleDownloadVCard}
                    >
                      <NotebookTabsIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
                {contactsGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="share" message="Share">
                    <IconButtonBase
                      size="sm"
                      label="Share"
                      variant="default"
                      onClick={() => setOpenShareModal(true)}
                    >
                      <Share2Icon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
                {canDeleteUsers &&
                  contactsGeneralPermissions[GeneralPermissionTypes.Delete] &&
                  contact?.id !== currentUser?.id && (
                    <Tooltip id="deleteContact" message="Delete">
                      <IconButtonBase
                        size="sm"
                        label="Delete Contact"
                        variant="default"
                        onClick={handleDelete}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </IconButtonBase>
                    </Tooltip>
                  )}
                {contactsGeneralPermissions[GeneralPermissionTypes.Edit] && (
                  <Tooltip id="editContact" message="Edit">
                    <IconButtonBase
                      size="sm"
                      label="Edit Contact"
                      variant="default"
                      onClick={goToEdit}
                    >
                      <PencilIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
              </>
            )}
          </>
        );
      }}
    </ActionBar>
  );
};
