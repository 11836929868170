import "react-datepicker/dist/react-datepicker.css";

import { CalendarIcon, XCircleIcon } from "@heroicons/react/24/outline";
import {
  DatePickerHeader,
  DatePickerQuarterPicker,
  DatePickerTimeComparisonProps,
  DatePickerYearPicker,
} from "./DatePickerHeader";
import { FC, useState } from "react";
import { formatYear, getQuarterDate, resolveDate } from "@amenda-utils";

import { AvailableLanguages } from "@amenda-constants";
import ReactDatePicker from "react-datepicker";
import { Sizes } from "@amenda-styles/theme";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export interface DateQuarterPickerProps
  extends Pick<DatePickerTimeComparisonProps, "maxDate" | "minDate"> {
  label: string;
  id: string;
  value: string;
  disabled?: boolean;
  size?: keyof Sizes;
  className?: string;
  customInput?: any;
  hideIcon?: boolean;
  isClearable?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const DateQuarterPickerBase: FC<DateQuarterPickerProps> = ({
  id,
  size,
  value,
  label,
  disabled,
  className,
  hideIcon = false,
  isClearable = false,
  onChange,
  onBlur,
  ...rest
}) => {
  const { t } = useTranslation();
  const language = useAppStore((state) => state.language);
  const [showYearPicker, setShowYearPicker] = useState(false);

  const locales: Record<AvailableLanguages, string> = {
    [AvailableLanguages.English]: "en-GB",
    [AvailableLanguages.German]: "de-DE",
  };

  const handleChange = (date: Date | null) => {
    const value = date ? getQuarterDate(date) : "";
    onChange(value);
  };

  const clearDate = () => {
    if (!disabled) {
      onChange("");
    }
  };

  const onYearClick = () => {
    setShowYearPicker((prev) => !prev);
  };

  return (
    <div className="amenda-date-picker relative flex flex-grow items-stretch">
      <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
        {!hideIcon && (
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
      </div>
      <ReactDatePicker
        id={id}
        disabled={disabled}
        locale={locales[language]}
        selected={resolveDate(value)}
        onChange={handleChange}
        dateFormat="QQQ/yyyy"
        showQuarterYearPicker
        showYearPicker={showYearPicker}
        autoComplete="off"
        popperPlacement="top"
        popperClassName="!z-50"
        ariaLabelledBy={t(label)}
        className={clsx("w-full", className, {
          "pl-10": !hideIcon,
          "pl-2": hideIcon,
          "bg-gray-100": disabled,
        })}
        calendarClassName="w-90 shadow-lg !text-gray-400 bg-white px-4 py-1 !border-gray-300 !rounded-none"
        onCalendarClose={() => {
          onBlur && onBlur();
          setShowYearPicker(false);
        }}
        renderYearContent={(year: number) => (
          <DatePickerYearPicker value={value} year={year} {...rest} />
        )}
        renderQuarterContent={(quarter: number, shortQuarter: string) => (
          <DatePickerQuarterPicker
            value={value}
            quarter={quarter}
            shortQuarter={shortQuarter}
            {...rest}
          />
        )}
        renderCustomHeader={({ date, decreaseYear, increaseYear }: any) => (
          <DatePickerHeader
            year={formatYear(date)}
            showQuarterPicker={true}
            showYearPicker={showYearPicker}
            increaseYear={increaseYear}
            decreaseYear={decreaseYear}
            onYearClick={onYearClick}
          />
        )}
        {...rest}
      />
      {!!value && isClearable && (
        <div
          onClick={clearDate}
          className={clsx("absolute inset-y-0 right-0 flex items-center pr-3", {
            "cursor-pointer": !disabled,
          })}
        >
          <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};
