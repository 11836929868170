import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { HelperMessage, Spinner } from "@amenda-components/App";
import { decodeToken, getFullName, getQueryParams } from "@amenda-utils";
import { useActivateUser, useAppStore } from "@amenda-domains/mutations";

import { AvailableNotificationTypes } from "@amenda-types";
import { FC } from "react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { TextField } from "@amenda-components/FormComponents";
import { UpdateProfileSchema } from "@amenda-constants";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface UpdateProfileInput {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

export const ProfileForm: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const showNotification = useAppStore((state) => state.showNotification);
  const query = getQueryParams(location.search);
  const token: string = query.token;
  const { activateUser, loading } = useActivateUser();

  const { handleSubmit, control } = useForm<UpdateProfileInput>({
    resolver: yupResolver(UpdateProfileSchema),
  });
  const decoded = decodeToken(token);

  const handleUpdateUser = async ({
    confirmPassword,
    ...rest
  }: UpdateProfileInput) => {
    if (decoded.uid) {
      await activateUser({
        input: {
          ...rest,
          uid: decoded.uid,
          fullName: getFullName(rest?.firstName, rest?.lastName),
        },
      });
    }
  };

  const onSubmit: SubmitHandler<UpdateProfileInput> = async (data) => {
    try {
      handleUpdateUser(data);
    } catch (error: any) {
      showNotification(AvailableNotificationTypes.Error, error.message);
    }
  };

  if (!token) {
    return (
      <HelperMessage message="Token is invalid, click the link in your email." />
    );
  }
  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="mb-2">Hallo, {decoded.email}!</div>
      <div className="font-apercu">
        Wir freuen uns, dass Sie sich für amenda registrieren. amenda ist eine
        büroweite Projekt- und Medien-Datenbank! Die von Ihnen angegebenen Daten
        sind streng vertraulich und für Dritte nicht einsehbar.
      </div>
      <div className="bg-white py-8">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="firstName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="firstName"
                    placeholder="First Name"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              name="lastName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="lastName"
                    placeholder="Last Name"
                    autoComplete="family-name"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              name="password"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    error={error?.message}
                    value={value}
                    type="password"
                    onChange={onChange}
                    StartIcon={LockClosedIcon}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              name="confirmPassword"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="confirm-password"
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    error={error?.message}
                    value={value}
                    type="password"
                    onChange={onChange}
                    StartIcon={LockClosedIcon}
                  />
                );
              }}
            />
          </div>
          <div className="flex items-center">
            <input
              id="consent"
              name="consent"
              type="checkbox"
              required={true}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor="consent"
              className="ml-2 block text-sm text-gray-900"
            >
              {t(
                "By checking this box you confirm that you have read and agree to our Privacy Policy",
              )}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="newsletter"
              name="newsletter"
              type="checkbox"
              required={false}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor="newsletter"
              className="ml-2 block text-sm text-gray-900"
            >
              {t("I am interested in receiving a monthly Newsletter")}
            </label>
          </div>
          <div>
            <button type="submit" className="amenda-button">
              {loading && <Spinner spinnerSize="xs" variant="primary" />}
              {t("Update Profile")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
