import { AllowedContactType, Option } from "@amenda-types";
import { FC, ReactNode, useEffect, useState } from "react";
import { useGetContactsById, useGetUsersById } from "@amenda-domains/queries";

import { EmptyIndicator } from "./EmptyIndicator";
import { ProjectContactCard } from "@amenda-components/Contacts";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";
import { useTranslation } from "react-i18next";

interface BaseProps {
  userIds?: string[];
  contactType?: AllowedContactType;
  children: (users: any[]) => ReactNode;
}

export const ReadOnlySearchAndSelectBase: FC<BaseProps> = ({
  userIds,
  children,
  contactType,
}) => {
  const [users, setUsers] = useState<any[]>([]);
  const { getUsersById } = useGetUsersById();
  const { getContactsById } = useGetContactsById();

  useEffect(() => {
    if (userIds) {
      const cb =
        contactType === AllowedContactType.office
          ? getUsersById
          : getContactsById;
      cb(userIds, setUsers);
    } else {
      setUsers([]);
    }

    return () => {
      setUsers([]);
    };
  }, [userIds, contactType, getUsersById, getContactsById]);

  return <>{children(users)}</>;
};

interface Props {
  label: string;
  roles: Option[];
  userIds?: any[];
  contactType?: AllowedContactType;
}

const getSelectedRoles = (userId: string, userIds: any[] = []) => {
  return userIds.find((u) => u.contactId === userId)?.roles;
};

const getRoleLabels = (roles: Option[], selectedRoles?: string[]) => {
  return roles
    .filter((role) => selectedRoles?.includes(role.value))
    .map((role) => role.label);
};

export const ReadOnlySearchAndSelect: FC<Props> = ({
  label,
  roles,
  userIds,
  contactType,
}) => {
  const { t } = useTranslation();

  return (
    <ReadOnlySearchAndSelectBase
      userIds={userIds?.map((id: any) => id?.contactId ?? id)}
      contactType={contactType}
    >
      {(users) => (
        <>
          {users.length > 0 ? (
            <div>
              <p className="amenda-component-label">{t(label)}</p>
              <div className="mb-2 grid grid-cols-1">
                {users.map((user) => {
                  const selectedRoles = getSelectedRoles(user.id, userIds);

                  return (
                    <ProjectContactCard
                      key={user.id}
                      contact={user}
                      roles={getRoleLabels(roles, selectedRoles)}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <TitleAndDescription
              container="column"
              title={label}
              description={<EmptyIndicator />}
            />
          )}
        </>
      )}
    </ReadOnlySearchAndSelectBase>
  );
};
