import { FC, useState } from "react";
import { Modal } from "@amenda-components/App";
import { MicrosoftExchangeModalContent } from "./MicrosoftExchangeModalContent";

interface Integration {
  name: string;
  category: string;
  icon: string;
}

const integrations: Integration[] = [
  {
    name: "Microsoft Exchange",
    category: "Email",
    icon: "path/to/microsoft-exchange-icon.png",
  },
  {
    name: "Microsoft Teams",
    category: "Collaboration",
    icon: "path/to/microsoft-teams-icon.png",
  },
  { name: "Slack", category: "Messaging", icon: "path/to/slack-icon.png" },
  { name: "Notion", category: "Productivity", icon: "path/to/notion-icon.png" },
  {
    name: "Amenda CMS",
    category: "Content Management",
    icon: "path/to/amenda-cms-icon.png",
  },
];

export const Integrations: FC = () => {
  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (integration: Integration) => {
    setSelectedIntegration(integration);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedIntegration(null);
    setIsModalOpen(false);
  };

  return (
    <div className="font-apercu mt-4 flex h-[calc(100vh-11rem)] w-full flex-col">
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
        {integrations.map((integration) => (
          <div
            key={integration.name}
            onClick={() => openModal(integration)}
            className="flex cursor-pointer flex-col items-center border p-4 hover:bg-gray-100"
          >
            <img
              src={integration.icon}
              alt={`${integration.name} icon`}
              className="mb-2 h-10 w-10"
            />
            <h3 className="text-md font-medium">{integration.name}</h3>
            <p className="text-sm text-gray-500">{integration.category}</p>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isModalOpen}
        title={selectedIntegration?.name}
        message={`Configure settings for ${selectedIntegration?.name}`}
        onClose={closeModal}
        withCancel
        withSuccess
        size="xl"
        successLabel="Save"
        onSuccess={() => {
          // Handle saving integration configuration here
          closeModal();
        }}
      >
        {selectedIntegration?.name === "Microsoft Exchange" ? (
          <MicrosoftExchangeModalContent />
        ) : (
          <div>General content for other integrations</div>
        )}
      </Modal>
    </div>
  );
};
