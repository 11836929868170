import { AllowedCollectionType } from "@amenda-types";
import { MaterialsPageWrapper } from "./MaterialsPageWrapper";
import { useEffect } from "react";
import { useFormStore } from "@amenda-domains/mutations";

export const AllMaterialsCollection = () => {
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );

  const selectedCollection =
    selectedCollectionByType[AllowedCollectionType.Materials];

  useEffect(() => {
    return () => {
      clearSelectedCollectionByType(AllowedCollectionType.Materials);
    };
  }, [clearSelectedCollectionByType]);

  return (
    <MaterialsPageWrapper
      isCollection={true}
      autoSelect={true}
      materialResourceIds={selectedCollection?.resourceIds}
    />
  );
};
