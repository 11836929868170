import {
  BuildingOfficeIcon,
  HomeIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { FC, Fragment, useState } from "react";
import { Marker, Popup } from "react-mapbox-gl";

import { Image } from "@amenda-components/App";
import { RoundedVariants } from "@amenda-types";
import { isCoordinateValid } from "./common";
import { useNavigate } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";

interface PopupProps {
  project: any;
  openPopup: boolean;
}

const PopupComponent: FC<PopupProps> = ({ project, openPopup }) => {
  const navigate = useNavigate();
  const address = project?.address ?? project?.formValues?.address;
  const projectNumber = project?.formValues?.number;
  const projectDescription = project?.formValues?.description_short;

  if (!isCoordinateValid(address?.coordinates)) {
    return null;
  }
  return (
    <Popup
      coordinates={address?.coordinates}
      style={{ zIndex: 50 }}
      anchor="top"
    >
      <div
        className="flex h-48 w-48 cursor-pointer flex-col lg:h-64 lg:w-64"
        onClick={() => {
          if (openPopup) {
            navigate(`/projekte/${project?.id}`);
          }
        }}
      >
        <div className="w-full">
          <Image
            url={project?.galleryUrl}
            isFullWidth={!project?.galleryUrl}
            lazyLoad={true}
            size="pb-2/3"
            magicSize="gallery"
            rounded={RoundedVariants.none}
          />
        </div>
        <div className="w-full">
          <div className="mt-2 flex justify-between">
            <p className="truncate text-left text-sm text-gray-900 lg:text-lg">
              {projectNumber ?? "(" + { projectNumber } + ")"} {project?.name}
            </p>
          </div>
          <p className="truncate pt-[1px] text-left text-xs text-gray-500">
            {project?.address?.name}
          </p>
          <p className="line-clamp-2 text-left text-sm text-gray-500">
            {projectDescription}
          </p>
        </div>
      </div>
    </Popup>
  );
};

interface Props {
  showCentralPin?: boolean;
  officeAddress?: number[];
  projects: any[];
}

export const ProjectMarkers: FC<Props> = ({
  showCentralPin,
  officeAddress,
  projects,
}) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  return (
    <>
      {projects.map((project) => {
        const address = project?.address ?? project?.formValues?.address;

        return (
          <Fragment key={project.id}>
            {isCoordinateValid(address?.coordinates) && (
              <>
                <Marker
                  coordinates={address.coordinates}
                  onClick={() => {
                    if (selectedProject?.id !== project?.id) {
                      setSelectedProject(project);
                      setOpenPopup(true);
                      return;
                    }
                    setSelectedProject(project);
                    setOpenPopup(!openPopup);
                  }}
                >
                  <MapPinIcon
                    className="h-10 w-10 cursor-pointer text-black"
                    aria-hidden={true}
                  />
                </Marker>
                {openPopup && (
                  <PopupComponent
                    project={selectedProject}
                    openPopup={openPopup}
                  />
                )}
              </>
            )}
          </Fragment>
        );
      })}
      {showCentralPin && isCoordinateValid(officeAddress) && (
        <Marker coordinates={officeAddress as number[]}>
          <HomeIcon className="h-6 w-6 text-black" aria-hidden={true} />
        </Marker>
      )}
    </>
  );
};

export const UserMarkers: FC<{
  centerCoordinate?: number[];
}> = ({ centerCoordinate }) => {
  return (
    <>
      {centerCoordinate && isCoordinateValid(centerCoordinate) && (
        <Marker coordinates={centerCoordinate}>
          <BuildingOfficeIcon
            className="h-10 w-10 cursor-pointer text-black"
            aria-hidden={true}
          />
        </Marker>
      )}
    </>
  );
};

export const PinnedLocationMarker: FC<{ centerCoordinate?: number[] }> = ({
  centerCoordinate,
}) => {
  return (
    <>
      {centerCoordinate && isCoordinateValid(centerCoordinate) && (
        <Marker coordinates={centerCoordinate}>
          <MapPinIcon
            className="h-10 w-10 cursor-pointer text-blue-600"
            aria-hidden={true}
          />
        </Marker>
      )}
    </>
  );
};
