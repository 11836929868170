import { FC, useEffect, useState } from "react";
import { MaterialDetails, MaterialsLayout } from "@amenda-components/Materials";
import {
  MaterialList,
  MaterialListMobile,
} from "@amenda-components/Materials/MaterialList";
import { isMobile, useRunOnMountOnly } from "@amenda-utils";
import {
  useGetAllMaterials,
  useSearchMaterials,
} from "@amenda-domains/queries";
import { useNavigate, useParams } from "react-router-dom";

import { AppRoutes } from "@amenda-types";
import { ArrowLeft } from "lucide-react";
import { Button } from "@amenda-components/App";
import { PaginationLimit } from "@amenda-constants";
import isEmpty from "lodash/isEmpty";
import { useMaterialsStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  autoSelect: boolean;
  isCollection?: boolean;
  materialResourceIds?: string[];
}

export const MaterialsPageWrapper: FC<Props> = ({
  autoSelect,
  isCollection = false,
  materialResourceIds = [],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { materialId } = useParams<{ materialId: string }>();
  const [isMounted, setIsMounted] = useState(false);
  const { getAllMaterials } = useGetAllMaterials();
  const { searchMaterials } = useSearchMaterials();
  const groupComponents = useMaterialsStore(
    (state) => state.groupingComponents,
  );
  const searchTerm = useMaterialsStore((state) => state.searchTerm);
  const setOpenMaterialListSlideOver = useMaterialsStore(
    (state) => state.setOpenMaterialListSlideOver,
  );

  const groupByComponentIds = groupComponents.map((c) => c.id);

  const handleOpenSlideOver = () => {
    if (materialId) {
      navigate(AppRoutes.Materials);
    }
    setOpenMaterialListSlideOver(true);
  };

  const runOnMount = async () => {
    let args: any = {
      autoSelect,
      isCollection,
    };

    if (!isEmpty(searchTerm) || !isEmpty(groupByComponentIds)) {
      args = {
        ...args,
        searchTerm,
        groupByComponentIds,
      };

      if (isCollection) {
        args.match = {
          isDeleted: false,
          _id: {
            $in: materialResourceIds,
          },
        };
      }
      await searchMaterials(args);
    } else {
      args = {
        ...args,
        isDeleted: false,
        limit: PaginationLimit.materials,
      };

      if (isCollection) {
        args.ids = materialResourceIds;
      }
      await getAllMaterials(args);
    }
  };

  useRunOnMountOnly(
    runOnMount,
    JSON.stringify([isCollection, materialResourceIds]),
  );

  useEffect(() => {
    if (!isMounted && !materialId) {
      setIsMounted(true);
      if (isMobile(window)) {
        setOpenMaterialListSlideOver(true);
      }
    }
  }, [isMounted, materialId, setOpenMaterialListSlideOver]);

  return (
    <MaterialsLayout>
      <div className="flex h-full w-full flex-col pb-10 pt-8 md:pt-0">
        <div className="absolute left-0 top-0 z-20 flex w-full items-end bg-white px-2 pt-4 md:hidden">
          <Button onClick={handleOpenSlideOver} variant="default">
            <span className="mr-2 flex flex-row items-center">
              <ArrowLeft className="w-4" /> {t("All Materials")}
            </span>
          </Button>
        </div>
        <MaterialDetails />
      </div>
      <MaterialList isCollection={isCollection} />
      <MaterialListMobile isCollection={isCollection} />
    </MaterialsLayout>
  );
};
