import {
  ComponentDropdownSearch,
  SearchComponentItemProps,
} from "@amenda-components/SearchComponents/ComponentDropdownSearch";
import { FC, SyntheticEvent } from "react";
import { Option, PageComponentProps } from "@amenda-types";
import { useDashboardStore, useProjectStore } from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import clsx from "clsx";
import { getComponentsFromForms } from "@amenda-utils";
import { getFormNameById } from "@amenda-components/SearchComponents/common";
import { getNumericComponents } from "./common";
import { useTranslation } from "react-i18next";

type OptionType = PageComponentProps & Option;

const searchComponentItem =
  (widgetId = "", componentId?: string): FC<SearchComponentItemProps> =>
  ({ position, formLabel, options, close, clearSearchTerm }) => {
    const { t } = useTranslation();
    const updateWidgetProps = useDashboardStore(
      (state) => state.updateWidgetProps,
    );

    const handleClick =
      ({ value }: OptionType) =>
      (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!widgetId) return;
        updateWidgetProps(widgetId, {
          componentId: value,
        });
        clearSearchTerm();
        close();
      };

    return (
      <div className="w-full">
        <div
          className={clsx("w-full border-b pb-2 pl-3 text-sm text-gray-800", {
            "pt-4": position !== 0,
          })}
        >
          {formLabel}
        </div>
        {options.map((option) => {
          const isSelected = componentId === option.value;

          return (
            <button
              key={option.value}
              onClick={handleClick(option)}
              className={clsx(
                "flex w-full items-center justify-between py-2 pl-6 text-gray-700 hover:bg-gray-900 hover:text-white",
                {
                  "bg-gray-200 pr-4": isSelected,
                },
              )}
            >
              <span className="font-apercu text-sm">{t(option.label)}</span>
            </button>
          );
        })}
      </div>
    );
  };

export const ComponentPicker: FC<{
  widgetId: string;
  componentId?: string;
  disabled?: boolean;
}> = ({ widgetId, componentId, disabled }) => {
  const { t } = useTranslation();
  const forms = useProjectStore((state) => state.forms) || {};

  const costGroupForms = forms[AvailableForms.CostGroupsCost] || [];
  const { formNameById } = getFormNameById(costGroupForms);
  const components = getComponentsFromForms(costGroupForms);
  const numericComponents = getNumericComponents(components);
  const SearchComponentItem = searchComponentItem(widgetId, componentId);
  const code = numericComponents.find((c) => c.id === componentId)?.properties
    ?.code;

  return (
    <ComponentDropdownSearch
      iconClassName="h-2 w-2"
      className="px-1 text-xxs hover:bg-gray-900 hover:text-white"
      disabled={disabled}
      formNameById={formNameById}
      components={numericComponents}
      SearchComponentItem={SearchComponentItem}
      label={
        code
          ? t("Code", {
              code,
            })
          : "Add Parameter"
      }
    />
  );
};
