import { gql, useClient } from "urql";
import { useCallback, useState } from "react";

import { devConsole } from "@amenda-utils";
import { useTemplatesStore } from "@amenda-domains/mutations/templates";

const GET_ALL_TEMPLATES = gql`
  query {
    templates: getAllTemplates {
      id: _id
      type
      format
      name
      orientation
      structure
      createdAt
      updatedAt
    }
  }
`;

const GET_TEMPLATE = gql`
  query GetTemplate($id: ID) {
    template: getTemplate(_id: $id) {
      id: _id
      type
      format
      name
      orientation
      structure
      createdAt
      updatedAt
    }
  }
`;

const GET_PROJECT_TEMPLATE_DATA = gql`
  query GetProjectTemplateData($projectId: ID, $attachmentId: ID, $id: ID) {
    projectTemplateData: getProjectTemplateData(
      projectId: $projectId
      attachmentId: $attachmentId
      _id: $id
    ) {
      id: _id
      name
      values
      projectId
      templateId
      createdAt
      updatedAt
      thumbnailUrl
      attachmentId
    }
  }
`;

export const useGetAllTemplates = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setDefaultTemplates = useTemplatesStore(
    (state) => state.setDefaultTemplates,
  );

  const getTemplates = useCallback(async () => {
    setLoading(true);
    const { data } = await client.query(GET_ALL_TEMPLATES, {});
    if (data?.templates) {
      setDefaultTemplates(data.templates);
    }
    setLoading(false);
  }, [client, setDefaultTemplates]);

  return {
    loading,
    getTemplates,
  };
};

export const useGetProjectTemplateData = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedTemplateData = useTemplatesStore(
    (state) => state.setSelectedTemplateData,
  );

  const getProjectTemplateData = useCallback(
    async (variables: Record<string, any>) => {
      setLoading(true);
      const { data } = await client.query(GET_PROJECT_TEMPLATE_DATA, variables);

      if (data?.projectTemplateData) {
        setSelectedTemplateData(data?.projectTemplateData);
      }
      setLoading(false);
    },
    [client, setSelectedTemplateData],
  );

  return {
    loading,
    getProjectTemplateData,
  };
};

export const useFetchTemplateDataWithClient = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const defaultTemplates = useTemplatesStore((state) => state.defaultTemplates);
  const setSelectedTemplateData = useTemplatesStore(
    (state) => state.setSelectedTemplateData,
  );
  const setFormTemplateProperties = useTemplatesStore(
    (state) => state.setFormTemplateProperties,
  );
  const setSelectedTemplate = useTemplatesStore(
    (state) => state.setSelectedTemplate,
  );
  const updateTemplateImage = useTemplatesStore(
    (state) => state.updateTemplateImage,
  );

  const fetchTemplateData = useCallback(
    async (prop: {
      attachmentId?: string;
      templateDataId?: string;
      projectId?: string;
    }) => {
      const { attachmentId } = prop;
      try {
        setLoading(true);
        const response = await client.query(GET_PROJECT_TEMPLATE_DATA, {
          attachmentId,
        });
        const { data } = response;
        if (data) {
          const templateValues = data?.projectTemplateData?.values || {};
          Object.keys(templateValues).forEach((key) => {
            updateTemplateImage(key, templateValues[key]?.properties?.src);
          });
          const selectedTemplate = [...defaultTemplates].find(
            (template) =>
              template?.id === data?.projectTemplateData?.templateId,
          );
          setSelectedTemplateData(data?.projectTemplateData);
          setFormTemplateProperties(templateValues);
          setSelectedTemplate(selectedTemplate);
        }
        setLoading(false);
      } catch (err) {
        devConsole?.error("amenda:something went wrong", err);
        setLoading(false);
      }
    },
    [
      client,
      defaultTemplates,
      setSelectedTemplateData,
      setFormTemplateProperties,
      setSelectedTemplate,
      updateTemplateImage,
    ],
  );

  return {
    loading,
    fetchTemplateData,
  };
};

export const useGetTemplate = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedTemplate = useTemplatesStore(
    (state) => state.setSelectedTemplate,
  );

  const getTemplate = useCallback(
    async (variables: Record<string, any>) => {
      setLoading(true);
      const { data } = await client.query(GET_TEMPLATE, variables);

      if (data?.template) {
        setSelectedTemplate(data.template);
      }
      setLoading(false);
    },
    [client, setSelectedTemplate],
  );

  return {
    loading,
    getTemplate,
  };
};

export const useGetDisplayedTemplates = () => {
  const defaultTemplates = useTemplatesStore((state) => state.defaultTemplates);
  const userTemplates = useTemplatesStore((state) => state.userTemplates);

  const userTemplateIds = userTemplates.map(
    (template: any) => template.templateId,
  );
  const remainingDefaults = defaultTemplates.filter(
    (template) => !userTemplateIds.includes(template.id),
  );
  const displayedTemplates = [...remainingDefaults, ...userTemplates];

  return {
    displayedTemplates,
    userTemplates,
    defaultTemplates,
  };
};
