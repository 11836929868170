import * as Yup from "yup";

import { ChangeEvent, FC } from "react";
import {
  Control,
  Controller,
  UseFieldArrayRemove,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { PlusIcon, XIcon } from "lucide-react";

import { EmptyIndicator } from "@amenda-components/Shared";
import { IconButtonBase } from "@amenda-components/App";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { PhoneInputBase } from "./PhoneInputBase";
import { ReadOnlyLabelledInputCard } from "./LabelledInput";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";

interface InputProps {
  id: string;
  value?: string;
  autoFocus?: boolean;
  className?: string;
  onChange: (value: string) => void;
}

const Input: FC<InputProps> = ({
  id,
  className,
  value = "",
  autoFocus = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const onInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  return (
    <input
      id={id}
      autoFocus={autoFocus}
      autoComplete="off"
      placeholder={t("Enter value and hit save")}
      className={className}
      value={value}
      onChange={onInputChange}
    />
  );
};

const isFieldValid = (type: "email" | "phone", value: string) => {
  if (type === "email") {
    return Yup.string().email().isValidSync(value);
  } else if (!value || value.length <= 4) {
    return true;
  }
  return isValidPhoneNumber(value);
};

const ReadOnlyLabelledInput: FC<Props> = ({
  id,
  label,
  values,
  isFullWidth,
  ...rest
}) => {
  const { t } = useTranslation();
  const allValues = Array.isArray(values) ? values : [];

  return (
    <div className="w-full">
      <label htmlFor={id} className="amenda-component-label">
        {t(label)}
      </label>
      <div
        className={clsx("mt-1 grid w-full grid-cols-1 gap-3", {
          "xl:grid-cols-2": !isFullWidth,
        })}
      >
        <ReadOnlyCards values={allValues} {...rest} />
      </div>
    </div>
  );
};

interface UpsertCardProps extends Pick<Props, "type" | "options"> {
  id: string;
  index: number;
  remove: UseFieldArrayRemove;
  control: Control<any>;
}

const UpsertCard: FC<UpsertCardProps> = ({
  id,
  index,
  type,
  options,
  remove,
  control,
}) => {
  const value = useWatch({
    control,
    name: `${id}.${index}.value`,
  });
  const fieldId = `${id}.${index}`;

  return (
    <div className="hover:group-hover group w-full flex-col">
      <div
        className={clsx(
          "h-[60px] shrink-0 border border-gray-300 py-[10px] pl-[15px] pr-[8px] group-focus-within:border-gray-500",
          { "border-red-300": !isFieldValid(type, value) },
        )}
      >
        <div className="flex h-full flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col items-start">
              <div className="w-full">
                <Controller
                  control={control}
                  name={fieldId + ".label"}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <OperationsMenu
                        label="Select label"
                        menuItemsClassName="z-50"
                        options={options}
                        selectedOption={value}
                        labelClassName="text-xs text-gray-500"
                        onChange={(option) => onChange(option.value ?? "")}
                        hasMenuOverflow={true}
                      />
                    );
                  }}
                />
              </div>
              <Controller
                control={control}
                name={fieldId + ".value"}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      {type === "phone" ? (
                        <PhoneInputBase
                          id={id}
                          value={value}
                          className="w-64"
                          inputClassName="text-sm text-gray-900 placeholder:text-sm placeholder:text-gray-400 p-0 focus:outline-none focus:ring-0"
                          onChange={onChange}
                        />
                      ) : (
                        <Input
                          id={id}
                          value={value}
                          className="w-64 border-0 p-0 text-sm text-gray-900 outline-none placeholder:text-sm placeholder:text-gray-400 focus:ring-0"
                          onChange={onChange}
                        />
                      )}
                    </>
                  );
                }}
              />
            </div>
          </div>
          <IconButtonBase
            label="Delete item"
            size="xss"
            className="!p-0 text-gray-400 hover:text-red-600"
            onClick={() => remove(index)}
          >
            <XIcon className="h-4 w-4 stroke-[1.5]" />
          </IconButtonBase>
        </div>
      </div>
    </div>
  );
};

interface EditModeLabelledInputProps extends Props {
  control: Control<any>;
}

const EditModeLabelledInput: FC<EditModeLabelledInputProps> = ({
  id,
  label,
  control,
  isFullWidth = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { fields, remove, append } = useFieldArray({
    control,
    name: id,
  });

  return (
    <div className="w-full">
      <div className="flex w-full items-center gap-1 overflow-x-hidden truncate">
        <label htmlFor={id} className="amenda-component-label">
          {t(label)}
        </label>
      </div>
      <div
        className={clsx("mt-1 grid w-full grid-cols-1 gap-3", {
          "xl:grid-cols-2": !isFullWidth,
        })}
      >
        {fields.map((field, index) => {
          return (
            <UpsertCard
              id={id}
              key={field.id}
              index={index}
              control={control}
              remove={remove}
              {...rest}
            />
          );
        })}
        <div className="flex items-center">
          <IconButtonBase
            size="xss"
            variant="round"
            className="bg-blue-600 text-white hover:bg-blue-500 hover:text-white"
            onClick={() => append({})}
          >
            <PlusIcon className="h-4 w-4" />
          </IconButtonBase>
        </div>
      </div>
    </div>
  );
};

interface Props {
  id: string;
  label: string;
  values?: {
    label: string;
    value: string;
  }[];
  type: "email" | "phone";
  isReadOnly?: boolean;
  isDisabled?: boolean;
  error?: string;
  control?: Control<any>;
  isFullWidth?: boolean;
  options: any[];
}

type ReadOnlyCardsProps = Pick<Props, "type" | "values">;

const ReadOnlyCards: FC<ReadOnlyCardsProps> = ({ type, values = [] }) => {
  if (isEmpty(values)) {
    return <EmptyIndicator />;
  }
  return (
    <>
      {values.map(({ label, value }) => (
        <ReadOnlyLabelledInputCard
          key={`${value}_${label}`}
          type={type}
          label={label}
          value={value}
        />
      ))}
    </>
  );
};

export const LabelledInputAlt: FC<Props> = (props) => {
  const { control, isReadOnly, ...rest } = props;

  if (isReadOnly || !control) {
    return <ReadOnlyLabelledInput {...rest} />;
  }
  return <EditModeLabelledInput control={control} {...rest} />;
};
