import { FormComponentTypes } from "./forms";
import { LogicalOperators } from "@amenda-types";

export const SimilaritySearchLimit = 3;
export const RouteBasedPermissionKey = "routeBased";
export const CostGroupComponentStaticId = "CostGroupComponentStaticId";
export const CostGroupPermissionPath = "costGroups.allFields";
export const PermissionComponentKey = "permissionComponents";

export enum AvailableLanguages {
  English = "en",
  German = "de",
}

export enum DebounceTimes {
  Search = 350,
  Autosave = 400,
}

export enum UID_SIZES {
  sm = 12,
  lg = 28,
}

export enum SearchCollections {
  Projects = "projects",
  Users = "users",
  Contacts = "contacts",
  Attachments = "attachments",
  Materials = "materials",
  RegionalFactors = "regional_factors",
  SystemRoles = "system_roles",
}

export const languageOptions = [
  {
    value: "no_selection",
    label: "No selection",
    unavailable: true,
  },
  {
    value: AvailableLanguages.English,
    label: "English",
  },
  {
    value: AvailableLanguages.German,
    label: "German",
  },
];

export const stagesColorOptions = [
  {
    value: "no_selection",
    label: "No Selection",
  },
  {
    value: "blue",
    label: "Blue",
  },
  {
    value: "emerald",
    label: "Emerald",
  },
  {
    value: "pink",
    label: "Pink",
  },
  {
    value: "gray",
    label: "Gray",
  },
  {
    value: "indigo",
    label: "Indigo",
  },
  {
    value: "red",
    label: "Red",
  },
  {
    value: "white",
    label: "White",
  },
  {
    value: "yellow",
    label: "Yellow",
  },
  {
    value: "Green",
    label: "green",
  },
];

export const PaginationLimit = {
  projects: 20,
  attachments: 50,
  regionalFactors: 100,
  searchResults: 10,
  dashboardPreviews: 5,
  app: 10,
  portfolios: 20,
  contacts: 50,
  timeline: 150,
  materials: 50,
  systemRoles: 50,
};

export const PdfTemplateScales: Record<string, number> = {
  a3: 0.4,
  a4: 0.4,
};

export const searchBadgeOptions = [
  { value: "projects", label: "Projects", route: "/projekte" },
  { value: "portfolio", label: "Portfolios", route: "/portfolio" },
  { value: "contacts", label: "Contacts", route: "/kontaktverzeichnis" },
];

export enum ApiFilterTypes {
  MetaData = "metadata",
  Keywords = "keywords",
  Values = "values",
}

export interface SidebarFilters {
  value: any;
  filterType: ApiFilterTypes;
  isNumberInputField: boolean;
  operation?: LogicalOperators;
  componentType: FormComponentTypes;
}

export enum FormIds {
  ProjectForm = "projectDynamicForm",
}

export enum AllowedCostGroupTypes {
  "Frame" = "Frame",
  "Rollover" = "Rollover",
  "Estimation" = "Estimate",
  "Statement" = "Statement",
  "Calculation" = "Calculation",
}

export enum GalleryModeTypes {
  List = "list",
  Slider = "slider",
}

export enum AllowedWidgetTypes {
  Dashboard = "dashboard",
  Project = "project",
}

export enum InvitationType {
  Email = "email",
  Silent = "silent",
}

export enum GeneralAccessTypes {
  Private = "private",
  Limited = "limited",
  Everyone = "everyone",
}

export const projectShareOptions = [
  {
    value: GeneralAccessTypes.Private,
    label: "Not shared",
    description: "This object is private and not shared",
  },
  {
    value: GeneralAccessTypes.Limited,
    label: "Limited",
    description: "Only share to the above contacts",
  },
  {
    value: GeneralAccessTypes.Everyone,
    label: "Everyone from Organization",
    description: "Share to everyone from your organization",
  },
];

export const getLogicalOperators = (isProject: boolean) => {
  let operators = [
    { value: LogicalOperators.AND, label: "and" },
    { value: LogicalOperators.OR, label: "or" },
  ];

  if (isProject) {
    operators = [...operators, { value: LogicalOperators.NOT, label: "not" }];
  }
  return [...operators, { value: LogicalOperators.RESET, label: "reset" }];
};
