import { useProjectStore, useUsersStore } from "@amenda-domains/mutations";

import { AllowedContactType } from "@amenda-types";
import { FC } from "react";
import { UserSliderOverBase } from "./UserSlideOverBase";

export const PersonSlideOver: FC = () => {
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const openPersonSlideOver = useUsersStore(
    (state) => state.openPersonSlideOver,
  );
  const setOpenPersonSlideOver = useUsersStore(
    (state) => state.setOpenPersonSlideOver,
  );

  const forms = formsByContactType[AllowedContactType.person];

  return (
    <UserSliderOverBase
      isOpen={openPersonSlideOver}
      handleClose={() => setOpenPersonSlideOver(false)}
      type={AllowedContactType.person}
      title="New Contact Person"
      description="Please enter the Contact Person Information"
      forms={forms}
    />
  );
};
