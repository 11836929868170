import { gql, useClient } from "urql";
import { useCallback, useState } from "react";

import { IMAGE_DESCRIPTOR_FRAGMENT } from "@amenda-domains/fragments/descriptors";
import { PaginationQueryProps } from "@amenda-types";
import { useImageRecognitionStore } from "@amenda-domains/mutations";

export const GET_ALL_IMAGE_DESCRIPTORS = gql`
  ${IMAGE_DESCRIPTOR_FRAGMENT}
  query GetAllImageDescriptors(
    $limit: Int
    $type: ImageDescriptorType
    $next: String
    $previous: String
    $selectImageDescriptorFields: Object
  ) {
    getAllImageDescriptors(
      type: $type
      limit: $limit
      next: $next
      previous: $previous
      selectImageDescriptorFields: $selectImageDescriptorFields
    ) {
      next
      docsCount
      previous
      hasNext
      hasPrevious
      imageDescriptors {
        ...ImageDescriptorFragment
      }
    }
  }
`;

interface GetAllImageDescriptors extends PaginationQueryProps {
  type: string;
  selectImageDescriptorFields?: any;
}

export const useGetAllImageDecorators = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setFaceImageDescriptors = useImageRecognitionStore(
    (state) => state.setFaceImageDescriptors,
  );

  const getAllImageDescriptors = useCallback(
    async ({ ...variables }: GetAllImageDescriptors) => {
      setLoading(true);

      const { data } = await client
        .query(GET_ALL_IMAGE_DESCRIPTORS, variables)
        .toPromise();

      if (data?.getAllImageDescriptors?.imageDescriptors) {
        setFaceImageDescriptors(data.getAllImageDescriptors.imageDescriptors);
      }
      setLoading(false);
      return data?.getAllImageDescriptors?.imageDescriptors || [];
    },
    [client, setFaceImageDescriptors],
  );

  return {
    loading,
    getAllImageDescriptors,
  };
};
