import { Avatar, HelperMessage, IconButton } from "@amenda-components/App";
import {
  CurrencyEuroIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { DeleteModal, EmptyIndicator } from "@amenda-components/Shared";
import { FC, useEffect, useState } from "react";
import { formatDate, formatNumbers } from "@amenda-utils";

import { TitleAndDescription } from "@amenda-components/PdfBuilder";
import { UseFieldArrayRemove } from "react-hook-form";
import { getMaterialSourceOption } from "./common";
import { getUserName } from "@amenda-components/Contacts/common";
import { isEmpty } from "lodash";
import { useGetUsersById } from "@amenda-domains/queries";

interface Props {
  index?: number;
  source: string;
  total_price: number;
  ownerId?: string;
  indication_year?: string;
  total_purchase_quantity: number;
  showActions?: boolean;
  setEditing?: (isEditing: boolean) => void;
  remove?: UseFieldArrayRemove;
  onBlur?: () => void;
}

export const MaterialReadOnlyUnitPriceCard: FC<Props> = ({
  index,
  source,
  ownerId,
  total_price,
  indication_year,
  total_purchase_quantity,
  showActions = false,
  remove,
  onBlur,
  setEditing,
}) => {
  const [openCostDeletionModal, setOpenCostDeletionModal] = useState(false);
  const { getUsersById } = useGetUsersById();
  const [user, setUser] = useState<any>();

  const selectedSource = getMaterialSourceOption(source);
  const totalCost = total_price * total_purchase_quantity;

  const handleCloseCostDeletionModal = () => setOpenCostDeletionModal(false);
  const handleOpenCostDeletionModal = () => setOpenCostDeletionModal(true);

  const handleEdit = () => setEditing?.(true);

  const handleDelete = () => {
    remove?.(index);
    onBlur?.();
  };

  useEffect(() => {
    if (ownerId) {
      const handleSelectUser = (users: any) => {
        setUser(users[0]);
      };

      getUsersById([ownerId], handleSelectUser);
    }
  }, [ownerId, getUsersById]);

  return (
    <div className="group/labelledInput mb-8 flex w-full flex-col">
      <DeleteModal
        openModal={openCostDeletionModal}
        title="Delete cost entry"
        description="Are you sure you want to delete this cost entry?"
        handleDelete={handleDelete}
        handleCloseModal={handleCloseCostDeletionModal}
      />
      <div className="min-h-16 shrink-0 border bg-white px-6 py-4 group-focus-within/labelledInput:border-gray-700">
        {showActions && (
          <div className="flex items-center justify-end space-x-1">
            <IconButton
              label="Delete item"
              iconSize={1}
              className="flex items-center justify-center bg-white text-gray-900 hover:bg-gray-900 hover:text-white"
              Icon={TrashIcon}
              onClick={handleOpenCostDeletionModal}
            />
            <IconButton
              label="Edit item"
              className="flex items-center justify-center bg-white text-gray-900 hover:bg-gray-900 hover:text-white"
              iconSize={1}
              Icon={PencilIcon}
              onClick={handleEdit}
            />
          </div>
        )}
        <div className="grid grid-cols-3 items-end gap-x-1 gap-y-2 xl:grid-cols-5">
          <div className="col-span-3 md:col-span-1">
            <TitleAndDescription
              container="column"
              title="Einheitspreis"
              description={
                total_price ? (
                  `${formatNumbers(total_price)} €`
                ) : (
                  <EmptyIndicator />
                )
              }
            />
          </div>
          <div className="col-span-3 md:col-span-1">
            <TitleAndDescription
              container="column"
              title="Gesamt Abnahmemenge"
              description={
                total_purchase_quantity ? (
                  formatNumbers(total_purchase_quantity)
                ) : (
                  <EmptyIndicator />
                )
              }
            />
          </div>
          <div className="col-span-3 md:col-span-1">
            <TitleAndDescription
              container="column"
              title="Gesamtpreis"
              description={
                totalCost ? formatNumbers(totalCost) + " €" : <EmptyIndicator />
              }
            />
          </div>
          <div className="col-span-3 md:col-span-1">
            <TitleAndDescription
              container="column"
              title="Ermittlungsdatum (Angabe aus dem Jahr)"
              description={
                indication_year ? (
                  formatDate(indication_year)
                ) : (
                  <EmptyIndicator />
                )
              }
            />
          </div>
          <div className="col-span-3 md:col-span-1">
            <TitleAndDescription
              container="column"
              title="Quelle"
              description={
                selectedSource ? selectedSource.label : <EmptyIndicator />
              }
            />
          </div>
        </div>
        {user && (
          <div className="flex items-center space-x-2 pb-2 pt-4">
            <Avatar
              name={getUserName(user)}
              src={user?.photoURL}
              className="h-12 w-12"
            />
            <span className="truncate text-sm text-gray-900">
              {getUserName(user)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const MaterialReadOnlyUnitPrices: FC<{
  values: Props[];
}> = ({ values }) => {
  if (isEmpty(values)) {
    return (
      <div className="mt-1 w-full">
        <HelperMessage
          Icon={CurrencyEuroIcon}
          message="No costs associated to this material found yet"
        />
      </div>
    );
  }
  return (
    <div className="mt-1 w-full">
      {values.map((value, i) => (
        <MaterialReadOnlyUnitPriceCard key={`card_${i}`} {...value} />
      ))}
    </div>
  );
};
