import { FC, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import { AddToCollection } from "./AddToCollection";
import { AllowedCollectionType } from "@amenda-types";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { CreateCollectionModal } from "./CreateCollectionModal";
import { Spinner } from "@amenda-components/App";
import { buttonTheme } from "@amenda-styles/theme";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  label?: string;
  showDelete?: boolean;
  isCollection?: boolean;
  isDeleting?: boolean;
  resourceIds: string[];
  collectionRoute: string;
  collectionType: AllowedCollectionType;
  getResourceIds: (args: any) => Promise<string[]>;
  handleDeleteFromCollection: () => void;
}

export const CollectionMenu: FC<Props> = ({
  label,
  isDeleting,
  showDelete = true,
  isCollection = false,
  handleDeleteFromCollection,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const clearSelectedUsers = useUsersStore((state) => state.clearSelectedUsers);

  return (
    <div className="flex">
      <CreateCollectionModal
        {...rest}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        clearSelectedResources={clearSelectedUsers}
      />
      <AddToCollection
        {...rest}
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        clearSelectedResources={clearSelectedUsers}
      />
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <MenuButton
              className={buttonTheme({
                size: "xs",
                variant: "default",
                className:
                  "group flex items-center border border-gray-200 hover:border-gray-900",
              })}
            >
              <span className="ml-1">{t("Select Action")}</span>
              {isDeleting ? (
                <Spinner
                  className="group-hover:border-white"
                  variant="default"
                  spinnerSize="xs"
                />
              ) : (
                <ChevronRightIcon
                  className={clsx("ml-1 h-4 w-4", {
                    "rotate-90 transform": open,
                  })}
                />
              )}
            </MenuButton>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                static
                className="absolute right-0 z-50 mt-1 w-60 origin-top-right bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:left-0 md:origin-top-left"
              >
                {!isCollection && (
                  <>
                    <MenuItem>
                      {({ focus }) => (
                        <button
                          onClick={() => setIsOpen(true)}
                          className={clsx(
                            "group flex w-full items-center px-2 py-2 text-xs",
                            {
                              "bg-white text-gray-800": !focus,
                              "bg-gray-900 text-white": focus,
                            },
                          )}
                        >
                          {t("Add to new collection")}
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ focus }) => (
                        <button
                          onClick={() => setIsEditOpen(true)}
                          className={clsx(
                            "group flex w-full items-center px-2 py-2 text-xs",
                            {
                              "bg-white text-gray-800": !focus,
                              "bg-gray-900 text-white": focus,
                            },
                          )}
                        >
                          {t("Add to existing collection")}
                        </button>
                      )}
                    </MenuItem>
                  </>
                )}
                {showDelete && (
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        onClick={handleDeleteFromCollection}
                        className={clsx(
                          "group flex w-full items-center px-2 py-2 text-xs",
                          {
                            "bg-white text-gray-800": !focus,
                            "bg-gray-900 text-white": focus,
                          },
                        )}
                      >
                        {t(!isCollection ? "Delete" : "Remove from collection")}
                      </button>
                    )}
                  </MenuItem>
                )}
              </MenuItems>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
